/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ENUM_COMPONENTCONFIGURATIONCONFIGURATION_NAME {
  candidacyUpdate = "candidacyUpdate",
  contactOffers = "contactOffers",
  news = "news",
}

export enum ENUM_JOBPOSITION_STATE {
  active = "active",
  closed = "closed",
  draft = "draft",
  invisible = "invisible",
  slot = "slot",
}

export enum ENUM_JOBPOSITION_WHERE_TO_SEARCH {
  PermisoUE = "PermisoUE",
  Spain = "Spain",
  TodoElMundo = "TodoElMundo",
}

export enum ENUM_REPORTS_REASON {
  CourseEval = "CourseEval",
  Duda = "Duda",
  Error = "Error",
  QuestionError = "QuestionError",
  Sugerencia = "Sugerencia",
}

export enum ENUM_USERJOBRESOLUTION_CANCELREASON {
  company = "company",
  location = "location",
  other = "other",
  role = "role",
  salary = "salary",
  tech = "tech",
}

export enum ENUM_USERSPERMISSIONSUSER_ENGLISHLEVEL {
  A1 = "A1",
  A2 = "A2",
  B1 = "B1",
  B2 = "B2",
  C1 = "C1",
  C2 = "C2",
}

export interface ActivePaths {
  pathId: string;
  preLevel?: string | null;
}

export interface AddCVExperienceInput {
  empresa?: string | null;
  puesto?: string | null;
  funciones?: string | null;
  inicio?: any | null;
  fin?: any | null;
  skills?: (string | null)[] | null;
}

export interface AddCareerInput {
  careerId: string;
  experienceYears?: number | null;
  activePaths?: (ActivePaths | null)[] | null;
  activeItineraries?: (string | null)[] | null;
  isMain: boolean;
}

export interface ApplyOfferData {
  name?: string | null;
  userPathId?: string | null;
  careerId: string;
  pathId: string;
  level?: string | null;
  preLevel?: string | null;
}

export interface ApplyOfferInput {
  where: InputID;
  data: (ApplyOfferData | null)[];
}

export interface BasicCities {
  label?: string | null;
  value: string;
}

export interface BasicCountries {
  label?: string | null;
  value: string;
}

export interface BasicProfession {
  professionId: string;
  experienceYears: number;
  subProfessions?: string[] | null;
}

export interface EditCareerInput {
  experienceYears?: number | null;
  activePaths?: (ActivePaths | null)[] | null;
  activeItineraries?: (string | null)[] | null;
}

export interface EditExperienceInput {
  empresa?: string | null;
  puesto?: string | null;
  funciones?: string | null;
  inicio?: any | null;
  fin?: any | null;
  skills?: (string | null)[] | null;
}

export interface InitialicePathLevelInput {
  where: InputID;
}

export interface InputID {
  id: string;
}

export interface PathGroups {
  paths?: (string | null)[] | null;
}

export interface QuizAnswers {
  questionId: string;
  optionIds: (string | null)[];
}

export interface UpdateCareerInput {
  where?: InputID | null;
  data?: EditCareerInput | null;
}

export interface UpdateCareerMainInput {
  where?: InputID | null;
}

export interface UpdateJobInput {
  where?: InputID | null;
  data?: addJobPositionInput | null;
}

export interface UsersPermissionsLoginInput {
  identifier: string;
  password: string;
  provider?: string | null;
}

export interface UsersPermissionsRegisterInput {
  username: string;
  email: string;
  password: string;
  CIF?: string | null;
  razonSocial?: string | null;
  comercialName?: string | null;
  countryResidence?: string | null;
  continent?: string | null;
  referred_from_num?: string | null;
  pathId?: string | null;
  levelId?: string | null;
}

export interface addJobPositionInput {
  salaryMin: number;
  salaryMax?: number | null;
  restrictedCountries: (BasicCountries | null)[];
  restrictedCities: (BasicCities | null)[];
  description: string;
  name: string;
  pathGroups: (PathGroups | null)[];
  profession: BasicProfession;
  where_to_search: ENUM_JOBPOSITION_WHERE_TO_SEARCH;
  isSlot: boolean;
  companyType?: string | null;
}

export interface deleteUserCareerInput {
  where?: InputID | null;
}

export interface editComponentConfigurationConfigurationInput {
  id?: string | null;
  mass_medias?: (string | null)[] | null;
  name?: ENUM_COMPONENTCONFIGURATIONCONFIGURATION_NAME | null;
}

export interface editComponentMessagingConfigurationMessagingConfigurationInput {
  id?: string | null;
  configuration?: (editComponentConfigurationConfigurationInput | null)[] | null;
}

export interface editUserInput {
  username?: string | null;
  email?: string | null;
  provider?: string | null;
  password?: string | null;
  resetPasswordToken?: string | null;
  confirmationToken?: string | null;
  confirmed?: boolean | null;
  blocked?: boolean | null;
  role?: string | null;
  name?: string | null;
  surname?: string | null;
  phoneNumber?: string | null;
  linkedinURL?: string | null;
  githubURL?: string | null;
  cv?: string | null;
  desiredMinSalary?: number | null;
  desiredCompanyTypes?: (string | null)[] | null;
  desiredLocations?: (string | null)[] | null;
  studies?: (string | null)[] | null;
  careers?: (string | null)[] | null;
  hasBetaAccess?: boolean | null;
  courses?: (string | null)[] | null;
  quizzes?: (string | null)[] | null;
  englishLevel?: ENUM_USERSPERMISSIONSUSER_ENGLISHLEVEL | null;
  desiredCareers?: (string | null)[] | null;
  allowReceiveJobPositions?: boolean | null;
  blockedCompanies?: (string | null)[] | null;
  jobResolutions?: (string | null)[] | null;
  isJobAdmin?: boolean | null;
  onboarding?: boolean | null;
  professions?: (string | null)[] | null;
  downloadable_file?: string | null;
  user_job_applications?: (string | null)[] | null;
  workshops?: (string | null)[] | null;
  experienceYearsTotal?: number | null;
  user_cv?: string | null;
  completed_profile?: boolean | null;
  job_notifications?: (string | null)[] | null;
  referral_num?: string | null;
  referred_from_num?: string | null;
  onTour?: boolean | null;
  continent?: string | null;
  desiredCities?: (string | null)[] | null;
  company?: string | null;
  desiredCountries?: (string | null)[] | null;
  photo?: string | null;
  countryResidence?: string | null;
  notifications?: (string | null)[] | null;
  lastActive?: any | null;
  messagingConfiguration?: editComponentMessagingConfigurationMessagingConfigurationInput | null;
  uuid?: string | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface updateExperienceInput {
  where?: InputID | null;
  data?: EditExperienceInput | null;
}

export interface updateUserInput {
  where?: InputID | null;
  data?: editUserInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
