import { useEffect, useLayoutEffect, useState } from 'react';

interface Props {
  errors: any;
  formEl: HTMLFormElement | null;
  isSubmitting: boolean;
}

export const ScrollToFirstError = ({ errors, formEl, isSubmitting }: Props) => {
  const [shouldScroll, setShouldScroll] = useState(true);

  useEffect(() => {
    setShouldScroll(true);
  }, [isSubmitting, setShouldScroll]);

  useLayoutEffect(() => {
    if (errors && formEl) {
      const firstErrorEl = formEl.querySelector<HTMLLabelElement>('.has-error');

      if (firstErrorEl && shouldScroll) {
        firstErrorEl.scrollIntoView();
        firstErrorEl.focus();
        setShouldScroll(false);
      }
    }
  }, [errors, formEl, shouldScroll, setShouldScroll]);

  return null;
};
