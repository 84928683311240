import { Me_meExtended } from 'graphql/generated/Me';
import { WithoutNulls } from 'utils/removeNulls';
import { normalizeCompanyTypes } from 'model/CompanyType';
import { normalizeStudies } from 'model/Study';
import { normalizeLocations } from 'model/Location';
import { normalizeCities } from './City';

const getFullName = ({ name, surname, email }: WithoutNulls<Me_meExtended>) => {
  if (name || surname) {
    return [name, surname].join(' ');
  }
  return email;
};

export const normalizeMe = (input: WithoutNulls<Me_meExtended>) => {
  return {
    id: input.id,
    uuid: input.uuid,
    confirmed: input.confirmed || false,
    blocked: input.blocked || false,
    hasBetaAccess: input.hasBetaAccess || false,
    isJobAdmin: input.isJobAdmin || false,
    username: input.username,
    professions: input.professions,
    completed_profile: input.completed_profile,
    name: input.name,
    surname: input.surname,
    email: input.email,
    fullName: getFullName(input),
    phoneNumber: input.phoneNumber,
    onboarding: input.onboarding,
    careers: input.careers,
    studies: normalizeStudies(input.studies || []),
    linkedinURL: input.linkedinURL,
    githubURL: input.githubURL,
    cv: { url: input.cv?.url || undefined, name: input.cv?.name },
    user_cv: input.user_cv,
    englishLevel: input.englishLevel,
    company: input.company,
    lastActive: input.lastActive,
    messagingConfiguration: input.messagingConfiguration?.configuration,
    desiredMinSalary: input.desiredMinSalary,
    desiredLocations: normalizeLocations(input.desiredCities || []),
    desiredCities: normalizeCities(input.desiredCities || []),
    desiredCountries: input.desiredCountries || [],
    desiredCompanyTypes: normalizeCompanyTypes(input.desiredCompanyTypes || []),
    desiredCareers: (input.desiredCareers || []).reduce<String[]>(
      (acum, career) => (!!career ? acum.concat(career.id) : acum),
      []
    ),
    job_notifications: input.job_notifications,
    referral_num: input.referral_num,
    referred_from_num: input.referred_from_num,
    allowReceiveJobPositions: input.allowReceiveJobPositions || false,
    countryResidence: input.countryResidence,
    continent: input.continent || undefined,
    updated_at: input.updated_at,
    photo: input.photo,
  };
};

export type Me = ReturnType<typeof normalizeMe>;
