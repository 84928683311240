import { MeCareerParts_activeItineraries_itinerary_workshops } from "graphql/generated/MeCareerParts";
import { MeItineraryParts } from "graphql/generated/MeItineraryParts";
import { WithoutNulls,removeNulls } from "utils/removeNulls";
import { normalizeSkills } from 'model/Skill';


const normalizeBasicWorkshop=(
    workshop:WithoutNulls<MeCareerParts_activeItineraries_itinerary_workshops>
)=>{
    return {
        id:workshop.id,
        name:workshop.name,
        estimatedTimeMinutes: workshop.estimatedTimeMinutes,
        progress: workshop.progress,
        isCompleted:workshop.isCompleted,
        skills: normalizeSkills(workshop.skills || []),
    }
}

export type BasicWorkshop = ReturnType<typeof normalizeBasicWorkshop>;

export const normalizeItinerary = (input: WithoutNulls<MeItineraryParts>) => ({
    itinerary:{
        id: input.itinerary!.id,
        name: input.itinerary!.Name,
        workshops:(input.itinerary?.workshops || []).reduce<BasicWorkshop[]>(
            (acum,workshop)=>
            !!workshop && workshop?.name
            ? acum.concat(normalizeBasicWorkshop(removeNulls(workshop)))
            : acum,
        []
        )
    },

    userCareer:{
        id:input.userCareer!.id
    },

    isCompleted:input.isCompleted
    
  });
  
  export type Itinerary = ReturnType<typeof normalizeItinerary>;