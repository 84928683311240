import useUser from 'graphql/hooks/admin/useUsers';
import { useEffect, useState } from 'react';
import usePaths from 'graphql/hooks/path/usePaths';
import useConnect from '../connect';

export const GetFilters = () => {
  const { users } = useUser();
  const { selectedLocations, selectedPaths, setExcelUsers } = useConnect();
  const [salaryMinState, setSalaryMinState] = useState('');
  const [salaryMaxState, setSalaryMaxState] = useState('');
  const [expYearsState, setExpYearsState] = useState('');
  const [professionState, setProfessionState] = useState('1');
  const [tecnologiesState, setTecnologiesState] = useState([[]]);
  const [whereToSearchState, setWhereToSearchState] = useState('');
  const [locationsState, setLocationsState] = useState([
    { label: '', value: '-1' },
  ]);
  const [totalUsers, setTotalUsers] = useState(users.length);
  const { paths } = usePaths();

  useEffect(() => {
    let results: any[] = users;

    results = results.filter(
      (user) =>
        (user.desiredCountries.some(
          (country: string) => country === 'España'
        ) ||
          !user.desiredCountries.length) &&
        user.developerExperienceYears !== undefined
    );

    if (locationsState[0]?.value !== '-1' && locationsState.length) {
      let validUsers: any[] = [];

      locationsState.forEach((location) => {
        results.forEach((user) => {
          user.citiesId.forEach((userLocation: any) => {
            const isInside = validUsers.some(
              (validUser) => validUser.id === user.id
            );

            if (userLocation === location.value && !isInside)
              validUsers.push(user);
          });
        });
      });

      results = validUsers;
    }
    selectedLocations.current = locationsState;

    if (whereToSearchState === 'Spain') {
      results = results.filter(
        (user) =>
          user.countryResidence === 'España' ||
          user.countryResidence === undefined
      );
    }

    if (whereToSearchState === 'PermisoUE') {
      results = results.filter(
        (user) =>
          user.userContinent === 'Europe' || user.countryResidence === undefined
      );
    }

    if (professionState !== '') {
      results = results.filter((user) => {
        let isProfessionSelected = user.professionsId.some(
          (userProfession: any) => userProfession === professionState
        );

        return isProfessionSelected;
      });
    }

    if (expYearsState !== '') {
      results = results.filter((user) => {
        let expYears = user.developerExperienceYears;

        if (professionState === '2') {
          expYears = user.managerExperienceYears;
        }

        return expYears >= expYearsState;
      });
    }

    if (tecnologiesState[0][0] !== '' && tecnologiesState[0].length) {
      results = results.filter((user) =>
        tecnologiesState.every((pathGroup) =>
          pathGroup.some((path) =>
            user.tecnologiesId.find((tecnology: any) => tecnology === path)
          )
        )
      );
    }

    //para el excel-----------------
    let auxiliarPaths: any = [];

    paths.forEach((path) => {
      tecnologiesState.forEach((pathGroup: any) => {
        pathGroup.forEach((p: string) => {
          if (p === path.id) {
            auxiliarPaths = [...auxiliarPaths, p];
          }
        });
      });
    });

    selectedPaths.current = paths.filter((path) => {
      const validPath = auxiliarPaths.find((p: any) => p === path.id);

      if (validPath !== undefined) {
        return path;
      }

      return null;
    });
    //----------------------------

    if (salaryMinState !== '') {
      results = results.filter((user) => {
        return user.salaryMin <= salaryMinState;
      });
    }

    if (salaryMaxState !== '') {
      setSalaryMinState('');
      results = results.filter((user) => {
        return user.salaryMin <= salaryMaxState;
      });
    }

    setTotalUsers(results.length);
    setExcelUsers(results.length);
  }, [
    expYearsState,
    locationsState,
    paths,
    professionState,
    salaryMaxState,
    salaryMinState,
    selectedLocations,
    selectedPaths,
    setExcelUsers,
    tecnologiesState,
    users,
    whereToSearchState,
  ]);

  return {
    setWhereToSearchState,
    setSalaryMinState,
    setSalaryMaxState,
    setExpYearsState,
    setProfessionState,
    tecnologiesState,
    setTecnologiesState,
    setLocationsState,
    totalUsers,
    locationsState,
  };
};
