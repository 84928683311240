import { Box, Button } from '@chakra-ui/react';
import { sanitizeLocations } from 'utils/sanitizeLocations';
import { Placeholder } from 'components/Placeholder';
import CandidateCard from 'containers/Admin/Candidate/CandidateCard';
import { NoCandidatesIllustration } from 'containers/Admin/Jobs/shared/noCandidatesIllustration';
import { NavLink } from 'react-router-dom';

interface Props {
  users: any[];
  newUsers?: any[];
  position: any;
  hasRemote: any;
  type: string;
  me: any;
  editIsDiscarded?: (type?: string, jobApplicationId?: string) => void;
  handleSendJob?: (jobId: string, userId: string) => void;
  jobId?: string;
  tabType?: string;
}

export const TabsInfo = ({
  users,
  newUsers,
  position,
  hasRemote,
  type,
  me,
  jobId,
  tabType,
  editIsDiscarded,
  handleSendJob,
}: Props) => {
  return (
    <Box ml="10%" mt="5">
      {users && users.length > 0 ? (
        users.map((user: any) => {
          let sanitizeUser: any[] = [];

          if (user.user) sanitizeUser = user.user;
          else sanitizeUser = user;

          const locations = sanitizeLocations(
            sanitizeUser,
            position,
            hasRemote
          );

          return (
            <CandidateCard
              skills={position.skills}
              user={sanitizeUser}
              jobApplicationId={user.id}
              jobId={position.id}
              type={type}
              isNew={user.new}
              isMatch={user.isMatch}
              locations={locations}
              newInscribed={newUsers}
              isInscribed={user.user ? true : false}
              company={me?.company}
              cancelReason={type === 'Reject' ? user.cancelReason : undefined}
              editIsDiscarded={editIsDiscarded}
              handleSendJob={handleSendJob}
            />
          );
        })
      ) : (
        <Placeholder
          mt="-4"
          mb="8"
          title={
            type === 'Inscribed'
              ? 'No hay nuevos candidatos inscritos'
              : 'No hay candidatos todavía'
          }
          icon={
            <NoCandidatesIllustration
              w="250px"
              h="300px"
              display={tabType ? 'none' : 'block'}
            />
          }
          boton={
            <Button
              colorScheme="brand"
              as={NavLink}
              exact
              to={`/company/job/${jobId}`}
              display={tabType ? 'flex' : 'none'}
            >
              Buscar candidatos en la base de datos
            </Button>
          }
        />
      )}
    </Box>
  );
};
