  import { WithoutNulls, removeNulls } from 'utils/removeNulls';
  import { normalizeSkills } from 'model/Skill';
import { FullWorkshopParts, FullWorkshopParts_sections, FullWorkshopParts_sections_lessons } from 'graphql/generated/FullWorkshopParts';
  
  export const normalizeLesson = (
    input: WithoutNulls<FullWorkshopParts_sections_lessons>
  ) => ({
    id: input.id,
    name: input.name,
    vimeoId: input.vimeoId,
    durationMinutes: input.durationMinutes,
  });
  
  export type Lesson = ReturnType<typeof normalizeLesson>;
  
  export const normalizeLessons = (
    input: (WithoutNulls<FullWorkshopParts_sections_lessons> | undefined)[]
  ) =>
    input.reduce<Lesson[]>(
      (acum, input) =>
        !!input ? acum.concat(normalizeLesson(removeNulls(input))) : acum,
      []
    );
  
  export const normalizeSection = (
    input: WithoutNulls<FullWorkshopParts_sections>
  ) => ({
    id: input.id,
    name: input.name,
    durationMinutes: input.durationMinutes,
    lessons: normalizeLessons(input.lessons || []),
  });
  
  export type Section = ReturnType<typeof normalizeSection>;
  
  export const normalizeSections = (
    input: (WithoutNulls<FullWorkshopParts_sections> | undefined)[]
  ) =>
    input.reduce<Section[]>(
      (acum, input) =>
        !!input ? acum.concat(normalizeSection(removeNulls(input))) : acum,
      []
    );
  
  export const normalizeFullWorkshop = (input: WithoutNulls<FullWorkshopParts>) => ({
    id: input.id,
    name: input.name,
    description: input.description,
    teacherName: input.teacherName,
    durationMinutes: input.estimatedTimeMinutes,
    skills: normalizeSkills(input.skills || []),
    sections: normalizeSections(input.sections || []),
    repoUrl: input.repoUrl,
  });
  
  export type FullCourse = ReturnType<typeof normalizeFullWorkshop>;
  