import { gql } from '@apollo/client';
import { SKILL_FRAGMENT } from './skill';

export const WORKSHOP_FRAGMENT = gql`
  fragment FullWorkshopParts on Workshop {
    id
    name
    description
    teacherName
    estimatedTimeMinutes
    skills {
      ...SkillParts
    }
    sections {
      id
      name
      durationMinutes
      lessons {
        id
        name
        vimeoId
        durationMinutes
      }
    }
    repoUrl
  }
  ${SKILL_FRAGMENT}
`;
