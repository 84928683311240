import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { removeNulls } from 'utils/removeNulls';
import { FULL_WORKSHOP } from 'graphql/queries/workshop';
import { normalizeFullWorkshop } from 'model/Workshop';
import { FullWorkshop, FullWorkshopVariables } from 'graphql/generated/FullWorkshop';

const useFullCourse = (id: string) => {
  const { data, loading, error } = useQuery<FullWorkshop,FullWorkshopVariables>(
    FULL_WORKSHOP,
    {
      variables: { id },
    }
  );

  const workshop = useMemo(
    () =>
      data && data.workshop
        ? normalizeFullWorkshop(removeNulls(data.workshop))
        : undefined,
    [data]
  );

  return {
    workshop,
    loading,
    error,
  };
};

export default useFullCourse;
