import { gql } from '@apollo/client';
import { WORKSHOP_FRAGMENT } from 'graphql/fragments/workshop';

export const FULL_WORKSHOP = gql`
  query FullWorkshop($id: ID!) {
    workshop(id: $id) {
      ...FullWorkshopParts
    }
  }
  ${WORKSHOP_FRAGMENT}
`;


  export const USER_WORKSHOP=gql`
  query userWorkshop($workshopId: ID!){
    userWorkshops(where:{workshop:$workshopId})
    {
      user{
        id
      }
      progress
      lessonsDone{
        lessonId
    }
  }
  }
  `