import {
  Avatar,
  HStack,
  Icon,
  Stack,
  Text,
  Link,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { FiLinkedin, FiGithub } from 'react-icons/fi';
import { User } from 'model/User';
import useUserPaths from 'graphql/hooks/career/useUserPaths';
import { Hexagon } from 'containers/Admin/Candidate/PersonalInfo/Hexagon/index';
import * as Sentry from '@sentry/react';
import { sanitizePathsHexagon } from 'utils/sanitizePathsHexagon';
interface Props {
  user: User;
  isDownload?: boolean;
}
export const PersonalInfo = ({ user, isDownload }: Props) => {
  let idUser = user.id;
  const { paths, error } = useUserPaths({ idUser });
  if (!paths) return null;
  const { pathsId, pathsHexagon, pathsWithLevel } = sanitizePathsHexagon(paths);

  const isUndefined = user.linkedinURL?.includes('undefined');

  if (error) Sentry.captureException(error?.graphQLErrors[0]);
  const hasHexagon = pathsWithLevel.length > 2 && pathsHexagon.length > 2;

  if (hasHexagon) {
    return (
      <Grid
        templateColumns={{ md: 'repeat(2, 1fr)', sm: 'repeat(1,auto)' }}
        bg="white"
        p="6"
        pt="4"
        pb="12"
        mt="10"
        borderRadius="md"
        gap={{ sm: '10' }}
      >
        {/*   Foto de perfil */}
        <Stack mt="8" mb="6" spacing="6">
          <HStack spacing="1">
            <Avatar
              size="xl"
              borderRadius="lg"
              mr="4"
              src={user.photo?.url && user.photo?.url}
            />
            <Stack>
              <Text as="h3" fontWeight="semibold" fontSize="1.7rem">
                {user.fullName}
              </Text>
              <Text textStyle="body">{user.email}</Text>
              <Text textStyle="body">{user.phoneNumber}</Text>
            </Stack>
          </HStack>
          {user.linkedinURL && !isUndefined && (
            <Link href={user.linkedinURL} isExternal>
              <HStack>
                <Icon as={FiLinkedin} color="gray.400" w={5} h={5} />
                <Text
                  pb={isDownload ? '4' : '0'}
                  textStyle="body"
                  color="gray.600"
                >
                  {user.linkedinURL}
                </Text>
              </HStack>
            </Link>
          )}
          {user.githubURL && (
            <Link href={user.githubURL} isExternal>
              <HStack>
                <Icon as={FiGithub} color="gray.400" w={6} h={6} />
                <Text
                  pb={isDownload ? '4' : '0'}
                  textStyle="body"
                  color="gray.600"
                >
                  {user.githubURL}
                </Text>
              </HStack>
            </Link>
          )}
        </Stack>
        <GridItem mt={{ md: '14' }}>
          <Hexagon
            cv="CvPage"
            paths={pathsWithLevel}
            pathsHexagon={pathsHexagon}
            pathsId={pathsId}
            idUser={idUser}
          />
        </GridItem>
      </Grid>
    );
  } else {
    return (
      <Flex bg="white" p="8" borderRadius="md" direction="column" align="start">
        <HStack>
          <Avatar size="xl" rounded="lg" mr="6" />
          <Stack direction="column">
            <Text as="h3" textStyle={{ base: 'h4', md: 'h3' }} maxWidth="500px">
              {user.fullName}
            </Text>
            <Text textStyle="body">{user.email}</Text>
            <Text textStyle="body">{user.phoneNumber}</Text>
          </Stack>
        </HStack>
        {user.linkedinURL && !isUndefined && (
          <Link href={user.linkedinURL} isExternal mb="4">
            <HStack>
              <Icon as={FiLinkedin} color="gray.400" w={6} h={6} />
              <Text textStyle="body">{user.linkedinURL}</Text>
            </HStack>
          </Link>
        )}
        {user.githubURL && (
          <Link href={user.githubURL} isExternal mb="4">
            <HStack>
              <Icon as={FiGithub} color="gray.400" w={6} h={6} />
              <Text textStyle="body">{user.githubURL}</Text>
            </HStack>
          </Link>
        )}
        {hasHexagon && (
          <Hexagon
            cv="CvPage"
            paths={pathsWithLevel}
            pathsHexagon={pathsHexagon}
            pathsId={pathsId}
            idUser={idUser}
          />
        )}
      </Flex>
    );
  }
};
