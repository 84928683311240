import { Stack } from '@chakra-ui/react';
import { SectionCard } from 'components/SectionCard';
import { Hexagon } from 'containers/Admin/Candidate/PersonalInfo/Hexagon/index';
import useUserPaths from 'graphql/hooks/career/useUserPaths';
import useConnect from '../../connect';

export const FlyncerMapTab = () => {
  const { idUser, uuid, isMobile } = useConnect();
  const { paths } = useUserPaths({ idUser });
  if (!paths) return null;

  let pathsWithLevel: any = [],
    pathsHexagon: any = [],
    pathsId: any = [];

  paths.forEach((path) => {
    if (path?.level !== null) {
      pathsWithLevel.push(path);
      if (path?.hexagon) {
        pathsHexagon.push(path);
        pathsId.push(path.path?.id);
      }
    }
  });

  return (
    <>
      <Stack spacing="6" mt="6" mb={isMobile ? '28' : '0'}>
        <SectionCard
          margin="auto"
          title="Flyncer map"
          subtitle="Escoge los skills que mejor describan tu perfil para poblar tu
              Flyncer Map. Podrás compartirlo como una certificación de tu nivel"
        >
          <Hexagon
            paths={pathsWithLevel}
            pathsHexagon={pathsHexagon}
            pathsId={pathsId}
            idUser={idUser}
            uuid={uuid}
          />
        </SectionCard>
      </Stack>
    </>
  );
};
