import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  Text,
  Stack,
} from '@chakra-ui/react';
import { Hexagon } from 'containers/Admin/Candidate/PersonalInfo/Hexagon/index';

import TagsCandidate from './tags';

interface Props {
  paths2: any[];
  skills: any[];
  pathsWithLevel: any[];
  pathsWithPreLevel: any[];
  pathsHexagon: any[];
  type: string;
  user: any;
  yearsExperience: number;
  orderedExp: any[];
  pathsId: any[];
  isMobile: boolean;
  showPrelevelMatches: boolean | undefined;
}

export const CandidatePathsExperiences = ({
  skills,
  pathsWithLevel,
  pathsHexagon,
  type,
  user,
  yearsExperience,
  orderedExp,
  pathsId,
  isMobile,
  pathsWithPreLevel,
  showPrelevelMatches,
}: Props) => {
  let allPaths = pathsWithLevel;

  if (showPrelevelMatches) {
    allPaths = pathsWithLevel.concat(pathsWithPreLevel);
    allPaths = allPaths.filter(
      (path, index) => allPaths.indexOf(path) === index
    );
  } else {
    allPaths = allPaths.filter((path) => path.level);
  }

  return (
    <>
      <Text mt="5" fontWeight="500">
        Tecnologías
      </Text>

      <TagsCandidate paths={allPaths} skills={skills} />

      {allPaths.length > 2 && pathsHexagon.length > 2 && (
        <Box position="relative" top={isMobile ? '0' : '7'} mt="-6">
          <Stack mr={isMobile ? '0' : '-8'} mb="auto">
            <Hexagon
              cv="Candidate"
              type={type}
              paths={allPaths}
              pathsHexagon={pathsHexagon}
              pathsId={pathsId}
              idUser={user.id}
            />
          </Stack>
        </Box>
      )}

      <Text mt={allPaths.length <= 3 ? '6' : '0'} fontWeight="500">
        Experiencia
      </Text>
      <Grid templateColumns="repeat(2,25%)">
        <Text>
          {user.professions
            ? `${user.professions[0]?.profession.name} - ${
                user.professions[0]?.startDate
                  ? yearsExperience !== 1
                    ? `${yearsExperience} años`
                    : `${yearsExperience} año`
                  : user.professions[0]?.experienceYears !== 0
                  ? `${user.professions[0]?.experienceYears} años`
                  : `${user.professions[0]?.experienceYears} año`
              } `
            : 'No tiene experiencia'}
        </Text>
        <Flex flexDirection="column">
          {orderedExp?.map((experience: any, index: number) => {
            if (index <= 1)
              return (
                <>
                  <Text>{experience.puesto}</Text>
                  <Text fontSize="12px" color="#8E9492">
                    {`${experience.inicio.substring(
                      5,
                      7
                    )}/${experience.inicio.substring(0, 4)}`}{' '}
                    -{' '}
                    {experience.fin !== undefined
                      ? `${experience.fin.substring(
                          5,
                          7
                        )}/${experience.fin.substring(0, 4)}`
                      : 'Actualidad'}
                  </Text>{' '}
                </>
              );
            else return '';
          })}
          {orderedExp?.length > 2 && (
            <Accordion
              allowToggle
              border="transparent"
              position="relative"
              zIndex="3"
            >
              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left" fontSize="12">
                    {orderedExp?.length - 2 > 1
                      ? `${orderedExp?.length - 2} experiencias más`
                      : `${orderedExp?.length - 2} experiencia más`}
                  </Box>
                  <AccordionIcon mr="8" />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {orderedExp.map((experience: any, cont: number) => {
                    if (cont > 1) {
                      return (
                        <>
                          <Text>{experience.puesto}</Text>
                          <Text fontSize="12px" color="#8E9492">
                            {`${experience.inicio.substring(
                              5,
                              7
                            )}/${experience.inicio.substring(0, 4)}`}{' '}
                            -{' '}
                            {experience.fin !== undefined
                              ? `${experience.fin.substring(
                                  5,
                                  7
                                )}/${experience.fin.substring(0, 4)}`
                              : 'Actualidad'}
                          </Text>{' '}
                        </>
                      );
                    } else return '';
                  })}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
        </Flex>
      </Grid>
    </>
  );
};
