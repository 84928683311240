import { useCallback, useState } from 'react';
import { FormikHelpers } from 'formik';
import * as Yup from 'yup';

import useAuth from 'graphql/hooks/auth/useAuth';
import usePixel from 'graphql/hooks/profile/usePixelActions';
import { FormValues } from './types';

const initialValues = {
  comercialName: '',
  razonSocial: '',
  CIF: '',
  email: '',
  password: '',
  accept: false,
};

const schema = Yup.object().shape({
  comercialName: Yup.string().required('Este campo es requerido'),
  razonSocial: Yup.string().required('Este campo es requerido'),
  CIF: Yup.string().required('Este campo es requerido'),
  email: Yup.string()
    .email('Introduce un email válido')
    .required('Este campo es requerido'),
  password: Yup.string()
    .required('Este campo es requerido')
    .min(8, 'Mínimo debe tener 8 carácteres'),
  accept: Yup.boolean()
    .required('Los terminos y condiciones deben ser aceptados.')
    .oneOf([true], 'Los terminos y condiciones deben ser aceptados.'),
});

const useConnect = () => {
  const { register } = useAuth();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { sendEvent } = usePixel();

  const handleSubmit = useCallback(
    async (
      values: FormValues,
      { setSubmitting, setFieldError }: FormikHelpers<FormValues>
    ) => {
      setSubmitting(true);
      try {
        await register({
          input: {
            comercialName: values.comercialName,
            razonSocial: values.razonSocial,
            CIF: values.CIF,
            username: values.email,
            email: values.email,
            password: values.password,
          },
        });
        await sendEvent({
          ip: '',
          userAgent: '',
          event: 'Iniciar Registro',
          eventID: 'START_REGISTER',
        });
        setShowConfirmation(true);
      } catch (error: any) {
        if (error.fields.id === 'Auth.form.error.email.taken') {
          setFieldError('email', 'Email no disponible');
        } else {
          setFieldError('password', error.message);
        }
        setSubmitting(false);
      }
    },
    [register, setShowConfirmation, sendEvent]
  );

  return {
    handleSubmit,
    initialValues,
    schema,
    showConfirmation,
  };
};

export default useConnect;
