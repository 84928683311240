import {
  Flex,
  Text,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/hooks';
import { UserCareer } from 'model/UserCareer';
import { UpdateCareerModal } from '../UpdateCareerModal';
import { FiMoreVertical, FiEdit2 } from 'react-icons/fi';
import { DeleteMenuItem } from './DeleteMenuItem';
import { MarkAsMainMenuItem } from './MarkAsMainMenuItem';

interface Props {
  userCareer: UserCareer;
}

export const Header = ({ userCareer }: Props) => {
  const { id, career, isMain } = userCareer;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex alignItems="center" className="my-first-step">
        <Text as="h3" textStyle={{ base: 'h4', md: 'h3' }} mr="4">
          {career.name}
        </Text>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<FiMoreVertical />}
            variant="outline"
          />
          <MenuList>
            {!isMain && <MarkAsMainMenuItem id={id} />}
            {!career.isBasic && (
              <MenuItem icon={<FiEdit2 />} onClick={onOpen}>
                Editar
              </MenuItem>
            )}
            <DeleteMenuItem id={id} />
          </MenuList>
        </Menu>
      </Flex>
      <UpdateCareerModal
        isOpen={isOpen}
        onClose={onClose}
        userCareer={userCareer}
      />
    </>
  );
};
