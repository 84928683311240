import { useQuery } from '@apollo/client';
import {
  userWorkshop,
  userWorkshopVariables,
} from 'graphql/generated/userWorkshop';
import { USER_WORKSHOP } from 'graphql/queries/workshop';
import { useMemo } from 'react';

const useFullUserWorkshop = (workshopId: string) => {
  const { data, loading, error, refetch } = useQuery<
    userWorkshop,
    userWorkshopVariables
  >(USER_WORKSHOP, {
    variables: { workshopId },
  });

  const userWorkshops = useMemo(() => {
    return data;
  }, [data]);

  return {
    userWorkshops,
    loading,
    error,
    refetch,
  };
};

export default useFullUserWorkshop;
