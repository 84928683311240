import { Formik, Form } from 'formik';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Stack, Text, Button, Link } from '@chakra-ui/react';
import { DividerWithText } from 'components/DividerWithText';
import { Layout } from 'containers/Guest/Shared/Layout';
import { FormInput } from 'components/FormInput';

import useConnect from './connect';
import { Confirmation } from './Confirmation';

export const ForgotPassword = () => {
  const {
    handleSubmit,
    initialValues,
    schema,
    showConfirmation,
  } = useConnect();

  if (showConfirmation) {
    return (
      <Layout>
        <Confirmation />
        <DividerWithText py="8" />
        <Box>
          <Text as="span" textStyle="body" mr="2">
            ¿Quizás no tienes cuenta?
          </Text>
          <Link
            as={RouterLink}
            to="/register"
            textStyle="bodyLink"
            color="brand.600"
          >
            Consigue una ahora
          </Link>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout
      title="¿Olvidaste la contraseña?"
      subtitle="Introduce la dirección de correo con la que creaste la cuenta y te enviarémos las instrucciones para recuperarla."
    >
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <>
            <Stack as={Form} noValidate spacing="6">
              <FormInput name="email" label="Email" autoComplete="email" />
              <Button
                type="submit"
                disabled={isSubmitting}
                colorScheme="brand"
                textStyle="button"
              >
                Envíar instrucciones para recuperarla
              </Button>
            </Stack>
            <DividerWithText py="8" />
            <Stack spacing="10">
              <Box>
                <Text as="span" textStyle="body" mr="2">
                  ¿No tienes cuenta?
                </Text>
                <Link
                  as={RouterLink}
                  to="/register"
                  textStyle="bodyLink"
                  color="brand.600"
                >
                  Registrate ahora
                </Link>
              </Box>
            </Stack>
          </>
        )}
      </Formik>
    </Layout>
  );
};
