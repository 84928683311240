import { Box, Flex, HStack, Stack, Text, Grid } from '@chakra-ui/react';
import { TagsCV } from 'components/TagsCV';
import { User } from 'model/User';
import { yearMonths } from 'utils/normalizeDate';
import { orderExp } from 'utils/orderExperience';
// import { normalizeExperience } from 'utils/normalizeExperience';

interface Props {
  user: User;
  isDownload?: boolean;
}

export const Cv = ({ user, isDownload }: Props) => {
  const { months } = yearMonths();

  const orderedExp = orderExp(user?.user_cv?.experiences);

  return (
    <Flex
      bg="white"
      p={{ base: '8', lg: '8' }}
      borderRadius="md"
      direction={{ base: 'column' }}
    >
      <HStack align="start">
        <Stack spacing="8" width="50%">
          <Text as="h4" mb="-5" textStyle="h4" color="gray.600">
            Cv
          </Text>
          {orderedExp?.map((experience: any) => {
            let tags: string[] = [];
            const beginDate = new Date(experience?.inicio);
            const endDate = new Date(experience?.fin);

            return (
              <Grid gap="10" templateColumns="40% 60%">
                <Box w="120%">
                  <Text fontSize="sm">
                    {experience?.fin !== undefined
                      ? `${
                          months[beginDate.getMonth()]
                        } ${beginDate.getFullYear()} - ${
                          months[endDate.getMonth()]
                            ? months[endDate.getMonth()]
                            : ''
                        } ${
                          endDate.getFullYear() ? endDate.getFullYear() : ''
                        } `
                      : `${
                          months[beginDate.getMonth()]
                        } ${beginDate.getFullYear()} - Actualidad`}
                  </Text>
                </Box>

                <Box w="220%">
                  <Text
                    as="h5"
                    mb="-3"
                    fontSize="18px"
                    fontWeight="500"
                    color="#151925"
                  >
                    {experience?.puesto}
                  </Text>
                  <br />
                  <Text fontStyle="italic">{experience?.empresa}</Text>

                  <Text>{experience?.funciones}</Text>
                  {experience?.skills?.forEach((skill: any) => {
                    if (skill !== undefined) tags.push(skill?.name);
                  })}

                  <TagsCV mt="2" isDownload={isDownload} tags={tags} />
                </Box>
              </Grid>
            );
          })}
        </Stack>
      </HStack>
    </Flex>
  );
};
