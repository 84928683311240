import { Badge, Flex, FormControl, Spacer, Text } from '@chakra-ui/react';
import { DesiredLocations } from 'containers/Main/Profile/Tabs/Goals/DesiredCities';
import WhereToSearch from 'containers/Admin/Jobs/formOffer/location/WhereToSearch';
import { Field } from 'formik';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  totalUsers: number;
  setLocationsState?: Dispatch<SetStateAction<Element[]>>;
  setWhereToSearchState: Dispatch<SetStateAction<string>>;
  seterrorLocation: (value: boolean) => void;
  errorLocation: boolean;
  locationsState: any;
}

interface Element {
  label: string;
  value: string;
}

export const LocationForm = ({
  totalUsers,
  setLocationsState,
  setWhereToSearchState,
  errorLocation,
  seterrorLocation,
  locationsState,
}: Props) => {
  if (locationsState[0].label !== '') seterrorLocation(false);
  return (
    <FormControl mt="10">
      <Text fontSize="16" fontWeight="bold">
        <Flex>
          Ubicación *
          <Spacer />
          <Badge fontSize="11" p="1" rounded="md" visibility="hidden">
            {totalUsers !== 1
              ? `${totalUsers} disponibles`
              : `${totalUsers} disponible`}
          </Badge>
        </Flex>
      </Text>
      <Field
        className="custom-select"
        name="desiredLocations"
        component={DesiredLocations}
        setLocationsState={setLocationsState}
      />

      {errorLocation && (
        <Text mt="2" fontSize="14px" color="#CB1410">
          Este campo es requerido
        </Text>
      )}

      <Text fontSize="14" fontWeight="semibold" mt="5">
        Dónde buscar los candidatos *
      </Text>
      <WhereToSearch
        name="where_to_search"
        setWhereToSearchState={setWhereToSearchState}
      />
    </FormControl>
  );
};
