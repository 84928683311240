import { MeCareerParts } from 'graphql/generated/MeCareerParts';
import { normalizePath, Path } from 'model/UserPath';
import { WithoutNulls, removeNulls } from 'utils/removeNulls';
import { Itinerary, normalizeItinerary } from './UserItinerary';

export const normalizeUserCareer = (input: WithoutNulls<MeCareerParts>) => ({
  id: input.id,
  isMain: input.isMain,
  experienceYears: input.experienceYears,
  career: {
    id: input.career!.id,
    name: input.career!.name,
    isBasic: input.career?.isBasic || false,
    esRecurso: input.career.esRecurso || false,
  },

  activePaths: (input.activePaths || []).reduce<Path[]>(
    (acum, input) =>
      !!input && !!input.path
        ? acum.concat(normalizePath(removeNulls(input)))
        : acum,
    []
  ),
  activeItineraries: (input.activeItineraries || []).reduce<Itinerary[]>(
    (acum, input) =>
      !!input && !!input.itinerary
        ? acum.concat(normalizeItinerary(removeNulls(input)))
        : acum,
    []
  ),
});

export type UserCareer = ReturnType<typeof normalizeUserCareer>;
