import { Box, useMediaQuery, Center, Spinner, Text } from '@chakra-ui/react';
import { useState } from 'react';
import './index.css';
import useUserPaths from 'graphql/hooks/career/useUserPaths';
import * as Sentry from '@sentry/react';
import { orderExp } from 'utils/orderExperience';
import { sanitizeDate2 } from 'utils/sanitizeDate';

import { CandidateFooter } from './components/candidateFooter';
import { CandidateHeader } from './components/candidateHeader';
import { CandidatePathsExperiences } from './components/candidatePathsExperiences';
import { sanitizePathsHexagon } from 'utils/sanitizePathsHexagon';
import { useConnect } from './connect';
import { sanitizeCancelReason } from 'utils/sanitizeCancelReason';
interface Props {
  user: any;
  type: string;
  jobId: string;
  isNew?: boolean;
  isMatch?: boolean;
  company?: any;
  newInscribed?: any;
  jobApplicationId?: string;
  cancelReason?: string;
  locations: any;
  skills: any;
  handleSendJob?: (jobId: string, userId: string) => void;
  editIsDiscarded?: (type?: string, jobApplicationId?: string) => void;
  isInscribed: boolean;
}

function CandidateCard({
  jobId,
  isNew,
  newInscribed,
  user,
  jobApplicationId,
  type,
  cancelReason,
  locations,
  company,
  skills,
  editIsDiscarded,
  isMatch,
  handleSendJob,
  isInscribed,
}: Props) {
  let idUser = user.id;
  const [isSend, setisSend] = useState(false);
  const [enter, setenter] = useState(false);
  const { handleClick, lastUpdate, error, refetch, paths2, position, job } =
    useConnect(
      user,
      jobId,
      company,
      isNew,
      enter,
      jobApplicationId,
      newInscribed
    );

  const sanitizeMinHeight = (pathsWithLevel: [], pathsHexagon: []) => {
    if (!isInscribed) {
      if (pathsHexagon.length > 2 && pathsHexagon.length < 5) {
        if (pathsWithLevel.length < 5) {
          return '400px';
        }
      } else if (pathsHexagon.length > 4) {
        if (pathsWithLevel.length > 6) {
          return '500px';
        } else {
          return '450px';
        }
      }
      return '200px';
    } else {
      if (pathsHexagon.length > 2 && pathsHexagon.length < 5) {
        if (pathsWithLevel.length > 3 && pathsWithLevel.length < 5)
          return '460px';
        else if (pathsWithLevel.length < 5) {
          return '400px';
        }
      } else if (pathsHexagon.length > 4) {
        if (pathsWithLevel.length > 6) {
          return '500px';
        } else {
          return '490px';
        }
      }
      return '200px';
    }
  };

  const orderedExp = orderExp(user.user_cv?.experiences);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const yearsExperience: any = sanitizeDate2(user);
  const {
    paths,
    error: userPathsError,
    loadingPaths,
  } = useUserPaths({ idUser });

  const { pathsId, pathsHexagon, pathsWithLevel, pathsWithPreLevel } =
    sanitizePathsHexagon(paths);

  if (error || userPathsError) {
    let message = error ? error : userPathsError;
    Sentry.captureException(message?.graphQLErrors[0]);
  }

  if (isSend === true) {
    refetch();
    setisSend(false);
  }

  const cancelMessage = sanitizeCancelReason(cancelReason);

  let isUndefined = false;

  if (user.linkedinURL) {
    isUndefined = user.linkedinURL.includes('undefined');
  }

  if (type === 'Pending' || type === 'Match' || type === 'Reject')
    user.name = undefined;

  if (loadingPaths)
    return (
      <Center height="170px">
        <Spinner color="brand.500" />
        <Text ml="2">Cargando...</Text>
      </Center>
    );

  return (
    <Box
      mt={type === 'PublicProfile' ? '9' : ''}
      border={type !== 'PublicProfile' ? '1px solid #D8DFEA' : ''}
      borderRadius="15px"
      width="100%"
      minHeight={sanitizeMinHeight(pathsWithLevel, pathsHexagon)}
      p="4"
      bg="white"
      mb="5"
      position="relative"
      onClick={handleClick}
    >
      <CandidateHeader
        isMatch={isMatch}
        position={position}
        type={type}
        user={user}
        isUndefined={isUndefined}
        lastUpdate={lastUpdate}
      />

      <CandidatePathsExperiences
        showPrelevelMatches={job?.position.showPrelevelMatches}
        paths2={paths2}
        skills={skills}
        pathsWithLevel={pathsWithLevel}
        pathsWithPreLevel={pathsWithPreLevel}
        pathsHexagon={pathsHexagon}
        type={type}
        user={user}
        yearsExperience={yearsExperience}
        orderedExp={orderedExp}
        pathsId={pathsId}
        isMobile={isMobile}
      />

      <CandidateFooter
        locations={locations}
        type={type}
        company={company}
        jobId={jobId}
        user={user}
        setenter={setenter}
        setisSend={setisSend}
        editIsDiscarded={editIsDiscarded}
        cancelMessage={cancelMessage}
        pathsWithLevel={pathsWithLevel}
        pathsWithPreLevel={pathsWithPreLevel}
        pathsHexagon={pathsHexagon}
        showPrelevelMatches={job?.position.showPrelevelMatches}
        paths2={paths2}
        jobApplicationId={jobApplicationId}
        handleSendJob={handleSendJob}
      />
    </Box>
  );
}

export default CandidateCard;
