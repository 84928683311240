import { FC } from 'react';
import {
  Box,
  Text,
  HStack,
  Wrap,
  Icon,
  Button,
  useDisclosure,
  Spacer,
} from '@chakra-ui/react';

import { Avatar } from 'components/Avatar';
import { Tags } from 'components/Tags';

import { FiDollarSign, FiMapPin, FiBriefcase } from 'react-icons/fi';

import { normalizeSalaryRange } from 'utils/normalizeSalaryRange';
import { normalizeExperience } from 'utils/normalizeExperience';
import { Position } from 'model/JobRequest';
import useJobActions from 'graphql/hooks/admin/useJobActions';
import { useHistory } from 'react-router-dom';
import { BsTrash } from 'react-icons/bs';
import { Link as RouterLink } from 'react-router-dom';
import { CloseOfferModal } from '../CloseOfferModal';
import { BlueToken } from 'components/TokenBlue';

interface Props {
  title: string;
  company: Position['company'];
  skills: Position['skills'];
  salary: Position['salary'];
  location: Position['location'];
  experienceYears: Position['experienceYears'];
  id?: string;
  buttons: boolean;
  created_at: string;
  bonos?: number;
  isSlot?: boolean;
}

export const JobHeader: FC<Props> = ({
  title,
  company,
  skills,
  salary,
  location,
  experienceYears,
  children,
  id,
  buttons,
  created_at,
  isSlot,
  bonos,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { replace } = useHistory();

  const { closeJob } = useJobActions();

  const closeOffer = async () => {
    if (id) await closeJob({ id });
    replace('/company');
  };

  let skillsName: any[] = [];
  skills?.forEach((skill) => {
    skillsName.push(skill);
  });

  return (
    <>
      <Box w="100%" h="16" />
      <Box
        as="main"
        mx="auto"
        w="100%"
        maxW={buttons ? '60rem' : '50rem'}
        px={{ base: '4', md: '6' }}
        pt="16"
        position="relative"
      >
        <Avatar
          w="20"
          h="20"
          alt={company.name}
          src={company.logo?.url}
          zIndex="1"
          position="absolute"
          mt="-10"
          top="0"
          companyZone={true}
        />

        <HStack spacing="7">
          <Text as="h3" textStyle={{ base: 'h4', md: 'h3' }} mb="4">
            {title}
          </Text>

          {isSlot && (
            <HStack
              spacing="2"
              border="1px solid"
              borderColor="gray.200"
              borderRadius="md"
              p="2"
              color="gray.500"
              minW="20vh"
            >
              <BlueToken /> <Text>Contactos Slot </Text>
              <Text fontWeight="bold"> {bonos} </Text>
            </HStack>
          )}
        </HStack>

        <HStack color="gray.500" spacing="5" mb="4">
          <Text textStyle="small">{company.name}</Text>
          <Text textStyle="small">Publicada {created_at}</Text>
        </HStack>

        <Tags tags={skillsName} mb="4" />

        <Wrap spacing="6">
          <HStack spacing={{ base: '4', md: '6', lg: '6' }}>
            <HStack spacing="2">
              <Icon as={FiDollarSign} color="gray.300" />
              <Text textStyle="small" color="gray.500">
                {normalizeSalaryRange(salary)}
              </Text>
            </HStack>
            <HStack spacing="2">
              <Icon as={FiMapPin} color="gray.300" />
              <Text textStyle="small" color="gray.500">
                {location.join(' / ')}
              </Text>
            </HStack>
            <HStack spacing="2">
              <Icon as={FiBriefcase} color="gray.300" />
              <Text textStyle="small" color="gray.500">
                {normalizeExperience(experienceYears)}
              </Text>
            </HStack>
          </HStack>
          <Spacer />

          {buttons && (
            <Wrap spacing="2">
              <Button
                as={RouterLink}
                to={`/company/description/${id}`}
                variant="outline"
                color="brand.700"
                borderColor="brand.700"
                _hover={{ bg: 'brand.50' }}
                fontSize="14"
                size="sm"
              >
                <Text>Ver oferta</Text>
              </Button>
              <Button
                onClick={onOpen}
                variant="outline"
                colorScheme="red"
                fontSize="14"
                size="sm"
              >
                <Text mr="2">Cerrar</Text> <BsTrash />
              </Button>
            </Wrap>
          )}
        </Wrap>

        {children}
      </Box>
      <CloseOfferModal
        isOpen={isOpen}
        onClose={onClose}
        closeOffer={closeOffer}
      />
    </>
  );
};
