import { Wrap, WrapProps, Text, HStack } from '@chakra-ui/react';

interface Props extends WrapProps {
  tags: any[];
  level: any;
}

export const AllOffersTags = ({ level, tags, ...props }: Props) => {
  return (
    <Wrap {...props}>
      {tags.map((tag) => {
        const isSuperior = tag.paths.some((path: any) =>
          level?.find(
            (userLevel: any) =>
              path.order <= userLevel?.level?.order &&
              userLevel.path.id === path.id
          )
        );

        return (
          <HStack
            color="gray.500"
            bg={isSuperior ? 'green.100' : 'gray.100'}
            key={tag.id}
            p="0.5"
            pl="2"
            borderRadius="5"
            m="1"
            fontSize="13"
            spacing="2"
          >
            {tag.paths.map((path: any, i: number) => (
              <>
                <Text>{path.name}</Text>
                <Text fontWeight="semibold">
                  {tag.paths.length - 1 !== i && 'OR'}
                </Text>
              </>
            ))}
          </HStack>
        );
      })}
    </Wrap>
  );
};
