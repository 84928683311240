import { Link as RouterLink } from 'react-router-dom';
import { Flex, Box, Text, Button } from '@chakra-ui/react';

export const NotFound = () => {
  return (
    <Box
      as="main"
      mx="auto"
      w="100%"
      maxW="50rem"
      p={{ base: '4', md: '6' }}
      pt="16"
      pb="12"
    >
      <Flex
        bg="white"
        p={{ base: '4', lg: '6' }}
        borderRadius="md"
        direction={{ base: 'column' }}
      >
        <Text as="h4" textStyle="h4" mb="4" color="gray.600">
          Curso no encontrado
        </Text>
        <Text textStyle="body" mb="4" color="gray.500">
          Es posible que no exista o no lo hayas desbloqueado aún.
        </Text>
        <Button as={RouterLink} to="/career">
          Volver a mi carrera
        </Button>
      </Flex>
    </Box>
  );
};
