export const sanitizePathsHexagon = (paths: any) => {
  let pathsWithLevel: any = [],
    pathsHexagon: any = [],
    pathsId: any = [],
    pathsWithPreLevel: any = [];

  paths?.forEach((path: any) => {
    if (path?.level !== null) {
      pathsWithLevel.push(path);
      if (path?.hexagon && pathsHexagon.length <= 6) {
        pathsHexagon.push(path);
        pathsId.push(path.path?.id);
      }
    } else if (path?.preLevel !== null) {
      pathsWithPreLevel.push(path);
      if (path?.hexagon && pathsHexagon.length <= 6) {
        pathsHexagon.push(path);
        pathsId.push(path.path?.id);
      }
    }
  });

  return { pathsWithLevel, pathsHexagon, pathsId, pathsWithPreLevel };
};
