import { Box, Container, Flex, Spacer, useMediaQuery } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { TestContainer } from './testContainer';
import { TrainningContainer } from './trainningContainer';
import { NoLoginHeader } from '../../../../components/NoLoginHeader';
import { ResultsContainer } from './resultsContainer';

type Evaluation = {
  level: string;
  percent: number;
};

export const TestCompleted = ({ level, percent }: Evaluation) => {
  const { replace } = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 830px)');

  const handleClick = (test: boolean) => {
    if (test) {
      localStorage.setItem('button', 'test');
    } else {
      localStorage.setItem('button', 'training');
    }
    replace('/register');
  };

  return (
    <>
      {!localStorage.getItem('flyncer-token') && <NoLoginHeader />}

      <Container
        maxW="container.xl"
        mx="auto"
        mt="10"
        w="100%"
        px={{ base: '8', md: '14' }}
        py="8"
        bg="white"
        borderRadius={'8px'}
      >
        <Flex flexDirection={isMobile ? 'column' : 'row'} alignItems="center">
          <Box order={isMobile ? 2 : 1} w="md">
            <TrainningContainer handleClick={handleClick} />
          </Box>

          <Spacer />

          <Box
            w="md"
            order={isMobile ? 1 : 2}
            border="1px solid"
            p="5"
            borderRadius="md"
            borderColor="gray.200"
          >
            <ResultsContainer level={level} percent={percent} />
          </Box>

          <Spacer />

          <Box order={3} w="md">
            <TestContainer handleClick={handleClick} />
          </Box>
        </Flex>
      </Container>
    </>
  );
};
