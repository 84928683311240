import { Dispatch, memo, SetStateAction, useCallback } from 'react';
import { FieldProps } from 'formik';
import { Stack, Text } from '@chakra-ui/react';
import Select from 'react-select';
import { CustomStyles } from 'styles/customStyles';
import makeAnimated from 'react-select/animated';
import useCities from 'graphql/hooks/profile/useCities';

interface Props extends FieldProps {
  className?: string;
  fromCompany: boolean;
  setLocationsState?: Dispatch<SetStateAction<Element[]>>;
  setforceRender?: (isSend: boolean) => void;
  seterrorLocations?: (isSend: boolean) => void;
}

interface Element {
  label: string;
  value: string;
}

// TODO: hay que mejorar el componente de autocomplete estilos y lógica
// TODO: hay que mejorar la normalización/denormalización de los items del Autocomplete
export const DesiredLocations = memo(
  ({
    className,
    fromCompany,
    setLocationsState,
    setforceRender,
    seterrorLocations,
    field,
    form,
  }: Props) => {
    const animatedComponents = makeAnimated();
    const isMulti = true;
    const { cities } = useCities();

    const options = cities
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((city) => ({
        label: city.name,
        value: city.id,
      }));

    const onChange = (option: any) => {
      if (seterrorLocations && option.length) seterrorLocations(false);

      if (setLocationsState) {
        setLocationsState((option as Element[]).map((item: Element) => item));
      }
      form.setFieldValue(
        field.name,
        isMulti
          ? (option as Element[]).map((item: Element) => item.value)
          : (option as Element).value
      );
    };

    const getValue = useCallback(() => {
      if (options) {
        return isMulti
          ? options.filter(
              (option: any) => field.value.indexOf(option.value) >= 0
            )
          : options.find((option: any) => option.value === field.value);
      } else {
        return isMulti ? [] : ('' as any);
      }
    }, [field.value, isMulti, options]);

    return (
      <>
        {!fromCompany && (
          <Stack
            spacing={8}
            mt="5"
            onClick={() => {
              if (setforceRender) setforceRender(true);
            }}
          >
            <Text as="h5" fontSize="14" fontWeight="semibold" mt="5" mb="-2">
              ¿En qué provincias de España?
            </Text>

            <Select
              className={className}
              name={field.name}
              placeholder="Provincia"
              value={getValue()}
              onChange={onChange}
              options={options}
              isMulti
              styles={CustomStyles}
              components={animatedComponents}
            />
          </Stack>
        )}
      </>
    );
  }
);
