import { HStack, StackProps, Text, StackDivider } from '@chakra-ui/react';
import { normalizeDuration } from 'utils/normalizeDuration';

interface Props extends StackProps {
  name: string;
  questionsCount: number;
  estimatedTimeMinutes?: number;
}

export const Metadata = ({
  name,
  questionsCount,
  estimatedTimeMinutes,
  ...props
}: Props) => {
  return (
    <HStack {...props} divider={<StackDivider color="gray.500" />}>
      <Text textStyle="body" color="gray.500">
        {name}
      </Text>
      <Text textStyle="body" color="gray.500">
        Preguntas: {questionsCount}
      </Text>
      {estimatedTimeMinutes && (
        <>
          <Text textStyle="body" color="gray.500">
            Tiempo estimado: {normalizeDuration(estimatedTimeMinutes)}
          </Text>
        </>
      )}
    </HStack>
  );
};
