import { ChangeEvent, memo, useCallback } from 'react';
import {
  Stack,
  StackProps,
  Icon,
  Text,
  Link,
  Input,
  HStack,
} from '@chakra-ui/react';
import { FiLinkedin, FiGithub } from 'react-icons/fi';

import useFileActions from 'graphql/hooks/profile/useCVActions';
import { FormInput } from 'components/FormInput';
import { DeleteCVButton } from './DeleteCVButton';

interface Props extends StackProps {
  cv?: any;
}

export const Curriculum = memo(({ cv, ...props }: Props) => {
  const { uploadCV, deleteCV, isLoading } = useFileActions();

  const handleUploadCV = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        await uploadCV({ file: event.target.files[0] });
      }
    },
    [uploadCV]
  );

  const handleDeleteCV = useCallback(async () => {
    await deleteCV();
  }, [deleteCV]);

  console.log(cv.url);

  return (
    <Stack spacing={8} {...props}>
      <FormInput
        name="linkedinURL"
        placeholder="URL de tu perfil en Linkedin"
        rightElement={{
          children: <Icon as={FiLinkedin} color="linkedin.500" />,
        }}
      />
      <FormInput
        name="githubURL"
        placeholder="URL de tu perfil en Github"
        rightElement={{
          children: <Icon as={FiGithub} color="gray.700" />,
        }}
      />
      {cv.name && cv.url ? (
        <>
          <HStack textStyle="caption" color="gray.400">
            <Link
              href={cv.url}
              isExternal
              textDecoration="underline"
              color="gray.900"
            >
              Ver mi CV en PDF
            </Link>
            .
            <DeleteCVButton
              handleDeleteCV={handleDeleteCV}
              isLoading={isLoading}
            />
          </HStack>
        </>
      ) : (
        <label>
          <Input
            type="file"
            name="file"
            display="none"
            onChange={handleUploadCV}
          />
          <Text textDecoration="underline" color="gray.900" cursor="pointer">
            Subir tu CV en PDF
          </Text>
        </label>
      )}
    </Stack>
  );
});
