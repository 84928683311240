import { gql } from '@apollo/client';
import { SKILL_FRAGMENT } from 'graphql/fragments/skill';

export const SKILLS = gql`
  query Skills {
    skills {
      ...SkillParts
    }
  }
  ${SKILL_FRAGMENT}
`;
