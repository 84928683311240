import { gql } from '@apollo/client';
import { PATH_FRAGMENT } from 'graphql/fragments/path';

export const PATHS = gql`
  query paths {
    paths {
      ...PathParts
    }
  }
  ${PATH_FRAGMENT}
`;
