import {
  FormControl,
  FormErrorMessage,
  Select,
  Stack,
  StackProps,
  Text,
} from '@chakra-ui/react';
import { useField } from 'formik';
import useCompanyTypes from 'graphql/hooks/profile/useCompanyTypes';

interface Props extends StackProps {
  name: string;
}

export const CompanyType = ({ name }: Props) => {
  const { companyTypes } = useCompanyTypes();
  const [field, { error, touched }] = useField(name);
  const isInvalid = !!error && touched;

  if (!companyTypes) return null;

  return (
    <Stack mt="10" spacing={8}>
      <FormControl name={name} isInvalid={isInvalid}>
        <Text fontSize="16" fontWeight="bold" my="6">
          Tipo de empresa *
        </Text>
        <Select placeholder="Seleccionar" {...field}>
          {companyTypes.map((company) => (
            <option value={company.id} key={company.id}>
              {company.name}
            </option>
          ))}
        </Select>
        {isInvalid && (
          <FormErrorMessage mt="1" textStyle="caption">
            {error}
          </FormErrorMessage>
        )}
      </FormControl>
    </Stack>
  );
};
