import { useCallback } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { FlyncerError } from 'graphql/helpers';

import {
  EvalutatePathQuiz,
  EvalutatePathQuizVariables,
} from 'graphql/generated/EvalutatePathQuiz';
import {
  EvalutateCourseQuiz,
  EvalutateCourseQuizVariables,
} from 'graphql/generated/EvalutateCourseQuiz';
import {
  EVALUATE_PATH_QUIZ,
  EVALUATE_COURSE_QUIZ,
} from 'graphql/mutations/quiz';
import { FullCareer } from 'graphql/generated/FullCareer';
import { FULL_CAREER } from 'graphql/queries/fullCareer';
import { GET_USER_PATHS, USER_LEVEL_FROM_PATH } from 'graphql/queries/userPath';
import useMe from '../profile/useMe';
import * as Sentry from '@sentry/react';
import { JOBS_MATCH_USER } from 'graphql/queries/job';

const useEvaluateQuizActions = () => {
  const client = useApolloClient();
  const { me } = useMe();

  let idUser = me?.id;

  const [forPathMutation, { loading: forPathLoading }] = useMutation<
    EvalutatePathQuiz,
    EvalutatePathQuizVariables
  >(EVALUATE_PATH_QUIZ, {
    refetchQueries: [
      { query: GET_USER_PATHS, variables: { idUser: idUser } },
      { query: USER_LEVEL_FROM_PATH },
      { query: JOBS_MATCH_USER },
    ],
  });

  const forPath = useCallback(
    async (input: EvalutatePathQuizVariables) => {
      try {
        const response = await forPathMutation({ variables: input });
        const evaluation = response.data?.evaluatePathQuiz;
        const fullCareer = evaluation?.fullCareer;

        if (fullCareer) {
          client.cache.writeQuery<FullCareer>({
            data: {
              fullCareer,
            },
            query: FULL_CAREER,
          });
        }

        if (evaluation) {
          return {
            successPercent: evaluation.successPercent,
            level: evaluation.level,
            jobsMatch: evaluation.jobsMatch,
          };
        }
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo crear'));
        throw new FlyncerError('No se pudo crear', error);
      }
    },
    [forPathMutation, client]
  );

  const [forCourseMutation, { loading: forCourseLoading }] = useMutation<
    EvalutateCourseQuiz,
    EvalutateCourseQuizVariables
  >(EVALUATE_COURSE_QUIZ, {
    refetchQueries: [
      { query: GET_USER_PATHS, variables: { idUser: idUser } },
      { query: USER_LEVEL_FROM_PATH },
    ],
  });
  const forCourse = useCallback(
    async (input: EvalutateCourseQuizVariables) => {
      try {
        const response = await forCourseMutation({ variables: input });
        const evaluation = response.data?.evaluateCourseQuiz;
        const fullCareer = evaluation?.fullCareer;

        if (fullCareer) {
          client.cache.writeQuery<FullCareer>({
            data: {
              fullCareer,
            },
            query: FULL_CAREER,
          });
        }

        if (evaluation) {
          return {
            successPercent: evaluation.successPercent,
            approved: evaluation.approved,
          };
        }
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo crear'));
        throw new FlyncerError('No se pudo crear', error);
      }
    },
    [forCourseMutation, client]
  );

  return {
    forPath,
    forCourse,
    loading: forPathLoading || forCourseLoading,
  };
};

export default useEvaluateQuizActions;
