export const PreLevelSvg = () => {
  return (
    <svg
      width="77"
      height="52"
      viewBox="0 0 77 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.3083 1.01958C64.4269 2.96103 63.4395 6.49276 64.9112 9.59412C66.9992 13.9939 74.2072 13.9376 76.3105 9.50498C77.1862 7.65993 76.9884 4.17526 75.9268 2.7403C74.0198 0.162657 69.8228 -0.675134 67.3083 1.01958ZM72.3297 7.58457L69.6353 10.2789L67.8266 8.47018C66.1193 6.76323 66.0546 6.62474 66.6728 6.00692C67.2907 5.38871 67.3928 5.41472 68.4903 6.46636L69.6533 7.58036L71.6349 5.59874C73.5106 3.72346 73.6544 3.65116 74.3205 4.25368C74.9938 4.86308 74.9092 5.00501 72.3297 7.58457ZM40.6016 6.74639V7.89405L52.8011 7.80071H62.3649V6.65305V5.50539H52.8011L40.6016 5.59874V6.74639ZM68.1032 16.9131C64.6874 18.4609 63.236 22.5137 64.8668 25.9502C67.0141 30.4754 74.1655 30.4754 76.3128 25.9502C78.4298 21.4885 75.4146 16.5593 70.6089 16.6262C69.5462 16.6411 68.4188 16.7701 68.1032 16.9131ZM72.3297 24.0343L69.6353 26.7287L67.8266 24.92C66.1193 23.213 66.0546 23.0745 66.6728 22.4567C67.2907 21.8385 67.3928 21.8645 68.4903 22.9161L69.6533 24.0301L71.6349 22.0485C73.5106 20.1732 73.6544 20.1009 74.3205 20.7035C74.9938 21.3129 74.9092 21.4548 72.3297 24.0343ZM43.2373 22.9116V23.8679H52.8011H62.3649V22.9116V21.9552H52.8011H43.2373V22.9116ZM67.3381 33.8518C65.6361 34.8491 64.765 36.0308 64.3611 37.8893C63.3033 42.7538 67.4685 46.8154 72.2654 45.5981C77.7665 44.2014 78.7164 36.4294 73.7153 33.7347C72.0183 32.8204 69.0034 32.8755 67.3381 33.8518ZM74.9892 37.6463C74.9892 37.7592 73.783 39.0488 72.3086 40.5117L69.6281 43.1716L67.8232 41.3663C66.1193 39.6628 66.0546 39.5243 66.6728 38.9065C67.2907 38.2883 67.3928 38.3143 68.4903 39.3659L69.6533 40.4799L71.6143 38.5189C73.299 36.8342 73.6747 36.6203 74.2822 36.9998C74.6709 37.2428 74.9892 37.5335 74.9892 37.6463ZM49.4724 39.3613V40.3177H52.8011H62.3649V39.3613V38.4049H52.8011H49.4724V39.3613Z"
        fill="#01AA50"
      />
      <path
        d="M26.742 25.781C32.6747 25.781 37.4841 20.9716 37.4841 15.0389C37.4841 9.10625 32.6747 4.29688 26.742 4.29688C20.8094 4.29688 16 9.10625 16 15.0389C16 20.9716 20.8094 25.781 26.742 25.781Z"
        stroke="#01AA50"
        strokeWidth="4.29682"
      />
      <path
        d="M37.4847 30.0781H38.241C39.8116 30.0786 41.3281 30.6525 42.5054 31.6922C43.6827 32.7318 44.4399 34.1656 44.6346 35.7241L45.4747 42.4358C45.5502 43.0404 45.4963 43.6542 45.3165 44.2364C45.1368 44.8186 44.8352 45.3559 44.4319 45.8126C44.0286 46.2694 43.5327 46.6351 42.9773 46.8856C42.4218 47.1361 41.8194 47.2655 41.2101 47.2654H12.2753C11.666 47.2655 11.0636 47.1361 10.5081 46.8856C9.95264 46.6351 9.4568 46.2694 9.05349 45.8126C8.65017 45.3559 8.34862 44.8186 8.16883 44.2364C7.98905 43.6542 7.93514 43.0404 8.0107 42.4358L8.84858 35.7241C9.04343 34.1649 9.80122 32.7305 10.9794 31.6908C12.1577 30.651 13.6751 30.0775 15.2465 30.0781H16.0006"
        stroke="#01AA50"
        strokeWidth="4.29682"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
