import { useMemo } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
} from '@chakra-ui/react';
import { WorkshopButtonModal } from './WorkshopButtonModal';
import { Section } from 'model/Course';
import { AccordionButtonSection } from './AccordionButtonSection';
import { RepoButtonSection } from './RepoButtonSection';

interface Props extends AccordionProps {
  sections: Section[];
  repoUrl?: string | undefined;
  quizId?: string;
  userWorkshop: any;
  quizApproved?: boolean;
  quizBlocked?: boolean;
}

export const Sections = ({ sections, repoUrl, userWorkshop }: Props) => {
  const userSections = useMemo(
    () =>
      sections.map((section) => ({
        ...section,
      })),
    [sections]
  );

  return (
    <Accordion allowToggle>
      {userSections.map((section, i) => (
        <AccordionItem
          borderX="1px"
          borderLeftColor="gray.200"
          borderRightColor="gray.200"
          key={section.id}
          borderTopRadius={i === 0 ? 'md' : '0'}
        >
          <AccordionButtonSection
            name={section.name}
            durationMinutes={section.durationMinutes}
          />
          <AccordionPanel bg="gray.10" p="0">
            {section.lessons.map((lesson) => {
              let isDone = false;
              if (userWorkshop !== undefined) {
                userWorkshop[0]?.lessonsDone.forEach((lessonDone: any) => {
                  if (lesson.id === lessonDone.lessonId) isDone = true;
                });
              }

              return (
                <WorkshopButtonModal
                  key={lesson.id}
                  lesson={lesson}
                  isDone={isDone}
                />
              );
            })}
          </AccordionPanel>
        </AccordionItem>
      ))}
      {repoUrl && <RepoButtonSection repoUrl={repoUrl} />}
    </Accordion>
  );
};
