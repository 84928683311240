import { FormControl } from '@chakra-ui/react';
import { Editor } from '@tinymce/tinymce-react';

interface Props {
  descriptionValue: string;
  handleChange: any;
}

export const DescriptionForm = ({ descriptionValue, handleChange }: Props) => {
  return (
    <FormControl>
      <Editor
        initialValue={descriptionValue}
        onEditorChange={(e) => {
          handleChange({
            target: { name: 'description', value: e },
          });
        }}
        init={{
          branding: false,
        }}
        apiKey={process.env.REACT_APP_TINYMCE_KEY}
      />
    </FormControl>
  );
};
