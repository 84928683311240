import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import {
  QuizForCourse,
  QuizForCourseVariables,
} from 'graphql/generated/QuizForCourse';
import { QUIZ_FOR_COURSE } from 'graphql/queries/quiz';
import { removeNulls } from 'utils/removeNulls';
import { normalizeQuiz } from 'model/Quiz';

const useQuizForCourse = ({ quizId }: QuizForCourseVariables) => {
  const { data, loading, error } = useQuery<
    QuizForCourse,
    QuizForCourseVariables
  >(QUIZ_FOR_COURSE, {
    variables: { quizId },
    fetchPolicy: 'no-cache',
  });

  const quiz = useMemo(
    () =>
      data && data.quizForCourse
        ? normalizeQuiz(removeNulls(data.quizForCourse))
        : undefined,
    [data]
  );

  return {
    error,
    quiz,
    loading,
  };
};

export default useQuizForCourse;
