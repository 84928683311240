import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { Countries } from 'graphql/generated/Countries';
import { COUNTRIES } from 'graphql/queries/location';
import { removeNulls } from 'utils/removeNulls';
import { normalizeCountry, Country } from 'model/Country';

const useCountries = () => {
  const { data, loading, error } = useQuery<Countries>(COUNTRIES);

  const countries = useMemo(() => {
    return (data?.countries || []).reduce<Country[]>(
      (acum, input) =>
        !!input ? acum.concat(normalizeCountry(removeNulls(input))) : acum,
      []
    );
  }, [data]);

  return {
    error,
    countries,
    loading,
  };
};

export default useCountries;
