import { useMutation } from '@apollo/client';

import { AddJob, AddJobVariables } from 'graphql/generated/AddJob';
import { ADD_JOB } from 'graphql/mutations/job';
import { useCallback, useRef } from 'react';
import { FlyncerError } from 'graphql/helpers';
import { addJobPositionInput } from 'graphql/generated/globalTypes';
import { COMPANIES } from 'graphql/queries/company';
import { ADMIN_JOBS } from 'graphql/queries/job';
import { createToast } from 'services/toast';
import * as Sentry from '@sentry/react';

const useAddJobActions = () => {
  const jobPositionId = useRef<string | undefined | null>('');
  const [addJobMutation, { loading }] = useMutation<AddJob, AddJobVariables>(
    ADD_JOB,
    {
      refetchQueries: [{ query: COMPANIES }, { query: ADMIN_JOBS }],
    }
  );

  const addJob = useCallback(
    async (input: addJobPositionInput) => {
      try {
        await addJobMutation({ variables: { input } }).then((res) => {
          jobPositionId.current = res.data?.addJobPosition;
        });

        createToast({
          title: 'Crear oferta',
          description: 'Oferta creada correctamente.',
          status: 'success',
        });
      } catch (error: any) {
        createToast({
          title: 'Error',
          description: 'Revisa los datos del formulario de creación.',
          status: 'error',
        });
        Sentry.captureException(new Error('No se pudo crear la oferta'));
        throw new FlyncerError('No se pudo crear la oferta', error);
      }
    },
    [addJobMutation]
  );

  return {
    jobPositionId,
    addJob,
    loading,
  };
};

export default useAddJobActions;
