import { NavLink, useLocation } from 'react-router-dom';

import {
  Box,
  Flex,
  Button,
  useToken,
  Icon,
  Text,
  useMediaQuery,
  Badge,
} from '@chakra-ui/react';
import { FiUser } from 'react-icons/fi';
import { BsBook, BsHexagon, BsBriefcase, BsCameraVideo } from 'react-icons/bs';
import useJobRequests from 'graphql/hooks/jobs/useJobRequests';
import useMe from 'graphql/hooks/profile/useMe';
import useFullCareer from 'graphql/hooks/career/useFullCareer';
import * as Sentry from '@sentry/react';
import useNotifications from 'graphql/hooks/notifications/useNotifications';

export const BottomBar = () => {
  const { me, error } = useMe();
  const { error: jobRequestsError } = useJobRequests();
  const { error: fullCareerError } = useFullCareer();
  const [brand600] = useToken('colors', ['brand.600']);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const location = useLocation();
  const { notifications } = useNotifications();

  if (error || jobRequestsError || fullCareerError) {
    let message = error
      ? error
      : jobRequestsError
      ? jobRequestsError
      : fullCareerError;
    Sentry.captureException(message?.graphQLErrors[0]);
  }

  if (!me) return null;

  const isCv =
    location.pathname.includes('/cv/') ||
    location.pathname.includes('/profile/flyncermap')
      ? true
      : false;

  const color = isCv ? '' : brand600;

  const seenNotifications = notifications?.filter((notification: any) =>
    notification.seenUsers.find((user: any) => user.id === me?.id)
  );

  return (
    <>
      {isMobile && (
        <Box bg="white" position="fixed" width="100%" bottom="0" zIndex="1">
          <Flex
            as="footer"
            aria-label="Navegación principal"
            h="16"
            justifyContent="center"
          >
            <Button
              className="careerTop"
              as={NavLink}
              exact
              to="/career"
              variant="ghost"
              activeStyle={{ color: brand600 }}
              fontSize="12px"
            >
              <Icon as={BsBook} fontSize="27px" position="absolute" />
              <Text mb="-10">Carrera</Text>
            </Button>
            <Button
              as={NavLink}
              to="/resources"
              className="offers"
              variant="ghost"
              activeStyle={{ color: brand600 }}
              fontSize="12px"
            >
              <Icon as={BsCameraVideo} fontSize="27px" position="absolute" />
              <Text mb="-10">Recursos</Text>
            </Button>
            <Button
              as={NavLink}
              to="/jobs"
              className="offers"
              variant="ghost"
              activeStyle={{ color: brand600 }}
              fontSize="12px"
            >
              <Icon as={BsBriefcase} fontSize="27px" position="absolute" />
              <Text mb="-10">Ofertas</Text>
            </Button>
            <Button
              as={NavLink}
              to="/profile/flyncermap"
              className="offers"
              variant="ghost"
              activeStyle={{ color: brand600 }}
              fontSize="12px"
            >
              <Icon as={BsHexagon} fontSize="27px" position="absolute" />
              <Icon as={BsHexagon} fontSize="15px" position="absolute" />
              <Text mb="-10">Flyncer Map</Text>
            </Button>
            <Button
              className="profile"
              as={NavLink}
              to="/profile"
              variant="ghost"
              activeStyle={{ color: color }}
              fontSize="12px"
            >
              {notifications?.length === seenNotifications?.length ? (
                <Icon as={FiUser} fontSize="27px" position="absolute" />
              ) : (
                <>
                  <Icon as={FiUser} fontSize="27px" position="absolute" />
                  <Badge
                    position="absolute"
                    fontSize="14px"
                    right="25%"
                    top="35%"
                    backgroundColor="orange.400"
                    variant="solid"
                  >
                    !
                  </Badge>
                </>
              )}

              <Text mb="-10">Mi perfil</Text>
            </Button>
          </Flex>
        </Box>
      )}
    </>
  );
};
