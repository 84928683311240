import {
  Box,
  Button,
  Grid,
  Tag,
  useDisclosure,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  useMediaQuery,
} from '@chakra-ui/react';
import { useOneUser } from 'graphql/hooks/admin/useUser';
import { Link as RouterLink } from 'react-router-dom';

import useMe from 'graphql/hooks/profile/useMe';

import { FiDownload } from 'react-icons/fi';
import { BsFillShareFill } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import { IconFlyncerMap } from 'components/IconFlyncerMap';
import { BsLinkedin } from 'react-icons/bs';
import * as Sentry from '@sentry/react';
import { FiChevronLeft } from 'react-icons/fi';
import { useEffect } from 'react';
import { yearMonths } from 'utils/normalizeDate';
import { sanitizePathsHexagon } from 'utils/sanitizePathsHexagon';
import { useConnect } from './connect';
import { IconList } from './IconList';
import { DataUserCV } from './DataUserCV';

function HexagonCV() {
  const { id } = useParams<{ id: any }>();
  const { replace } = useHistory();
  const { me, error } = useMe();
  const uuid = id;
  const { user, error: userError } = useOneUser({ uuid });
  let idUser = user?.id;

  const {
    downloadCV,
    isPrinted,
    lastUpdate,
    isExisted,
    userPathsError,
    paths,
    loadingPaths,
  } = useConnect(idUser);
  const { onOpen } = useDisclosure();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    document.getElementById('top')?.scrollIntoView();
  }, []);

  if (error || userError || userPathsError) {
    let message = error ? error : userError ? userError : userPathsError;
    Sentry.captureException(message?.graphQLErrors[0]);
  }

  if (loadingPaths || !user) return null;

  const { pathsWithLevel, pathsHexagon, pathsId } = sanitizePathsHexagon(paths);

  const { months } = yearMonths();

  let today = new Date();

  const pathsHexagonLevel = pathsHexagon.filter((path: any) => path.level);

  if (pathsHexagonLevel.length < 2) replace('/career');

  return (
    <Box mb="8">
      {idUser === me?.id && (
        <Box
          as="main"
          mx="auto"
          maxW="73rem"
          textAlign="center"
          px={{ base: '4', md: '6' }}
          py={{ base: '8', md: '1' }}
          mt="10"
          mb="4"
          display="flex"
          // justifyContent={{ base: 'space-around', md: 'space-between' }}
          alignItems="center"
          flexWrap="wrap"
          id={isMobile ? 'top' : ''}
        >
          <Button
            as={RouterLink}
            to="/profile/flyncermap"
            variant="link"
            mb="6"
            leftIcon={<FiChevronLeft />}
            my="auto"
            mr="auto"
          >
            Volver a mi perfil
          </Button>
          <HStack
            mt={{ base: '4' }}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Button
              id="download-button"
              leftIcon={<FiDownload />}
              variant="outline"
              ml="-5"
              mb={isMobile ? '2' : '0'}
              onClick={() => {
                downloadCV();
              }}
              colorScheme="brand"
            >
              Descargar certificado
            </Button>
            <Button
              id="linkedinDiploma-button"
              leftIcon={<BsLinkedin />}
              width="auto"
              colorScheme="linkedin"
              ml="2"
              mt={{ base: '3', md: '0' }}
              disabled={
                pathsHexagon.length < 3 || pathsWithLevel.length < 3
                  ? true
                  : false
              }
              onClick={() => {
                window.location.href = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=Flyncer Map&organizationId=71021392&issueYear=${today.getFullYear()}&issueMonth=${
                  today.getMonth() + 1
                }&certUrl=https://app.flyncer.com/cv/${id}&certId=${id}`;
              }}
            >
              Añadir diploma a Linkedin
            </Button>
          </HStack>
          <Menu>
            <MenuButton
              as={Button}
              className="share-button"
              leftIcon={<BsFillShareFill />}
              width="auto"
              colorScheme="green"
              ml="2"
              mt={{ base: '3', md: '15px' }}
              onClick={onOpen}
            >
              Compartir
            </MenuButton>
            <MenuList mr="20">
              <IconList id={id} />
            </MenuList>
          </Menu>
        </Box>
      )}
      <Grid
        id="cv"
        bg="white"
        margin="auto"
        paddingY="10"
        maxW="73rem"
        templateColumns={{ md: '30% 60%' }}
        templateRows={{ sm: 'repeat(2,auto)' }}
      >
        <Box
          bg="white"
          w="100%"
          maxW="50rem"
          textAlign="center"
          px={{ base: '3', md: '5' }}
          py="4"
        >
          <IconFlyncerMap />
          {isExisted?.length ? (
            <Tag
              pb={isPrinted ? 4 : ''}
              mb="2"
              fontWeight="medium"
              mt="2"
              borderRadius="10"
            >
              Expedido el {lastUpdate.getDate()} {months[lastUpdate.getMonth()]}
              , {lastUpdate.getFullYear()}
            </Tag>
          ) : (
            ''
          )}{' '}
        </Box>

        <DataUserCV
          isPrinted={isPrinted}
          pathsWithLevel={pathsWithLevel}
          pathsHexagon={pathsHexagonLevel}
          pathsId={pathsId}
          me={me}
          user={user}
        />
      </Grid>
    </Box>
  );
}

export default HexagonCV;
