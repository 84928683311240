import './styles.css';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from 'recharts';
import { Text, Box } from '@chakra-ui/react';
import useUserPaths from 'graphql/hooks/career/useUserPaths';
import * as Sentry from '@sentry/react';
import { useConnect } from 'containers/Admin/Candidate/PersonalInfo/Hexagon/connect';

export default function MiniHexagonProfile(paths: any) {
  let idUser: string = paths.idUser;

  const { basicHexagonInfo, data } = useConnect(paths, idUser);

  const { error } = useUserPaths({ idUser });

  if (error) Sentry.captureException(error?.graphQLErrors[0]);

  return (
    <>
      {(paths.paths.length < 3 || paths.pathsId?.length < 3) &&
      paths.type !== 'Modal' &&
      paths.cv !== 'HexagonTopBar' ? (
        <Text textStyle="small" color="gray.500" mt="5">
          *Haz 3 test para desbloquear el Flyncer Map.
        </Text>
      ) : (
        ''
      )}

      <Box className="menu" alignItems="center">
        <Box
          className={
            paths.paths.length > 2 && paths.pathsId.length > 2
              ? 'cv-container'
              : paths.cv === 'HexagonTopBar'
              ? 'blurTopBar'
              : 'blur'
          }
        >
          <RadarChart
            width={380}
            height={150}
            data={data.length > 2 ? data : basicHexagonInfo}
          >
            <PolarGrid />
            <PolarAngleAxis dataKey="subject" />
            <PolarRadiusAxis tick={false} angle={90} domain={[0, 100]} />
            <Radar
              preserveAspectRatio="true"
              className="hexagon"
              name="Mike"
              dataKey="A"
              stroke="#01AA50"
              fill="#01AA50"
              fillOpacity={0.4}
            />
          </RadarChart>
        </Box>
      </Box>
    </>
  );
}
