import { gql } from '@apollo/client';
import { FILE_FRAGMENT } from 'graphql/fragments/file';

export const UPLOAD_CV = gql`
  mutation UploadCV($file: Upload!) {
    uploadCV(file: $file) {
      ...FileParts
    }
  }
  ${FILE_FRAGMENT}
`;

export const UPLOAD_PHOTO = gql`
  mutation UploadPhoto($file: Upload!) {
    uploadPhoto(file: $file) {
      ...FileParts
    }
  }
  ${FILE_FRAGMENT}
`;

export const DELETE_CV = gql`
  mutation DeleteCV {
    deleteCV
  }
`;

export const DELETE_PHOTO = gql`
  mutation DeletePhoto {
    deletePhoto
  }
`;
