import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { GET_USER_PATHS } from 'graphql/queries/userPath';
import {
  getUserPaths,
  getUserPathsVariables,
} from 'graphql/generated/getUserPaths';

const useUserPaths = ({ idUser }: getUserPathsVariables) => {
  const {
    data,
    loading: loadingPaths,
    error,
    refetch,
  } = useQuery<getUserPaths, getUserPathsVariables>(GET_USER_PATHS, {
    skip: idUser ? false : true,
    variables: { idUser: idUser },
  });
  const paths = useMemo(() => {
    return data?.getUserPaths ? data.getUserPaths : [];
  }, [data]);

  return {
    paths,
    loadingPaths,
    refetch,
    error,
  };
};

export default useUserPaths;
