import { Fragment, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box, BoxProps, SimpleGrid } from '@chakra-ui/react';

import { CourseCard } from 'components/CourseCard';
import { EndPathCard } from 'components/EndPathCard';
import { Path } from 'model/UserPath';
import { Header } from './Header';
import { NoLevelPlaceholder } from './NoLevelPlaceholder';

interface Props extends BoxProps {
  userPath: Path;
}

export const Itinerary = ({ userPath, ...props }: Props) => {
  const { path, level, courses } = userPath;
  const orderedCourses = useMemo(
    () => courses.sort((a, b) => (a.level?.order || 0) - (b.level?.order || 0)),
    [courses]
  );
  const currentLevel = orderedCourses.filter(
    (course) =>
      course.level?.name === level?.name && course.quizApproved === false
  );
  const currentLevelID =
    currentLevel.length === 0 ? undefined : currentLevel[0].id;

  return (
    <Box {...props}>
      <Header name={path.name} level={level?.name} mb="4" />
      {level ? (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap="8">
          {orderedCourses.map((course) => (
            <Fragment key={course.id}>
              {course.blocked ? (
                <CourseCard
                  title={course.name}
                  subtitle={course.level?.name}
                  tags={course.skills.map(({ name }) => name)}
                  durationMinutes={course.estimatedTimeMinutes}
                  progress={course.progress}
                  quizApproved={course.quizApproved}
                  locked={true}
                />
              ) : (
                <Link to={`/course/${course.id}`}>
                  <CourseCard
                    title={course.name}
                    subtitle={course.level?.name}
                    tags={course.skills.map(({ name }) => name)}
                    durationMinutes={course.estimatedTimeMinutes}
                    progress={course.progress}
                    quizApproved={course.quizApproved}
                    locked={false}
                    active={currentLevelID === course.id}
                  />
                </Link>
              )}
            </Fragment>
          ))}
          {currentLevelID === undefined && <EndPathCard path={path.name} />}
        </SimpleGrid>
      ) : (
        <NoLevelPlaceholder userPath={userPath} />
      )}
    </Box>
  );
};
