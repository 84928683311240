export const orderExp = (experiences: any) => {
  const expWithFin = experiences?.filter(
    (exp: any) => exp?.inicio !== undefined && exp?.fin !== undefined
  );
  const orderedExpWithFin = expWithFin
    ?.sort(
      (a: any, b: any) =>
        new Date(a?.fin).getTime() - new Date(b?.fin).getTime()
    )
    .reverse();

  const expWithoutFin = experiences?.filter(
    (exp: any) => exp?.inicio !== undefined && exp?.fin === undefined
  );
  const orderedExpWithoutFin = expWithoutFin
    ?.sort(
      (a: any, b: any) =>
        new Date(a?.inicio).getTime() - new Date(b?.inicio).getTime()
    )
    .reverse();

  const orderedExp = orderedExpWithoutFin?.concat(orderedExpWithFin);

  return orderedExp;
};
