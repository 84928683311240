import { useCallback, useEffect } from 'react';
import {
  Text,
  Stack,
  Button,
  Box,
  RadioGroup,
  Radio,
  Spinner,
  Center,
  Grid,
  GridItem,
  useMediaQuery,
} from '@chakra-ui/react';
import { Editor } from './editor';
import useMeCV from 'graphql/hooks/profile/useMeCV';
import useMe from 'graphql/hooks/profile/useMe';
import { Formik, Form } from 'formik';
import * as Sentry from '@sentry/react';
import { orderExp } from 'utils/orderExperience';
import { ModalLinkedin } from 'components/ModalLinkedin';
import { useConnect } from './connect';
import { AccordionExp } from './AccordidonExp';

interface Props {
  setForceRender?: (isSend: boolean) => void;
  setvalueExp: (isSend: string) => void;
  valueExp: string;
  errorExp: boolean;
  seterrorExp: (isSend: boolean) => void;
}

export const ExperienceCV = ({
  setForceRender,
  seterrorExp,
  setvalueExp,
  valueExp,
}: Props) => {
  const {
    initialValuesIndex,
    setLoadImport,
    isOpen,
    setisOpen,
    setDeleted,
    deleted,
    errorImport,
    seterrorImport,
    handleSubmit1,
    loadImport,
    loadingExp,
    errorExpLength,
    seterrorExpLength,
    handleDeleteIndex,
    updateDeleted,
    updateUpdates,
    setShowEdit,
    showEdit,
    createCV,
  } = useConnect(setvalueExp, seterrorExp);
  const { loading, refetch, error } = useMeCV();
  const { me, error: meError } = useMe();

  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const onChange = useCallback(
    async (newValue) => {
      if (setvalueExp) setvalueExp(newValue);
      if (newValue === '2' && !me?.user_cv) {
        await createCV();
        seterrorExp(false);
      }
    },
    [setvalueExp, me?.user_cv, createCV, seterrorExp]
  );

  useEffect(() => {
    if (!me) return;
    if (!me.onboarding && me.user_cv) setvalueExp('1');
  }, [me, setvalueExp]);

  if (error || meError) {
    let message = error ? error : meError;
    Sentry.captureException(message?.graphQLErrors[0]);
  }

  useEffect(() => {
    return () => {
      setDeleted(false);
      setLoadImport(false);
      setShowEdit('off');
    };
  }, [setDeleted, setLoadImport, setShowEdit]);

  if (deleted === true) {
    refetch();
    setDeleted(false);
  }

  const orderedExp = orderExp(initialValuesIndex?.experiences);

  if (loading || !me) {
    return null;
  }

  return (
    <Stack spacing={8}>
      {me.onboarding || !me.user_cv ? (
        <>
          <Text mb="-3" mt="-3">
            ¿Tienes experiencia profesional como developer?
          </Text>

          <RadioGroup onChange={onChange}>
            <Stack spacing={5} direction="row">
              <Radio value="1" colorScheme="brand">
                Sí
              </Radio>
              <Radio value="2" ml="3" colorScheme="brand">
                No
              </Radio>
            </Stack>
          </RadioGroup>
        </>
      ) : null}
      {valueExp === '1' && (
        <Stack spacing={5}>
          <Text textStyle="body" fontWeight="semibold">
            Experiencias
          </Text>

          <Grid templateColumns="repeat(5, 1fr)" gap={0}>
            <GridItem mr="2" colSpan={isMobile ? 5 : 1}>
              <Button
                mb="3"
                colorScheme="brand"
                type="submit"
                variant="outline"
                width="max-content"
                onClick={() => {
                  if (setForceRender) setForceRender(true);
                  if (errorImport) seterrorImport(false);
                  if (errorExpLength) seterrorExpLength(false);
                  seterrorExp(false);
                  setShowEdit('on');
                }}
              >
                Añadir experiencia
                <Text fontSize="18px" as="span" ml="1">
                  +
                </Text>
              </Button>
            </GridItem>

            <GridItem colSpan={isMobile ? 5 : 3}>
              <Formik
                initialValues={initialValuesIndex}
                onSubmit={handleSubmit1}
              >
                <Form>
                  <Button
                    mb="3"
                    colorScheme="linkedin"
                    type={
                      me.linkedinURL || orderedExp.length ? 'submit' : 'button'
                    }
                    variant="solid"
                    width="max-content"
                    onClick={() => {
                      if (!me.linkedinURL) setisOpen(true);
                      if (orderedExp.length && me.linkedinURL) {
                        handleDeleteIndex();
                        handleSubmit1(initialValuesIndex);
                      }
                    }}
                  >
                    {orderedExp.length
                      ? 'Actualizar experiencias desde Linkedin'
                      : 'Importar experiencias desde Linkedin'}
                  </Button>
                </Form>
              </Formik>
            </GridItem>
            <ModalLinkedin
              isOpen={isOpen}
              setisOpen={setisOpen}
              handleDelete={handleDeleteIndex}
              initialValues={initialValuesIndex}
              loadImport={loadImport}
              errorImport={errorImport}
              handleSubmit1={handleSubmit1}
              orderedExpLength={orderedExp.length}
            />
          </Grid>
          {errorExpLength && (
            <Text mt="2" fontSize="14px" color="#CB1410">
              No hemos encontrado experiencias en tu perfil. Prueba a añadirlas
              manualmente.
            </Text>
          )}

          {showEdit === 'on' && (
            <Editor
              onTour={me?.onboarding}
              setForceRender={setForceRender}
              update={updateDeleted}
              updateUpdate={updateUpdates}
            />
          )}

          {!orderedExp.length && !me.onboarding && !loadingExp && (
            <Box
              borderTop="1px"
              borderTopColor="gray.300"
              p={{ base: '2', md: '3' }}
              color="gray.400"
            >
              Sin experiencias
            </Box>
          )}

          {loadingExp && (
            <Center>
              <Spinner size="lg" color="brand.500" />
            </Center>
          )}
          <AccordionExp
            orderedExp={orderedExp}
            showEdit={showEdit}
            setShowEdit={setShowEdit}
            setForceRender={setForceRender}
            updateUpdates={updateUpdates}
            updateDeleted={updateDeleted}
          />
        </Stack>
      )}
    </Stack>
  );
};
