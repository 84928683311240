import { Formik, Form } from 'formik';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Stack, Text, Button, Link } from '@chakra-ui/react';
import { Layout } from 'containers/Guest/Shared/Layout';
import { DividerWithText } from 'components/DividerWithText';
import { FormInputPassword } from 'components/FormInputPassword';

import useConnect from './connect';

export const ResetPassword = () => {
  const { handleSubmit, initialValues, schema } = useConnect();

  return (
    <Layout
      title="Cambiar contraseña"
      subtitle="Introduce tu nueva clave de acceso."
    >
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <>
            <Stack as={Form} noValidate spacing="6">
              <FormInputPassword
                name="password"
                autoComplete="current-password"
                label="Nueva contraseña"
              />
              <FormInputPassword
                name="passwordConfirmation"
                label="Repetir contraseña"
              />
              <Button
                type="submit"
                disabled={isSubmitting}
                colorScheme="brand"
                textStyle="button"
              >
                Actualizar
              </Button>
              <DividerWithText py="8" />
              <Box>
                <Text as="span" textStyle="body" mr="2">
                  ¿Ya la cambiaste?
                </Text>
                <Link
                  as={RouterLink}
                  to="/login"
                  textStyle="bodyLink"
                  color="brand.600"
                >
                  Accede ahora
                </Link>
              </Box>
            </Stack>
          </>
        )}
      </Formik>
    </Layout>
  );
};
