import { gql } from '@apollo/client';
import { ME_FRAGMENT } from 'graphql/fragments/me';

export const LOGIN = gql`
  mutation Login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        ...MeParts
      }
    }
  }
  ${ME_FRAGMENT}
`;

export const REGISTER = gql`
  mutation Register($input: UsersPermissionsRegisterInput!) {
    register(input: $input) {
      jwt
      user {
        ...MeParts
      }
    }
  }
  ${ME_FRAGMENT}
`;

export const AUX_REGISTER = gql`
  mutation AuxRegister($cookie: String) {
    auxRegister(cookie: $cookie)
  }
`;
