import { memo, useCallback } from 'react';
import {
  BoxProps,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Question } from 'model/Quiz';
import { Markdown } from 'components/Markdown';
import { quizRenderer } from 'components/Markdown/QuizRenderer';
import { HighlightCode } from 'components/HightlightCode';
import { FiFlag } from 'react-icons/fi';
import useReportActions from 'graphql/hooks/report/useReportActions';
import { createToast } from 'services/toast';
import { ReportModal } from 'components/ReportModal';

interface Props extends BoxProps {
  question: Question;
  questionNumber: number;
  value: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  error?: any;
  quizzErrors: string[];
  idCourse: string;
}

export const QuestionCard = memo(
  ({
    question,
    questionNumber,
    value,
    setFieldValue,
    quizzErrors,
    error,
    idCourse,
    ...props
  }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { createReport } = useReportActions();

    const onChange = useCallback(
      (newValue: string) => {
        let position = 0;
        if (quizzErrors.length > 0) position = quizzErrors.indexOf(newValue);
        if (position === -1 || quizzErrors.length === 0) {
          quizzErrors.push(newValue);
        } else quizzErrors.splice(position, 1);
      },
      [quizzErrors]
    );

    const sendReport = async () => {
      let message: string = '';
      const reason: any = 'QuestionError';

      quizzErrors.forEach((error) => {
        message += error + '.\n';
      });

      const optionIds = question.options.map((q) => {
        return q.id;
      });

      if (quizzErrors.length) {
        await createReport({
          message: message,
          reason: reason,
          optionIds: optionIds,
        });
        onClose();
      } else
        createToast({
          title: `Selecciona una opción`,
          description: 'Debes de seleccionar al menos una opción',
          status: 'error',
        });
    };

    return (
      <FormControl
        id={question.id}
        bg="white"
        p={{ base: '4', lg: '6' }}
        borderRadius="md"
        border="1px"
        isInvalid={!!error}
        borderColor={error ? 'red.500' : 'transparent'}
        {...props}
      >
        <FormLabel
          fontSize="19px"
          fontWeight="500"
          lineHeight="125%"
          display="flex"
          mb="3"
          color={error ? 'red.500' : 'gray.600'}
          className={error ? 'has-error' : ''}
        >
          Pregunta {questionNumber}
        </FormLabel>
        <FormErrorMessage mb="2">{error}</FormErrorMessage>
        <Markdown source={question.content!} renderers={quizRenderer()} />

        {question.precode && <HighlightCode content={question.precode} />}
        {question.isMultiple ? (
          <CheckboxGroup
            onChange={(optionId) =>
              setFieldValue(question.id, optionId, !!error)
            }
            value={value}
            colorScheme="brand"
          >
            <VStack spacing="6" align="stretch" mt="10">
              {question.options.map((option) => (
                <Checkbox value={option.id} key={option.id}>
                  <Markdown
                    source={option.content}
                    renderers={quizRenderer()}
                  />
                </Checkbox>
              ))}
            </VStack>
            <Button
              variant="outline"
              colorScheme="red"
              onClick={onOpen}
              float="right"
              size="xs"
              position="relative"
              top="8px"
            >
              <FiFlag /> <Text ml="1">Reportar</Text>
            </Button>

            <ReportModal
              isOpen={isOpen}
              onChange={onChange}
              onClose={onClose}
              sendReport={sendReport}
            />
          </CheckboxGroup>
        ) : (
          <RadioGroup
            onChange={(optionId) =>
              setFieldValue(question.id, [optionId], !!error)
            }
            value={value[0]}
            colorScheme="brand"
          >
            <VStack spacing="6" align="stretch" mt="10">
              {question.options.map((option) => (
                <Radio value={option.id} key={option.id}>
                  <Markdown
                    source={option.content}
                    renderers={quizRenderer()}
                  />
                </Radio>
              ))}
            </VStack>
            <Button
              variant="outline"
              colorScheme="red"
              onClick={onOpen}
              float="right"
              size="xs"
              position="relative"
              top="8px"
            >
              <FiFlag /> <Text ml="1">Reportar</Text>
            </Button>

            <ReportModal
              isOpen={isOpen}
              onChange={onChange}
              onClose={onClose}
              sendReport={sendReport}
            />
          </RadioGroup>
        )}
      </FormControl>
    );
  }
);
