import {
  FormControl,
  FormLabel,
  Switch,
  SwitchProps,
  Text,
} from '@chakra-ui/react';

interface Props extends SwitchProps {}

export const JobNotifcation = ({ ...props }: Props) => {
  return (
    <FormControl className="receiveOffers" display="flex" alignItems="center">
      <FormLabel htmlFor="job-notification" mb="0">
        <Text textStyle="body">Recibir oportunidades</Text>
      </FormLabel>
      <Switch id="job-notification" colorScheme="brand" size="lg" {...props} />
    </FormControl>
  );
};
