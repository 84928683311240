import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  CompleteItinerary,
  CompleteItineraryVariables,
} from 'graphql/generated/CompleteItinerary';
import { COMPLETE_ITINERARY } from 'graphql/mutations/completeItinerary';
import { FULL_CAREER } from 'graphql/queries/fullCareer';
import * as Sentry from '@sentry/react';
import { initializeEmployabilityCareer } from 'graphql/generated/initializeEmployabilityCareer';
import {
  INITIALIZE_EMPLOYABILITY_CAREER,
  INITIALIZE_MANAGEMENT_CAREER,
} from 'graphql/mutations/userCareer';

const useFullItineraryActions = () => {
  const [completeItineraryMutation, { loading }] = useMutation<
    CompleteItinerary,
    CompleteItineraryVariables
  >(COMPLETE_ITINERARY, { refetchQueries: [{ query: FULL_CAREER }] });

  const completeItinerary = useCallback(
    async (input: CompleteItineraryVariables) => {
      try {
        await completeItineraryMutation({ variables: input });
      } catch (error: any) {
        Sentry.captureException(
          new Error('No se pudo completar el itinerario')
        );
      }
    },
    [completeItineraryMutation]
  );

  const [
    initializeEmployabilityCareerMutation,
    { loading: loadingInitialize },
  ] = useMutation<initializeEmployabilityCareer>(
    INITIALIZE_EMPLOYABILITY_CAREER,
    { refetchQueries: [{ query: FULL_CAREER }] }
  );

  const initializeEmployability = useCallback(async () => {
    try {
      await initializeEmployabilityCareerMutation();
    } catch (error: any) {
      Sentry.captureException(new Error('No se pudo iniciar la carrera'));
    }
  }, [initializeEmployabilityCareerMutation]);

  const [
    initializeManagementCareerMutation,
    { loading: loadingInitializeManagement },
  ] = useMutation<initializeEmployabilityCareer>(INITIALIZE_MANAGEMENT_CAREER, {
    refetchQueries: [{ query: FULL_CAREER }],
  });

  const initializeManagement = useCallback(async () => {
    try {
      await initializeManagementCareerMutation();
    } catch (error: any) {
      Sentry.captureException(new Error('No se pudo iniciar la carrera'));
    }
  }, [initializeManagementCareerMutation]);

  return {
    initializeManagement,
    initializeEmployability,
    loading,
    completeItinerary,
    loadingInitialize,
    loadingInitializeManagement,
  };
};

export default useFullItineraryActions;
