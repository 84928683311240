import { useCallback, useState } from 'react';
import { FormikHelpers } from 'formik';
import * as Yup from 'yup';
import useAuth from 'graphql/hooks/auth/useAuth';
import usePixel from 'graphql/hooks/profile/usePixelActions';
import { FormValues } from './types';
import { appConfig } from 'config';
import useCountries from 'graphql/hooks/profile/useCountries';
import { useLocation } from 'react-router-dom';
import { getCookie } from 'utils/getCookie';
import useAuxRegister from 'graphql/hooks/auth/useAuxRegister';

const initialValues = {
  referred_from_num: '',
  email: '',
  password: '',
  accept: false,
};

const schema = Yup.object().shape({
  referred_from_num: Yup.string().optional(),
  email: Yup.string()
    .email('Introduce un email válido')
    .required('Este campo es requerido'),
  password: Yup.string()
    .required('Este campo es requerido')
    .min(8, 'Mínimo debe tener 8 carácteres'),
  accept: Yup.boolean()
    .required('Los terminos y condiciones deben ser aceptados.')
    .oneOf([true], 'Los terminos y condiciones deben ser aceptados.'),
});

const useConnect = () => {
  const { countries } = useCountries();
  const { register } = useAuth();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { sendEvent } = usePixel();
  const location = useLocation();
  const cookie = getCookie('amp_306923');

  const { auxRegister } = useAuxRegister();

  const handleSubmit = useCallback(
    async (
      values: FormValues,
      { setSubmitting, setFieldError }: FormikHelpers<FormValues>
    ) => {
      let userContinent = '';
      let country: any;
      setSubmitting(true);

      try {
        fetch(
          `https://api.ipregistry.co/?key=${process.env.REACT_APP_IPREGISTRY_KEY}`
        )
          .then((response) => {
            return response.json();
          })
          .then((payload) => {
            if (payload.location.country.name === 'Spain') {
              country = countries.find((country) => country?.name === 'España');
            } else if (
              countries.some(
                (country) => country?.name === payload.location.country.name
              )
            ) {
              country = countries.find(
                (country) => country?.name === payload.location.country.name
              );
            } else {
              country = countries.find((country) => country?.name === 'Otro');
            }

            userContinent = payload.location.continent.name;
          })
          .finally(async () => {
            const pathId: any = localStorage.getItem('pathID');
            let levelId: any = localStorage.getItem('evaluation');

            if (location.pathname.toLowerCase().includes('/becasow')) {
              values.referred_from_num = 'finbecasow';
            }

            levelId = JSON.parse(levelId);

            levelId = levelId?.level?.id;

            if (pathId && levelId) {
              await register({
                input: {
                  referred_from_num: values.referred_from_num,
                  username: values.email,
                  email: values.email,
                  password: values.password,
                  countryResidence: country?.id,
                  continent: userContinent,
                  pathId,
                  levelId,
                },
              });
              await auxRegister(cookie);
            } else {
              await register({
                input: {
                  referred_from_num: values.referred_from_num,
                  username: values.email,
                  email: values.email,
                  password: values.password,
                  countryResidence: country?.id,
                  continent: userContinent,
                },
              });

              await auxRegister(cookie);
            }
            await sendEvent({
              ip: '',
              userAgent: '',
              event: 'Iniciar Registro',
              eventID: 'START_REGISTER',
            });
          });
        setShowConfirmation(true);
      } catch (error: any) {
        if (error.fields.id === 'Auth.form.error.email.taken') {
          setFieldError('email', 'Email no disponible');
        } else {
          setFieldError('password', error.message);
        }
        setSubmitting(false);
      }
    },
    [
      register,
      setShowConfirmation,
      sendEvent,
      countries,
      location.pathname,
      auxRegister,
      cookie,
    ]
  );

  const handleGithubProvider = () => {
    window.location.href = `${appConfig.apiUrl}/connect/github`;
  };

  const handleLinkedinProvider = () => {
    window.location.href = `${appConfig.apiUrl}/connect/linkedin`;
  };

  return {
    location,
    handleSubmit,
    initialValues,
    schema,
    showConfirmation,
    handleGithubProvider,
    handleLinkedinProvider,
  };
};

export default useConnect;
