import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { DOWNLOADABLES } from 'graphql/queries/downloadableFile';
import { allDownloadableFiles } from 'graphql/generated/allDownloadableFiles';

const useDownloadables = () => {
  const { data, loading, error } =
    useQuery<allDownloadableFiles>(DOWNLOADABLES);

  const downloadables = useMemo(() => (data ? data : undefined), [data]);

  return {
    error,
    downloadables,
    loading,
  };
};

export default useDownloadables;
