export const sanitizeName = (
  name: String | undefined,
  email: String | undefined
) => {
  let text: String | undefined = '';

  name === '  ' ||
  name === ' ' ||
  name === 'null null' ||
  name === 'undefined undefined'
    ? (text = email)
    : name === undefined
    ? (text = 'Has Picado')
    : (text = name);

  return text;
};
