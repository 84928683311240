import { FormikHelpers } from 'formik';
import { useCallback, useMemo, useRef, useState } from 'react';
import { FormValues } from './types';
import useCompanies from 'graphql/hooks/company/useCompany';
import useAddJobActions from 'graphql/hooks/jobs/useJobPositionActions';
import { addJobPositionInput } from 'graphql/generated/globalTypes';
import { useJob } from 'graphql/hooks/admin/useJob';
import { useParams } from 'react-router-dom';
import useJobActions from 'graphql/hooks/admin/useJobActions';
import * as Yup from 'yup';
import useCountries from 'graphql/hooks/profile/useCountries';
import * as Sentry from '@sentry/react';
import { ENUM_JOBPOSITION_WHERE_TO_SEARCH } from 'graphql/generated/globalTypes';
import axios from 'axios';

const useConnect = (salaryMinState?: string) => {
  const { id } = useParams<{ id: string }>();
  let jobId = id;
  // const { job, loading: loadingJob, refetch: refetchJob } = OneJob({ id });
  const { countries, loading: loadingCountries } = useCountries();
  const [errorLocation, seterrorLocation] = useState(false);
  const [errorPath, seterrorPath] = useState(false);
  const [errorValidation, seterrorValidation] = useState(false);
  const { refetch: refetchActiveJob, error } = useJob({ jobId });
  const { updateJob } = useJobActions();
  const { addJob, jobPositionId } = useAddJobActions();
  const { company, loading, refetch, error: companyError } = useCompanies();
  const [excelUsers, setExcelUsers] = useState(0);
  const selectedLocations = useRef([{ label: '', value: '' }]);
  const selectedPaths = useRef([{ id: '', name: '' }]);

  if (error || companyError) {
    let message = error ? error : companyError;
    Sentry.captureException(message?.graphQLErrors[0]);
  }

  const schema = Yup.object().shape({
    name: Yup.string().required('Este campo es requerido'),
    salaryMin: Yup.number()
      .typeError('Este campo es requerido')
      .required('Este campo es requerido.')
      .min(15000, 'El valor debe ser superior a 15000'),
    salaryMax: Yup.number()
      .typeError('Este campo es requerido')
      .required('Este campo es requerido.')
      .min(
        salaryMinState ? parseInt(salaryMinState) : 15000,
        'El salario máximo debe ser superior al minimo'
      ),
    profession: Yup.object().shape({
      professionId: Yup.string().required('Este campo es requerido'),
      experienceYears: Yup.number().required('Este campo es requerido'),
    }),
  });

  const desiredCountries = countries.filter(
    (country) => country.name === 'España'
  );

  const initialValues = useMemo(
    () => ({
      name: '',
      description: '',
      desiredLocations: [''],
      desiredCountries: desiredCountries.map((country) => ({
        label: country.name,
        value: country.id,
      })),
      where_to_search: ENUM_JOBPOSITION_WHERE_TO_SEARCH.Spain,
      expYears: 0,
      pathGroups: [['']],
      salaryMin: 0,
      salaryMax: 0,
      profession: {
        experienceYears: 0,
        professionId: '1',
      },
      companyType: undefined,
    }),
    [desiredCountries]
  );

  const isSlot = localStorage.getItem('isSlot');

  const handleSubmit = useCallback(
    async (
      values: FormValues,
      { setSubmitting }: FormikHelpers<FormValues>
    ) => {
      setSubmitting(true);

      if (values.pathGroups[0][0] === '') seterrorPath(true);
      if (values.desiredLocations[0] === '') seterrorLocation(true);

      if (
        values.desiredLocations[0] === '' ||
        values.pathGroups[0][0] === '' ||
        values.name === '' ||
        values.salaryMin < 1500 ||
        values.salaryMax < values.salaryMin
      )
        seterrorValidation(true);

      try {
        let object: addJobPositionInput = {
          salaryMin: values.salaryMin,
          salaryMax: values.salaryMax,
          description: values.description,
          name: values.name,
          pathGroups: values.pathGroups.map((pathGroup) => ({
            paths: pathGroup.map((path) => path),
          })),
          where_to_search: values.where_to_search,
          restrictedCountries: values.desiredCountries.map((country) => ({
            value: country.value,
          })),
          restrictedCities: values.desiredLocations.map((city) => ({
            value: city,
          })),
          profession: {
            professionId: values.profession.professionId,
            experienceYears: values.profession.experienceYears,
            subProfessions: values.profession.subProfessions,
          },
          isSlot: isSlot === 'true' ? true : false,
          companyType: values.companyType ? values.companyType : undefined,
        };

        const locations = selectedLocations.current.map((city) => {
          return city.label;
        });

        const technologies = selectedPaths.current.map((path) => {
          return path.name;
        });

        const excelObject = {
          Ubicaciones: locations.join(', '),
          Tecnologias: technologies.join(', '),
          'Años experiencia': values.profession.experienceYears,
          Salario: `${values.salaryMin} - ${values.salaryMax}`,
          Matches: excelUsers,
        };

        if (window.location.href.substring(0, 5) === 'https')
          axios.post(
            'https://sheet.best/api/sheets/96e23f9f-2bfb-4856-af30-741ea43cde0f',
            excelObject
          );

        if (id) {
          await updateJob({
            input: {
              where: { id },
              data: object,
            },
          });
        } else {
          await addJob(object);
        }
        // refetchJob();
        refetchActiveJob();
        refetch();
      } catch (error: any) {
        console.log(error);
      }
    },
    [
      isSlot,
      addJob,
      refetch,
      id,
      updateJob,
      refetchActiveJob,
      excelUsers,
      selectedPaths,
      selectedLocations,
    ]
  );

  return {
    isSlot,
    jobPositionId,
    id,
    schema,
    company,
    loading,
    loadingCountries,
    initialValues,
    handleSubmit,
    setExcelUsers,
    excelUsers,
    countries,
    selectedLocations,
    selectedPaths,
    seterrorLocation,
    seterrorPath,
    errorLocation,
    errorPath,
    errorValidation,
    seterrorValidation,
  };
};

export default useConnect;
