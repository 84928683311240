import { User_user, User_user_careers } from 'graphql/generated/User';
import { removeNulls, WithoutNulls } from 'utils/removeNulls';
import { normalizeStudies } from 'model/Study';

const getFullName = ({ name, surname, email }: WithoutNulls<User_user>) => {
  if (name || surname) {
    return [name, surname].join(' ');
  }
  return email;
};

interface Path {
  id: string;
  name: string;
  level: string;
}

const normalizeCareer = (input: WithoutNulls<User_user_careers>) => ({
  id: input.id,
  name: input.career?.name || '',
  paths: (input.activePaths || []).reduce<Path[]>(
    (acum, input) =>
      !!input
        ? acum.concat({
            id: input.id,
            name: input.path?.name || '',
            level: input.level?.name || '',
          })
        : acum,
    []
  ),
});

export type Career = ReturnType<typeof normalizeCareer>;

export const normalizeUser = (input: WithoutNulls<User_user>) => {
  return {
    id: input.id,
    name: input.name,
    surname: input.surname,
    email: input.email,
    fullName: getFullName(input),
    phoneNumber: input.phoneNumber,
    photo: input.photo,

    studies: normalizeStudies(input.studies || []),
    linkedinURL: input.linkedinURL,
    githubURL: input.githubURL,
    cv: input.cv?.url || undefined,
    englishLevel: input.englishLevel,

    careers: (input.careers || []).reduce<Career[]>(
      (acum, input) =>
        !!input ? acum.concat(normalizeCareer(removeNulls(input))) : acum,
      []
    ),
    user_cv: input.user_cv,
  };
};

export type User = ReturnType<typeof normalizeUser>;
