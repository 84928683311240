import { Formik, Form } from 'formik';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Stack, Text, Button, Link } from '@chakra-ui/react';
import { DividerWithText } from 'components/DividerWithText';
import { FormInput } from 'components/FormInput';
import { FormInputPassword } from 'components/FormInputPassword';
import { FormCheckbox } from 'components/FormCheckbox';

import useConnect from './connect';
import { Confirmation } from './Confirmation';
import { LayoutCompany } from '../Shared/LayoutCompany';

export const RegisterCompany = () => {
  const {
    handleSubmit,
    initialValues,
    schema,
    showConfirmation,
  } = useConnect();

  if (showConfirmation) {
    return (
      <LayoutCompany>
        <Confirmation />
        <DividerWithText py="8" />
        <Box>
          <Text as="span" textStyle="body" mr="2">
            ¿Ya la has confirmado?
          </Text>
          <Link
            as={RouterLink}
            to="/company"
            textStyle="bodyLink"
            color="brand.600"
          >
            Accede ahora
          </Link>
        </Box>
      </LayoutCompany>
    );
  }

  return (
    <LayoutCompany
      title="Únete a flyncer"
      subtitle="Crea tu cuenta y encuentra el talento que necesitas."
    >
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <>
            <Stack spacing="6">
              <Stack as={Form} noValidate spacing="6">
                <FormInput
                  name="comercialName"
                  label="Nombre Comercial"
                  autoComplete="nombre"
                />
                <FormInput
                  name="razonSocial"
                  label="Razón Social"
                  autoComplete="razonSocial"
                />
                <FormInput name="CIF" label="CIF" autoComplete="cif" />
                <FormInput name="email" label="Email" autoComplete="email" />
                <FormInputPassword
                  name="password"
                  autoComplete="current-password"
                  label="Contraseña"
                />
                <FormCheckbox name="accept">
                  He leido y acepto los{' '}
                  <Link
                    href="https://flyncer.com/gprd/"
                    isExternal
                    textDecoration="underline"
                  >
                    Términos y Condiciones
                  </Link>
                  .
                </FormCheckbox>

                <Button
                  type="submit"
                  disabled={isSubmitting}
                  colorScheme="brand"
                  textStyle="button"
                >
                  Crear cuenta
                </Button>
              </Stack>
              <Box mt="4">
                <Text as="span" textStyle="body" mr="2">
                  ¿Ya tienes cuenta?
                </Text>
                <Link
                  as={RouterLink}
                  to="/company/login"
                  textStyle="bodyLink"
                  color="brand.600"
                >
                  Accede ahora
                </Link>
              </Box>
            </Stack>
          </>
        )}
      </Formik>
    </LayoutCompany>
  );
};
