import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import Joyride from 'react-joyride';
import { useHistory } from 'react-router-dom';

interface Props {
  me: any;
  isOpen2: boolean;
  setisOpen2: (isOpen: boolean) => void;
  onOpen: () => void;
  steps: any[];
  stepIndex: number;
  callBack?: (data: any) => void;
}

export const ModalOnboarding = ({
  me,
  isOpen2,
  setisOpen2,
  stepIndex,
  steps,
  onOpen,
  callBack,
}: Props) => {
  const { replace } = useHistory();

  return (
    <>
      {me?.onboarding && (
        <Modal
          id="onboardingStep1"
          isOpen={isOpen2}
          onClose={() => {
            setisOpen2(false);
            replace('/onboarding-careers-selector');
            if (localStorage.getItem('button')) {
              onOpen();
            }
          }}
        >
          <ModalOverlay />
          <ModalContent display="block" minWidth="34%">
            <ModalHeader id="onboarding-header">
              ¡Bienvenido a Flyncer!
            </ModalHeader>
            <ModalBody>
              En Flyncer podrás combinar la búsqueda de empleo y la formación.
              ¡Nuestro objetivo es ayudarte a encontrar el trabajo de tus
              sueños!
              <br />
              <br />
              Comencemos con un pequeño tour para que conozcas cómo funciona.
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="brand"
                onClick={() => {
                  setisOpen2(false);
                  replace('/onboarding-careers-selector');
                  if (localStorage.getItem('button')) {
                    onOpen();
                  }
                }}
              >
                Comenzar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {me?.onboarding && !isOpen2 && (
        <Joyride
          showSkipButton={false}
          continuous={true}
          showProgress={false}
          steps={steps}
          stepIndex={stepIndex}
          callback={callBack}
          floaterProps={{ placement: 'right' }}
          styles={{
            options: { zIndex: 3000, overlayColor: '#3A3939' },
            buttonNext: {
              backgroundColor: '#009253',
            },
          }}
        />
      )}
    </>
  );
};
