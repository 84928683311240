import { chakra } from '@chakra-ui/react';

interface Props {
  fromCompany?: boolean;
}

export const FlyncerSvg = ({ fromCompany = false }: Props) => {
  return (
    <chakra.svg
      width="21"
      height="21"
      viewBox="0 0 13 13"
      position={fromCompany ? 'absolute' : 'relative'}
      right={fromCompany ? '-8px' : '0'}
      top={fromCompany ? '-12px' : '0'}
      ml={fromCompany ? '-4' : '0'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.5" cy="6.5" r="6.5" fill="#C8FACC" />
      <path
        d="M5.46071 11.7308C5.41277 11.7308 5.37083 11.7128 5.33487 11.6768C5.29892 11.6409 5.28094 11.5989 5.28094 11.551C5.28094 11.4971 5.29292 11.4491 5.31689 11.4072L5.93564 9.88383L4.20978 5.81188C4.18581 5.75795 4.17383 5.716 4.17383 5.68604C4.17982 5.6321 4.20079 5.58716 4.23675 5.5512C4.27271 5.50926 4.31765 5.48828 4.37158 5.48828H5.09968C5.21354 5.48828 5.29444 5.54521 5.34238 5.65907L6.5379 8.59843L7.76937 5.65907C7.81731 5.54521 7.89821 5.48828 8.01207 5.48828H8.73118C8.77912 5.48828 8.82107 5.50626 8.85702 5.54221C8.89897 5.57817 8.91995 5.62012 8.91995 5.66806C8.91995 5.71001 8.90796 5.75795 8.88399 5.81188L6.42252 11.56C6.36859 11.6738 6.28469 11.7308 6.17083 11.7308H5.46071Z"
        fill="#595F69"
      />
      <path
        d="M8.90667 4.61304C8.90667 4.65999 8.89324 4.69911 8.86637 4.73041C8.83412 4.75649 8.79382 4.76953 8.74545 4.76953L8.13281 4.76953C8.06832 4.76953 8.01727 4.75388 7.97965 4.72258C7.93666 4.69129 7.89098 4.64173 7.84261 4.57392L6.52866 3.07061L5.2147 4.57392C5.16633 4.64173 5.12334 4.69128 5.08572 4.72258C5.04273 4.75388 4.9863 4.76953 4.91644 4.76953L4.31186 4.76953C4.25812 4.76953 4.21781 4.75388 4.19094 4.72258C4.1587 4.69128 4.14258 4.65477 4.14258 4.61304C4.14258 4.57131 4.16676 4.51653 4.21513 4.44872L6.06917 2.2099C6.12829 2.12644 6.18472 2.06906 6.23846 2.03776C6.2922 2.00124 6.35669 1.98299 6.43192 1.98299L6.62539 1.98299C6.70063 1.98299 6.76511 2.00124 6.81885 2.03776C6.86722 2.06906 6.92096 2.12644 6.98008 2.2099L8.84218 4.44872C8.88518 4.51132 8.90667 4.56609 8.90667 4.61304Z"
        fill="#33CC6B"
      />
    </chakra.svg>
  );
};
