import {
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import useAuth from 'graphql/hooks/auth/useAuth';
import useNotifications from 'graphql/hooks/notifications/useNotifications';
import useNotificationsActions from 'graphql/hooks/notifications/useNotificationsActions';
import useMe from 'graphql/hooks/profile/useMe';
import { useState } from 'react';
import { FiBell, FiSettings } from 'react-icons/fi';
import { NavLink, useHistory } from 'react-router-dom';
import { yearMonths } from 'utils/normalizeDate';

export const MobileOptions = () => {
  const { notifications } = useNotifications();
  const { deleteNotification } = useNotificationsActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isOpen2, setisOpen2] = useState(false);
  const { replace, location } = useHistory();
  const history = useHistory();
  const { months } = yearMonths();
  const { logout } = useAuth();
  const { me } = useMe();

  if (!notifications) return null;

  let notificacions2 = [...notifications];

  const orderedNotifications = notificacions2
    ?.sort(
      (a: any, b: any) =>
        new Date(a?.created_at).getTime() - new Date(b?.created_at).getTime()
    )
    .reverse();

  const seenNotifications = orderedNotifications.filter((notification) =>
    notification.seenUsers.find((user: any) => user.id === me?.id)
  );

  return (
    <HStack position="absolute" ml="250">
      <Button
        as={IconButton}
        variant="ghost"
        fontSize="32"
        bg="transparent"
        p="0"
        icon={<FiSettings />}
        onClick={() => setisOpen2(true)}
      />
      <Modal
        isOpen={isOpen2}
        onClose={() => {
          setisOpen2(false);
        }}
        size="100%"
      >
        <ModalOverlay />
        <ModalContent position="fixed" bottom="-16">
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" padding="5">
              <Button
                as={NavLink}
                to="/configuration"
                aria-label="Configuración"
                variant="ghost"
                w="98%"
              >
                Configuración
              </Button>
              <Button
                variant="ghost"
                w="98%"
                onClick={() => {
                  if (
                    location.pathname.includes('/jobsOffer/') ||
                    location.pathname.includes('/cv/')
                  )
                    history.go(0);
                  logout();
                }}
              >
                Logout
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Button
        as={IconButton}
        fontSize="32px"
        variant="ghost"
        bg="transparent"
        position="relative"
        p="0"
        icon={
          notifications?.length === seenNotifications?.length ? (
            <FiBell />
          ) : (
            <>
              <FiBell />{' '}
              <Badge
                position="relative"
                fontSize="16px"
                right="12%"
                top="5%"
                backgroundColor="orange.400"
                variant="solid"
              >
                !
              </Badge>
            </>
          )
        }
        aria-label="Notifications"
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="100%">
        <ModalOverlay />
        <ModalContent position="fixed" bottom="-16">
          <ModalCloseButton />
          <ModalBody>
            {orderedNotifications.length ? (
              orderedNotifications?.map((notification: any, index: number) => {
                const created_at = new Date(notification?.created_at);

                return (
                  <>
                    <Flex direction="column" padding="5">
                      <Stack
                        onClick={() => {
                          deleteNotification({
                            notificationID: notification?.id,
                          });
                          replace(notification?.Url);
                        }}
                      >
                        <Grid templateColumns="repeat(8,1fr)">
                          <GridItem
                            colStart={1}
                            colSpan={8}
                            rowStart={1}
                            fontWeight={600}
                            fontSize="16px"
                            color="gray.500"
                          >
                            {notification?.Title}
                          </GridItem>
                          <GridItem
                            color="gray.500"
                            fontSize="14px"
                            whiteSpace="pre"
                            colStart={8}
                            rowStart={1}
                          >
                            {created_at.getDate()}{' '}
                            {months[created_at.getMonth()]}
                          </GridItem>
                        </Grid>

                        <Text color="gray.500" fontSize="14px">
                          {notification?.Message}
                        </Text>
                      </Stack>
                    </Flex>
                    {index !== notifications?.length - 1 && <Divider />}
                  </>
                );
              })
            ) : (
              <Flex direction="column" padding="5">
                No tienes notificaciones
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </HStack>
  );
};
