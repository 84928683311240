import { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import useQuizForPath from 'graphql/hooks/career/useQuizForPath';
import useEvaluateQuizActions from 'graphql/hooks/career/useEvaluateQuizActions';
import { useHistory } from 'react-router-dom';

import { Quiz } from 'components/Quiz';
import { Answer } from 'components/Quiz/Form';
import * as Sentry from '@sentry/react';
import { ModalQuizForPath } from 'components/ModalQuiz/ForPath';

type Evaluation = {
  level: string;
  percent: number;
  jobsMatch: number;
};

export const QuizForPath = () => {
  const { id } = useParams<{ id: string }>();
  const { quiz, loading, error } = useQuizForPath({ userPathId: id });
  const { forPath } = useEvaluateQuizActions();
  const [evaluation, setEvaluation] = useState<Evaluation | undefined>();
  const { replace } = useHistory();

  if (error) Sentry.captureException(error?.graphQLErrors[0]);

  const handleSubmit = useCallback(
    async (answers: Answer[]) => {
      try {
        const response = await forPath({ userPathId: id, answers });

        if (response) {
          setEvaluation({
            level: response.level.name,
            percent: response.successPercent,
            jobsMatch: response.jobsMatch,
          });

          replace(`/quiz/path/result/${id}`);
        }
      } catch (error: any) {
        console.log(error);
      }
    },
    [id, forPath, setEvaluation, replace]
  );

  if (loading && !quiz) return <div></div>;

  if (!quiz) return <div>El quiz no existe o no lo has desbloqueado aún.</div>;

  return (
    <>
      <Quiz
        quiz={quiz}
        quizType="Evaluación de nivel"
        handleSubmit={handleSubmit}
      />
      {evaluation && (
        <ModalQuizForPath
          isOpen={true}
          onClose={() => {}}
          level={evaluation.level}
          percent={evaluation.percent}
          matchUserJobs={evaluation.jobsMatch}
        />
      )}
    </>
  );
};
