import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Modal,
  ModalProps,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
  Divider,
  Text,
} from '@chakra-ui/react';
import { FiAward } from 'react-icons/fi';
import { Header } from './shared/Header';
import { Percent } from './shared/Percent';
import { useEffect, useRef } from 'react';

interface Props extends Omit<ModalProps, 'children'> {
  level: string;
  percent: number;
  matchUserJobs?: number | undefined;
}

export const ModalQuizForPath = ({
  level,
  percent,
  isOpen,
  onClose,
  matchUserJobs,
}: Props) => {
  const jobId = localStorage.getItem('jobId');
  const jobIdRef = useRef(jobId);
  const { replace } = useHistory();

  useEffect(() => {
    localStorage.removeItem('jobId');
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      scrollBehavior="inside"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody mt="2">
          <Header icon={FiAward} title="Tu nivel" subtitle={level} />
          <Divider mb="6" />
          <Percent mb="7" percent={percent} />
          <Text textStyle="body" color="gray.500">
            Sigue completando los cursos recomendados para ascender de nivel
          </Text>
          {matchUserJobs ? (
            <Button
              variant="ghost"
              mt="4"
              p="2"
              colorScheme="brand"
              onClick={() => {
                replace('/jobs');
              }}
            >
              Ahora haces match con {matchUserJobs}{' '}
              {matchUserJobs !== 1 ? 'ofertas' : 'oferta'}
            </Button>
          ) : null}
        </ModalBody>

        <ModalFooter>
          <Button
            ml="4"
            colorScheme="brand"
            as={RouterLink}
            to={
              jobIdRef.current !== null
                ? `/jobsOffer/${jobIdRef.current}`
                : '/career'
            }
          >
            {jobIdRef.current !== null
              ? 'Volver a la oferta'
              : 'Ir a mi carrera'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
