import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Box,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Divider,
  Button,
  Switch,
  HStack,
  Badge,
  useToken,
  useMediaQuery,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import useUserLevel from 'graphql/hooks/career/useUserLevel';
import * as Sentry from '@sentry/react';

import { useJobMatchUsers } from 'graphql/hooks/jobs/useJobsMatchUsers';

import useConnect from '../connect';
import { OnboardingModal } from '../OnboardingModal';
import { JobNotifcation } from '../JobNotifcation';
import { AllJobList } from '../AllJobList';
import { JobList } from '../JobList';

export const EndOnboarding = () => {
  const {
    me,
    toggleAllowReceiveJob,
    loading,
    currentLevelID,
    allJobsError,
    getJobs,
    acceptedJobsFiltered,
    acceptedJobsOrdered,
    pendingJobsFiltered,
    jobsFiltered,
    pathsNames,
  } = useConnect();
  const idUser = me?.id;
  const { userLevel, error } = useUserLevel();
  const [orange400] = useToken('colors', ['orange.400']);
  const [showClosed, setShowClosed] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const { replace } = useHistory();

  const handleClick = () => {
    setShowClosed(!showClosed);
  };

  const { jobsMatchUserUser } = useJobMatchUsers();

  const offerId = localStorage.getItem('offerId');
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    document.getElementById('top')?.scrollIntoView();
  }, []);

  if (offerId !== null && me?.onboarding) {
    replace(`/jobsOffer/${offerId}`);
  }

  if (error || allJobsError) {
    let message = error ? error : allJobsError;
    Sentry.captureException(message?.graphQLErrors[0]);
  }

  if (!me || !getJobs) return null;

  if (!me.user_cv && me.onboarding) replace('/onboarding-profile');
  else if (
    !me?.desiredMinSalary &&
    (!me?.desiredLocations.length || !me.desiredCountries.length) &&
    me.onboarding
  )
    replace('/profile/goals');
  else if (
    (!me.name ||
      !me.surname ||
      (me?.phoneNumber && me?.phoneNumber?.length < 10)) &&
    me.onboarding
  )
    replace('/profile/contact');

  return (
    <>
      <OnboardingModal
        isMobile={isMobile}
        isOpen={isOpen}
        setisOpen={setisOpen}
        pathsNames={pathsNames}
        idUser={idUser}
        currentLevelID={currentLevelID}
      />
      <Box
        as="main"
        mx="auto"
        w="100%"
        maxW="container.xl"
        px={{ base: '4', md: '6' }}
        py={{ base: '8', md: '14' }}
        id={isMobile ? 'top' : ''}
      >
        <Flex
          mb={{ base: '6', md: '8' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Text as="h3" textStyle={{ base: 'h4', md: 'h3' }}>
            Oportunidades
          </Text>
          <Button
            variant="outline"
            colorScheme="brand"
            as={RouterLink}
            to="/profile/goals"
          >
            Configuración
          </Button>
        </Flex>
        <Tabs colorScheme="brand" mb="10">
          <Flex
            flexDirection={{ base: 'column', md: 'row-reverse' }}
            justify="space-between"
            align="center"
          >
            <Box alignSelf="flex-start" mb={{ base: '6', md: '0' }}>
              <JobNotifcation
                isChecked={me.allowReceiveJobPositions}
                onChange={toggleAllowReceiveJob}
                isDisabled={loading.update}
              />
            </Box>
            <TabList
              border="0"
              position="relative"
              zIndex={1}
              w={{ base: '100%', md: 'auto' }}
            >
              <Tab fontWeight="semibold">
                <Text textStyle="body">Publicadas</Text>
              </Tab>
              <Tab fontWeight="semibold">
                <Text textStyle="body">Recibidas</Text>
                {pendingJobsFiltered.length > 0 && (
                  <Badge ml="1" backgroundColor={orange400} variant="solid">
                    !
                  </Badge>
                )}
              </Tab>
              <Tab fontWeight="semibold">
                <Text textStyle="body">Aceptadas</Text>
              </Tab>
            </TabList>
          </Flex>
          <Box pos="relative" zIndex={0}>
            <Divider
              borderBottomWidth="2px"
              opacity={1}
              borderColor="gray.200"
            />
          </Box>
          <TabPanels>
            <TabPanel p="0" mb={isMobile ? '100' : '0'}>
              <AllJobList
                userId={me.id}
                active={true}
                job={jobsFiltered}
                level={userLevel}
                jobsMatchUserUser={jobsMatchUserUser}
                tab="1"
              />
            </TabPanel>
            <TabPanel p="0" mb={isMobile ? '120' : '0'}>
              <JobList
                active={me.allowReceiveJobPositions}
                job={pendingJobsFiltered}
                receive={true}
              />
            </TabPanel>
            <TabPanel p="0" mb={isMobile ? '120' : '0'}>
              <HStack align="end">
                <Text mt="4">Mostrar ofertas cerradas</Text>
                <Switch colorScheme="brand" onChange={handleClick} />
              </HStack>

              <JobList
                active={me.allowReceiveJobPositions}
                job={showClosed ? acceptedJobsOrdered : acceptedJobsFiltered}
                receive={false}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};
