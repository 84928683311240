import { useParams } from 'react-router-dom';
import { useRouteMatch } from 'react-router';

import {
  Box,
  TabList,
  Tab,
  Text,
  Tabs,
  TabPanels,
  TabPanel,
  Tag,
  Divider,
  Spinner,
  VStack,
  Flex,
  HStack,
  Switch,
  Spacer,
  Center,
} from '@chakra-ui/react';

import { JobHeader } from 'components/JobHeader';
import { useJob } from 'graphql/hooks/admin/useJob';

import useMe from 'graphql/hooks/profile/useMe';
import { useNewsActions } from 'graphql/hooks/admin/useNewsActions';
import * as Sentry from '@sentry/react';
import { sanitizeDate } from 'utils/sanitizeDate';
import { ObjectCandidates, sanitizeCandidates } from 'utils/sanitizeCandidates';
import { InscribedTab } from './components/inscribedTab';
import { TabsInfo } from './components/tabsInfo';
import { Bbddtab } from './components/bbddTab';
import { useCallback, useState } from 'react';
import useTogglePrelevelMatches from 'graphql/hooks/jobs/useTogglePrelevelMatches';
import { useJobApplicationActions } from 'graphql/hooks/admin/useJobApplicationActions';
import useJobActions from 'graphql/hooks/admin/useJobActions';

export const Job = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const { me, error } = useMe();
  const { job, refetch, error: jobError } = useJob({ jobId });
  const { quitNews } = useNewsActions();
  const { togglePrelevelMatches } = useTogglePrelevelMatches();
  const [loadingToggle, setloadingToggle] = useState(false);
  const [loadingEditDiscarded, setloadingEditDiscarded] = useState(false);
  const [loadingSendJob, setloadingSendJob] = useState(false);
  const { editDiscarded } = useJobApplicationActions();
  const { sendJob } = useJobActions();

  const handleSendJob = async (jobId: string, userId: string) => {
    setloadingSendJob(true);
    await sendJob({ id: jobId, userId });
    setloadingSendJob(false);
  };

  const editIsDiscarded = async (type?: string, jobApplicationId?: string) => {
    setloadingEditDiscarded(true);
    if (jobApplicationId) await editDiscarded({ jobApplicationId, type });
    refetch().then(() => {
      setloadingEditDiscarded(false);
    });
  };
  const inscribedMatch = useRouteMatch({
    path: '/company/job/inscribed/:id',
    exact: true,
  });

  const handleSwitch = useCallback(() => {
    setloadingToggle(true);
    togglePrelevelMatches({ positionId: jobId }).finally(() => {
      refetch().then(() => setloadingToggle(false));
    });
  }, [jobId, togglePrelevelMatches, refetch]);

  if (error || jobError) {
    let message = error ? error : jobError;
    Sentry.captureException(message?.graphQLErrors[0]);
  }

  const deleteAllNews = (list: any[], listNews: any[], type: string) => {
    if (listNews.length)
      list.forEach((user) => {
        let idUser = user.user.id,
          jobPositionId = jobId;
        quitNews({ type, jobPositionId, idUser }).then(() => {
          refetch();
        });
      });
  };

  let tabIndex = 0;

  if (!inscribedMatch) {
    tabIndex = 1;
  }

  if (!job) {
    return (
      <VStack flexDirection="column" pt="40px">
        <Spinner color="brand.500" padding="10px" height="40px" width="40px" />
        <Text textStyle="h4" color="grey.500">
          Cargando la oferta
        </Text>
      </VStack>
    );
  }

  const {
    position,
    candidates: { list },
  } = job;

  const {
    inscribedUsers,
    pendingUsers,
    newInscribed,
    shortlistedUsers,
    matchUsers,
    discardedUsers,
    rejectNews,
    pendingNews,
    acceptedNews,
    hasRemote,
    rejectUsers,
    acceptedUsers,
  } = sanitizeCandidates(position, list);

  const { inscribed, candidatesMatch } = ObjectCandidates(
    inscribedUsers,
    position,
    hasRemote,
    newInscribed,
    shortlistedUsers,
    me,
    discardedUsers,
    matchUsers,
    acceptedUsers,
    pendingUsers,
    rejectUsers
  );

  let locations = position.countries.filter((country) => country === 'Remoto');

  if (!locations.length) locations = position.city;

  const created_at = sanitizeDate(position.created_at);

  const isSlot = job.position.state === 'slot';
  const isInvisible = job.position.state === 'invisible';

  return (
    <>
      <JobHeader
        created_at={created_at}
        title={position.name}
        company={position.company}
        skills={position.skills}
        experienceYears={position.experienceYears}
        location={locations}
        salary={position.salary}
        id={jobId}
        buttons={true}
        bonos={job.position.bonosCandidates}
        isSlot={isSlot}
      />

      <Box as="section">
        <Box
          as="main"
          mx="auto"
          w="100%"
          maxW="60rem"
          px={{ base: '4', md: '6' }}
        >
          <Divider my="8" color="gray.200" />
          <Tabs
            variant="unstyled"
            mt="8"
            index={tabIndex}
            defaultIndex={1}
            isLazy
          >
            <TabList border="0" zIndex={1}>
              <Flex w="full">
                <InscribedTab job={job} newInscribed={newInscribed} />
                <Bbddtab
                  jobId={jobId}
                  isInvisible={isInvisible}
                  pendingNews={pendingNews}
                  acceptedNews={acceptedNews}
                  rejectNews={acceptedNews}
                />
                <Spacer />
                <HStack>
                  <Text>Mostrar tecnologías sin validar</Text>
                  <Switch
                    colorScheme="brand"
                    onChange={handleSwitch}
                    isChecked={
                      job.position.showPrelevelMatches !== undefined
                        ? job.position.showPrelevelMatches
                        : true
                    }
                  />
                </HStack>
              </Flex>
            </TabList>

            <TabPanels bg="#FFFF">
              <TabPanel bg="#FFFF">
                <Box ml="-12">
                  <Tabs colorScheme="green" mt="8">
                    <TabList ml="10%" border="2" zIndex={1}>
                      <Tab fontWeight="semibold">
                        <Text textStyle="body">
                          Inscritos{' '}
                          {newInscribed && newInscribed.length > 0 && (
                            <Tag>{newInscribed.length}</Tag>
                          )}
                        </Text>
                      </Tab>
                      <Tab fontWeight="semibold">
                        <Text textStyle="body">Preseleccionados </Text>
                      </Tab>
                      <Tab fontWeight="semibold">
                        <Text textStyle="body">No aptos </Text>
                      </Tab>
                    </TabList>

                    <TabPanels>
                      {' '}
                      {!loadingToggle && !loadingEditDiscarded ? (
                        inscribed.map((inscribed) => (
                          <TabPanel>
                            <TabsInfo
                              users={inscribed.users}
                              position={inscribed.position}
                              hasRemote={inscribed.hasRemote}
                              newUsers={
                                inscribed.newUsers
                                  ? inscribed.newUsers
                                  : undefined
                              }
                              type={inscribed.type}
                              me={inscribed.me}
                              editIsDiscarded={editIsDiscarded}
                              jobId={job.id}
                              tabType={"inscritos"}
                            />
                          </TabPanel>
                        ))
                      ) : (
                        <Center height="170px">
                          <Spinner color="brand.500" />
                          <Text ml="2">Cargando...</Text>
                        </Center>
                      )}
                    </TabPanels>
                  </Tabs>
                </Box>
              </TabPanel>

              <TabPanel>
                <Box ml="-12">
                  <Tabs colorScheme="green" mt="8">
                    <TabList ml="10%" border="2" zIndex={1}>
                      <Tab textStyle="body">Matches</Tab>
                      <Tab
                        textStyle="body"
                        onFocus={() =>
                          deleteAllNews(pendingUsers, pendingNews, 'Pending')
                        }
                      >
                        Contacto solicitado{' '}
                        {pendingNews.length > 0 && (
                          <Tag>{pendingNews.length}</Tag>
                        )}
                      </Tab>
                      <Tab
                        textStyle="body"
                        onFocus={() =>
                          deleteAllNews(acceptedUsers, acceptedNews, 'Accepted')
                        }
                      >
                        Contacto aceptado{' '}
                        {acceptedNews.length > 0 && (
                          <Tag>{acceptedNews.length}</Tag>
                        )}
                      </Tab>
                      <Tab
                        textStyle="body"
                        onFocus={() =>
                          deleteAllNews(rejectUsers, rejectNews, 'Reject')
                        }
                      >
                        Contacto rechazado{' '}
                        {rejectNews.length > 0 && (
                          <Tag>{rejectNews.length}</Tag>
                        )}
                      </Tab>
                    </TabList>

                    <TabPanels>
                      {!loadingToggle && !loadingSendJob ? (
                        candidatesMatch.map((candidate) => (
                          <TabPanel>
                            <TabsInfo
                              users={candidate.users}
                              position={candidate.position}
                              hasRemote={candidate.hasRemote}
                              type={candidate.type}
                              me={candidate.me}
                              handleSendJob={handleSendJob}
                            />
                          </TabPanel>
                        ))
                      ) : (
                        <Center height="170px">
                          <Spinner color="brand.500" />
                          <Text ml="2">Cargando...</Text>
                        </Center>
                      )}
                    </TabPanels>
                  </Tabs>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </>
  );
};
