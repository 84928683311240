import { gql } from '@apollo/client';
import { FULL_CAREER_FRAGMENT } from 'graphql/fragments/fullCareer';

export const COMPLETE_LESSON = gql`
  mutation CompleteLesson($courseId: ID!, $lessonId: ID!) {
    completeLesson(courseId: $courseId, lessonId: $lessonId) {
      ...FullCareerParts
    }
  }
  ${FULL_CAREER_FRAGMENT}
`;
