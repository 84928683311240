import { Circle, HStack, Text } from '@chakra-ui/react';
import { PopoverInfoPrelevel } from 'components/PopoverInfoPreLevel';
import { normalizeLevel } from 'utils/normalizeLevel';
import { levels } from 'utils/preLevels';

export const PrelevelGuide = () => {
  return (
    <HStack spacing={3}>
      {levels.map((level, index) => (
        <HStack spacing={1} key={index}>
          <Circle
            fontSize="12"
            fontWeight="normal"
            size="20px"
            color="black"
            borderWidth="1px"
          >
            {level}
          </Circle>
          <Text fontSize="13" fontWeight="normal" color="black">
            {normalizeLevel(level)}
          </Text>
        </HStack>
      ))}
      <PopoverInfoPrelevel />
    </HStack>
  );
};
