import {
  FormControl,
  FormErrorMessage,
  Select,
  Stack,
  StackProps,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { ENUM_JOBPOSITION_WHERE_TO_SEARCH } from 'graphql/generated/globalTypes';

interface Props extends StackProps {
  name: string;
  setWhereToSearchState: Dispatch<SetStateAction<string>>;
}

function WhereToSearch({ name, setWhereToSearchState }: Props) {
  const [field, { error, touched }] = useField(name);
  const isInvalid = !!error && touched;

  setWhereToSearchState(field.value);

  return (
    <Stack mt="6" spacing={8}>
      <FormControl name={name} isInvalid={isInvalid}>
        <Select placeholder="Seleccionar" {...field}>
          {Object.values(ENUM_JOBPOSITION_WHERE_TO_SEARCH).map((item) => (
            <option value={item} key={item}>
              {item === 'Spain'
                ? 'España'
                : item === 'PermisoUE'
                ? 'Permiso UE'
                : 'Todo el mundo'}
            </option>
          ))}
        </Select>
        {isInvalid && (
          <FormErrorMessage mt="1" textStyle="caption">
            {error}
          </FormErrorMessage>
        )}
      </FormControl>
    </Stack>
  );
}

export default WhereToSearch;
