import { Formik, Form } from 'formik';
import { FormSelect } from 'components/FormSelect';
import { FormTextarea } from 'components/FormTextarea';

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverCloseButton,
  Box,
  Portal,
  Text,
  Stack,
  Button,
  useMediaQuery,
} from '@chakra-ui/react';
import useConnect from './connect';
import { IntroVideo } from '../IntroVideo';

const reasons = ['Duda', 'Sugerencia', 'Error'];

export const ReportForm = () => {
  const { handleSubmit, initialValues, schema, formId } = useConnect();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Portal>
      <Box
        position="fixed"
        right="32px"
        bottom={isMobile ? '80px' : '32px'}
        zIndex="10"
      >
        <Popover placement="left-end">
          <PopoverTrigger>
            <Button
              type="submit"
              aria-label="Contacta con nosotros"
              colorScheme="brand"
              variant="ghost"
              size="lg"
              height="56px"
              width="56px"
              borderRadius="50%"
              fontWeight="semibold"
              fontSize="32px"
              shadow="md"
              backgroundColor="white"
            >
              ?
            </Button>
          </PopoverTrigger>
          <PopoverContent pb="3" width={isMobile ? 'min-content' : 'xs'}>
            <PopoverCloseButton />
            <PopoverBody mt="2">
              <Text
                fontWeight="medium"
                borderColor="gray.200"
                color="gray.600"
                fontSize="16px"
                pt="2"
                pb="1"
              >
                Contacta con nosotros
              </Text>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={schema}
                enableReinitialize={true}
                key={formId}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Text variant="body" color="gray.500" mb="5">
                      Si tienes cualquier pregunta o comentario, contacta con
                      nosotros a través de este formulario.
                    </Text>
                    <Stack spacing="3">
                      <FormSelect
                        name="reason"
                        placeholder="Razón del contacto"
                      >
                        {reasons.map((reason, index) => {
                          return (
                            <option value={reason} key={'reason' + index}>
                              {reason}
                            </option>
                          );
                        })}
                      </FormSelect>
                      <FormTextarea resize="none" rows={5} name="message" />
                    </Stack>
                    <Button
                      type="submit"
                      colorScheme="brand"
                      disabled={isSubmitting}
                    >
                      Enviar
                    </Button>
                  </Form>
                )}
              </Formik>
              <IntroVideo />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    </Portal>
  );
};
