import { useCallback, useState, useMemo, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { FormikHelpers } from 'formik';
import * as Yup from 'yup';

import useMe from 'graphql/hooks/profile/useMe';
import useMeActions from 'graphql/hooks/profile/useMeActions';
import { FormValues } from './types';
import useProfession from 'graphql/hooks/profile/useProfessions';
import useUserProfessionsActions from 'graphql/hooks/profile/useProfessionsActions';
import useCountries from 'graphql/hooks/profile/useCountries';
import { useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useMediaQuery } from '@chakra-ui/react';

const schema = Yup.object().shape({
  linkedinURL: Yup.string().matches(
    new RegExp('^https://www.linkedin.com/in/'),
    {
      message:
        'Debe ser una URL de Linkedin https://www.linkedin.com/in/{USERNAME}',
      excludeEmptyString: true,
    }
  ),
  githubURL: Yup.string().matches(new RegExp('^https://github.com/'), {
    message: 'Debe ser una URL de GitHub https://github.com/{USERNAME}',
    excludeEmptyString: true,
  }),
});

const schema3 = Yup.object().shape({
  name: Yup.string().required('Este campo es requerido'),
  surname: Yup.string().required('Este campo es requerido'),
});

const useConnect = () => {
  const { me, refetchUser, error } = useMe();
  const {
    profession,
    refetchUserProfession,
    error: proffesionError,
  } = useProfession();
  const { updateProfile } = useMeActions();
  const { update, create } = useUserProfessionsActions();
  const { countries, loading } = useCountries();
  const goalsMatch = useRouteMatch({ path: '/profile/goals', exact: true });
  const contactMath = useRouteMatch({ path: '/profile/contact', exact: true });
  const flyncerMap = useRouteMatch({
    path: '/profile/flyncermap',
    exact: true,
  });
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const sanitizePhoneNumber = (number: any) => {
    let auxiliarNumber = '';

    if (number) {
      if (
        number?.substring(0, 3) !== '+34' &&
        (number?.substring(0, 1) === '6' || number?.substring(0, 1) === '7')
      )
        auxiliarNumber = `+34${number}`;
      else if (number?.substring(0, 3) === '+34') auxiliarNumber = number;
      else auxiliarNumber = number;
    } else auxiliarNumber = '';

    return auxiliarNumber;
  };

  const number = sanitizePhoneNumber(me?.phoneNumber);

  const [errorExp, seterrorExp] = useState(false);
  const [phoneNumber, setphoneNumber] = useState<string>(number);

  useEffect(() => {
    if (number) setphoneNumber(number);
  }, [number]);

  const [errorYearsExp, seterrorYearsExp] = useState(false);
  const [errorSalary, seterrorSalary] = useState(false);
  const [errorLocations, seterrorLocations] = useState(false);
  const [errorPhoneNumber, seterrorPhoneNumber] = useState(false);

  const [touched, settouched] = useState(false);
  const [touchedSalary, settouchedSalary] = useState(false);

  const [valueExp, setvalueExp] = useState('');

  const { replace } = useHistory();
  const location = useLocation();
  if (error || proffesionError) {
    let message = error ? error : proffesionError;
    Sentry.captureException(message?.graphQLErrors[0]);
  }

  const spainData = countries.find((country) => country.name === 'España');

  let fullUsername: string = '';

  if (me?.name !== '' && me?.surname !== '')
    fullUsername = `${me?.name} ${me?.surname}`;
  else fullUsername = ``;
  let tabIndex = 0;
  if (goalsMatch) {
    tabIndex = 1;
  } else if (contactMath) {
    tabIndex = 2;
  } else if (flyncerMap) {
    tabIndex = 3;
  }

  const sanitizeDate = () => {
    const today = new Date();
    let date: any;
    if (profession && profession.oneUserProfession[0]?.startDate)
      date = new Date(profession.oneUserProfession[0]?.startDate);
    else date = new Date();
    let years = today.getFullYear() - date?.getFullYear();

    if (
      profession?.oneUserProfession[0]?.experienceYears !== null &&
      years - 1 >= 0
    ) {
      if (today.getMonth() < date?.getMonth()) years--;
      else if (today.getMonth() === date?.getMonth()) {
        if (today.getDate() < date?.getDate()) years--;
      }
    } else years = 0;

    return years;
  };

  let yearsExperience: any = sanitizeDate();

  const initialValues = useMemo(
    () => ({
      name: me?.name,
      surname: me?.surname,
      phoneNumber: me?.phoneNumber,
      email: me?.email,
      studies: (me?.studies || []).map(({ id }) => id),
      englishLevel: me?.englishLevel,
      linkedinURL: me?.linkedinURL,
      githubURL: me?.githubURL,
      desiredMinSalary: me?.desiredMinSalary,
      desiredCompanyTypes: (me?.desiredCompanyTypes || []).map(({ id }) => id),
      desiredLocations: me?.desiredCities
        ? me.desiredCities.map((city) => city.id)
        : [''],
      desiredCountries: me?.desiredCountries
        ? me.desiredCountries.map((country) => {
            if (country) {
              return country?.id;
            }
            return '';
          })
        : [''],
      countryResidence: me?.countryResidence
        ? me.countryResidence.id
        : spainData?.id,
      desiredCareers: me?.desiredCareers || [],
      professionId:
        profession &&
        profession.oneUserProfession &&
        profession.oneUserProfession[0] &&
        profession.oneUserProfession[0].profession &&
        profession.oneUserProfession[0].profession.id
          ? profession.oneUserProfession[0].profession.id
          : '',
      subprofessionIds:
        profession &&
        profession.oneUserProfession &&
        profession.oneUserProfession[0] &&
        profession.oneUserProfession[0].subprofessions &&
        profession.oneUserProfession[0].subprofessions.length > 0
          ? profession.oneUserProfession[0].subprofessions.map(
              (subprofession) => subprofession?.id
            )
          : [],
      expYearsDeveloper:
        profession?.oneUserProfession[0]?.profession?.id === '1'
          ? yearsExperience
          : 0,
      expYearsManager:
        profession?.oneUserProfession[0]?.profession?.id === '2'
          ? yearsExperience
          : 0,
    }),
    [me, profession, spainData, yearsExperience]
  );

  if (profession && !profession.oneUserProfession[0]?.startDate)
    yearsExperience = profession.oneUserProfession[0]?.experienceYears;

  const [value, setValue] = useState('');

  const handleSubmit = useCallback(
    async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      if (!me) return;
      if (!profession) return;
      helpers.setSubmitting(true);

      if (!touched && me.onboarding) seterrorYearsExp(true);

      const hasSpain = values.desiredCountries?.some(
        (country) => country === '1'
      );

      if (
        !values.desiredCountries?.length ||
        (hasSpain && !values.desiredLocations?.length)
      ) {
        seterrorLocations(true);
      }

      if (
        location.pathname === '/onboarding-profile-goals' &&
        !touchedSalary &&
        me.onboarding
      )
        seterrorSalary(true);

      if (phoneNumber.length < 10) seterrorPhoneNumber(true);

      if (!hasSpain && values.desiredLocations?.length)
        values.desiredLocations = [];

      try {
        let userData = {
          name: values.name,
          surname: values.surname,
          phoneNumber:
            phoneNumber && phoneNumber.substring(0, 1) !== '+'
              ? `+${phoneNumber}`
              : phoneNumber,
          email: values.email,
          studies: values.studies,
          englishLevel: values.englishLevel,
          linkedinURL: values.linkedinURL,
          githubURL: values.githubURL,
          desiredMinSalary: values.desiredMinSalary,
          desiredCompanyTypes: values.desiredCompanyTypes,
          desiredCities: values.desiredLocations,
          desiredCountries: values.desiredCountries,
          countryResidence: values.countryResidence
            ? values.countryResidence
            : me.countryResidence?.id,
          continent: me.continent,
        };

        if (valueExp === '2' && !me.user_cv && me.onboarding) seterrorExp(true);

        if (valueExp === '' && !me.user_cv && me.onboarding) seterrorExp(true);

        if (
          valueExp === '1' &&
          !me.user_cv?.experiences?.length &&
          me.onboarding
        )
          seterrorExp(true);

        if (!touched && me.onboarding) seterrorYearsExp(true);

        if (
          (location.pathname === '/onboarding-profile' &&
            me.user_cv &&
            touched &&
            me.onboarding) ||
          (location.pathname === '/onboarding-profile-goals' &&
            touchedSalary &&
            (values.desiredLocations?.length ||
              values.desiredCountries?.length) &&
            ((hasSpain && values.desiredLocations?.length) ||
              (!hasSpain && !values.desiredLocations?.length)) &&
            me.onboarding) ||
          (location.pathname === '/onboarding-profile-contact' &&
            phoneNumber.length >= 10) ||
          (!me.onboarding &&
            !errorExp &&
            location.pathname === '/profile' &&
            me.user_cv) ||
          (!me.onboarding &&
            location.pathname === '/profile/contact' &&
            phoneNumber.length >= 10) ||
          (location.pathname === '/profile/goals' &&
            (values.desiredLocations?.length ||
              values.desiredCountries?.length) &&
            ((hasSpain && values.desiredLocations?.length) ||
              (!hasSpain && !values.desiredLocations?.length)) &&
            !me.onboarding)
        ) {
          let userProfessionId;
          profession?.oneUserProfession?.forEach((userProfession) => {
            userProfessionId = userProfession?.id;
          });
          let professionId = values.professionId;
          let expYearsManager = values.expYearsManager;
          let expYearsDeveloper = values.expYearsDeveloper;
          let subprofessions = values.subprofessionIds;

          const today2 = new Date();

          let today = new Date();

          if (me.professions && me.professions[0]?.startDate) {
            today = new Date(me.professions[0]?.startDate);
            today.setFullYear(today2.getFullYear());
          }

          const positionT = today.toISOString().indexOf('T');

          if (userProfessionId !== undefined) {
            if (professionId === '1') {
              if (expYearsDeveloper)
                today.setFullYear(today.getFullYear() - expYearsDeveloper);

              await update(
                userProfessionId,
                professionId,
                expYearsDeveloper,
                [],
                today.toISOString().substring(0, positionT)
              );
            } else if (professionId === '2') {
              if (expYearsManager)
                today.setFullYear(today.getFullYear() - expYearsManager);
              await update(
                userProfessionId,
                professionId,
                expYearsManager,
                subprofessions,
                today.toISOString().substring(0, positionT)
              );
            }
          } else {
            if (professionId === '1')
              await create(
                professionId,
                me?.id,
                expYearsDeveloper,
                [],
                today.toISOString().substring(0, positionT)
              );
            else if (professionId === '2')
              await create(
                professionId,
                me?.id,
                expYearsManager,
                subprofessions,
                today.toISOString().substring(0, positionT)
              );
            refetchUserProfession();
          }

          await updateProfile({
            input: {
              where: {
                id: me.id,
              },
              data: userData,
            },
          }).then(() => {
            refetchUser();
            if (location.pathname === '/onboarding-profile' && me.onboarding)
              replace('/onboarding-profile-goals');
            if (
              location.pathname === '/onboarding-profile-goals' &&
              me.onboarding
            ) {
              replace('/onboarding-profile-contact');
            }
            if (
              location.pathname === '/onboarding-profile-contact' &&
              me.onboarding
            )
              replace('/onboarding-jobs');
          });
        }

        //actualizar años de experiencia
      } catch (error: any) {
        helpers.setSubmitting(false);
      }
    },
    [
      updateProfile,
      refetchUser,
      valueExp,
      phoneNumber,
      me,
      location.pathname,
      errorExp,
      update,
      profession,
      create,
      refetchUserProfession,
      replace,
      touched,
      touchedSalary,
    ]
  );

  return {
    fullUsername,
    handleSubmit,
    initialValues,
    schema,
    fullName: me?.fullName,
    cv: me?.cv,
    referral_num: me?.referral_num,
    tabIndex,
    setValue,
    idUser: me?.id,
    uuid: me?.uuid,
    value,
    me,
    location,
    schema3,
    errorExp,
    seterrorExp,
    errorYearsExp,
    settouched,
    touched,
    seterrorYearsExp,
    errorSalary,
    seterrorSalary,
    touchedSalary,
    settouchedSalary,
    errorLocations,
    seterrorLocations,
    countries,
    loading,
    phoneNumber,
    setphoneNumber,
    errorPhoneNumber,
    seterrorPhoneNumber,
    valueExp,
    setvalueExp,
    isMobile,
  };
};

export default useConnect;
