import { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { HiX } from 'react-icons/hi';

interface Props {
  handleDeleteCV: () => void;
  isLoading: boolean;
}

export const DeleteCVButton = ({ handleDeleteCV, isLoading }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <IconButton
        size="sm"
        fontSize="md"
        variant="ghost"
        colorScheme="red"
        icon={<HiX />}
        aria-label="Eliminar CV"
        onClick={onOpen}
      />
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Eliminar CV
            </AlertDialogHeader>

            <AlertDialogBody>¿Estás seguro?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                colorScheme="brand"
                onClick={handleDeleteCV}
                ml={3}
                disabled={isLoading}
              >
                Eliminar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
