import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { FullCareer } from 'graphql/generated/FullCareer';
import { FULL_CAREER } from 'graphql/queries/fullCareer';
import { removeNulls } from 'utils/removeNulls';
import { normalizeUserCareer, UserCareer } from 'model/UserCareer';
import { normalizeUserCourse, UserCourse } from 'model/UserCourse';
import { getToken } from 'services/token';

const useFullCareer = () => {
  const isAuth = getToken() !== '';
  const { data, loading, refetch, error } = useQuery<FullCareer>(FULL_CAREER, {
    skip: !isAuth,
  });

  const fullCareer = useMemo(() => {
    return {
      careers: (data?.fullCareer.careers || []).reduce<UserCareer[]>(
        (acum, input) =>
          !!input ? acum.concat(normalizeUserCareer(removeNulls(input))) : acum,
        []
      ),
      courses: (data?.fullCareer.courses || []).reduce<UserCourse[]>(
        (acum, input) =>
          !!input ? acum.concat(normalizeUserCourse(removeNulls(input))) : acum,
        []
      ),
    };
  }, [data]);

  return {
    error,
    userCareers: fullCareer.careers,
    userCourses: fullCareer.courses,
    loading,
    refetch,
  };
};

export default useFullCareer;
