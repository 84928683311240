import { Studies_studies } from 'graphql/generated/Studies';
import { removeNulls, WithoutNulls } from 'utils/removeNulls';

export const normalizeStudy = (input: WithoutNulls<Studies_studies>) => ({
  id: input.id,
  name: input.name,
});

export type Study = ReturnType<typeof normalizeStudy>;

export const normalizeStudies = (input: (Studies_studies | undefined)[]) =>
  input.reduce<Study[]>(
    (acum, input) =>
      !!input ? acum.concat(normalizeStudy(removeNulls(input))) : acum,
    []
  );
