import { gql } from '@apollo/client';
import { COMPANY_TYPE_FRAGMENT } from './companyType';
import { STUDY_FRAGMENT } from './study';
import { LOCATION_FRAGMENT } from './location';
import { FILE_FRAGMENT } from './file';
import { DOWNLOADABLE_FILE_FRAGMENT } from './downloadableFile';
import { USER_PROFESSION_FRAGMENT } from './userProfession';
import { SKILL_FRAGMENT } from './skill';
import { CITY_FRAGMENT } from './city';

export const ME_FRAGMENT = gql`
  fragment MeParts on UsersPermissionsMe {
    id
    email
    confirmed
    role {
      name
    }
    blocked
  }
`;

export const ME_EXTENDED_FRAGMENT = gql`
  fragment MeExtendedParts on UsersPermissionsUser {
    id
    uuid
    confirmed
    blocked
    hasBetaAccess
    isJobAdmin
    username
    professions {
      ...UserProfessionParts
    }
    completed_profile
    name
    surname
    email
    phoneNumber
    onboarding

    company {
      id
      bonosJobPosting
      bonosCandidates
      superAdmin
    }

    experienceYearsTotal

    studies {
      ...StudyParts
    }
    englishLevel
    linkedinURL
    githubURL
    cv {
      ...FileParts
    }
    photo {
      ...FileParts
    }
    user_cv {
      id
      experiences {
        id
        puesto
        empresa
        inicio
        fin
        funciones
        skills {
          ...SkillParts
        }
      }
    }

    desiredMinSalary

    desiredLocations {
      ...LocationParts
    }

    desiredCities {
      ...CityParts
    }

    desiredCountries {
      id
      name
    }

    desiredCompanyTypes {
      ...CompanyTypeParts
    }

    allowReceiveJobPositions

    desiredCareers {
      id
    }

    careers {
      id
      career {
        name
      }
      activePaths {
        id
        path {
          name
        }
        level {
          name
        }
      }
    }

    downloadable_file {
      ...DownloadableFileParts
    }
    job_notifications {
      id
    }
    referral_num
    referred_from_num
    countryResidence {
      id
      name
    }
    lastActive
    messagingConfiguration {
      configuration {
        name
        mass_medias {
          id
          name
        }
      }
    }
    continent
    updated_at
  }
  ${CITY_FRAGMENT}
  ${COMPANY_TYPE_FRAGMENT}
  ${LOCATION_FRAGMENT}
  ${STUDY_FRAGMENT}
  ${FILE_FRAGMENT}
  ${DOWNLOADABLE_FILE_FRAGMENT}
  ${USER_PROFESSION_FRAGMENT}
  ${SKILL_FRAGMENT}
`;
