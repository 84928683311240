import { LevelParts } from 'graphql/generated/LevelParts';
import { WithoutNulls } from 'utils/removeNulls';

export const normalizeLevel = (input: WithoutNulls<LevelParts>) => ({
  id: input.id,
  name: input.name,
  order: input.order,
});

export type Level = ReturnType<typeof normalizeLevel>;
