import {
  AccordionButton,
  AccordionButtonProps,
  AccordionIcon,
  Box,
  Text,
} from '@chakra-ui/react';
import { Section } from 'model/Course';
import { normalizeDuration } from 'utils/normalizeDuration';

interface Props extends AccordionButtonProps {
  name: Section['name'];
  durationMinutes: Section['durationMinutes'];
}

export const AccordionButtonSection = ({ name, durationMinutes }: Props) => {
  return (
    <Text textStyle="body" color="gray.500">
      <AccordionButton p="4">
        <Box flex="1" textAlign="left">
          {name}
        </Box>
        <Box textAlign="right" mr="6">
          {normalizeDuration(durationMinutes)}
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </Text>
  );
};
