import { useCallback } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { FlyncerError } from 'graphql/helpers';

import {
  CompleteLesson,
  CompleteLessonVariables,
} from 'graphql/generated/CompleteLesson';
import { COMPLETE_LESSON } from 'graphql/mutations/userCourse';
import { FullCareer } from 'graphql/generated/FullCareer';
import { FULL_CAREER } from 'graphql/queries/fullCareer';
import * as Sentry from '@sentry/react';

const useFullCourseActions = () => {
  const client = useApolloClient();

  const [completeLessonMutation, { loading }] = useMutation<
    CompleteLesson,
    CompleteLessonVariables
  >(COMPLETE_LESSON);

  const completeLesson = useCallback(
    async (input: CompleteLessonVariables) => {
      try {
        const response = await completeLessonMutation({ variables: input });
        const fullCareer = response.data?.completeLesson;

        if (fullCareer) {
          client.cache.writeQuery<FullCareer>({
            data: {
              fullCareer,
            },
            query: FULL_CAREER,
          });
        }
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo completar la lección'));
        throw new FlyncerError('No se pudo completar la lección', error);
      }
    },
    [completeLessonMutation, client]
  );

  return {
    completeLesson,
    loading,
  };
};

export default useFullCourseActions;
