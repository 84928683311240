import { UserCourseParts } from 'graphql/generated/UserCourseParts';
import { WithoutNulls } from 'utils/removeNulls';

export const normalizeUserCourse = (input: WithoutNulls<UserCourseParts>) => ({
  id: input.id,
  progress: input.progress,
  course: input.course
    ? {
        id: input.course.id,
      }
    : undefined,
  lessonsDone: (input.lessonsDone || []).reduce<string[]>(
    (acum, lesson) => (lesson ? [...acum, lesson.lessonId] : acum),
    []
  ),
  quizApproved: !!input.userQuiz?.id,
});

export type UserCourse = ReturnType<typeof normalizeUserCourse>;
