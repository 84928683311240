import { gql } from '@apollo/client';
import { SKILL_FRAGMENT } from './skill';

export const USER_ITINERARY_FRAGMENT=gql`
    
    fragment MeItineraryParts on MeItinerary{
        itinerary{
            Name
            id
            workshops{
                id
                name
                estimatedTimeMinutes
                skills{
                    ...SkillParts
                }
                progress
                isCompleted
            }
        }
        userCareer{
            id
        }
        isCompleted
    }
    ${SKILL_FRAGMENT}

`