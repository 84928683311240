import {
  Circle,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';

export const PopoverInfoPrelevel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const initialRef = useRef(null);

  const infoLevel = [
    {
      number: 1,
      info: 'Junior - Conocimientos bajos de la tecnología, requiero muy alta supervisión',
    },
    {
      number: 2,
      info: 'Intermedio - Conocimientos medios de la tecnología, requiero mínima supervisión',
    },
    {
      number: 3,
      info: 'Avanzado - Conocimientos altos de la tecnología, no requiero supervisión',
    },
    {
      number: 4,
      info: 'Experto - Especializado en la tecnología, sirvo de referente para otros',
    },
  ];
  return (
    <Popover
      isLazy
      isOpen={isOpen}
      initialFocusRef={initialRef}
      placement="bottom-end"
    >
      <PopoverTrigger>
        <Circle
          onMouseEnter={() => setIsOpen(!isOpen)}
          onMouseOut={() => setIsOpen(!isOpen)}
          as="button"
          fontSize="12"
          fontWeight="normal"
          size="20px"
          color="white"
          bg="black"
          borderWidth="1px"
          cursor="pointer"
        >
          ?
        </Circle>
      </PopoverTrigger>
      <PopoverContent w="2md" bg="#D8DFEA" right="-65px">
        <PopoverBody
          fontSize="11"
          fontWeight="semibold"
          ref={initialRef}
          w="full"
          p="1"
        >
          <VStack align="left" spacing={1}>
            {infoLevel.map((level, index) => (
              <HStack key={index}>
                <Circle
                  fontSize="12"
                  fontWeight="normal"
                  size="20px"
                  color="black"
                  borderWidth="1px"
                  bg="white"
                >
                  {level.number}
                </Circle>
                <Text>{level.info}</Text>
              </HStack>
            ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
