import { gql } from '@apollo/client';

export const COMPANY_FRAGMENT = gql`
  fragment CompanyParts on CompanyAdmin {
    id
    name
    bonosJobPosting
    bonosCandidates
    bonosSlot
    superAdmin
    headHunting
    logo {
      url
    }
  }
`;
