import { Link as RouterLink } from 'react-router-dom';
import {
  AccordionButton,
  AccordionItem,
  AccordionProps,
  Box,
  Icon,
} from '@chakra-ui/react';
import { FiArrowRight, FiCheckCircle, FiLock } from 'react-icons/fi';

interface Props extends AccordionProps {
  quizId?: string;
  quizApproved?: boolean;
  quizBlocked?: boolean;
}

export const QuizButtonSection = ({
  quizId,
  quizApproved = false,
  quizBlocked = false,
}: Props) => {
  if (quizBlocked) {
    return (
      <AccordionItem
        borderX="1px"
        borderLeftColor="gray.200"
        borderRightColor="gray.200"
        borderBottomRadius="md"
        bg="gray.100"
      >
        <AccordionButton
          color="gray.600"
          p="4"
          as="span"
          disabled={true}
          title="El progreso debe ser superior al 0% para hacer el test."
        >
          <Box flex="1" textAlign="left">
            Test de evaluación
          </Box>
          <Icon color="gray.600" as={FiLock} />
        </AccordionButton>
      </AccordionItem>
    );
  }

  if (quizApproved) {
    return (
      <AccordionItem
        borderX="1px"
        borderLeftColor="gray.200"
        borderRightColor="gray.200"
        borderBottomRadius="md"
        bg="gray.100"
      >
        <AccordionButton color="gray.600" p="4" as="span" disabled={true}>
          <Box flex="1" textAlign="left">
            Test de evaluación
          </Box>
          <Icon color="blue.400" as={FiCheckCircle} w={5} h={5} />
        </AccordionButton>
      </AccordionItem>
    );
  }

  return (
    <AccordionItem
      borderX="1px"
      borderLeftColor="gray.200"
      borderRightColor="gray.200"
      borderBottomRadius="md"
      bg="gray.100"
    >
      <AccordionButton
        color="gray.600"
        p="4"
        as={RouterLink}
        to={`/quiz/${quizId}`}
      >
        <Box flex="1" textAlign="left">
          Test de evaluación
        </Box>
        <Icon color="gray.600" as={FiArrowRight} />
      </AccordionButton>
    </AccordionItem>
  );
};
