import { Center, CenterProps, Text } from '@chakra-ui/react';

interface Props extends CenterProps {
  title?: string;
  subtitle?: string;
  icon?: React.ReactElement;
  boton?: React.ReactElement;
}

export const Placeholder = ({ title, subtitle, icon, boton, ...props }: Props) => (
  <Center
    align="center"
    flexDirection="column"
    color="gray.200"
    mt="14"
    w="100%"
    maxW="md"
    mx="auto"
    {...props}
  >
    {icon}
    {title && (
      <Text as="h4" textStyle="h4" mb="4" mt="8" color="gray.600">
        {title}
      </Text>
    )}
    {subtitle && (
      <Text textStyle="body" color="gray.500">
        {subtitle}
      </Text>
    )}
    {boton}
  </Center>
);
