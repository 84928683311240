import {
  Box,
  Text,
  Icon,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  IconButton,
  Link,
  useToken,
  useMediaQuery,
} from '@chakra-ui/react';
import useDownloadables from 'graphql/hooks/downloadableFiles/useDownloadableFiles';
import UpdateDownloadableFileActions from 'graphql/hooks/downloadableFiles/useDownloadableFilesActions';
import useMe from 'graphql/hooks/profile/useMe';
import { FiGift, FiDownload } from 'react-icons/fi';
import { useRouteMatch } from 'react-router-dom';
import './animation.css';
import * as Sentry from '@sentry/react';

function GiftButton() {
  const { downloadables, error } = useDownloadables();
  const { me, error: meError } = useMe();
  const { updateDownloadableFile } = UpdateDownloadableFileActions();
  const [brand500] = useToken('colors', ['brand.500']);
  const homeMatch = useRouteMatch({ path: '/career', exact: true });
  const offerMatch = useRouteMatch({ path: '/jobs/', exact: true });
  const jobsResolutionsMatch = useRouteMatch({
    path: '/jobsResolutions/',
    exact: true,
  });
  const jobsAcceptedMatch = useRouteMatch({
    path: '/jobsAccepted/',
    exact: true,
  });
  const profileMatch = useRouteMatch({ path: '/profile', exact: false });
  const resourcesMatch = useRouteMatch({ path: '/resources', exact: false });
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  if (error || meError) {
    let message = error ? error : meError;
    Sentry.captureException(message?.graphQLErrors[0]);
  }

  if (!downloadables || !downloadables.allDownloadableFiles) return null;

  let viewedByActiveUser = false;
  let viewers = downloadables.allDownloadableFiles[0]?.viewedBy;

  if (viewers)
    viewedByActiveUser = viewers?.some((user: any) => user?.id === me?.id);

  if (
    homeMatch ||
    offerMatch ||
    profileMatch ||
    resourcesMatch ||
    jobsResolutionsMatch ||
    jobsAcceptedMatch
  )
    return (
      <Box
        position="fixed"
        left="32px"
        bottom={isMobile ? '80px' : '32px'}
        zIndex="10"
        className="my-first-step"
      >
        <Popover placement="right-end">
          <PopoverTrigger>
            <IconButton
              onClick={updateDownloadableFile}
              className={!viewedByActiveUser ? 'animation' : ''}
              type="submit"
              aria-label="Contacta con nosotros"
              colorScheme={viewedByActiveUser ? 'brand' : 'whiteAlpha'}
              variant={viewedByActiveUser ? 'outline' : 'solid'}
              size="lg"
              height="56px"
              width="56px"
              borderRadius="50%"
              fontWeight="semibold"
              fontSize="32px"
              shadow="md"
              backgroundColor={viewedByActiveUser ? 'white' : `orange.400`}
              _hover={
                viewedByActiveUser ? { bg: `brand.50` } : { bg: `orange.600` }
              }
              icon={<FiGift />}
              border={
                viewedByActiveUser
                  ? `solid 1px ${brand500}`
                  : `solid 1px orange.400`
              }
            />
          </PopoverTrigger>
          <PopoverContent width={isMobile ? 'min-content' : 'xs'}>
            <PopoverBody>
              <HStack
                ml="3"
                as={Link}
                href={
                  downloadables?.allDownloadableFiles
                    ? downloadables?.allDownloadableFiles[0]?.file?.url
                    : 'No existe'
                }
              >
                <Text>
                  {downloadables?.allDownloadableFiles
                    ? downloadables?.allDownloadableFiles[0]?.name
                    : 'No existe el archivo'}
                </Text>
                <Icon as={FiDownload} color="gray.800" />
              </HStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    );
  else return null;
}

export default GiftButton;
