import { gql } from '@apollo/client';
import {ITINERARY_FRAGMENT} from "../fragments/itinerary"

export const COMPLETE_ITINERARY=gql`
    mutation CompleteItinerary($input:ID!){
        completeItinerary(itineraryId:$input){
            ...ItineraryParts
        }
    }
    ${ITINERARY_FRAGMENT}
`