import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const InscribedModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Te has inscrito a la oferta</ModalHeader>
        <ModalBody>
          <Text textStyle="body">
            La empresa recibirá tu solicitud. ¡Mucha suerte!
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="brand" as={RouterLink} to="/jobs">
            Volver a las ofertas
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
