import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import { MdOutlineSchool } from 'react-icons/md';

interface Props {
  handleClick: (test: boolean) => void;
}

export const TrainningContainer = ({ handleClick }: Props) => {
  return (
    <Box textAlign="center" color="gray.500" p="5">
      <VStack>
        <Icon as={MdOutlineSchool} color="brand.500" w={12} h={12} />
        <Text fontSize="20" fontWeight="bold">
          ¿Quieres subir de nivel?
        </Text>
        <Text>Accede a la formación gratuita de JavaScript</Text>
      </VStack>
      <Divider my="10" />
      <VStack color="brand.500" spacing={4}>
        <Text fontWeight="bold">Curso intensivo de JavaScript</Text>
        <HStack>
          <Tag colorScheme="brand">Javascript</Tag>
          <Tag colorScheme="brand">Jquery</Tag>
          <Tag colorScheme="brand">Ajax</Tag>
        </HStack>

        <Text>+23h</Text>
      </VStack>
      <Divider my="10" />
      <Button colorScheme="brand" onClick={() => handleClick(false)}>
        Acceder
      </Button>
    </Box>
  );
};
