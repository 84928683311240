import { useCallback, useState } from 'react';
import { FormikHelpers } from 'formik';
import * as Yup from 'yup';

import useAuth from 'graphql/hooks/auth/useAuth';
import { FormValues } from './types';

const initialValues: FormValues = { email: '' };

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Introduce un email válido')
    .required('Este campo es requerido'),
});

const useConnect = () => {
  const { forgotPassword } = useAuth();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmit = useCallback(
    async (
      values: FormValues,
      { setSubmitting, setFieldError }: FormikHelpers<FormValues>
    ) => {
      setSubmitting(true);
      try {
        await forgotPassword(values);
        setShowConfirmation(true);
      } catch (error: any) {
        if (error.fields?.id === 'Auth.form.error.user.not-exist') {
          setShowConfirmation(true);
        } else {
          setFieldError('passwordConfirmation', error.message);
        }
        setSubmitting(false);
      }
    },
    [forgotPassword]
  );

  return {
    handleSubmit,
    initialValues,
    schema,
    showConfirmation,
  };
};

export default useConnect;
