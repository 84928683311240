import {
  FormControl,
  FormErrorMessage,
  RadioProps,
  Radio,
  RadioGroup,
  Text,
  Grid,
  CheckboxGroup,
  Checkbox,
} from '@chakra-ui/react';
import { useField } from 'formik';
import useSubProfessions from 'graphql/hooks/profile/useSubProfessions';
import { Dispatch, memo, SetStateAction, useCallback } from 'react';
import * as Sentry from '@sentry/react';
interface Props extends RadioProps {
  name: string;
  altError?: String;
  setProfessionState: Dispatch<SetStateAction<string>>;
}

export const ProfessionSelector = memo(
  ({ name, setProfessionState, altError }: Props) => {
    const [{ value = '' }, { error, touched }, { setValue }] = useField(name);
    const { subProfessions, error: subProffesionsError } = useSubProfessions(
      '2'
    );

    if (error) Sentry.captureException(subProffesionsError);

    const [
      { value: subprofessionIds = [] },
      ,
      { setValue: setSubprofessionIds },
    ] = useField('profession.subProfessions');

    const onChange = useCallback(
      (newValue) => {
        setValue(newValue);
        setProfessionState(newValue);
      },
      [setValue, setProfessionState]
    );

    const onChangeSubprofession = useCallback(
      (newValue, isChecked) => {
        if (isChecked) {
          setSubprofessionIds(
            subprofessionIds.filter((sub: any) => sub !== newValue)
          );
        } else {
          setSubprofessionIds([...subprofessionIds, newValue]);
        }
      },
      [setSubprofessionIds, subprofessionIds]
    );

    const isInvalid = touched && !!error;
    const isManager = value === '2';

    return (
      <FormControl isInvalid={isInvalid}>
        <RadioGroup
          defaultValue="1"
          value={value}
          onChange={onChange}
          name={name}
          mt="7"
        >
          <Radio value="1" colorScheme="brand">
            Developer
          </Radio>
          <Radio value="2" ml="30%" colorScheme="brand">
            Manager
          </Radio>
        </RadioGroup>

        {isManager && (
          <>
            <Text mt="6" mb="4" textStyle="h6" color="gray.900">
              Posición *
            </Text>

            <Grid templateColumns="repeat(2, 1fr">
              <CheckboxGroup defaultValue={subprofessionIds}>
                {subProfessions?.allSubProfessions.map((subProfession: any) => {
                  const isChecked = subprofessionIds.includes(subProfession.id);

                  return (
                    <Checkbox
                      mb="2"
                      colorScheme="brand"
                      onChange={() =>
                        onChangeSubprofession(subProfession.id, isChecked)
                      }
                      key={subProfession.id}
                      value={subProfession.id}
                    >
                      {subProfession.Name}
                    </Checkbox>
                  );
                })}
              </CheckboxGroup>
            </Grid>
          </>
        )}

        {isInvalid && (
          <FormErrorMessage textStyle="caption">
            {altError ? altError : error}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  }
);
