import { gql } from '@apollo/client';
import { FULL_CAREER_FRAGMENT } from 'graphql/fragments/fullCareer';

export const CREATE_USER_CAREER = gql`
  mutation AddCareer($input: AddCareerInput!) {
    addCareer(input: $input) {
      ...FullCareerParts
    }
  }
  ${FULL_CAREER_FRAGMENT}
`;

export const UPDATE_USER_CAREER = gql`
  mutation UpdateMeCareer($input: UpdateCareerInput!) {
    updateMeCareer(input: $input) {
      ...FullCareerParts
    }
  }
  ${FULL_CAREER_FRAGMENT}
`;

export const UPDATE_USER_CAREER_MAIN = gql`
  mutation UpdateMeCareerMain($input: UpdateCareerMainInput!) {
    updateMeCareerMain(input: $input) {
      ...FullCareerParts
    }
  }
  ${FULL_CAREER_FRAGMENT}
`;

export const DELETE_USER_CAREER = gql`
  mutation DeleteMeCareer($input: deleteUserCareerInput!) {
    deleteMeCareer(input: $input) {
      ...FullCareerParts
    }
  }
  ${FULL_CAREER_FRAGMENT}
`;

export const INITIALIZE_EMPLOYABILITY_CAREER = gql`
  mutation initializeEmployabilityCareer {
    initializeEmployabilityCareer
  }
`;

export const INITIALIZE_MANAGEMENT_CAREER = gql`
  mutation initializeManagementCareer {
    initializeManagementCareer
  }
`;
