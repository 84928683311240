import { useQuery } from '@apollo/client';

import { oneUserWorkshops } from 'graphql/generated/oneUserWorkshops';
import { ONE_USER_WORKSHOP } from 'graphql/queries/userWorkshop';
import { useMemo } from 'react';

const useUserWorkshops = () => {
  const { data, loading, error, refetch } =
    useQuery<oneUserWorkshops>(ONE_USER_WORKSHOP);

  const userWorkshops = useMemo(() => {
    return data?.oneUserWorkshops ? data.oneUserWorkshops : [];
  }, [data]);

  return {
    userWorkshops,
    loading,
    error,
    refetch,
  };
};

export default useUserWorkshops;
