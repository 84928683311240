import { Dispatch, memo, SetStateAction } from 'react';
import { FieldProps } from 'formik';

import usePaths from 'graphql/hooks/path/usePaths';
import Select, { components, DropdownIndicatorProps } from 'react-select';
// import makeAnimated from 'react-select/animated';
import { CustomStyles } from 'styles/customStyles';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface Props extends FieldProps {
  setTecnologiesState: Dispatch<SetStateAction<Element[]>>;
  tecnologiesState: [];
}

interface Element {
  label: string;
  value: string;
}

const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <text>
        <b>OR</b>
        <ChevronDownIcon w={5} h={6} />
      </text>
    </components.DropdownIndicator>
  );
};

export const Tecnologies = memo(
  ({ setTecnologiesState, tecnologiesState, field, form, ...props }: Props) => {
    const { paths, loading } = usePaths();
    let orderedPaths = paths.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
    // const animatedComponents = makeAnimated();
    const isMulti = true;

    const options = orderedPaths.map((path) => ({
      label: path.name,
      value: path.id,
    }));

    if (loading) {
      return null;
    }

    let allPaths: any = [];
    orderedPaths.forEach((orderedPath) => {
      tecnologiesState.forEach((pathGroup: any) => {
        pathGroup.forEach((path: any) => {
          if (path === orderedPath.id) {
            allPaths = [...allPaths, path];
          }
        });
      });
    });

    orderedPaths = orderedPaths.filter((orderedPath) => {
      const path = allPaths.find((path: any) => path === orderedPath.id);

      if (path !== undefined) {
        return null;
      }

      return orderedPath;
    });

    setTecnologiesState(form.values.pathGroups);

    const onChange = (option: any) => {
      // setTecnologiesState((options as Element[]).map((item: Element) => item));
      form.setFieldValue(
        field.name,
        isMulti
          ? (option as Element[]).map((item: Element) => item.value)
          : (option as Element).value
      );
    };

    const getValue = () => {
      if (options) {
        return isMulti
          ? options.filter(
              (option: any) => field.value.indexOf(option.value) >= 0
            )
          : options.find((option: any) => option.value === field.value);
      } else {
        return isMulti ? [] : ('' as any);
      }
    };

    return (
      <Select
        name={field.name}
        placeholder="Seleccionar"
        className="custom-select"
        value={getValue()}
        onChange={onChange}
        options={orderedPaths.map((path) => ({
          label: path.name,
          value: path.id,
        }))}
        components={{ DropdownIndicator }}
        isMulti={isMulti}
        menuPortalTarget={document.body}
        styles={CustomStyles}
      />
    );
  }
);
