import {
  FormControl,
  FormErrorMessage,
  Checkbox,
  CheckboxProps,
} from '@chakra-ui/react';
import { useField } from 'formik';

interface Props extends CheckboxProps {}

export const FormCheckbox = ({ id, name = '', children, ...props }: Props) => {
  const [field, { error, touched }] = useField(name);

  const isInvalid = touched && !!error;

  return (
    <FormControl id={id || name} isInvalid={isInvalid}>
      <Checkbox colorScheme="brand" {...props} {...field}>
        {children}
      </Checkbox>
      {isInvalid && (
        <FormErrorMessage mt="1" textStyle="caption">
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
