import { BoxProps, Box, SimpleGrid } from '@chakra-ui/react';
import { Itinerary } from 'model/UserItinerary';
import { Header } from '../Itinerary/Header';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { WorkshopCard } from 'components/WorkshopCard';
import { EndItineraryCard } from 'components/EndItineraryCard';

interface Props extends BoxProps {
  userItinerary: Itinerary;
}

export const ItineraryData = ({ userItinerary, ...props }: Props) => {
  const { itinerary } = userItinerary;

  const percentages = itinerary.workshops.filter(
    (workshop) => workshop.progress
  );

  return (
    <Box {...props}>
      <Header
        name={`${itinerary.name}`}
        isCompleted={
          percentages.filter((percentage) => percentage.progress === 100)
            .length === itinerary.workshops.length
            ? 'Completado'
            : percentages.length > 0 &&
              percentages.length !== itinerary.workshops.length
            ? 'En curso'
            : ''
        }
        my="4"
      />
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap="8">
        {itinerary.workshops.map((workshop) => {
          return (
            <Fragment key={workshop.id}>
              <Link to={`/workshop/${workshop.id}`}>
                <WorkshopCard
                  title={workshop.name}
                  tags={workshop.skills.map(({ name }) => name)}
                  durationMinutes={workshop.estimatedTimeMinutes}
                  progress={workshop.progress}
                  locked={false}
                />
              </Link>
            </Fragment>
          );
        })}

        {userItinerary.isCompleted && (
          <EndItineraryCard itinerary={itinerary.name} />
        )}
      </SimpleGrid>
    </Box>
  );
};
