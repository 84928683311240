export type WithoutNulls<T> = T extends object
  ? {
      [TKey in keyof T]: WithoutNulls<T[TKey]>;
    }
  : T extends null
  ? undefined
  : T;

export function removeNulls<T>(input: T): WithoutNulls<T> {
  if (input === null) {
    return (undefined as unknown) as WithoutNulls<T>;
  }

  if (typeof input === 'object') {
    if (Array.isArray(input)) {
      return (input.map(removeNulls) as unknown) as WithoutNulls<T>;
    }

    return Object.entries(input).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: removeNulls(value),
      }),
      {} as WithoutNulls<T>,
    );
  }

  return (input as unknown) as WithoutNulls<T>;
}
