import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { UPDATE_DOWNLOADABLE_FILE } from 'graphql/mutations/updateDownloadableFile';
import * as Sentry from '@sentry/react';
import { DOWNLOADABLES } from 'graphql/queries/downloadableFile';

const UpdateDownloadableFileActions = () => {
  const [updateDownloadableMutation, { loading: updateLoading }] = useMutation(
    UPDATE_DOWNLOADABLE_FILE,
    { refetchQueries: [{ query: DOWNLOADABLES }] }
  );

  const updateDownloadableFile = useCallback(async () => {
    try {
      await updateDownloadableMutation();
    } catch (error: any) {
      Sentry.captureException(
        new Error('No se pudo actualizar el downloadableFile')
      );
    }
  }, [updateDownloadableMutation]);

  return {
    isLoading: updateLoading,
    updateDownloadableFile,
  };
};

export default UpdateDownloadableFileActions;
