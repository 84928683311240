import { JobRequestParts } from 'graphql/generated/JobRequestParts';
import { PositionParts } from 'graphql/generated/PositionParts';
import { PositionSanitizedParts } from 'graphql/generated/PositionSanitizedParts';
import { removeNulls, WithoutNulls } from 'utils/removeNulls';

export const normalizePositionSanitized = (
  input: WithoutNulls<PositionSanitizedParts>
) => ({
  id: input.id,
  name: input.name,
  description: input.description,
  salary: {
    min: input.salaryMin,
    max: input.salaryMax,
  },
  location: (input.locations || []).map((location) => location?.name || ''),
  cities: (input.cities || []).map((city) => city?.name || ''),
  countries: (input.countries || []).map((country) => country?.name || ''),
  experienceYears: input.experienceYears,
  profession: input.profession,
  subprofessions: input.subprofessions,
  company: {
    id: input.company.id,
    name: input.company.name,
    logo: input.company.logo,
    type: input.company.type,
  },
  state: input.state,
  skills: input.skills?.map((skill) => ({
    paths: skill.paths?.map((path) => ({
      id: path?.id,
      name: path?.name,
      level: {
        id: path.level?.id,
        name: path.level?.name,
        order: path.level?.order,
      },
      order: path?.order,
    })),
  })),
  created_at: input.created_at,
  user_job_applications: (input.user_job_applications || []).map(
    (jobApplication) => ({
      id: jobApplication?.id,
      user: jobApplication?.user,
      isDiscarded: jobApplication?.isDiscarded,
    })
  ),
});

export type PositionSanitized = ReturnType<typeof normalizePositionSanitized>;

export const normalizePosition = (input: WithoutNulls<PositionParts>) => ({
  id: input.id,
  name: input.name,
  description: input.description,
  salary: {
    min: input.salaryMin,
    max: input.salaryMax,
  },
  location: (input.locations || []).map((location) => location?.name || ''),
  countries: (input.countries || []).map((country) => country?.name || ''),
  city: (input.cities || []).map((city) => city.name || ''),
  experienceYears: input.experienceYears,
  profession: input.profession,
  subprofessions: input.subprofessions,
  company: {
    id: input.company.id,
    name: input.company.name,
    logo: input.company.logo,
    type: input.company.type,
  },
  skills: input.skills?.map((skill) => ({
    paths: skill.paths?.map((path) => ({
      id: path?.id,
      name: path?.name,
      level: {
        id: path.level?.id,
        name: path.level?.name,
        order: path.level?.order,
      },
      order: path?.order,
    })),
  })),
  bonosCandidates: input.bonosCandidates,
  seenUsers: input.seenUsers || [],
  notifiedUsers: input.notifiedUsers || [],
  resolutions: input.resolutions || [],
  state: input.state,
  created_at: input.created_at,
  user_job_applications: input.user_job_applications,
  showPrelevelMatches: input.showPrelevelMatches,
});

export type Position = ReturnType<typeof normalizePosition>;

export const normalizeJobRequest = (input: WithoutNulls<JobRequestParts>) => ({
  id: input.id,
  isAccepted: input.isAccepted,
  position: normalizePositionSanitized(input.position),
});

export type JobRequest = ReturnType<typeof normalizeJobRequest>;

export const normalizeJobRequests = (
  input: (WithoutNulls<JobRequestParts> | undefined)[]
) =>
  input.reduce<JobRequest[]>(
    (acum, input) =>
      !!input ? acum.concat(normalizeJobRequest(removeNulls(input))) : acum,
    []
  );
