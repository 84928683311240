import { Box, BoxProps, Text, Kbd } from '@chakra-ui/react';

import { Metadata } from './MetaData';
import { Answer, FormQuiz } from './Form';
import { Quiz as QuizModel } from 'model/Quiz';

interface Props extends BoxProps {
  quiz: QuizModel;
  quizType: string;
  handleSubmit: (answers: Answer[]) => void;
}

export const Quiz = ({ quizType, quiz, handleSubmit, ...props }: Props) => {
  return (
    <Box
      as="main"
      mx="auto"
      w="100%"
      maxW="50rem"
      p={{ base: '4', md: '6' }}
      pt="16"
      pb="12"
      {...props}
    >
      <Text as="h3" textStyle="h3" mb="4" color="gray.600">
        {quiz.name}
      </Text>
      <Text mb="10" color="gray.600">
        Usa <Kbd>tab</Kbd> o <Kbd>shift</Kbd> + <Kbd>tab</Kbd> para navegar
        entre preguntas y <Kbd>Flecha arriba</Kbd> o <Kbd>Flecha abajo</Kbd>{' '}
        para hacerlo entre las opciones de cada pregunta.
      </Text>
      <Metadata name={quizType} questionsCount={quiz.questions.length} mb="4" />

      <FormQuiz textCheckbox={[]} quiz={quiz} onSubmit={handleSubmit} />
    </Box>
  );
};
