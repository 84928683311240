import { Redirect, Route, Switch } from 'react-router-dom';

import { Login } from './Login';
import { Register } from './Register';
import { RegisterCompany } from './RegisterCompany';
import { ForgotPassword } from './ForgotPassword';
import { ResetPassword } from './ResetPassword';
import { useEffect } from 'react';
import useAuth from 'graphql/hooks/auth/useAuth';
import { AuthRedirect } from './AuthRedirect';
import { LoginCompany } from './LoginCompany';

export const Guest = () => {
  const { logout } = useAuth();

  useEffect(() => {
    // Para asegurarnos que no queden token antiguos
    // siempre que el usuario acceda a esta vista forzamos el logout
    logout();
  }, [logout]);

  return (
    <Switch>
      <Route component={Login} exact path="/" />
      <Route component={Register} exact path="/register" />
      <Route component={Register} exact path="/register/becasow" />
      <Route component={RegisterCompany} exact path="/company/register" />
      <Route component={LoginCompany} exact path="/company/login" />
      <Route component={ForgotPassword} exact path="/forgot-password" />
      <Route component={ResetPassword} exact path="/change-password" />
      <Route
        component={AuthRedirect}
        exact
        path="/connect/:providerName/redirect"
      />
      <Redirect to="/" />
    </Switch>
  );
};
