import { Box, Flex, Heading, Tag, Text, Link } from '@chakra-ui/react';
import { Hexagon } from 'containers/Admin/Candidate/PersonalInfo/Hexagon/index';

interface Props {
  pathsWithLevel: any[];
  pathsHexagon: any[];
  pathsId: any[];
  user: any;
  me: any;
  isPrinted: boolean;
}

export const DataUserCV = ({
  pathsWithLevel,
  pathsHexagon,
  pathsId,
  me,
  user,
  isPrinted,
}: Props) => {
  return (
    <Box
      bg="white"
      as="main"
      mx={{ md: '12' }}
      w="100%"
      maxW="60rem"
      px={{ base: '4', md: '6' }}
      py={{ base: '8', md: '1' }}
    >
      <Heading fontSize="3xl" mt="4">
        Certificación de conocimientos técnicos
      </Heading>
      <Text mt="1" mb="4">
        Expedido por{' '}
        <Link
          color="green.600"
          textDecor="underline"
          href="https://flyncer.com/"
          isExternal
        >
          Flyncer
        </Link>
      </Text>
      <Text mt="1" mb="5">
        Con este certificado, Flyncer acredita que{' '}
        <Text as="span" fontWeight="bold" whiteSpace="pre" mr="1">
          {user?.fullName}
        </Text>
        ha obtenido las siguientes puntuaciones en nuestros test de nivel.
      </Text>
      <Heading fontSize="md" mt="5" mb="1">
        Niveles disponibles
      </Heading>
      Junior, Intermedio, Avanzado y Experto.
      <Heading fontSize="md" mt="5">
        Mapa de skills
      </Heading>
      <Hexagon
        cv={'HexagonCV'}
        paths={pathsWithLevel}
        pathsHexagon={pathsHexagon}
        pathsId={pathsId}
        idUser={me?.id}
      />
      <Heading fontSize="md" mt="14">
        Niveles obtenidos
      </Heading>
      <Flex my="4" flexWrap="wrap">
        {pathsHexagon.map((path: any) => {
          return (
            <Tag
              mr="4"
              pb={isPrinted ? 4 : ''}
              mb="2"
              bg="none"
              border="1px solid #D8DFEA"
              borderRadius={10}
              fontWeight="medium"
            >
              {path.path?.name} - {path.level?.name}
            </Tag>
          );
        })}
      </Flex>
    </Box>
  );
};
