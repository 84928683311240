import { Box, Heading, Text, Button, useMediaQuery } from '@chakra-ui/react';
import { PersonalInfo } from 'containers/Admin/Candidate/PersonalInfo';
import { Skills } from 'containers/Admin/Candidate/Skills';
import { Cv } from 'containers/Admin/Candidate/Cv';
import { Link as RouterLink } from 'react-router-dom';

import useMe from 'graphql/hooks/profile/useMe';
import { normalizeUser } from 'model/User';
import { FiChevronLeft } from 'react-icons/fi';

function PrivateProfile() {
  const { me } = useMe();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  if (!me) return null;

  const object: any = {
    id: me.id,
    name: me.name,
    surname: me.surname,
    email: me.email,
    fullName: me.fullName,
    studies: me.studies,
    linkedinURL: me.linkedinURL,
    githubURL: me.githubURL,
    cv: me.cv,
    photo: me.photo,
    englishLevel: me.englishLevel,
    careers: me.careers,
    user_cv: me.user_cv,
  };
  const user = normalizeUser(object);

  return (
    <Box
      as="main"
      mx="auto"
      w="100%"
      maxW="container.md"
      py="8"
      px={{ base: '8' }}
    >
      <Button
        as={RouterLink}
        to="/profile"
        variant="link"
        leftIcon={<FiChevronLeft />}
      >
        Volver a tu perfil
      </Button>
      <Button
        as={RouterLink}
        to={`/publicProfile`}
        borderRadius="20"
        variant="outline"
        float={isMobile ? 'none' : 'right'}
        mt={isMobile ? '2' : '-2'}
        mr={isMobile ? '100' : ''}
      >
        Ver perfil público
      </Button>
      <Heading mt="12">Perfil Privado</Heading>
      <Text mt="4">
        <Text as="span" fontWeight="500">
          Así te ven cuando liberas tus datos.
        </Text>{' '}
        Contiene toda tu información y será lo que terminará de conquistarles.
      </Text>
      <Box spacing="6" bg="white" mr="-8" ml="-8" mt="10">
        <PersonalInfo user={user} />
        <Skills user={user} />
        <Cv user={user} />
      </Box>{' '}
    </Box>
  );
}

export default PrivateProfile;
