import { gql } from '@apollo/client';
import { FULL_COURSE_FRAGMENT } from 'graphql/fragments/course';

export const FULL_COURSE = gql`
  query FullCourse($id: ID!) {
    course(id: $id) {
      ...FullCourseParts
    }
  }
  ${FULL_COURSE_FRAGMENT}
`;
