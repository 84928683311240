import { gql } from '@apollo/client';
import { COMPANY_TYPE_FRAGMENT } from 'graphql/fragments/companyType';

export const COMPANY_TYPES = gql`
  query CompanyTypes {
    companyTypes {
      ...CompanyTypeParts
    }
  }
  ${COMPANY_TYPE_FRAGMENT}
`;
