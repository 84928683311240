import { Route, Switch, useLocation } from 'react-router-dom';

import useIsAuth from 'graphql/hooks/auth/useIsAuth';
import { Guest } from 'containers/Guest';
import { Main } from 'containers/Main';
import { Admin } from 'containers/Admin';
import * as Sentry from '@sentry/react';

export const App = () => {
  const { loading, isAuth, error } = useIsAuth();
  const location = useLocation();

  if (error) Sentry.captureException(error?.graphQLErrors[0]);

  if (loading) return null;

  if (!isAuth || isAuth === undefined) {
    if (
      !location.pathname.includes('/jobsOffer/') &&
      !location.pathname.includes('/cv/') &&
      !location.pathname.includes('/onlyPath') &&
      !location.pathname.includes('/jobs/') &&
      isNaN(parseInt(location.pathname.substring(4)))
    ) {
      return (
        <>
          <Guest />
        </>
      );
    }
  }

  if (isAuth && !isAuth?.confirmed && isAuth?.role.name !== 'Company') {
    return <div>Email no confirmado</div>;
  }

  if (!isAuth?.confirmed && isAuth?.role.name === 'Company') {
    return <div>Empresa no confirmada</div>;
  } else if (isAuth?.confirmed && isAuth?.role.name === 'Company') {
    return <Admin />;
  }

  return (
    <Switch>
      <Route path="/company" component={Admin} />
      <Route path="/" component={Main} />
    </Switch>
  );
};
