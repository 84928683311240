import {
  CheckboxGroup,
  Checkbox,
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';

interface Props {
  onChange: (value: string) => void;
  isOpen: boolean;
  onClose: () => void;
  sendReport: () => void;
}

export const ReportModal = ({
  onChange,
  isOpen,
  onClose,
  sendReport,
}: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <CheckboxGroup colorScheme="green">
            <Checkbox
              onChange={() => {
                onChange('La pregunta no se entiende');
              }}
              mb="2"
              mt="5"
            >
              <Text as="span" fontWeight="500">
                {' '}
                La pregunta{' '}
              </Text>{' '}
              no se entiende
            </Checkbox>
            <Checkbox
              onChange={() => {
                onChange('La pregunta tiene un error');
              }}
              mb="2"
            >
              <Text as="span" fontWeight="500">
                La pregunta
              </Text>{' '}
              tiene un error
            </Checkbox>
            <Checkbox
              onChange={() => {
                onChange('Una de las respuestas no se ve correctamente');
              }}
              mb="2"
            >
              <Text as="span" fontWeight="500">
                Una de las respuestas
              </Text>{' '}
              no se ve correctamente
            </Checkbox>
            <Checkbox
              onChange={() => {
                onChange('Una de las respuestas no se entiende bien');
              }}
              mb="2"
            >
              <Text as="span" fontWeight="500">
                Una de las respuestas
              </Text>{' '}
              no se entiende bien
            </Checkbox>
            <Checkbox
              onChange={() => {
                onChange('Una de las respuestas aparece duplicada');
              }}
              mb="2"
            >
              <Text as="span" fontWeight="500">
                Una de las respuestas
              </Text>{' '}
              aparece duplicada
            </Checkbox>
          </CheckboxGroup>
        </ModalBody>

        <ModalFooter mt="-2">
          <Button
            colorScheme="brand"
            onClick={() => {
              sendReport();
            }}
          >
            Guardar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
