import { gql } from '@apollo/client';
import { QUIZ_FRAGMENT } from 'graphql/fragments/quiz';

export const QUIZ_ONLY_PATH = gql`
  query QuizOnlyPath($pathId: ID!) {
    quizOnlyPath(pathId: $pathId) {
      ...QuizParts
    }
  }
  ${QUIZ_FRAGMENT}
`;

export const QUIZ_FOR_PATH = gql`
  query QuizForPath($userPathId: ID!) {
    quizForPath(userPathId: $userPathId) {
      ...QuizParts
    }
  }
  ${QUIZ_FRAGMENT}
`;

export const QUIZ_FOR_COURSE = gql`
  query QuizForCourse($quizId: ID!) {
    quizForCourse(quizId: $quizId) {
      ...QuizParts
    }
  }
  ${QUIZ_FRAGMENT}
`;
