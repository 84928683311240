import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { FlyncerError } from 'graphql/helpers';

import {
  EvaluateOnlyPath,
  EvaluateOnlyPathVariables,
} from 'graphql/generated/EvaluateOnlyPath';
import { EVALUATE_ONLY_PATH } from 'graphql/mutations/quiz';
import * as Sentry from '@sentry/react';

const useEvaluateOnlyPathActions = () => {
  const [onlyPathMutation, { loading: onlyPathLoading }] = useMutation<
    EvaluateOnlyPath,
    EvaluateOnlyPathVariables
  >(EVALUATE_ONLY_PATH);

  const onlyPath = useCallback(
    async (input: EvaluateOnlyPathVariables) => {
      try {
        const response = await onlyPathMutation({ variables: input });
        const evaluation = response.data?.evaluateOnlyPath;

        if (evaluation) {
          return {
            successPercent: evaluation.successPercent,
            level: evaluation.level,
          };
        }
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo crear'));
        throw new FlyncerError('No se pudo crear', error);
      }
    },
    [onlyPathMutation]
  );

  return {
    onlyPath,
    onlyPathLoading,
  };
};

export default useEvaluateOnlyPathActions;
