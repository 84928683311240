import { Topbar } from './Shared/TopBar';
import { BottomBar } from './Shared/BottomBar';
import { ReportForm } from './Shared/ReportForm';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { Courses } from './Courses';
import { Course } from './Course';
import { QuizForPath } from './Quiz/ForPath';
import { QuizForCourse } from './Quiz/ForCourse';
import { Jobs } from './Jobs';
import { Job } from './Job';
import { Profile } from './Profile';
import { Configuration } from './Configuration';
import { Resources } from './Resources';
// import { Onboarding } from './Shared/Onboarding';

import { JobOffer } from './JobOffer';
import { Workshop } from './Workshop';
import { Helmet } from 'react-helmet';
import { useEffect, useRef } from 'react';
import useMe from 'graphql/hooks/profile/useMe';
import { useDisclosure } from '@chakra-ui/react';
import { ModalCompletedProfile } from 'components/ModalCompletedProfile';
import GiftButton from './Shared/GiftButton';
import HexagonCV from './Profile/HexagonCv';
import PublicProfile from './Profile/PublicProfile';
import PrivateProfile from './Profile/PrivateProfile';
import * as Sentry from '@sentry/react';
import StepsCompleteProfileModal from './Shared/StepsCompleteProfileModal';
import { QuizOnlyPath } from './Quiz/OnlyPath';
import { EndOnboarding } from './Jobs/EndOnboarding';

export const Main = () => {
  const { me, error } = useMe();
  const history = useHistory();
  const isCompletedBefore = useRef(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();

  if (!sessionStorage.getItem('isSeen'))
    sessionStorage.setItem('isSeen', 'open');

  useEffect(() => {
    if (
      !me &&
      !location.pathname.includes('/jobsOffer/') &&
      !location.pathname.includes('/onlyPath/') &&
      !location.pathname.includes('/jobs/') &&
      (!location.pathname.includes('/cv/') ||
        isNaN(parseInt(location.pathname.substring(4))))
    ) {
      return;
    }

    if (me?.completed_profile === false) {
      isCompletedBefore.current = false;
    }

    if (me?.completed_profile && !isCompletedBefore.current) {
      onOpen();

      isCompletedBefore.current = true;
    }
  }, [me?.completed_profile, me, onOpen, history, location.pathname]);

  if (error) {
    let message = error;
    Sentry.captureException(message?.graphQLErrors[0]);
  }

  const tagManagerArgs = {
    dataLayer: {
      email: me?.email,
    },
  };
  TagManager.dataLayer(tagManagerArgs);

  let pathsWithLevel = [];

  if (me?.careers)
    pathsWithLevel = me?.careers.filter((career) =>
      career?.activePaths?.find((path) => path?.level)
    );

  return (
    <>
      <Helmet>
        <script
          src="https://cdn.onesignal.com/sdks/OneSignalSDK.js"
          async
        ></script>
        <script>
          {`window.OneSignal = window.OneSignal || [];
      OneSignal.push(function () {
        OneSignal.init({
          appId: '453b8db2-6464-4e62-8ea5-95ad3cdd1424',
        });
      });`}
        </script>
      </Helmet>
      {/* <Onboarding /> */}

      <Topbar />

      {/* {!me?.completed_profile && <ProgressBar />} */}
      {me?.completed_profile && !me.onboarding && <GiftButton />}

      {me &&
      !me?.completed_profile &&
      !me?.onboarding &&
      (location.pathname === '/career' ||
        location.pathname.includes('/profile') ||
        location.pathname.includes('/resources') ||
        location.pathname === '/jobs') ? (
        <StepsCompleteProfileModal
          hasCareer={me?.careers?.length ? true : false}
          experiences={me?.user_cv ? true : false}
          contactInfo={me?.name && me.surname && me.phoneNumber ? true : false}
          professionalRol={
            me?.professions && me?.professions[0]?.startDate ? true : false
          }
          pathsWithLevel={pathsWithLevel?.length ? true : false}
          hasObjectives={
            (me?.desiredCities.length || me.desiredCountries.length) &&
            me.desiredMinSalary
              ? true
              : false
          }
        />
      ) : (
        ''
      )}

      <ReportForm />
      <ModalCompletedProfile isOpen={isOpen} onClose={onClose} />

      <Switch>
        <Route component={Resources} exact path="/resources" />
        <Route component={Courses} exact path="/career" />
        <Route component={Courses} exact path="/onboarding-welcome" />
        <Route component={Courses} exact path="/onboarding-careers-selector" />
        <Route component={Courses} exact path="/onboarding-send-to-profile" />

        <Route component={Course} exact path="/course/:id" />
        <Route component={Course} exact path="/course/video/:id" />
        <Route component={Workshop} exact path="/workshop/:id" />
        <Route component={QuizForPath} exact path="/quiz/path/:id" />
        <Route component={QuizForPath} exact path="/quiz/path/result/:id" />
        <Route component={QuizOnlyPath} exact path="/quiz/onlyPath/:id" />
        <Route
          component={QuizOnlyPath}
          exact
          path="/quiz/onlyPath/result/:id"
        />
        <Route component={QuizForCourse} exact path="/quiz/:id" />
        <Route component={Jobs} exact path="/jobs" />
        <Route component={Jobs} exact path="/onboarding-jobs" />
        <Route component={Jobs} exact path="/jobsResolutions" />
        <Route component={Jobs} exact path="/jobsAccepted" />
        <Route component={EndOnboarding} exact path="/jobs/onboarding-end" />
        <Route component={Job} exact path="/jobs/:id" />
        <Route component={Job} exact path="/jobResolution/:id" />
        <Route component={Job} exact path="/jobAccepted/:id" />
        <Route component={JobOffer} exact path="/jobsOffer/:id" />
        <Route component={JobOffer} exact path="/jobsOffer/apply/:id" />
        <Route component={Profile} path="/profile" />
        <Route component={Profile} path="/onboarding-profile" />
        <Route component={Profile} path="/onboarding-profile-goals" />
        <Route component={Profile} path="/onboarding-profile-contact" />
        <Route component={PublicProfile} path="/publicProfile" />
        <Route component={PrivateProfile} path="/privateProfile" />
        <Route component={Configuration} path="/configuration" />
        <Route component={HexagonCV} path="/cv/:id" />
        <Redirect to="/career" />
      </Switch>

      <BottomBar />
    </>
  );
};
