import { Tab, Tag, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  isInvisible: boolean;
  pendingNews: [];
  acceptedNews: [];
  rejectNews: [];
  jobId: string;
}

export const Bbddtab = ({
  isInvisible,
  pendingNews,
  acceptedNews,
  rejectNews,
  jobId,
}: Props) => {
  return (
    <>
      <Tab
        as={RouterLink}
        to={
          isInvisible
            ? `/company/mistery/job/${jobId}`
            : `/company/job/${jobId}`
        }
        _selected={{ color: 'black', bg: '#FFFF' }}
        color="#8E9492"
        fontWeight="semibold"
      >
        <Text textStyle="body">
          Base de datos{' '}
          {pendingNews.length > 0 ||
          acceptedNews.length > 0 ||
          rejectNews.length ? (
            <Tag>!</Tag>
          ) : (
            ''
          )}{' '}
        </Text>
      </Tab>
    </>
  );
};
