export const StepsCompleteProfile = (
  hasCarrer: boolean,
  professionalRol: boolean,
  experiences: boolean,
  contactInfo: boolean,
  hasObjectives: boolean,
  pathsWithLevel: boolean
) => {
  const stepsCompleteProfile = [
    { title: 'Rellenar tu perfil', url: '/career', isCompleted: hasCarrer },
    { title: 'Añadir carreras', url: '/profile', isCompleted: professionalRol },
    {
      title: 'Añadir experiencia profesional',
      url: '/profile',
      isCompleted: experiences,
    },
    {
      title: 'Añadir tus objetivos',
      url: '/profile/goals',
      isCompleted: hasObjectives,
    },
    {
      title: 'Añadir datos de contacto',
      url: '/profile/contact',
      isCompleted: contactInfo,
    },
    {
      title: 'Realizar un test de nivel',
      url: '/career',
      isCompleted: pathsWithLevel,
    },
  ];

  return { stepsCompleteProfile };
};
