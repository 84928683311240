import {
  useCallback,
  useMemo,
  memo,
  useRef,
  useLayoutEffect,
  useState,
} from 'react';
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { VStack, Button, HStack, Checkbox } from '@chakra-ui/react';

import { Quiz } from 'model/Quiz';
import { QuestionCard } from './QuestionCard';
import { ScrollToFirstError } from './ScrollToFirstError';

interface Props {
  quiz: Quiz;
  textCheckbox: any;
  onSubmit: (answers: Answer[]) => void;
}

export interface Answer {
  questionId: string;
  optionIds: string[];
}

export const FormQuiz = memo(({ quiz, onSubmit, textCheckbox }: Props) => {
  const [isEdited, setisEdited] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const { replace } = useHistory();

  const initialValues = useMemo(
    () =>
      quiz.questions.reduce(
        (acum, question) => ({
          ...acum,
          [question.id]: [],
        }),
        {}
      ),
    [quiz]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape(
        quiz.questions.reduce(
          (acum, question) => ({
            ...acum,
            [question.id]: Yup.array().min(
              1,
              'Todas las preguntas son obligatorias'
            ),
          }),
          {}
        )
      ),
    [quiz]
  );

  const handleSubmit = useCallback(
    async (values) => {
      const answers = Object.entries(values).map(([questionId, optionIds]) => ({
        questionId,
        optionIds: optionIds as string[],
      }));

      try {
        if (location.pathname.includes('/onlyPath')) {
          replace(`/quiz/onlyPath/result/${id}`);
        }
        onSubmit(answers);
      } catch (error: any) {
        console.log(error);
      }
    },
    [onSubmit, id, location.pathname, replace]
  );

  const onChange = useCallback(
    (newValue: string) => {
      let position = 0;
      if (textCheckbox.length > 0) position = textCheckbox.indexOf(newValue);
      if (position === -1 || textCheckbox.length === 0) {
        textCheckbox.push(newValue);
      } else textCheckbox.splice(position, 1);

      if (isEdited) setisEdited(false);
      else setisEdited(true);
    },
    [textCheckbox, isEdited]
  );

  useLayoutEffect(() => {}, []);

  return (
    <Formik<any>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, setFieldValue, isSubmitting }) => (
        <Form ref={formRef}>
          <ScrollToFirstError
            errors={errors}
            formEl={formRef.current}
            isSubmitting={isSubmitting}
          />
          <VStack spacing="4" align="stretch">
            {quiz.questions.map((question, index) => (
              <QuestionCard
                idCourse={quiz.id}
                key={question.id}
                question={question}
                quizzErrors={[]}
                questionNumber={index + 1}
                value={values[question.id]}
                setFieldValue={setFieldValue}
                error={errors[question.id]}
              />
            ))}

            <HStack pt="20px" pb="20px">
              <Button type="button" as={RouterLink} to="/careers">
                Cancelar
              </Button>
              <Button
                disabled={textCheckbox.length ? false : true}
                type="submit"
                colorScheme="brand"
              >
                Enviar
              </Button>
              <Checkbox
                colorScheme="green"
                value="1"
                float="right"
                onChange={() => {
                  onChange('1');
                }}
              >
                He realizado el test sin ayuda de terceros
              </Checkbox>
            </HStack>
          </VStack>
        </Form>
      )}
    </Formik>
  );
});
