import { memo } from 'react';
import { useField } from 'formik';

import { StackProps, Checkbox, Text, Stack } from '@chakra-ui/react';
import useCompanyTypes from 'graphql/hooks/profile/useCompanyTypes';
import * as Sentry from '@sentry/react';

interface Props extends StackProps {
  name: string;
}

export const DesiredCompanyTypes = memo(({ name, ...props }: Props) => {
  const { companyTypes, loading, error } = useCompanyTypes();
  const [{ value }, , { setValue }] = useField<string[]>(name);

  if (error) Sentry.captureException(error?.graphQLErrors[0]);

  if (loading) return null;

  const onChange = (isChecked: boolean, newValue: string) => {
    if (isChecked) {
      setValue(value.filter((v) => v !== newValue));
    } else {
      setValue([...value, newValue]);
    }
  };

  return (
    <Stack spacing={8} {...props}>
      {companyTypes.map((companyType) => {
        const isChecked = value.includes(companyType.id);
        return (
          <Checkbox
            key={companyType.id}
            name={name}
            value={companyType.id}
            display="flex"
            alignItems="flex-start"
            size="lg"
            spacing={3}
            onChange={() => onChange(isChecked, companyType.id)}
            isChecked={isChecked}
            colorScheme="brand"
          >
            <Text textStyle="body">{companyType.name}</Text>
            <Text textStyle="caption" color="gray.500" mt={1.5}>
              {companyType.description}
            </Text>
          </Checkbox>
        );
      })}
    </Stack>
  );
});
