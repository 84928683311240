import { gql } from '@apollo/client';
import { STUDY_FRAGMENT } from 'graphql/fragments/study';

export const STUDIES = gql`
  query Studies {
    studies {
      ...StudyParts
    }
  }
  ${STUDY_FRAGMENT}
`;
