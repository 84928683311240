import { Button, Stack, Text } from '@chakra-ui/react';
import { SectionCard } from 'components/SectionCard';
import { Form, Formik } from 'formik';
import useSubProfessions from 'graphql/hooks/profile/useSubProfessions';
import { useState } from 'react';
import Joyride from 'react-joyride';
import useConnect from '../../connect';
import { CountryResidence } from './CountryResidence';
import { Curriculum } from './Curriculum';
import { EnglishLevel } from './EnglishLevel';
import { ExperienceCV } from './ExperienceCV';
import { ExperienceYears } from './ExperienceYears';
import { Studies } from '../../Studies';
import { useLocation } from 'react-router-dom';

export const ProfileTab = () => {
  const { subProfessions } = useSubProfessions('2');
  const {
    handleSubmit,
    initialValues,
    schema,
    cv,
    me,
    errorExp,
    seterrorExp,
    errorYearsExp,
    settouched,
    seterrorYearsExp,
    touched,
    countries,
    valueExp,
    setvalueExp,
    isMobile,
  } = useConnect();
  const [forceRender, setforceRender] = useState(false);
  const locationPath = useLocation();

  if (
    errorExp &&
    (locationPath.pathname === '/profile' ||
      locationPath.pathname === '/onboarding-profile')
  )
    document.getElementById('experience')?.scrollIntoView();
  else if (
    errorYearsExp &&
    (locationPath.pathname === '/profile' ||
      locationPath.pathname === '/onboarding-profile')
  )
    document.getElementById('rol')?.scrollIntoView();

  const steps: any = [
    {
      target: '.profileStack',
      disableBeacon: true,
      disableOverlayClose: true,
      spotlightClicks: true,
      hideCloseButton: true,
      disableCloseOnEsc: true,
      hideFooter: true,
      placement: 'right-start',
      content: (
        <>
          <Text>
            Ahora rellenemos tu perfil. Será clave para que las empresas
            entiendan quién eres.
          </Text>
          <Text mt="3" color="#009253" fontWeight="500">
            Paso (3/6)
          </Text>
        </>
      ),
    },
  ];

  if (!me) return null;

  if (forceRender) {
    setTimeout(() => {
      setforceRender(false);
    }, 250);
  }

  return (
    <>
      {me?.onboarding && (
        <Joyride
          scrollToFirstStep={true}
          showSkipButton={false}
          continuous={true}
          steps={steps}
          showProgress={false}
          styles={{
            options: { zIndex: 3000, overlayColor: '#3A3939' },
            overlay: { height: 'auto' },
            buttonNext: {
              backgroundColor: '#009253',
            },
          }}
        />
      )}
      <Stack spacing="6" mt="6" className="profileStack">
        <SectionCard
          id="experience"
          border={
            errorExp
              ? '1px solid #CB1410'
              : !me.onboarding && !me.user_cv
              ? '1px solid #F25D09'
              : ''
          }
          title="Experiencia Profesional"
          onboarding={true}
        >
          <ExperienceCV
            setvalueExp={setvalueExp}
            valueExp={valueExp}
            seterrorExp={seterrorExp}
            errorExp={errorExp}
            setForceRender={setforceRender}
          />
          {errorExp && (
            <Text mt="2" fontSize="14px" color="#CB1410">
              Este campo es requerido
            </Text>
          )}
        </SectionCard>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <Stack spacing="6" mt="6" mb={isMobile ? '28' : '0'}>
                <SectionCard
                  id="rol"
                  title="Rol profesional"
                  border={
                    errorYearsExp
                      ? '1px solid #CB1410'
                      : !me.onboarding &&
                        me?.professions &&
                        me?.professions[0]?.experienceYears === undefined
                      ? '1px solid #F25D09'
                      : ''
                  }
                  onboarding={true}
                >
                  <ExperienceYears
                    name="professionId"
                    subProfessions={subProfessions}
                    settouched={settouched}
                    seterrorYearsExp={seterrorYearsExp}
                    touched={touched}
                  />
                  {errorYearsExp && (
                    <Text mt="2" fontSize="14px" color="#CB1410">
                      Este campo es requerido
                    </Text>
                  )}
                </SectionCard>
                <SectionCard title="Formación">
                  <Studies name="studies" />
                </SectionCard>
                <SectionCard className="cvs" title="Curriculum">
                  <Curriculum cv={cv} />
                </SectionCard>
                <SectionCard title="País de residencia">
                  <CountryResidence
                    name="countryResidence"
                    countries={countries}
                  />
                </SectionCard>
                <SectionCard title="Nivel de inglés">
                  <EnglishLevel name="englishLevel" />
                </SectionCard>
                <Button
                  colorScheme="brand"
                  className="save"
                  mt="6"
                  mb="10"
                  type="submit"
                  disabled={isSubmitting}
                  maxWidth="max-content"
                >
                  Guardar
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </>
  );
};
