import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputProps,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  InputAddonProps,
  InputElementProps,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { useField } from 'formik';

interface Props extends InputProps {
  label?: string;
  leftAddon?: InputAddonProps;
  rightAddon?: InputAddonProps;
  leftElement?: InputElementProps;
  rightElement?: InputElementProps;
  altError?: String;
}

export const FormInput = ({
  id,
  label,
  name = '',
  leftAddon,
  leftElement,
  rightAddon,
  rightElement,
  altError,
  ...props
}: Props) => {
  const [field, { error, touched }] = useField(name);

  const isInvalid = touched && !!error;

  return (
    <FormControl id={id || name} isInvalid={isInvalid}>
      {label && (
        <FormLabel mb="2" textStyle="small">
          {label}
        </FormLabel>
      )}
      <InputGroup>
        {leftAddon && <InputLeftAddon {...leftAddon} />}
        {leftElement && <InputLeftElement {...leftElement} />}
        <Input id={id} focusBorderColor="brand.500" {...props} {...field} />
        {rightAddon && <InputRightAddon {...rightAddon} />}
        {rightElement && <InputRightElement {...rightElement} />}
      </InputGroup>

      {isInvalid && (
        <FormErrorMessage mt="1" textStyle="caption">
          {altError ? altError : error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
