import { Button, HStack, Text } from '@chakra-ui/react';
import useUserActions from 'graphql/hooks/profile/useMeActions';
import { Link as RouterLink, useHistory } from 'react-router-dom';

export const StepsOnboarding = (setisOpen?: any) => {
  const { replace } = useHistory();

  const { finishTour } = useUserActions();

  const steps: any = [
    {
      target: '.addPaths',
      disableOverlayClose: true,
      disableBeacon: true,
      content:
        'Comienza añadiendo las tecnologías que manejas. Haz clic aquí para añadirlas.',
      hideCloseButton: true,
      spotlightClicks: true,
      hideFooter: true,
      disableCloseOnEsc: true,
    },
    {
      target: '.modal',
      disableOverlayClose: true,
      placement: 'right-start',

      content: (
        <>
          <Text>
            {localStorage.getItem('button') === 'training'
              ? 'Escoge otras tecnologías que manejes. Esto desbloqueará los tests y formaciones.'
              : 'Escoge las tecnologías que manejas de cada área. Esto desbloqueará los tests y formaciones. Dale a guardar cuando las tengas.'}
          </Text>
          <Text mt="3" color="#009253" fontWeight="500">
            Paso (1/6)
          </Text>
        </>
      ),
      hideCloseButton: true,
      spotlightClicks: true,
      hideFooter: true,
      disableBeacon: true,
      disableCloseOnEsc: true,
    },

    {
      target: '.careerTop',
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      hideFooter: true,

      content: (
        <>
          <Text>
            Podrás encontrar las formaciones y los tests en el área de Carrera.
          </Text>
          <HStack mt="3" justifyContent="flex-end">
            <Text color="#009253" fontWeight="500">
              Paso (2/6)
            </Text>
            <Button
              as={RouterLink}
              to={`/profile`}
              _hover={{ bg: '#009253' }}
              variant="outline"
              bg="#009253"
              color="white"
              p="2"
            >
              Siguiente
            </Button>
          </HStack>
        </>
      ),
      hideCloseButton: true,
    },
  ];

  const stepsTest: any = [
    {
      target: '.modal',
      disableOverlayClose: true,
      content: (
        <>
          <Text>
            {localStorage.getItem('button') === 'training'
              ? 'Escoge otras tecnologías que manejes. Esto desbloqueará los tests y formaciones.'
              : 'Escoge las tecnologías que manejas de cada área. Esto desbloqueará los tests y formaciones. Dale a guardar cuando las tengas.'}
          </Text>
          <Text mt="3" color="#009253" fontWeight="500">
            Paso (1/6)
          </Text>
        </>
      ),
      hideCloseButton: true,
      spotlightClicks: true,
      hideFooter: true,
      disableBeacon: true,
      disableCloseOnEsc: true,
    },

    {
      target: '.careerTop',
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      hideFooter: true,

      content: (
        <>
          <Text>
            Podrás encontrar las formaciones y los tests en el área de Carrera.
          </Text>
          <HStack mt="3" justifyContent="flex-end">
            <Text color="#009253" fontWeight="500">
              Paso (2/6)
            </Text>
            <Button
              as={RouterLink}
              to={`/onboarding-profile`}
              _hover={{ bg: '#009253' }}
              variant="outline"
              bg="#009253"
              color="white"
              p="2"
            >
              Siguiente
            </Button>
          </HStack>
        </>
      ),
      hideCloseButton: true,
    },
  ];

  const steps2 = [
    {
      target: '.offers',
      disableOverlayClose: true,
      hideCloseButton: true,
      disableBeacon: true,
      disableCloseOnEsc: true,
      hideFooter: true,
      content: (
        <>
          <Text>
            Aquí podrás manejar tu búsqueda de empleo. También será donde podrás
            ver las ofertas que recibas de las empresas.
          </Text>
          <HStack mt="3" justifyContent="flex-end">
            <Text color="#009253" fontWeight="500">
              Paso (6/6)
            </Text>
            <Button
              onClick={() => {
                finishTour();
                replace('/jobs/onboarding-end');
              }}
              _hover={{ bg: '#009253' }}
              variant="outline"
              bg="#009253"
              color="white"
              p="2"
            >
              Finalizar
            </Button>
          </HStack>
        </>
      ),
    },
  ];

  const steps3: any = [
    {
      target: '.contactStack',
      disableBeacon: true,
      disableOverlayClose: true,
      spotlightClicks: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: 'right-start',
      disableCloseOnEsc: true,
      content: (
        <>
          <Text>
            Terminemos con tus datos de contacto. Serán secretos hasta que
            aceptes liberarlos para una oferta.
          </Text>
          <Text mt="3" color="#009253" fontWeight="500">
            Paso (5/6)
          </Text>
        </>
      ),
    },
  ];

  return { steps, steps2, steps3, stepsTest };
};

export default StepsOnboarding;
