import { useMutation } from '@apollo/client';
import {
  CREATE_USER_PROFESSION,
  UPDATE_USER_PROFESSION,
} from 'graphql/mutations/userProfession';
import { useCallback } from 'react';
import { FlyncerError } from 'graphql/helpers';
import {
  UpdateExpYearsVariables,
  UpdateExpYears,
} from 'graphql/generated/UpdateExpYears';
import {
  createUserProfession,
  createUserProfessionVariables,
} from 'graphql/generated/createUserProfession';
import * as Sentry from '@sentry/react';

const useUserProfessionsActions = () => {
  const [updateProfession] = useMutation<
    UpdateExpYears,
    UpdateExpYearsVariables
  >(UPDATE_USER_PROFESSION);

  const [createProfession] = useMutation<
    createUserProfession,
    createUserProfessionVariables
  >(CREATE_USER_PROFESSION);

  const update = useCallback(
    async (input, professionId, expYears, subprofessions, startDate) => {
      try {
        await updateProfession({
          variables: {
            input,
            professionId,
            expYears,
            subprofessions,
            startDate,
          },
        });
      } catch (error: any) {
        Sentry.captureException(
          new Error('No se pudo actualizar el user profession')
        );
        throw new FlyncerError('No se pudo crear', error);
      }
    },
    [updateProfession]
  );

  const create = useCallback(
    async (profession, userId, expYears, subprofessions, startDate) => {
      try {
        await createProfession({
          variables: {
            profession,
            userId,
            expYears,
            subprofessions,
            startDate,
          },
        });
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo crear la profesión'));
        throw new FlyncerError('No se pudo crear', error);
      }
    },
    [createProfession]
  );

  return {
    create,
    update,
  };
};

export default useUserProfessionsActions;
