import {
  Avatar,
  Box,
  CheckboxGroup,
  Circle,
  CircularProgress,
  Grid,
  GridItem,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Progress,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FiGift } from 'react-icons/fi';
import { StepsCompleteProfile } from 'utils/stepsCompleteProfile';
import { ProgressCheckbox } from './ProgressCheckbox';

interface Props {
  hasCareer: boolean;
  experiences: boolean;
  contactInfo: boolean;
  professionalRol: boolean;
  pathsWithLevel: boolean;
  hasObjectives: boolean;
}

function StepsCompleteProfileModal({
  hasCareer,
  experiences,
  contactInfo,
  professionalRol,
  pathsWithLevel,
  hasObjectives,
}: Props) {
  const array = [
    hasCareer,
    experiences,
    contactInfo,
    professionalRol,
    pathsWithLevel,
    hasObjectives,
  ];

  const stepsDone = array.filter((done) => done);
  const [isOpen, setisOpen] = useState(false);
  const [isRender, setisRender] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const { stepsCompleteProfile } = StepsCompleteProfile(
    hasCareer,
    professionalRol,
    experiences,
    contactInfo,
    hasObjectives,
    pathsWithLevel
  );

  const progress = (stepsDone.length * 100) / 6;

  return (
    <Box
      id="completeProfile-widget-button"
      position="fixed"
      left={isMobile ? '17px' : '32px'}
      bottom={isMobile ? '70px' : '32px'}
      zIndex="10"
      cursor="pointer"
    >
      <CircularProgress
        onClick={() => {
          setisOpen(true);
        }}
        zIndex="2"
        value={progress}
        size="90px"
        color="brand.500"
      />
      <Avatar
        position="fixed"
        left={isMobile ? '30px' : '45px'}
        bottom={isMobile ? '84.5px' : '45px'}
        size="lg"
        bg="transparent"
        src="/fallback-avatar.png"
      />
      <Modal
        blockScrollOnMount={false}
        isOpen={sessionStorage.getItem('isSeen') === 'open' ? true : isOpen}
        onClose={() => {
          if (sessionStorage.getItem('isSeen')) {
            sessionStorage.setItem('isSeen', 'close');
            if (!isRender) setisRender(true);
          }
          setisOpen(false);
        }}
      >
        <ModalContent
          fontSize="22px"
          position="fixed"
          width={isMobile ? '70%' : '35%'}
          left={isMobile ? '110px' : '130px'}
          bottom={isMobile ? '10px' : '-2'}
          maxWidth="300"
        >
          <ModalHeader fontSize="18px">Completa tu perfil</ModalHeader>
          <Grid templateColumns="repeat(4,1fr)" mr="6">
            <GridItem colSpan={1} rowStart={1}>
              <Text ml="3" textAlign="center" fontSize="16px">
                {stepsDone.length}/6
              </Text>
            </GridItem>

            <GridItem colSpan={3} colStart={2} rowStart={1}>
              <Progress
                border="1px solid #ABB3C2"
                borderRadius="10px"
                bg="transparent"
                mt="7px"
                colorScheme="brand"
                value={progress}
              />
            </GridItem>

            <GridItem
              colStart={4}
              rowStart={1}
              zIndex="3"
              display="flex"
              justifyContent="flex-end"
            >
              <Circle size="28px" bg="white" border="1px solid #ABB3C2">
                <Icon color="gray.300" as={FiGift} h="4"></Icon>
              </Circle>
            </GridItem>
          </Grid>

          <ModalCloseButton />
          <ModalBody mt="2">
            <CheckboxGroup colorScheme="green">
              {stepsCompleteProfile.map((step) => (
                <ProgressCheckbox
                  title={step.title}
                  isCompleted={step.isCompleted}
                  url={step.url}
                />
              ))}
            </CheckboxGroup>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default StepsCompleteProfileModal;
