import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_REPORT } from 'graphql/mutations/createReport';
import { AddReport, AddReportVariables } from 'graphql/generated/AddReport';
import { createToast } from 'services/toast';

const useReportActions = () => {
  const [createReportMutation, { loading: createReportLoading }] = useMutation<
    AddReport,
    AddReportVariables
  >(CREATE_REPORT);

  const createReport = useCallback(
    async (data: AddReportVariables) => {
      try {
        await createReportMutation({ variables: data });
        createToast({
          title: 'Mensaje enviado',
          description:
            data?.reason === 'Duda' || data?.reason === 'Error'
              ? 'Muy pronto  nos pondremos en contacto contigo.'
              : 'Gracias por tu mensaje.',
          status: 'success',
        });
      } catch (error: any) {
        createToast({
          title: 'Error de envío',
          description: `Algo falló intentando mandar el mensaje.`,
          status: 'error',
        });
        console.log(error);
      }
    },
    [createReportMutation]
  );

  return {
    isLoading: createReportLoading,
    createReport,
  };
};

export default useReportActions;
