import { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { FormValues } from './types';
import useMeActions from 'graphql/hooks/profile/useMeActions';
import useMe from 'graphql/hooks/profile/useMe';
import useMassMedias from 'graphql/hooks/profile/useMassMedias';
import { ENUM_COMPONENTCONFIGURATIONCONFIGURATION_NAME } from 'graphql/generated/globalTypes';

const useConnect = () => {
  const { me, loading, refetchUser } = useMe();
  const { updateProfile } = useMeActions();
  const { massMedias, loading: loadingMedias } = useMassMedias();
  let contactOffersArray: any = useMemo(() => [], []),
    candidacyUpdateArray: any = useMemo(() => [], []),
    newsArray: any = useMemo(() => [], []);

  if (me?.messagingConfiguration) {
    me.messagingConfiguration.forEach((configuration) => {
      if (
        configuration?.name ===
        ENUM_COMPONENTCONFIGURATIONCONFIGURATION_NAME.contactOffers
      ) {
        contactOffersArray = configuration.mass_medias?.map(
          (media) => media?.id
        );
      }

      if (
        configuration?.name ===
        ENUM_COMPONENTCONFIGURATIONCONFIGURATION_NAME.candidacyUpdate
      ) {
        candidacyUpdateArray = configuration.mass_medias?.map(
          (media) => media?.id
        );
      }

      if (
        configuration?.name ===
        ENUM_COMPONENTCONFIGURATIONCONFIGURATION_NAME.news
      ) {
        newsArray = configuration.mass_medias?.map((media) => media?.id);
      }
    });
  }

  const whatsapp = massMedias.find((media) => media.name === 'Whatsapp');
  const sms = massMedias.find((media) => media.name === 'SMS');
  const email = massMedias.find((media) => media.name === 'Email');

  const schema = Yup.object().shape({
    contactOffers: Yup.array()
      .min(1, 'Debes seleccionar al menos una forma de recibir las ofertas')
      .required(),
    candidacyUpdate: Yup.array()
      .min(
        1,
        'Debes seleccionar al menos una forma de recibir actualizaciones de las candidaturas'
      )
      .required(),
  });

  const initialValues: FormValues = useMemo(
    () => ({
      contactOffers: me?.messagingConfiguration
        ? contactOffersArray
        : [sms?.id, email?.id],
      candidacyUpdate: me?.messagingConfiguration
        ? candidacyUpdateArray
        : [whatsapp?.id],
      news: me?.messagingConfiguration ? newsArray : [email?.id],
    }),
    [
      email,
      whatsapp,
      candidacyUpdateArray,
      contactOffersArray,
      newsArray,
      me?.messagingConfiguration,
      sms,
    ]
  );

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      if (!me) return;
      try {
        await updateProfile({
          input: {
            where: { id: me.id },
            data: {
              messagingConfiguration: {
                configuration: [
                  {
                    mass_medias: values.contactOffers.map((id) => id),
                    name: ENUM_COMPONENTCONFIGURATIONCONFIGURATION_NAME.contactOffers,
                  },
                  {
                    mass_medias: values.candidacyUpdate.map((id) => id),
                    name: ENUM_COMPONENTCONFIGURATIONCONFIGURATION_NAME.candidacyUpdate,
                  },
                  {
                    mass_medias: values.news.map((id) => id),
                    name: ENUM_COMPONENTCONFIGURATIONCONFIGURATION_NAME.news,
                  },
                ],
              },
            },
          },
        });

        refetchUser();
      } catch (e) {
        console.log(e);
      }
    },
    [me, updateProfile, refetchUser]
  );

  return {
    me,
    whatsapp,
    email,
    loading,
    massMedias,
    loadingMedias,
    schema,
    initialValues,
    handleSubmit,
    candidacyUpdateArray,
    contactOffersArray,
    newsArray,
    sms,
  };
};

export default useConnect;
