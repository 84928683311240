import { Box, Button, Flex, Stack, Link } from '@chakra-ui/react';
import { useOneUser } from 'graphql/hooks/admin/useUser';
import { useParams } from 'react-router-dom';
import { Cv } from './Cv';
import { PersonalInfo } from './PersonalInfo/index';
import { Skills } from './Skills';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
import * as Sentry from '@sentry/react';
import { useState } from 'react';

export const Candidate = () => {
  const { id } = useParams<{ id: string }>();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const uuid = id;
  const { user, error } = useOneUser({ uuid });
  const [isDownload, setisDownload] = useState(false);

  if (error) Sentry.captureException(error?.graphQLErrors[0]);

  const downloadCV = async () => {
    await setisDownload(true);
    const cv = document.getElementById('cv') as HTMLElement;
    html2canvas(cv).then((canvas) => {
      var data = canvas.toDataURL();
      setisDownload(false);

      var docDefinition: any = {
        pageSize: { width: 598, height: 'auto' },
        pageMargins: 0,
        content: [
          {
            image: data,
            width: 598,
          },
        ],
      };
      pdfMake.createPdf(docDefinition).download(`perfil-${user?.fullName}.pdf`);
    });
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <Box
        bg="gray.10"
        id="cv"
        as="main"
        mx="auto"
        w="100%"
        maxW="50rem"
        px={{ base: '4', md: '6' }}
        py={{ base: '8', md: '14' }}
      >
        <Stack spacing="6">
          <PersonalInfo user={user} isDownload={isDownload} />
          <Skills user={user} />
          <Cv user={user} isDownload={isDownload} />
        </Stack>{' '}
      </Box>
      <Flex
        width={{ sm: 'auto', lg: '73.5%' }}
        mb="6"
        justifyContent="end"
        mx="auto"
        w="100%"
        maxW="50rem"
        px={{ base: '4', md: '6' }}
        mt="-8"
      >
        {user.cv && (
          <Link
            href={user.cv}
            _hover={{ textDecorationLine: 'none' }}
            isExternal
          >
            <Button mr="4" variant="outline" colorScheme="brand">
              Descargar CV
            </Button>
          </Link>
        )}
        <Button
          mr={{ sm: '3' }}
          colorScheme="green"
          width="auto"
          onClick={() => {
            downloadCV();
          }}
        >
          Descargar perfil
        </Button>
      </Flex>
    </>
  );
};
