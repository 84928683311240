import { Text, Stack, Icon } from '@chakra-ui/react';
import { FiLock } from 'react-icons/fi';

export const IncompleteModal = () => {
  return (
    <Stack
      maxW="full"
      background="white"
      height="2xs"
      textAlign="center"
      justifyContent="center"
      alignItems="center"
    >
      <Icon as={FiLock} color="brand.500" height="80px" width="80px" />
      <Text textStyle="h4">
        Completa tu perfil para acceder al path de Management
      </Text>
    </Stack>
  );
};
