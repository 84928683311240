import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_CV_EXPERIENCE, CREATE_CV } from 'graphql/mutations/userCV';
import { FlyncerError } from 'graphql/helpers';
import { createToast } from 'services/toast';
import {
  AddCVExperience,
  AddCVExperienceVariables,
} from 'graphql/generated/AddCVExperience';
import { ME } from 'graphql/queries/me';
import * as Sentry from '@sentry/react';
import useMe from './useMe';
import { createCV } from 'graphql/generated/createCV';

const useMeCVActions = () => {
  const { me, refetchUser } = useMe();

  const [addCVExperienceMutation, { loading: CVLoading }] = useMutation<
    AddCVExperience,
    AddCVExperienceVariables
  >(ADD_CV_EXPERIENCE, { refetchQueries: [{ query: ME }] });

  const addCVExperience = useCallback(
    async (data: AddCVExperienceVariables) => {
      try {
        await addCVExperienceMutation({ variables: data });
        if (!me?.onboarding)
          createToast({
            title: 'Añadir experiencia',
            description: 'Experiencia añadida correctamente.',
            status: 'success',
          });
      } catch (error: any) {
        createToast({
          title: 'Añadir experiencia',
          description: 'Algo falló intentando añadir la experiencia.',
          status: 'error',
        });
        Sentry.captureException(new Error('No se pudo actualizar el CV'));
        throw new FlyncerError('No se pudo actualizar el CV', error);
      }
    },
    [addCVExperienceMutation, me?.onboarding]
  );

  const [createCVMutation, { loading: createCVLoading }] =
    useMutation<createCV>(CREATE_CV);

  const createCV = useCallback(async () => {
    try {
      if (!me?.user_cv) {
        await createCVMutation().then(() => {
          if (me?.onboarding) refetchUser();
        });
      }
    } catch (error: any) {
      Sentry.captureException(new Error('No se pudo actualizar el CV'));
      throw new FlyncerError('No se pudo actualizar el CV', error);
    }
  }, [createCVMutation, me?.user_cv, me?.onboarding, refetchUser]);

  return {
    CVLoading,
    addCVExperience,
    createCV,
    createCVLoading,
  };
};

export default useMeCVActions;
