import { gql } from '@apollo/client';

export const SEND_PIXEL = gql`
  mutation SendPixel(
    $ip: String!
    $userAgent: String!
    $event: String!
    $eventID: String!
  ) {
    fbPixel(ip: $ip, userAgent: $userAgent, event: $event, eventID: $eventID)
  }
`;
