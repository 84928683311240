import {
  Text,
  HStack,
  Box,
  IconButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { FiEdit2 } from 'react-icons/fi';
import { Editor } from '../editor';

interface Props {
  orderedExp: any[];
  showEdit: string;
  setShowEdit: any;
  setForceRender: any;
  updateUpdates: () => void;
  updateDeleted: () => void;
}

export const AccordionExp = ({
  orderedExp,
  showEdit,
  setShowEdit,
  setForceRender,
  updateUpdates,
  updateDeleted,
}: Props) => {
  return (
    <>
      {orderedExp.map((exp: any, index: number) =>
        showEdit !== exp.id && index <= 2 ? (
          <Box
            borderRadius="md"
            border="1px"
            borderColor="gray.300"
            p={{ base: '2', md: '4' }}
            key={`CV ${exp.id}`}
          >
            <HStack justify="space-between">
              <Text textStyle="h6">{exp.empresa}</Text>
              <IconButton
                aria-label="Editar experiencia"
                icon={<FiEdit2 />}
                variant="outline"
                onClick={() => {
                  setShowEdit(exp.id ? exp.id : 'off');
                  if (setForceRender) setForceRender(true);
                }}
              />
            </HStack>
            <Text>{exp.puesto}</Text>
            <Text>{`${exp.inicio ? exp.inicio : ''} - ${
              exp.fin ? exp.fin : 'Actualidad'
            }`}</Text>{' '}
          </Box>
        ) : showEdit === exp.id && index <= 2 ? (
          <Editor
            empresa={exp.empresa}
            puesto={exp.puesto}
            inicio={exp.inicio}
            fin={exp.fin}
            funciones={exp.funciones}
            userSkills={exp?.skills}
            expId={exp?.id}
            update={updateDeleted}
            updateUpdate={updateUpdates}
          />
        ) : (
          ''
        )
      )}

      {orderedExp?.length > 3 && (
        <Accordion
          allowToggle
          border="transparent"
          position="relative"
          zIndex="3"
        >
          <AccordionItem>
            <AccordionButton
              onClick={() => {
                if (setForceRender) setForceRender(true);
              }}
              w="auto"
              border="1px solid #D8DFEA"
              borderRadius="10"
            >
              {orderedExp?.length - 3 > 1
                ? `${orderedExp?.length - 3} experiencias más`
                : `${orderedExp?.length - 3} experiencia más`}
              <AccordionIcon ml="2" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              {orderedExp.map((exp: any, index: number) =>
                showEdit !== exp.id && index >= 3 ? (
                  <Box
                    borderRadius="md"
                    border="1px"
                    borderColor="gray.300"
                    p={{ base: '2', md: '4' }}
                    mt="3"
                    key={`CV ${exp.id}`}
                  >
                    <HStack justify="space-between">
                      <Text textStyle="h6">{exp.empresa}</Text>
                      <IconButton
                        aria-label="Editar experiencia"
                        icon={<FiEdit2 />}
                        variant="outline"
                        onClick={() => {
                          setShowEdit(exp.id ? exp.id : 'off');
                          if (setForceRender) setForceRender(true);
                        }}
                      />
                    </HStack>
                    <Text>{exp.puesto}</Text>
                    <Text>{`${exp.inicio ? exp.inicio : ''} - ${
                      exp.fin ? exp.fin : 'Actualidad'
                    }`}</Text>{' '}
                  </Box>
                ) : showEdit === exp.id ? (
                  <Editor
                    empresa={exp.empresa}
                    puesto={exp.puesto}
                    inicio={exp.inicio}
                    fin={exp.fin}
                    funciones={exp.funciones}
                    userSkills={exp?.skills}
                    expId={exp?.id}
                    update={updateDeleted}
                    updateUpdate={updateUpdates}
                  />
                ) : (
                  ''
                )
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </>
  );
};
