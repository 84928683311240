import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  Icon,
  IconButton,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Field, FieldArray } from 'formik';
import { AiOutlinePlus } from 'react-icons/ai';
import { IoTrashBin } from 'react-icons/io5';
import { Tecnologies } from './Tecnologies';

interface Props {
  totalUsers: number;
  values: any;
  setTecnologiesState: any;
  tecnologiesState: any;
  seterrorPath: (value: boolean) => void;
  errorPath: boolean;
}

export const TecnologiesForm = ({
  totalUsers,
  values,
  setTecnologiesState,
  tecnologiesState,
  errorPath,
  seterrorPath,
}: Props) => {
  if (tecnologiesState[0][0] !== '') seterrorPath(false);
  return (
    <FormControl mt="10">
      <Text fontSize="16" fontWeight="bold">
        <Flex>
          Tecnologías *
          <Spacer />
          <Badge fontSize="11" p="1" rounded="md" visibility="hidden">
            {totalUsers !== 1
              ? `${totalUsers} disponibles`
              : `${totalUsers} disponible`}
          </Badge>
        </Flex>
      </Text>
      <Text fontSize="12" color="gray.500" my="2">
        Añade las tecnologias que quieras que controlen tus candidatos
      </Text>
      <FieldArray name="pathGroups">
        {({ insert, remove, push }) => (
          <Stack spacing={5} mt={5}>
            {values.pathGroups.length > 0 &&
              values.pathGroups.map((path: any, i: any) => {
                const isLast = values.pathGroups.length - 1 === i;
                return (
                  <>
                    <Stack direction="row">
                      <Box w="full">
                        <Field
                          name={`pathGroups.${i}`}
                          component={Tecnologies}
                          setTecnologiesState={setTecnologiesState}
                          tecnologiesState={tecnologiesState}
                        />
                      </Box>

                      {i > 0 && (
                        <IconButton
                          aria-label="delete"
                          icon={<IoTrashBin />}
                          variant="ghost"
                          colorScheme="red"
                          onClick={() => remove(i)}
                        />
                      )}
                    </Stack>
                    <Center>
                      <Button
                        size="xs"
                        borderRadius="15px"
                        colorScheme="brand.500"
                        variant="outline"
                        fontSize="11"
                        isDisabled={!isLast}
                        onClick={() => push([''])}
                      >
                        AND{' '}
                        {isLast && (
                          <Icon
                            as={AiOutlinePlus}
                            ml="1"
                            color="blackAlpha.900"
                          />
                        )}
                      </Button>
                    </Center>
                  </>
                );
              })}
          </Stack>
        )}
      </FieldArray>
      {errorPath && (
        <Text mt="2" fontSize="14px" color="#CB1410">
          Este campo es requerido
        </Text>
      )}
    </FormControl>
  );
};
