import {
  Text,
  Button,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Grid,
  ModalCloseButton,
  VStack,
  Icon,
  GridItem,
  Center,
} from '@chakra-ui/react';
import { PartyPopper } from 'components/ModalCompletedProfile/party-popper';
import { Hexagon } from 'containers/Admin/Candidate/PersonalInfo/Hexagon/index';
import { MdOutlineSchool } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  setisOpen: (isOpen: boolean) => void;
  pathsNames: any;
  isMobile: boolean;
  idUser: any;
  currentLevelID: string;
}

export const OnboardingModal = ({
  pathsNames,
  isMobile,
  idUser,
  currentLevelID,
}: Props) => {
  const { replace } = useHistory();

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        replace('/');
        localStorage.removeItem('button');
        localStorage.removeItem('pathID');
        localStorage.removeItem('evaluation');
      }}
    >
      <ModalOverlay />
      <ModalContent display="block" minWidth="34%">
        <ModalHeader textAlign="center">
          <PartyPopper w="60px" h="60px" mb="4" mt="4" />
          <br />
          <Text as="h3" fontSize="22px" fontWeight="bold" color="grey.100">
            ¡Felicidades! <br /> Has completado el onboarding
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign="center" pb="16">
          Ahora, comienza midiendo tu nivel en una tecnología
          <Grid
            mx="4"
            templateColumns="repeat(2, 1fr)"
            gap="2"
            mt="4"
            alignItems="center"
          >
            {pathsNames?.map((path: any) => {
              return (
                <GridItem colSpan={isMobile ? 2 : 1}>
                  <Center>
                    <HStack spacing="5" boxShadow="md" p="2" borderRadius="md">
                      <Text>{path.name}</Text>
                      <Button
                        as={RouterLink}
                        to={`/quiz/path/${path.id}`}
                        colorScheme="brand"
                      >
                        Hacer test
                      </Button>
                    </HStack>
                  </Center>
                </GridItem>
              );
            })}
          </Grid>
          {localStorage.getItem('button') !== 'training' && (
            <>
              {' '}
              <Text mt="6" mb="6">
                ¡Realiza al menos 3 tests para desbloquear tu flyncer map!
              </Text>
              <Hexagon
                cv="Candidate"
                type="Modal"
                paths={[]}
                pathsHexagon={[]}
                pathsId={[]}
                idUser={idUser}
              />
            </>
          )}
          {localStorage.getItem('button') === 'training' && (
            <VStack mt="10" boxShadow="md" p="4">
              <Icon as={MdOutlineSchool} color="brand.500" w={12} h={12} />
              <Text fontWeight="semibold">
                {' '}
                Si no, ¡comienza tu curso de Javascript!
              </Text>
              <br />
              <Button
                position="relative"
                bottom="15px"
                as={RouterLink}
                to={currentLevelID ? `/course/${currentLevelID}` : '/'}
                colorScheme="brand"
              >
                Comenzar ahora
              </Button>
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
