import { gql } from '@apollo/client';
import { SANITIZED_JOB_FRAGMENT } from 'graphql/fragments/job';

export const JOB_REQUEST_IS_ACCEPTED_FRAGMENT = gql`
  fragment JobRequestParts on JobRequest {
    isAccepted
  }
`;

export const JOB_REQUEST_FRAGMENT = gql`
  fragment JobRequestParts on JobRequest {
    id
    isAccepted
    position {
      ...PositionSanitizedParts
    }
  }
  ${SANITIZED_JOB_FRAGMENT}
`;
