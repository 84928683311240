import { Box, HStack, Flex, Image, Text, Badge } from '@chakra-ui/react';
import { AllOffersTags } from 'components/AllOffersTags';
import { Avatar } from 'components/Avatar';
import { Position } from 'model/JobRequest';
import { normalizeExperience } from 'utils/normalizeExperience';
import { normalizeSalaryRange } from 'utils/normalizeSalaryRange';
import { StarIcon } from '@chakra-ui/icons';
import { PerfectMatch } from 'components/PerfectMatch';
import { Me } from 'model/Me';

interface Props {
  company: Position['company'];
  position: Position['name'];
  skills: Position['skills'];
  location: Position['location'];
  experienceYears: Position['experienceYears'];
  salary: Position['salary'];
  level: any;
  userJobApplication: any;
  created_at: any;
  match: boolean;
  me: Me;
}

export const AllJobCard = ({
  userJobApplication,
  company,
  position,
  skills,
  location,
  experienceYears,
  salary,
  level,
  created_at,
  match,
  me,
}: Props) => {
  let skillsName: any[] = [];
  skills?.forEach((skill) => {
    skillsName.push(skill);
  });
  let matchLocations;

  matchLocations = me?.desiredCountries.some((userLocations: any) =>
    location.find((locs) => locs === userLocations.name)
  );

  if (location[0] !== 'Remoto')
    matchLocations = me?.desiredCities.some((userLocations) =>
      location.find((locs) => locs === userLocations.name)
    );

  const matchCompanyType = me?.desiredCompanyTypes.find(
    (userCompanyTypes) => userCompanyTypes.name === company?.type
  );

  const todayDate = new Date();
  const jobOfferDate = new Date(created_at.created_at);

  const diff =
    (todayDate.getTime() - jobOfferDate.getTime()) / (1000 * 60 * 60 * 24);

  if (!me) return null;

  let userExpYears;
  if (me.professions) {
    userExpYears = me?.professions[0]?.experienceYears;
  }

  const isValid =
    experienceYears &&
    userExpYears &&
    userExpYears >= experienceYears &&
    salary.max &&
    me?.desiredMinSalary &&
    salary.max >= me?.desiredMinSalary &&
    matchLocations &&
    matchCompanyType &&
    Math.floor(diff) <= 7
      ? true
      : false;

  return (
    <Flex
      direction="column"
      justifyContent="fl"
      bg="white"
      shadow="base"
      p="6"
      pt={isValid ? '1.5' : '6'}
      rounded="md"
      _hover={{
        transform: 'translateY(-4px)',
        boxShadow: 'md',
      }}
      border={isValid ? '1px solid orange' : ''}
    >
      {isValid && (
        <HStack spacing={0} mb="2">
          <StarIcon w={2.5} h={2.5} color="orange.400" mr="1" />
          <Text color="orange.400" fontSize="0.8em">
            Novedad para ti
          </Text>
        </HStack>
      )}

      <HStack spacing="2" mb="5">
        <Avatar
          w="12"
          h="12"
          alt={company?.name}
          src={company?.logo?.url}
          zIndex="1"
        />
        <Image
          rounded="md"
          objectFit="cover"
          border="1px"
          borderColor="gray.200"
        />
        <Box>
          <Text as="h5" textStyle="h5">
            {position}
          </Text>
          <Text textStyle="body" color="gray.500">
            {company?.name}
          </Text>
        </Box>
      </HStack>

      <AllOffersTags tags={skillsName} level={level} mb="5" />

      <HStack mt="auto" spacing="4" justifyContent="space-between">
        <HStack>
          {location && <Text textStyle="caption">{location.join(' / ')}</Text>}

          <Text textStyle="caption" whiteSpace="nowrap">
            {normalizeExperience(experienceYears)}
          </Text>
          {salary && (
            <Text textStyle="caption">{normalizeSalaryRange(salary)}</Text>
          )}
        </HStack>

        <HStack>
          {match && !userJobApplication && (
            <Badge fontWeight="bold" backgroundColor="transparent">
              <PerfectMatch spacing={1} color="brand.600">
                MATCH 100%
              </PerfectMatch>
            </Badge>
          )}
          {userJobApplication && (
            <Badge
              fontSize="0.7em"
              variant="outline"
              colorScheme={
                userJobApplication?.isDiscarded === null
                  ? 'gray'
                  : userJobApplication?.isDiscarded
                  ? 'red'
                  : 'green'
              }
            >
              {userJobApplication?.isDiscarded === null
                ? 'Aplicada'
                : userJobApplication?.isDiscarded
                ? 'Descartado'
                : 'Preseleccionado'}
            </Badge>
          )}
        </HStack>
      </HStack>
    </Flex>
  );
};
