import { useCallback } from 'react';
import { Form, Formik } from 'formik';

import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  FormControl,
  FormLabel,
  Switch,
  Stack,
  Radio,
  RadioGroup,
  Textarea,
} from '@chakra-ui/react';
import { ENUM_USERJOBRESOLUTION_CANCELREASON } from 'graphql/generated/globalTypes';
import { useHistory } from 'react-router';

interface FormValues {
  cancelReason: ENUM_USERJOBRESOLUTION_CANCELREASON;
  cancelReasonOtherMessage?: string;
  banCompany: boolean;
}

interface Props {
  handleReject: (values: FormValues) => void;
}

const initialValues = {
  cancelReason: ENUM_USERJOBRESOLUTION_CANCELREASON.company,
  cancelReasonOtherMessage: undefined,
  banCompany: false,
};

export const CancelButtonWithModal = ({ handleReject }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { replace } = useHistory();

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await handleReject(values);
        replace('/jobs');
      } catch {}
    },
    [handleReject, replace]
  );

  return (
    <>
      <Button
        variant="outline"
        colorScheme="red"
        onClick={onOpen}
        mb="200"
        id="rejectOffer-button1"
      >
        Rechazar
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
        scrollBehavior="inside"
      >
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                  Tus skills y expectativas parecen encajar con la oferta.
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text textStyle="small" color="gray.500">
                    ¿Puedes indicarnos los motivos para rechazar el contacto y
                    así evitar hacerte llegar solicitudes de contacto de ofertas
                    que no encajen en tus expectativas en el futuro?
                  </Text>
                  <RadioGroup
                    onChange={(value) =>
                      setFieldValue(
                        'cancelReason',
                        value as ENUM_USERJOBRESOLUTION_CANCELREASON
                      )
                    }
                    value={values.cancelReason}
                    colorScheme="brand"
                    mt="10"
                  >
                    <Stack spacing="8">
                      <Radio
                        value={ENUM_USERJOBRESOLUTION_CANCELREASON.company}
                      >
                        Empresa
                      </Radio>
                      <Radio value={ENUM_USERJOBRESOLUTION_CANCELREASON.role}>
                        Puesto y/o funciones
                      </Radio>
                      <Radio value={ENUM_USERJOBRESOLUTION_CANCELREASON.tech}>
                        Tecnologías
                      </Radio>
                      <Radio value={ENUM_USERJOBRESOLUTION_CANCELREASON.salary}>
                        Salario
                      </Radio>
                      <Radio
                        value={ENUM_USERJOBRESOLUTION_CANCELREASON.location}
                      >
                        Ubicación
                      </Radio>
                      <Radio value={ENUM_USERJOBRESOLUTION_CANCELREASON.other}>
                        Otros
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  {values.cancelReason ===
                    ENUM_USERJOBRESOLUTION_CANCELREASON.other && (
                    <FormControl mt="10">
                      <Text
                        as={FormLabel}
                        htmlFor="other-reason"
                        textStyle="small"
                      >
                        Describe cual
                      </Text>
                      <Textarea
                        id="other-reason"
                        value={values.cancelReasonOtherMessage}
                        onChange={(e) =>
                          setFieldValue(
                            'cancelReasonOtherMessage',
                            e.target.value
                          )
                        }
                      />
                    </FormControl>
                  )}

                  <FormControl display="flex" alignItems="center" mt="10">
                    <Switch
                      id="email-alerts"
                      colorScheme="brand"
                      mr="2"
                      size="lg"
                      isChecked={values.banCompany}
                      onChange={() =>
                        setFieldValue('banCompany', !values.banCompany)
                      }
                    />
                    <Text
                      as={FormLabel}
                      htmlFor="email-alerts"
                      mb="0"
                      textStyle="body"
                      color="gray.500"
                    >
                      No quiero recibir más ofertas de esta empresa
                    </Text>
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button onClick={onClose} mr="4">
                    Cancelar
                  </Button>
                  <Button
                    id="rejectOffer-button2"
                    colorScheme="red"
                    type="submit"
                    isDisabled={isSubmitting}
                  >
                    Rechazar oferta
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
