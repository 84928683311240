import { gql } from '@apollo/client';

export const DELETE_NOTIFICATION = gql`
  mutation deleteUserNotification($notificationID: ID!) {
    deleteUserNotification(notificationID: $notificationID)
  }
`;

export const ADD_SEEN_USER_NOTIFICATION = gql`
  mutation addSeenUserNotification($notificationID: ID!) {
    addSeenUserNotification(notificationID: $notificationID)
  }
`;
