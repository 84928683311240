import { gql } from '@apollo/client';
import { CV_FRAGMENT } from 'graphql/fragments/meCV';

export const ADD_CV_EXPERIENCE = gql`
  mutation AddCVExperience($input: AddCVExperienceInput!) {
    addCVExperience(input: $input) {
      ...MeCVParts
    }
  }
  ${CV_FRAGMENT}
`;

export const CREATE_CV = gql`
  mutation createCV {
    createCV
  }
`;
