import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  TogglePrelevelMatches,
  TogglePrelevelMatchesVariables,
} from 'graphql/generated/TogglePrelevelMatches';
import { TOGGLE_MATCHES } from 'graphql/mutations/togglePrelevelMatches';
import { FlyncerError } from 'graphql/helpers';
import * as Sentry from '@sentry/react';

export const useTogglePrelevelMatches = () => {
  const [togglePrelevelMatchesMutation, { loading }] = useMutation<
    TogglePrelevelMatches,
    TogglePrelevelMatchesVariables
  >(TOGGLE_MATCHES);

  const togglePrelevelMatches = useCallback(
    async (positionId: TogglePrelevelMatchesVariables) => {
      try {
        await togglePrelevelMatchesMutation({
          variables: positionId,
        });
      } catch (error: any) {
        Sentry.captureException(
          new Error('No se pudo cambiar el estado de la oferta')
        );
        throw new FlyncerError(
          'No se pudo cambiar el estado de la oferta',
          error
        );
      }
    },
    [togglePrelevelMatchesMutation]
  );

  return {
    togglePrelevelMatches,
    loading,
  };
};

export default useTogglePrelevelMatches;
