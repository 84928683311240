import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS = gql`
  query getNotifications {
    getNotifications {
      id
      created_at
      Title
      Message
      Url
      seenUsers {
        id
      }
    }
  }
`;
