import useMeCVActions from 'graphql/hooks/profile/useMeCVActions';
import useMeExperienceActions from 'graphql/hooks/profile/useMeExperienceActions';
import { useCallback, useMemo, useState } from 'react';
import { createToast } from 'services/toast';
import { sanitizeDateExp } from 'utils/sanitizeDate';
import { FormValues } from './types';
import * as Yup from 'yup';
import useSkills from 'graphql/hooks/profile/useSkills';
import useMeCV from 'graphql/hooks/profile/useMeCV';
import useMe from 'graphql/hooks/profile/useMe';

interface LinkedinValues {
  linkedinURL: string | undefined | null;
}

export const useConnect = (
  eId?: any,
  update?: any,
  setForceRender?: any,
  updateUpdate?: any,
  empresa?: any,
  puesto?: any,
  inicio?: any,
  fin?: any,
  funciones?: any,
  userSkills?: any,
  seterrorExp?: (error: boolean) => void
) => {
  const { addCVExperience } = useMeCVActions();
  const { updateExperience, deleteExperience } = useMeExperienceActions();
  const { skills, loading, error } = useSkills();
  const { meCV, loading: loadingCv } = useMeCV();
  const { me, refetchUser } = useMe();
  const { createCV } = useMeCVActions();
  const [loadingExp, setloadingExp] = useState(false);
  const [loadImport, setLoadImport] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [errorImport, seterrorImport] = useState(false);
  const [showEdit, setShowEdit] = useState('off');

  const {
    importLinkedinExperience,
    deleteAllExperiences,
    seterrorExpLength,
    errorExpLength,
  } = useMeExperienceActions();

  const schema = Yup.object().shape({
    empresa: Yup.string().required('Campo obligatorio'),
    puesto: Yup.string().required('Campo obligatorio'),
    /**Falta */
    inicio: Yup.date().required('Campo obligatorio'),
  });

  const validSkills = userSkills?.map((skill: any) => ({
    label: skill.name,
    value: skill.id,
  }));

  const initialValues = {
    empresa: empresa ? empresa : '',
    puesto: puesto ? puesto : '',
    inicio: inicio ? inicio : null,
    fin: fin ? fin : null,
    funciones: funciones ? funciones : '',
    skills: validSkills ? validSkills.map((skill: any) => skill.value) : [],
    id: eId ? eId : '',
  };

  const initialValuesIndex = useMemo(
    () => ({
      linkedinURL: me?.linkedinURL,
      experiences: (meCV?.experiences || []).map((exp) => ({
        empresa: exp.empresa,
        puesto: exp.puesto,
        funciones: exp.funciones,
        inicio: exp.inicio,
        fin: exp.fin,
        skills: (exp?.skills || []).map((skill) => ({
          id: skill.id,
          name: skill.name,
        })),
        id: exp.id ? exp.id : '',
      })),
    }),
    [me, meCV]
  );

  const handleSubmit = useCallback(
    async (values: FormValues, { setSubmitting }) => {
      setSubmitting(true);
      const isValid = sanitizeDateExp(values);

      if (isValid) {
        if (eId === '') {
          try {
            await addCVExperience({
              input: {
                empresa: values.empresa,
                puesto: values.puesto,
                inicio: values.inicio,
                fin: values.fin ? values.fin : null,
                funciones: values.funciones,
                skills: values.skills ? values.skills.map((s) => s) : undefined,
              },
            });
            update();
            if (setForceRender) setForceRender(true);
          } catch (error: any) {
            console.log(error);
          }
        } else {
          try {
            await updateExperience({
              input: {
                where: {
                  id: eId,
                },
                data: {
                  empresa: values.empresa,
                  puesto: values.puesto,
                  inicio: values.inicio,
                  fin: values.fin ? values.fin : null,
                  funciones: values.funciones,
                  skills: values.skills ? values.skills : undefined,
                },
              },
            });
            if (setForceRender) setForceRender(true);
          } catch (error: any) {
            console.log('error' + error);
            setSubmitting(false);
          }
        }
        updateUpdate();
      } else {
        createToast({
          title: `Fechas erróneas`,
          description: 'Revisa las fechas de inicio y de fin',
          status: 'error',
        });
      }
    },
    [
      updateExperience,
      addCVExperience,
      eId,
      updateUpdate,
      update,
      setForceRender,
    ]
  );

  const handleSubmit1 = useCallback(
    async (values: LinkedinValues) => {
      try {
        if (values.linkedinURL) {
          setloadingExp(true);
          setLoadImport(true);
          await importLinkedinExperience({
            url: values.linkedinURL,
          }).then(() => {
            if (seterrorExp) seterrorExp(false);
            if (setForceRender) setForceRender(true);
            if (seterrorExp) seterrorExp(false);

            setloadingExp(false);

            refetchUser();
          });
          setLoadImport(false);
          if (isOpen) setisOpen(false);
          setDeleted(true);
          if (errorImport) seterrorImport(false);
        } else seterrorImport(true);
      } catch (error: any) {
        console.log(error);
      }
    },
    [
      importLinkedinExperience,
      refetchUser,
      seterrorExp,
      setForceRender,
      errorImport,
      isOpen,
    ]
  );
  const orderedSkills = skills.sort(function (a, b) {
    return a.name.localeCompare(b.name);
  });

  const handleDelete = useCallback(async () => {
    try {
      await deleteExperience({
        id: eId,
      });
      if (setForceRender) setForceRender(true);
      update();
    } catch (error: any) {
      console.log(error);
    }
  }, [deleteExperience, eId, update, setForceRender]);

  const handleClose = () => {
    updateUpdate();
  };

  const handleDeleteIndex = useCallback(async () => {
    try {
      setLoadImport(true);
      await deleteAllExperiences().then(() => {
        refetchUser();
        if (isOpen) setisOpen(false);
      });
      setLoadImport(true);
      setDeleted(true);
    } catch (error: any) {
      console.log(error);
    }
  }, [deleteAllExperiences, refetchUser, isOpen]);

  const updateDeleted = () => {
    if (seterrorExp) seterrorExp(false);
    setDeleted(true);
  };
  const updateUpdates = () => {
    setShowEdit('off');
    if (setForceRender) setForceRender(true);
  };

  return {
    handleSubmit,
    schema,
    initialValues,
    skills,
    loading,
    error,
    orderedSkills,
    handleClose,
    handleDelete,
    initialValuesIndex,
    createCV,
    setLoadImport,
    isOpen,
    setisOpen,
    setDeleted,
    deleted,
    handleSubmit1,
    errorImport,
    seterrorImport,
    loadImport,
    loadingCv,
    loadingExp,
    errorExpLength,
    seterrorExpLength,
    deleteAllExperiences,
    handleDeleteIndex,
    updateDeleted,
    updateUpdates,
    setShowEdit,
    showEdit,
  };
};
export default useConnect;
