import { FC } from 'react';
import { Box, Flex, Text, Center } from '@chakra-ui/react';
import { Logo } from 'components/Logo';
import { IllustrationCompany } from './IllustrationCompany';

interface Props {
  title?: string;
  subtitle?: string;
}

export const LayoutCompany: FC<Props> = ({ title, subtitle, children }) => {
  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      overflow="hidden"
      minH="100vh"
      height="100%"
    >
      <Box flex="1" p="10" display={{ base: 'none', md: 'block' }} bg="#4A5568">
        <Flex
          maxW={{ md: 'sm' }}
          mr={{ lg: '20' }}
          ml="auto"
          h="100%"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Logo
            w="auto"
            h="8"
            alignSelf="flex-start"
            colorWhite
            justifySelf="flex-start"
          />
          <Center flexDirection="column">
            <IllustrationCompany ml="-12" maxW="400px" w="100%" h="300px" />
            <Text as="h4" textStyle="h4" mt={'10'} color="white">
              Acceso Empresas
            </Text>
          </Center>
          <Box />
        </Flex>
      </Box>

      <Box flex="1" p={{ base: '5', md: '10' }} overflowY="auto">
        <Logo w="auto" h="8" mb="10" display={{ base: 'block', md: 'none' }} />
        <Flex
          maxW={{ md: 'sm' }}
          ml={{ lg: '20' }}
          h="100%"
          mx="auto"
          alignItems="center"
        >
          <Box flex="1">
            {title && (
              <Text as="h3" textStyle="h3" mb="1">
                {title}
              </Text>
            )}
            {subtitle && (
              <Text as="h3" textStyle="body" mb="10">
                {subtitle}
              </Text>
            )}
            {children}
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};
