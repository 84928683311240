import {
  Button,
  Container,
  Grid,
  GridItem,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { NewOfferIllustration } from 'containers/Admin/Jobs/shared/newOfferIllustration';
import { useCallback, useState } from 'react';
import { NewSlotIllustration } from '../shared/newSlotIlustration';
interface props {
  bonos: Number;
  title: string;
  text: string;
  alert: string;
  isSlot?: boolean;
}

export const CreateOfferContainer = ({
  bonos,
  title,
  text,
  alert,
  isSlot,
}: props) => {
  const history = useHistory();
  const isDisabled = bonos === 0;
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(() => {
    if (isDisabled) {
      setIsOpen(true);
    } else {
      if (isSlot) {
        localStorage.setItem('isSlot', 'true');
      } else {
        localStorage.removeItem('isSlot');
      }
      history.push('company/form');
    }
  }, [isDisabled, history, isSlot]);

  return (
    <Container
      maxW="container.xl"
      mx="auto"
      mt="7"
      w="100%"
      boxShadow="md"
      px={{ base: '8', md: '14' }}
      py="8"
      bg="white"
      borderRadius={'8px'}
    >
      <Grid templateColumns="repeat(6, 1fr)" gap={6}>
        <GridItem colSpan={{ base: 6, md: 4 }}>
          <Heading mt="1">{title}</Heading>
          <Text mt="5">{text}</Text>

          <Popover isOpen={isOpen} isLazy>
            <PopoverTrigger>
              <Button
                onClick={handleClick}
                mt="5"
                colorScheme={isSlot ? 'blue' : 'brand'}
                background={isSlot ? 'blue.700' : 'brand.500'}
                opacity={isDisabled ? '0.4' : 1}
              >
                Publicar oferta
              </Button>
            </PopoverTrigger>
            <PopoverContent p="2">
              <PopoverArrow />
              <PopoverCloseButton onClick={() => setIsOpen(false)} />
              <PopoverHeader fontWeight="bold" border="0">
                Créditos insuficientes
              </PopoverHeader>
              <PopoverBody fontSize="15">{alert}</PopoverBody>
            </PopoverContent>
          </Popover>
        </GridItem>

        <GridItem colSpan={{ base: 6, md: 2 }}>
          {isSlot ? (
            <NewSlotIllustration mt="-10" maxW="275px" w="100%" h="220px" />
          ) : (
            <NewOfferIllustration mt="-10" maxW="275px" w="100%" h="220px" />
          )}
        </GridItem>
      </Grid>
    </Container>
  );
};
