import { Button, MenuItem, VStack, Text } from '@chakra-ui/react';
import MiniHexagonProfile from '../MiniHexagon/miniHexagonProfile';
import { NavLink } from 'react-router-dom';

interface Props {
  pathsWithLevel: any[];
  isOpen: boolean;
  pathsHexagon: any[];
  pathsId: any[];
  me: any;
}
export const HexagonMenuItem = ({
  pathsHexagon,
  pathsId,
  pathsWithLevel,
  me,
  isOpen,
}: Props) => {
  return (
    <MenuItem cursor="default" _hover={{ bg: 'transparent' }}>
      <VStack maxWidth={270} border="1px solid green" p="2" borderRadius="md">
        <Text mb={pathsWithLevel.length < 3 ? '2' : ''} fontWeight="medium">
          Tu Flyncer Map
        </Text>
        {pathsWithLevel.length < 3 && (
          <Text fontSize="14" textAlign="center" color="gray.400">
            *Haz 3 test para desbloquear el Flyncer Map.
          </Text>
        )}
        {isOpen && (
          <MiniHexagonProfile
            cv={'HexagonTopBar'}
            paths={pathsWithLevel}
            pathsHexagon={pathsHexagon}
            pathsId={pathsId}
            idUser={me.id}
          />
        )}
        <Button
          variant="outline"
          colorScheme="green"
          borderRadius="10"
          as={NavLink}
          to="/profile/flyncermap"
        >
          {' '}
          Ver Flyncer Map
        </Button>
      </VStack>
    </MenuItem>
  );
};
