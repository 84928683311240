import { useParams } from 'react-router';
import useFullWorkshop from 'graphql/hooks/career/useFullWorkshop';
import useFullUserWorkshop from 'graphql/hooks/career/useFullUserWorkshop';
import * as Sentry from '@sentry/react';

export const useConnect = () => {
  const { id } = useParams<{ id: string }>();
  const { workshop, loading, error } = useFullWorkshop(id);
  const {
    userWorkshops,
    refetch,
    error: fullUserWorkshopError,
  } = useFullUserWorkshop(id);

  if (error || fullUserWorkshopError) {
    let message = error ? error : fullUserWorkshopError;
    Sentry.captureException(message?.graphQLErrors[0]);
  }

  return {
    workshop,
    userWorkshops,
    refetch,
    loading,
  };
};
