import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { jobPositions } from 'graphql/generated/jobPositions';
import { ADMIN_JOBS } from 'graphql/queries/job';

export const useJobs = () => {
  const { data, loading, refetch, error } = useQuery<jobPositions>(ADMIN_JOBS);

  const allJobs = useMemo(() => {
    return data?.getJobs;
  }, [data]);

  return {
    error,
    allJobs,
    loading,
    refetch,
  };
};
