import {
  Box,
  Container,
  Divider,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { ItineraryData } from '../Courses/Itinerary';
import useConnect from './connect';
import useCareers from 'graphql/hooks/career/useCareers';
import { NewItinerary } from '../Courses/Itinerary/NewItinerary';
import { IncompleteModal } from './incompleteModal';
import { useState, useEffect } from 'react';
import useUserWorkshops from 'graphql/hooks/career/useUserWorkshops';
import { EmpleabilityData } from '../Courses/Itinerary/Empleability';
import { NewEmpleabilityItinerary } from '../Courses/Itinerary/NewEmpleability';

export const Resources = () => {
  const { me, userCareers, refetch } = useConnect();
  const { careers } = useCareers();
  const { userWorkshops } = useUserWorkshops();

  const [of, setOf] = useState(false);

  useEffect(() => {
    refetch();
    if (of !== me?.completed_profile) {
      setOf(me?.completed_profile ? me?.completed_profile : of);
    }
    return () => {
      setOf(me?.completed_profile ? me?.completed_profile : of);
    };
  }, [me?.completed_profile, setOf, of, refetch]);

  if (!me) {
    return null;
  }

  return (
    <>
      <Box
        as="main"
        mx="auto"
        w="100%"
        maxW="container.xl"
        px={{ base: '4', md: '6' }}
        py={{ base: '8', md: '14' }}
      >
        <Flex
          mb={{ base: '6', md: '8' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Text as="h3" textStyle={{ base: 'h4', md: 'h3' }}>
            Recursos
          </Text>
        </Flex>
        <Tabs colorScheme="brand" mb="10">
          <TabList
            border="0"
            position="relative"
            zIndex={1}
            w={{ base: '100%', md: 'auto' }}
          >
            <Tab fontWeight="semibold">
              <Text textStyle="body">Empleabilidad</Text>
            </Tab>
            <Tab fontWeight="semibold">
              <Text textStyle="body">Management</Text>
            </Tab>
          </TabList>
          <Box pos="relative" zIndex={0}>
            <Divider
              borderBottomWidth="2px"
              opacity={1}
              borderColor="gray.200"
            />
          </Box>
          <TabPanels>
            <TabPanel p="0">
              <Container maxW="full" py={6} px="0">
                {userCareers.some((career) => career.career.esRecurso)
                  ? userCareers
                      .filter((career) => career.career.esRecurso)
                      .map((userCareer) => {
                        const { activeItineraries } = userCareer;
                        return (
                          <>
                            {activeItineraries.map((itinerary) => {
                              return (
                                <EmpleabilityData
                                  key={itinerary.itinerary.id}
                                  userItinerary={itinerary}
                                />
                              );
                            })}
                          </>
                        );
                      })
                  : careers
                      .filter((career) => career.esRecurso)
                      .map((career) => {
                        return (
                          <>
                            <NewEmpleabilityItinerary career={career} />
                          </>
                        );
                      })}
              </Container>
            </TabPanel>
            <TabPanel p="0">
              <Container maxW="full" py={6} px="0">
                {of || userWorkshops.length ? (
                  <>
                    {userCareers.filter(
                      (career) => career.career.name === 'Management'
                    ).length
                      ? userCareers
                          .filter(
                            (career) => career.career.name === 'Management'
                          )
                          .map((userCareer) => {
                            const { activeItineraries } = userCareer;
                            return (
                              <>
                                {activeItineraries.map((itinerary) => {
                                  return (
                                    <ItineraryData
                                      key={itinerary.itinerary.id}
                                      userItinerary={itinerary}
                                    />
                                  );
                                })}
                              </>
                            );
                          })
                      : careers
                          .filter((career) => career.name === 'Management')
                          .map((career) => {
                            return (
                              <>
                                <NewItinerary career={career} />
                              </>
                            );
                          })}
                  </>
                ) : (
                  <IncompleteModal />
                )}
              </Container>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};
