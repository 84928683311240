import { useCallback, useState } from 'react';
import useReportActions from 'graphql/hooks/report/useReportActions';
import { FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { ENUM_REPORTS_REASON } from 'graphql/generated/globalTypes';

interface ReportValues {
  reason?: ENUM_REPORTS_REASON;
  message?: string;
}

let initialValues: ReportValues = {
  reason: undefined,
  message: '',
};

const schema = Yup.object({
  reason: Yup.string().required('Este campo es requerido'),
  message: Yup.string().required('Este campo es requerido'),
});

const useConnect = () => {
  const [formId, setFormId] = useState(0);
  const { createReport } = useReportActions();

  const handleSubmit = useCallback(
    async (values: ReportValues, helpers: FormikHelpers<ReportValues>) => {
      helpers.setSubmitting(true);
      try {
        await createReport({
          message: values.message!,
          reason: values.reason!,
        });
        setFormId((formId) => formId + 1);
      } catch (error: any) {
        helpers.setSubmitting(false);
      }
    },
    [createReport, setFormId]
  );
  return {
    handleSubmit,
    initialValues,
    schema,
    formId,
  };
};

export default useConnect;
