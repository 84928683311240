export const normalizeLevel = (level: string | undefined) => {
  if (level === undefined) {
    return '';
  }

  if (level === '1') {
    return 'Junior';
  }

  if (level === '2') {
    return 'Intermedio';
  }

  if (level === '3') {
    return 'Avanzado';
  }

  return 'Experto';
};
