import { AiFillStar } from 'react-icons/ai';
import { BiPhone } from 'react-icons/bi';
import { HiOutlineMail } from 'react-icons/hi';
import { FiLinkedin, FiGithub } from 'react-icons/fi';
import { sanitizeName } from 'utils/sanitizeName';
import { sanitizeDate } from 'utils/sanitizeDate';
import { Flex, Heading, Spacer, Tag, Text } from '@chakra-ui/react';
import { PerfectMatch } from 'components/PerfectMatch';

interface Props {
  position: number | undefined;
  type: string;
  user: any;
  isUndefined: boolean;
  lastUpdate: any;
  isMatch?: boolean;
}
export const CandidateHeader = ({
  position,
  type,
  user,
  isUndefined,
  lastUpdate,
  isMatch,
}: Props) => {
  return (
    <>
      {position !== -1 && type === 'Inscribed' && (
        <Flex mb="2">
          <AiFillStar className="star" color="#c18e28" />
          <Text color="#c18e28">Nuevo</Text>
        </Flex>
      )}
      <Flex flexWrap="wrap">
        <Heading
          filter={
            user.name === undefined || type === 'PublicProfile'
              ? 'blur(7px)'
              : ''
          }
          fontSize={{ lg: '28px', sm: '15px' }}
          whiteSpace="pre"
        >
          {type === 'PublicProfile'
            ? sanitizeName(undefined, ' ')
            : sanitizeName(user.name, user.email)}
        </Heading>

        <Spacer />
        {isMatch && (
          <Tag fontWeight="400" fontSize="11" height="30px" bg="transparent">
            <PerfectMatch color="brand.600" fontWeight="extrabold">
              PERFECT MATCH
            </PerfectMatch>
          </Tag>
        )}
        <Tag fontWeight="400" fontSize="11" height="30px" ml={1} mr={2}>
          Activo {sanitizeDate(lastUpdate)}
        </Tag>
        {user.cv && <Tag height="30px">CV</Tag>}

        {user.linkedinURL !== '' && !isUndefined && (
          <Tag height="30px" ml={1}>
            {' '}
            <FiLinkedin />{' '}
          </Tag>
        )}

        {user.githubURL && (
          <Tag height="30px" ml={1}>
            <FiGithub />
          </Tag>
        )}
      </Flex>
      {user.name !== undefined && (
        <Flex mt={{ lg: '1' }}>
          <BiPhone className="icon" />
          <Text ml="1" mr="2">
            {' '}
            {user.phoneNumber ? user.phoneNumber : 'No disponible'}
          </Text>
          <HiOutlineMail className="icon" />
          <Text ml="1"> {user.email ? user.email : 'No disponible'}</Text>
        </Flex>
      )}
    </>
  );
};
