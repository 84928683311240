import { Countries_countries } from 'graphql/generated/Countries';
import { removeNulls, WithoutNulls } from 'utils/removeNulls';
import { normalizeLocations } from 'model/Location';
import { normalizeCities } from './City';

export const normalizeCountry = (input: WithoutNulls<Countries_countries>) => ({
  id: input.id,
  name: input.name,
  locations: normalizeLocations(input.cities || []),
  cities: normalizeCities(input.cities || []),
});

export type Country = ReturnType<typeof normalizeCountry>;

export const normalizeCountries = (
  input: (WithoutNulls<Countries_countries> | undefined)[]
) =>
  input.reduce<Country[]>(
    (acum, input) =>
      !!input ? acum.concat(normalizeCountry(removeNulls(input))) : acum,
    []
  );
