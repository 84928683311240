import { gql } from '@apollo/client';
import { DOWNLOADABLE_FILE_FRAGMENT } from 'graphql/fragments/downloadableFile';

export const DOWNLOADABLES = gql`
  query allDownloadableFiles {
    allDownloadableFiles {
      ...DownloadableFileParts
    }
  }
  ${DOWNLOADABLE_FILE_FRAGMENT}
`;
