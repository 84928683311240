import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { Jobs } from 'graphql/generated/Jobs';
import { ALLJOBS, JOBS, SANITIZEDJOBS } from 'graphql/queries/job';
import { removeNulls } from 'utils/removeNulls';
import { normalizeJobs } from 'model/Job';

export const useJobs = () => {
  const { data, loading, error } = useQuery<Jobs>(JOBS);

  const jobs = useMemo(() => {
    return data?.activeJobs ? normalizeJobs(removeNulls(data.activeJobs)) : [];
  }, [data]);

  return {
    error,
    jobs,
    loading,
  };
};

export const AllJobs = () => {
  const { data, loading, refetch, error } = useQuery(ALLJOBS);

  const getJobs = useMemo(() => {
    return data?.allJobs ? data.allJobs : [];
  }, [data]);

  return {
    error,
    refetch,
    getJobs,
    loading,
  };
};

export const SanitizedJobs = () => {
  const { data, loading, refetch, error } = useQuery(SANITIZEDJOBS);

  const getJobs = useMemo(() => {
    return data?.allJobsSanitized ? data.allJobsSanitized : [];
  }, [data]);

  return {
    refetch,
    getJobs,
    loading,
    error,
  };
};
