import { NavLink } from 'react-router-dom';
import useAuth from 'graphql/hooks/auth/useAuth';

import {
  Box,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link,
  Button,
  Text,
  MenuDivider,
  Table,
  Tr,
  Td,
} from '@chakra-ui/react';
import { Logo } from 'components/Logo';
import { FiUser } from 'react-icons/fi';
import useCompanies from 'graphql/hooks/company/useCompany';
import { Token } from 'components/Token';
import * as Sentry from '@sentry/react';
import { BlueToken } from 'components/TokenBlue';

export const Topbar = () => {
  const { logout } = useAuth();
  const { company, error } = useCompanies();

  if (error) Sentry.captureException(error?.graphQLErrors[0]);

  return (
    <Box bg="#4A5568">
      <Flex
        as="nav"
        aria-label="Navegación principal"
        maxW="container.xl"
        px={{ base: '4', md: '6' }}
        mx="auto"
        h="16"
        alignItems="center"
      >
        <Link as={NavLink} to="/company">
          <Logo colorWhite w="auto" height="8" />
        </Link>
        <HStack ml="auto" spacing={{ base: '2', md: '6' }}>
          <Button
            as={NavLink}
            exact
            to="/company"
            variant="ghost"
            color="white"
          >
            Ofertas
          </Button>
          <Menu>
            <MenuButton
              as={IconButton}
              isRound
              fontSize="xl"
              aria-label="Perfil de usuario"
              variant="ghost"
              color="gray.10"
              icon={<FiUser />}
            />
            <MenuList>
              <MenuItem
                cursor="default"
                _hover={{ bg: 'transparent' }}
                fontSize="lg"
              >
                Tus créditos:{' '}
              </MenuItem>

              <Table size="sm" variant="unstyled">
                <Tr>
                  <MenuItem cursor="default" _hover={{ bg: 'transparent' }}>
                    <Td maxW="12">
                      <Token />
                    </Td>
                    <Td maxW="20">
                      <Text whiteSpace="pre">Job posting </Text>
                    </Td>
                    <Td isNumeric>
                      <Text fontWeight="500"> {company?.bonosJobPosting} </Text>
                    </Td>
                  </MenuItem>
                </Tr>
                <Tr>
                  <MenuItem cursor="default" _hover={{ bg: 'transparent' }}>
                    <Td maxW="12">
                      <Token />{' '}
                    </Td>
                    <Td maxW="20">
                      <Text whiteSpace="pre">Contactos </Text>
                    </Td>
                    <Td>
                      <Text fontWeight="500"> {company?.bonosCandidates} </Text>
                    </Td>
                  </MenuItem>
                </Tr>

                {company?.bonosSlot ? (
                  <Tr>
                    <MenuItem cursor="default" _hover={{ bg: 'transparent' }}>
                      <Td maxW="12">
                        <BlueToken />{' '}
                      </Td>
                      <Td minW="20">
                        <Text whiteSpace="pre">Slots </Text>
                      </Td>
                      <Td isNumeric>
                        <Text fontWeight="500"> {company?.bonosSlot} </Text>
                      </Td>
                    </MenuItem>
                  </Tr>
                ) : null}
              </Table>

              <MenuDivider />

              <MenuItem onClick={logout}>Logout</MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Flex>
    </Box>
  );
};
