import { Text, Divider, IconButton, VStack, HStack } from '@chakra-ui/react';
import { FiLink } from 'react-icons/fi';
import { BsTwitter, BsLinkedin, BsFacebook, BsWhatsapp } from 'react-icons/bs';
import { createToast } from 'services/toast';

interface Props {
  id: string;
}
export const IconList = ({ id }: Props) => {
  return (
    <>
      <Text fontWeight="semibold" fontSize="14.57px" mt="3" mb="3">
        Social
      </Text>

      <HStack justify="space-evenly" mt="2">
        <VStack>
          <IconButton
            id="twitterShare-button"
            onClick={() => {
              window.location.href = `https://twitter.com/intent/tweet?text=Acabo de crear mi @flyncer map, que certifica mi nivel de conocimiento en mis tecnologías principales. Echadle un ojo!&url=https://app.flyncer.com/cv/${id}`;
            }}
            icon={<BsTwitter fontSize="20" color="white" />}
            colorScheme="twitter"
            aria-label="Twitter"
          />
          <Text fontSize="sm">Twitter</Text>
        </VStack>
        <VStack>
          <IconButton
            id="linkedinShare-button"
            colorScheme="linkedin"
            onClick={() => {
              window.location.href = `https://www.linkedin.com/sharing/share-offsite/?url=https://app.flyncer.com/cv/${id}`;
            }}
            icon={<BsLinkedin fontSize="20" color="white" />}
            aria-label="linkedin"
          />
          <Text fontSize="sm">Linkedin</Text>
        </VStack>
        <VStack>
          <IconButton
            id="facebookShare-button"
            icon={<BsFacebook fontSize="20" />}
            colorScheme="facebook"
            aria-label="facebook"
            onClick={() => {
              window.location.href = `https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fapp.flyncer.com%2Fcv%2F${id}&amp;src=sdkpreparse`;
            }}
          />
          <Text fontSize="sm">Facebook</Text>
        </VStack>
      </HStack>

      <Divider my="4" />

      <Text fontWeight="semibold" fontSize="14.57px" mt="3" mb="2">
        Otros
      </Text>
      <HStack justify="space-evenly" mt="2" alignItems="flex-start">
        <VStack mr="1">
          <IconButton
            id="linkShare-button"
            icon={<FiLink fontSize="20" color="white" />}
            bg="#787878"
            aria-label="link"
            onClick={() => {
              navigator.clipboard.writeText(`https://app.flyncer.com/cv/${id}`);

              createToast({
                title: `Enlace copiado a portapapeles`,
                status: 'info',
              });
            }}
          />
          <Text fontSize="sm">
            Copiar
            <br />
            enlace
          </Text>
        </VStack>
        <VStack>
          <IconButton
            id="whatsappShare-button"
            onClick={() => {
              window.location.href = `https://api.whatsapp.com/send?text=Te comparto mi flyncer map, un certificado de mi nivel de conocimiento en mis tecnologías principales. Échale un ojo! https://app.flyncer.com/cv/${id}`;
            }}
            colorScheme="whatsapp"
            icon={<BsWhatsapp fontSize="20" color="white" />}
            aria-label="whatsapp"
          />
          <Text fontSize="sm">Whatsapp</Text>
        </VStack>
      </HStack>
    </>
  );
};
