export const normalizeDate = (date: Date) => {
  let newDate = new Date(date);
  return (
    newDate.getDate() +
    '-' +
    (newDate.getMonth() + 1) +
    '-' +
    newDate.getFullYear()
  );
};

export const yearMonths = () => {
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  return { months };
};
