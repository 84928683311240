import { useMemo } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
} from '@chakra-ui/react';
import { LessonButtonModal } from './LessonButtonModal';
import { Section } from 'model/Course';
import { AccordionButtonSection } from './AccordionButtonSection';
import { QuizButtonSection } from './QuizButtonSection';
import { RepoButtonSection } from './RepoButtonSection';
import { Link as RouterLink, useParams } from 'react-router-dom';

interface Props extends AccordionProps {
  sections: Section[];
  repoUrl?: string | undefined;
  quizId?: string;
  lessonsDone: string[];
  quizApproved?: boolean;
  quizBlocked?: boolean;
}

export const Sections = ({
  sections,
  repoUrl,
  quizId,
  lessonsDone,
  quizApproved = false,
  quizBlocked = false,
}: Props) => {
  const { id } = useParams<{ id: string }>();

  const userSections = useMemo(
    () =>
      sections.map((section) => ({
        ...section,
        lessons: section.lessons.map((lesson) => ({
          ...lesson,
          isDone: !!lessonsDone.find((lessonId) => lessonId === lesson.id),
        })),
      })),
    [sections, lessonsDone]
  );

  return (
    <Accordion allowToggle>
      {userSections.map((section, i) => (
        <AccordionItem
          borderX="1px"
          borderLeftColor="gray.200"
          borderRightColor="gray.200"
          key={section.id}
          borderTopRadius={i === 0 ? 'md' : '0'}
        >
          <AccordionButtonSection
            name={section.name}
            durationMinutes={section.durationMinutes}
          />
          <AccordionPanel
            bg="gray.10"
            p="0"
            as={RouterLink}
            to={`/course/video/${id}`}
          >
            {section.lessons.map((lesson) => (
              <LessonButtonModal
                key={lesson.id}
                lesson={lesson}
                isDone={lesson.isDone}
              />
            ))}
          </AccordionPanel>
        </AccordionItem>
      ))}
      {repoUrl && <RepoButtonSection repoUrl={repoUrl} />}
      {quizId && (
        <QuizButtonSection
          quizId={quizId}
          quizApproved={quizApproved}
          quizBlocked={quizBlocked}
        />
      )}
    </Accordion>
  );
};
