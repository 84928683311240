import { MeCV_meCV } from 'graphql/generated/MeCV';
import { normalizeMeExperience, Experience } from 'model/userCVExperience';
import { WithoutNulls } from 'utils/removeNulls';

export const normalizeCV = (input: WithoutNulls<MeCV_meCV>) => ({
  id: input.id,
  experiences: (input.experiences || []).reduce<Experience[]>(
    (acum, input) =>
      !!input ? acum.concat(normalizeMeExperience(input)) : acum,
    []
  ),
});

export type meCV = ReturnType<typeof normalizeCV>;
