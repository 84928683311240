import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { JOBS_MATCH_USER } from 'graphql/queries/job';
import { jobsMatchUser } from 'graphql/generated/jobsMatchUser';

export const useJobMatchUsers = () => {
  const { data, loading } = useQuery<jobsMatchUser>(JOBS_MATCH_USER);

  const jobsMatchUserUser = useMemo(() => {
    return data?.jobsMatchUser;
  }, [data]);

  return {
    jobsMatchUserUser,
    loading,
  };
};
