import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_PROFILE } from 'graphql/mutations/updateProfile';
import { FlyncerError } from 'graphql/helpers';
import { UpdateUser, UpdateUserVariables } from 'graphql/generated/UpdateUser';
import { createToast } from 'services/toast';
import { FINISH_TOUR } from 'graphql/mutations/finishTour';
import { finishTour } from 'graphql/generated/finishTour';
import { ME } from 'graphql/queries/me';
import * as Sentry from '@sentry/react';
import useMe from './useMe';
import { JOBS_MATCH_USER } from 'graphql/queries/job';
import useCountries from 'graphql/hooks/profile/useCountries';

const useUserActions = () => {
  const { me } = useMe();
  const { countries } = useCountries();

  const [updateProfileMutation, { loading: updateProfileLoading }] =
    useMutation<UpdateUser, UpdateUserVariables>(UPDATE_PROFILE, {
      refetchQueries: [{ query: JOBS_MATCH_USER }],
    });

  const [finishTourMutation] = useMutation<finishTour>(FINISH_TOUR, {
    refetchQueries: [{ query: ME }],
  });

  const finishTour = useCallback(async () => {
    await finishTourMutation();
  }, [finishTourMutation]);

  const updateProfile = useCallback(
    async (data: UpdateUserVariables) => {
      try {
        if (me !== undefined && !me.continent && !me.countryResidence) {
          fetch(
            `https://api.ipregistry.co/?key=${process.env.REACT_APP_IPREGISTRY_KEY}`
          )
            .then((response) => {
              return response.json();
            })
            .then((payload) => {
              let userCountry;

              if (payload.location.country.name === 'Spain') {
                userCountry = countries.find(
                  (country) => country.name === 'España'
                );
              } else if (
                countries.some(
                  (country) => country.name === payload.location.country.name
                )
              ) {
                userCountry = countries.find(
                  (country) => country.name === payload.location.country.name
                );
              } else {
                userCountry = countries.find(
                  (country) => country.name === 'Otro'
                );
              }

              if (data.input.data) {
                data.input.data.countryResidence = userCountry?.id;
                data.input.data.continent = payload.location.continent.name;
              }
            })
            .finally(async () => {
              await updateProfileMutation({ variables: data });
            });
        } else {
          await updateProfileMutation({ variables: data });
        }

        if (!me?.onboarding && !data.input.data?.lastActive) {
          createToast({
            title: 'Actualizar perfil',
            description: 'Perfil actualizado correctamente.',
            status: 'success',
          });
        }
      } catch (error: any) {
        if (!data.input.data?.lastActive) {
          createToast({
            title: 'Actualizar perfil',
            description: 'Algo falló intentando actualizar el perfil.',
            status: 'error',
          });
        }
        Sentry.captureException(new Error('No se pudo actualizar el perfil'));
        throw new FlyncerError('No se pudo actualizar el perfil', error);
      }
    },
    [updateProfileMutation, me, countries]
  );

  const updateOnboarding = useCallback(
    async (data: UpdateUserVariables) => {
      try {
        await updateProfileMutation({ variables: data });
      } catch (error: any) {
        Sentry.captureException(
          new Error('No se pudo actualizar el onboarding')
        );
        console.log(error);
      }
    },
    [updateProfileMutation]
  );

  return {
    isLoading: updateProfileLoading,
    updateProfile,
    updateOnboarding,
    finishTour,
  };
};

export default useUserActions;
