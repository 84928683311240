import { Box, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { User } from 'model/User';
// import { normalizeExperience } from 'utils/normalizeExperience';

interface Props {
  user: User;
}

export const Skills = ({ user }: Props) => {
  return (
    <Flex
      bg="white"
      p={{ base: '8', lg: '8' }}
      borderRadius="md"
      direction={{ base: 'column' }}
    >
      <HStack align="start">
        <Stack spacing="8" width="50%">
          <Text as="h4" mb="-5" textStyle="h4" color="gray.600">
            Tecnologías
          </Text>
          {user.careers
            .filter(
              (career) =>
                career.name !== 'Management' && career.name !== 'Empleabilidad'
            )
            .map((career) => (
              <Box key={career.id}>
                <Text
                  as="h5"
                  fontWeight="500"
                  mb="-3"
                  color="#151925"
                  fontSize="18px"
                >
                  {career?.name}
                </Text>
                {/* REVISAR EXP.YEARS CV PERFIL */}
                {/* <Text fontSize="lg" mb="3">
                {career.name} - {normalizeExperience(career.experienceYears)}
                </Text> */}
                <br />
                <Stack spacing="4" align="start">
                  {career.paths.map((career) => (
                    <Text
                      key={career.id}
                      fontSize="sm"
                      paddingX="2"
                      borderRadius="md"
                    >
                      {career.name} -{' '}
                      {career.level ? career.level : 'No evaluada'}
                    </Text>
                  ))}
                </Stack>
              </Box>
            ))}
        </Stack>
        <Stack spacing="8" width="50%">
          {user.studies.length > 0 && (
            <Stack spacing="2">
              <Text as="h4" textStyle="h4" color="gray.600">
                Formación
              </Text>
              {user.studies.map((study) => (
                <Text textStyle="body" key={study.id}>
                  {study.name}
                </Text>
              ))}
            </Stack>
          )}

          {user.englishLevel && (
            <Stack spacing="2">
              <Text as="h4" textStyle="h4" color="gray.600">
                Idiomas
              </Text>
              <Text textStyle="body">Inglés - {user.englishLevel}</Text>
            </Stack>
          )}
        </Stack>
      </HStack>
    </Flex>
  );
};
