export const sanitizeCancelReason = (cancelReason: any) => {
  let cancelMessage;
  switch (cancelReason) {
    case 'location':
      cancelMessage = 'Localización';
      break;
    case 'company':
      cancelMessage = 'Compañía';
      break;
    case 'role':
      cancelMessage = 'Rol';
      break;
    case 'tech':
      cancelMessage = 'Tecnología/s';
      break;
    case 'salary':
      cancelMessage = 'Salario';
      break;
    case 'other':
      cancelMessage = 'Otro';
      break;
  }

  return cancelMessage;
};
