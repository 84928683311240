import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalProps,
  Button,
  Text,
  Grid,
  Center,
  Heading,
} from '@chakra-ui/react';

//icons
import { PartyPopper } from './party-popper';

interface Props extends Omit<ModalProps, 'children'> {
  name?: String;
}

export const ModalCompletedProfile = ({ isOpen, onClose }: Props) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalBody mt={6}>
            <Grid
              templateColumns="repeat(1, 1fr)"
              gap={2}
              alignItems="center"
              textAlign="center"
            >
              <Center>
                <PartyPopper w={20} h={20} />
              </Center>

              <Heading>¡Enhorabuena!</Heading>
              <Text mt={4}>Has completado tu perfil</Text>

              <Text>Ya puedes acceder a las oportunidades de trabajo</Text>
            </Grid>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} colorScheme="brand">
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
