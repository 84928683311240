import { useCallback, useRef } from 'react';
import {
  useDisclosure,
  MenuItem,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { FiTrash2 } from 'react-icons/fi';
import useUserCareerActions from 'graphql/hooks/career/useUserCareerActions';

interface Props {
  id: string;
}

export const DeleteMenuItem = ({ id }: Props) => {
  const { remove } = useUserCareerActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  const handleDelete = useCallback(async () => {
    try {
      await remove({ where: { id } });
      onClose();
    } catch {}
  }, [id, remove, onClose]);

  return (
    <>
      <MenuItem icon={<FiTrash2 />} onClick={onOpen}>
        Eliminar
      </MenuItem>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Borrar carrera
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Estás seguro que quieres borrar esta carrera? Si lo haces
              perderás el progreso, los cambios son irreversibles.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Eliminar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
