import { gql } from '@apollo/client';

export const MASS_MEDIAS = gql`
  query massMedias {
    massMedias {
      id
      name
    }
  }
`;
