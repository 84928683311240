import { useCallback } from 'react';
import { FormikHelpers } from 'formik';
import * as Yup from 'yup';

import useAuth from 'graphql/hooks/auth/useAuth';
import { FormValues } from './types';
import { appConfig } from 'config';

const initialValues = { identifier: '', password: '' };

const schema = Yup.object().shape({
  identifier: Yup.string()
    .email('Introduce un email válido')
    .required('Este campo es requerido'),
  password: Yup.string().required('Este campo es requerido'),
});

const useConnect = () => {
  const { login } = useAuth();

  const handleSubmit = useCallback(
    async (
      values: FormValues,
      { setSubmitting, setFieldError }: FormikHelpers<FormValues>
    ) => {
      setSubmitting(true);
      try {
        await login({ input: values });
      } catch (error: any) {
        if (error.fields?.id === 'Auth.form.error.invalid') {
          setFieldError('password', 'No se pudo acceder con esas credenciales');
        } else if (error.fields?.message === 'Password attempts exceeded') {
          setFieldError('password', 'Ha superado el número de intentos');
        } else if (error.fields?.id === 'Auth.form.error.confirmed') {
          setFieldError('password', 'Aún no has validado el email');
        } else {
          setFieldError('password', error.message);
        }
        setSubmitting(false);
      }
    },
    [login]
  );

  const handleGithubProvider = () => {
    window.location.href = `${appConfig.apiUrl}/connect/github`;
  };

  const handleLinkedinProvider = () => {
    window.location.href = `${appConfig.apiUrl}/connect/linkedin`;
  };

  return {
    handleSubmit,
    initialValues,
    schema,
    handleGithubProvider,
    handleLinkedinProvider,
  };
};

export default useConnect;
