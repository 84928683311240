import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { GET_NOTIFICATIONS } from 'graphql/queries/notifications';

const useNotifications = () => {
  const { data, loading, error } = useQuery(GET_NOTIFICATIONS);

  const notifications = useMemo(() => {
    return data?.getNotifications;
  }, [data]);

  return {
    error,
    notifications,
    loading,
  };
};

export default useNotifications;
