import { gql } from '@apollo/client';

export const All_SUB_PROFESSIONS = gql`
  query allSubProfessions($professionId: ID!) {
    allSubProfessions(professionId: $professionId) {
      id
      Name
    }
  }
`;
