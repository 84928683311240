import { Box, Button, Heading, Text, useMediaQuery } from '@chakra-ui/react';
import CandidateCard from 'containers/Admin/Candidate/CandidateCard';
import useMe from 'graphql/hooks/profile/useMe';
import { Link as RouterLink } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';

function PublicProfile() {
  const { me } = useMe();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  if (!me) return null;

  let allLocations: any[] = me.desiredCities;

  const remoto = me.desiredCountries.find(
    (country) => country?.name === 'Remoto'
  );

  if (remoto !== undefined) {
    allLocations = [...allLocations, remoto];
  }

  return (
    <Box
      as="main"
      mx="auto"
      w="100%"
      maxW="container.md"
      py="8"
      px={{ base: '8' }}
    >
      <Button
        as={RouterLink}
        to="/profile"
        variant="link"
        leftIcon={<FiChevronLeft />}
      >
        Volver a tu perfil
      </Button>
      <Button
        as={RouterLink}
        to={`/privateProfile`}
        borderRadius="20"
        variant="outline"
        float={isMobile ? 'none' : 'right'}
        mt={isMobile ? '2' : '-2'}
        mr={isMobile ? '100' : ''}
      >
        Ver perfil privado
      </Button>
      <Heading mt="12">Perfil Público</Heading>
      <Text mt="4">
        <Text as="span" fontWeight="500">
          Así de anónimo te ven cuando te quieren encontrar.
        </Text>{' '}
        Te recomendamos que lo completes al máximo, ya que será lo que vean las
        empresas para decidir si quieren contactarte.
      </Text>

      <Box pt="2" pl="3" bg="white" mt="12" mr="-8" ml="-8">
        <CandidateCard
          isInscribed={false}
          skills={[]}
          user={me}
          jobId=""
          locations={allLocations}
          type="PublicProfile"
        />
      </Box>
    </Box>
  );
}

export default PublicProfile;
