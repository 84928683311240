import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

export const Confirmation = () => {
  return (
    <Alert
      status="success"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        ¡Gracias por registrarte en Flyncer!
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        Antes de continuar necesitamos validar tu cuenta. En breves recibirás un
        email de parte de nuestro equipo comercial.
      </AlertDescription>
    </Alert>
  );
};
