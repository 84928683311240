import {
  Button,
  Container,
  Grid,
  GridItem,
  Heading,
  Text,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { PublishedOfferIllustration } from '../../shared/publishedOfferillustration';

interface Props {
  id: string | undefined | null;
}

export const OfferPublished = ({ id }: Props) => {
  return (
    <>
      <Grid templateColumns="repeat (2, 1fr)" gap={6}>
        <GridItem>
          <Container
            maxW="container.xl"
            mx="auto"
            mt="5"
            w="100%"
            px={{ base: '4', md: '6' }}
            py={{ base: '8', md: '10' }}
            textAlign="center"
          >
            <Heading m="5">¡Oferta publicada!</Heading>
            <Text mt="5">
              Tu oferta ha sido publicada, Puedes esperar a que se inscriban los
              candidatos o buscar los candidatos óptimos para el puesto en
              nuestra base de datos
            </Text>
            <Text mt="-4" mb="-4">
              <PublishedOfferIllustration w="450px" h="300px" />
            </Text>
            <Button
              as={NavLink}
              exact
              to={`/company/job/${id}`}
              colorScheme="brand"
              m="5"
            >
              Encontrar candidatos
            </Button>
          </Container>
        </GridItem>
      </Grid>
    </>
  );
};
