import {
  Flex,
  FlexProps,
  HStack,
  Icon,
  Box,
  Text,
  Button,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Sections } from './Sections';
import { FiClock, FiCheckCircle, FiChevronLeft } from 'react-icons/fi';

import { useConnect } from './connect';
import { normalizeDuration } from 'utils/normalizeDuration';
import { NotFound } from './NotFound';
import LogoOW from 'components/LogoOW';
import * as Sentry from '@sentry/react';
import { OfferTags } from 'components/OfferTags';

interface Props extends FlexProps {}

export const Course = (props: Props) => {
  const { course, userCourse, loading, error, fullCareerError } = useConnect();

  if (error) {
    Sentry.captureException(error?.graphQLErrors[0]);
    return <NotFound />;
  }

  if (fullCareerError) {
    Sentry.captureException(fullCareerError?.graphQLErrors[0]);
  }

  if ((loading && !course) || !course) {
    return <div></div>;
  }

  return (
    <Box
      as="main"
      mx="auto"
      w="100%"
      maxW="50rem"
      p={{ base: '4', md: '6' }}
      pt="16"
      pb="12"
    >
      <Button
        as={RouterLink}
        to="/career"
        variant="link"
        mb="6"
        leftIcon={<FiChevronLeft />}
      >
        Volver a mi carrera
      </Button>
      <Flex
        bg="white"
        p={{ base: '4', lg: '6' }}
        borderRadius="md"
        direction={{ base: 'column' }}
        {...props}
      >
        <Text as="h4" textStyle="h4" mb="4" color="gray.600">
          {course.name}
        </Text>
        <Text textStyle="body" mb="4" color="gray.500">
          {course.description}
        </Text>
        {course.teacherName && (
          <Text textStyle="body" color="gray.600">
            Impartido por
            <Text as="strong">{' ' + course.teacherName}</Text>
          </Text>
        )}
        <HStack spacing="6" mb="10" mt="6">
          <HStack spacing="2">
            <Icon color="gray.300" as={FiClock} />
            <Text textStyle="body" color="gray.600">
              {normalizeDuration(course.durationMinutes)}
            </Text>
          </HStack>
          <OfferTags
            tags={course.skills.map((skill) => skill.name)}
            mt="5"
            spacing="3"
          />
        </HStack>
        <Flex
          mb={{ base: '6', md: '8' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Text as="h5" textStyle="h5">
            Temario del curso
          </Text>
          <HStack spacing="3">
            <Icon color="blue.400" as={FiCheckCircle} w={5} h={5} />
            <Text textStyle="body" color="gray.600">
              {(userCourse?.progress || 0) + '% Completado'}
            </Text>
          </HStack>
        </Flex>
        <Sections
          quizId={course.quiz?.id}
          repoUrl={course.repoUrl}
          sections={course.sections}
          lessonsDone={userCourse?.lessonsDone || []}
          quizApproved={userCourse?.quizApproved}
          quizBlocked={!userCourse}
        />
      </Flex>

      <Text textAlign="center" mt="20">
        Powered by
      </Text>
      <LogoOW />
    </Box>
  );
};
