import { gql } from '@apollo/client';

export const CREATE_REPORT = gql`
  mutation AddReport(
    $message: String!
    $reason: ENUM_REPORTS_REASON!
    $optionIds: [ID]
    $courseId: ID
    $courseAssessment: Int
  ) {
    sendReport(
      message: $message
      reason: $reason
      optionIds: $optionIds
      courseId: $courseId
      courseAssessment: $courseAssessment
    )
  }
`;
