import { useCallback } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { INITALIZE_LEVEL, SAVE_PATHS } from 'graphql/mutations/userPath';
import { FlyncerError } from 'graphql/helpers';

import {
  InitialiceLevel,
  InitialiceLevelVariables,
} from 'graphql/generated/InitialiceLevel';
import { InitialicePathLevelInput } from 'graphql/generated/globalTypes';
import { FullCareer } from 'graphql/generated/FullCareer';
import { FULL_CAREER } from 'graphql/queries/fullCareer';
import { ME } from 'graphql/queries/me';
import * as Sentry from '@sentry/react';
import { GET_USER_PATHS, USER_LEVEL_FROM_PATH } from 'graphql/queries/userPath';
import {
  saveHexagonPaths,
  saveHexagonPathsVariables,
} from 'graphql/generated/saveHexagonPaths';
import useMe from 'graphql/hooks/profile/useMe';

const useUserPathActions = () => {
  const client = useApolloClient();
  const { me } = useMe();

  const [initialiceLevelMutation, { loading: loadingInitalice }] = useMutation<
    InitialiceLevel,
    InitialiceLevelVariables
  >(INITALIZE_LEVEL, {
    refetchQueries: [
      { query: USER_LEVEL_FROM_PATH },
      { query: GET_USER_PATHS, variables: { idUser: me?.id } },
      { query: USER_LEVEL_FROM_PATH },
      { query: ME },
    ],
  });
  const initialiceLevel = useCallback(
    async (input: InitialicePathLevelInput) => {
      try {
        const response = await initialiceLevelMutation({
          variables: { input },
        });
        const fullCareer = response.data?.initialiceMePathLevel;

        if (fullCareer) {
          client.cache.writeQuery<FullCareer>({
            data: {
              fullCareer,
            },
            query: FULL_CAREER,
          });
        }
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo actualizar el nivel'));
        throw new FlyncerError('No se pudo actualizar', error);
      }
    },
    [initialiceLevelMutation, client]
  );

  const [savePathsMutation, { loading: saveLoading }] = useMutation<
    saveHexagonPaths,
    saveHexagonPathsVariables
  >(SAVE_PATHS);

  const savePaths = useCallback(
    async ({ idUser, pathId }) => {
      try {
        await savePathsMutation({
          variables: { pathId: pathId, idUser: idUser },
        });
      } catch (error: any) {
        throw new FlyncerError('No se pudo guardar', error);
      }
    },
    [savePathsMutation]
  );

  return {
    isLoading: loadingInitalice,
    initialiceLevel,
    savePaths,
    saveLoading,
  };
};

export default useUserPathActions;
