import { gql } from '@apollo/client';
import { EXPERIENCE_FRAGMENT, EXPERIENCE_FRAGMENT2 } from './userCVExperience';

export const CV_FRAGMENT = gql`
  fragment MeCVParts on meCV {
    id
    experiences {
      ...ExperienceParts
    }
  }
  ${EXPERIENCE_FRAGMENT}
`;

export const CV_FRAGMENT2 = gql`
  fragment MeCVParts2 on UserCv {
    id
    experiences {
      ...ExperienceParts2
    }
  }
  ${EXPERIENCE_FRAGMENT2}
`;
