import { HStack, StackProps, Text, Icon } from '@chakra-ui/react';
import { FiCheckCircle } from 'react-icons/fi';

interface Props extends StackProps {
  percent: number;
}

export const Percent = ({ percent }: Props) => {
  return (
    <HStack mb="7">
      <Icon as={FiCheckCircle} color="gray.300" />
      <Text textStyle="body" color="gray.500">
        {percent}% acertadas
      </Text>
    </HStack>
  );
};
