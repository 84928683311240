import { Box, Divider, Flex, FlexProps } from '@chakra-ui/react';

export const DividerWithText = ({ children, ...props }: FlexProps) => (
  <Flex align="center" color="gray.200" {...props}>
    <Box flex="1">
      <Divider borderColor="currentcolor" />
    </Box>
    <Box as="span" px="3" textStyle="Body" color="gray.500">
      {children}
    </Box>
    <Box flex="1">
      <Divider borderColor="currentcolor" />
    </Box>
  </Flex>
);
