export const CustomStyles = {
  menuPortal: (styles: any) => ({
    ...styles,
    zIndex: 9999,
  }),

  multiValue: (styles: any) => ({
    ...styles,
    backgroundColor: '#C8FACC',
  }),

  multiValueLabel: (styles: any) => ({
    ...styles,
    color: '#00624B',
  }),

  multiValueRemove: (styles: any) => ({
    ...styles,
    color: '#00624B',
    backgroundColor: '#C8FACC',
  }),
};
