import { Image, ImageProps } from '@chakra-ui/react';

interface Props extends ImageProps {
  companyZone?: boolean;
}

export const Avatar = ({ companyZone, ...props }: Props) => {
  return (
    <Image
      rounded="md"
      bg="white"
      objectFit="cover"
      border={!companyZone ? '1px' : ''}
      borderColor="gray.200"
      {...props}
    />
  );
};
