import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { User, UserVariables } from 'graphql/generated/User';
import { oneUser, oneUserVariables } from 'graphql/generated/oneUser';
import { hasPhoneNumber, hasPhoneNumberVariables } from 'graphql/generated/hasPhoneNumber';
import { USER, ONE_USER, HAS_PHONE_NUMBER } from 'graphql/queries/user';
import { removeNulls } from 'utils/removeNulls';
import { normalizeUser } from 'model/User';

const useUser = ({ id }: UserVariables) => {
  const { data, loading, error } = useQuery<User, UserVariables>(USER, {
    variables: { id },
  });
  const user = useMemo(() => {
    return data?.user ? normalizeUser(removeNulls(data.user)) : undefined;
  }, [data]);

  return {
    error,
    user,
    loading,
  };
};

export const useOneUser = ({ uuid }: oneUserVariables) => {
  const { data, loading, error } = useQuery<oneUser, oneUserVariables>(
    ONE_USER,
    {
      variables: { uuid },
    }
  );

  const user = useMemo(() => {
    return data?.oneUser ? normalizeUser(removeNulls(data.oneUser)) : undefined;
  }, [data]);

  return {
    error,
    user,
    loading,
  };
};

export const useHasPhoneNumber = ({ id }: hasPhoneNumberVariables) => {
  const { data, loading, error } = useQuery<hasPhoneNumber, hasPhoneNumberVariables>(
    HAS_PHONE_NUMBER,
    {
      variables: { id },
    }
  );

  const hasPhoneNumber = useMemo(() => {
    return data?.hasPhoneNumber ? true : false
  }, [data]);

  return {
    error,
    hasPhoneNumber,
    loading,
  };
};

export default useUser;
