import { Box, Button, Icon, Text, VStack } from '@chakra-ui/react';
import { Hexagon } from 'containers/Admin/Candidate/PersonalInfo/Hexagon/index';
import { IoRocketOutline } from 'react-icons/io5';

interface Props {
  handleClick: (test: boolean) => void;
}

export const TestContainer = ({ handleClick }: Props) => {
  return (
    <Box textAlign="center" color="gray.500" p="5">
      <VStack mb="4">
        <Icon as={IoRocketOutline} color="brand.500" w={12} h={12} />
        <Text fontSize="20" fontWeight="bold">
          ¿Te sientes listo para otro test?
        </Text>

        <Text>Completa tu Flyncer Map</Text>
      </VStack>

      <Hexagon
        cv="Candidate"
        type="Modal"
        paths={[]}
        pathsHexagon={[]}
        pathsId={[]}
      />

      <Button colorScheme="brand" mt="12" onClick={() => handleClick(true)}>
        Hacer otro test
      </Button>
    </Box>
  );
};
