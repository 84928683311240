import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { allSubProfessions } from 'graphql/generated/allSubProfessions';
import { All_SUB_PROFESSIONS } from 'graphql/queries/allSubProfessions';

const useSubProfessions = (id: string) => {
  const { data, loading, error } = useQuery<allSubProfessions>(
    All_SUB_PROFESSIONS,
    {
      variables: { professionId: id },
    }
  );

  const subProfessions = useMemo(() => {
    return data;
  }, [data]);

  return {
    error,
    subProfessions,
    loading,
  };
};

export default useSubProfessions;
