import { HStack, Text, StackProps } from '@chakra-ui/react';
import { AiOutlineStar } from 'react-icons/ai';

export const PerfectMatch = ({ children, ...props }: StackProps) => {
  return (
    <HStack {...props}>
      <AiOutlineStar fontSize="18px" />
      <Text>{children}</Text>
    </HStack>
  );
};
