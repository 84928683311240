import { Flex, FlexProps, Text, Box, Button } from '@chakra-ui/react';
import { FiArrowRight } from 'react-icons/fi';
import { AddCareerModal } from '../AddCareerModal';

interface Props extends FlexProps {
  title: string;
  subtitle: string;
  firstCareer?: boolean;
  userCareersLength?: number;
  setisSend?: (isSend: boolean) => void;
  onTour?: boolean;
  isOpen: boolean;
  isOpen2: boolean;
  setaddPaths: (add: boolean) => void;
  onOpen: (isSend: boolean) => void;
  onClose: () => void;
  stepIndex?: number;
  forceRender?: (isForce: boolean) => void;
}

export const ExtendCard = ({
  title,
  subtitle,
  onOpen,
  firstCareer = false,
  userCareersLength,
  isOpen,
  setisSend,
  onTour,
  stepIndex,
  onClose,
  forceRender,
  isOpen2,
  setaddPaths,
  ...props
}: Props) => {
  return (
    <Flex
      bg="white"
      px={{ base: '10', lg: '20' }}
      py={{ base: '10' }}
      shadow="base"
      borderRadius="md"
      direction={{ base: 'column', lg: 'row' }}
      justifyContent="space-between"
      alignItems={{ base: 'flex-start', lg: 'center' }}
      {...props}
      key={userCareersLength}
    >
      <Box>
        <Text as="h3" textStyle={{ base: 'h4', md: 'h3' }}>
          {title}
        </Text>
        <Text textStyle="body" color="gray.500" mt="4" maxW="lg">
          {subtitle}
        </Text>
      </Box>
      <AddCareerModal
        forceRender={forceRender}
        isOpen={isOpen}
        isOpen2={isOpen2}
        onClose={onClose}
        setisSend={setisSend}
        onTour={onTour}
        stepIndex={stepIndex}
        subtitle={firstCareer && true}
        title={
          localStorage.getItem('button') === 'training'
            ? '¿Quieres formarte en otras tecnologías?'
            : localStorage.getItem('button') === 'test'
            ? 'Escoge las tecnologías que dominas'
            : firstCareer
            ? '¿Con qué área sueles trabajar?'
            : 'Añadir otras tecnologías'
        }
      />
      <Button
        className="addPaths"
        colorScheme="brand"
        rightIcon={<FiArrowRight />}
        mt={{ base: '6', lg: '0' }}
        ml={{ base: '0', lg: '6' }}
        onClick={() => {
          onOpen(true);
          if (!localStorage.getItem('button')) setaddPaths(true);
        }}
      >
        Añadir tecnologías
      </Button>
    </Flex>
  );
};
