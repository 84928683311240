import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import client from 'graphql/client';
import { theme } from 'styles/theme';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { App } from 'containers/App';

const isLocal = process.env.REACT_APP_API_URL?.includes('localhost');

if (!isLocal) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: 'production',
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', `${process.env.REACT_APP_API_URL}`],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
  });
}

export const Root = () => (
  <ApolloProvider client={client}>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ChakraProvider>
  </ApolloProvider>
);
