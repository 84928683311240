import { chakra } from '@chakra-ui/react';

export const BlueToken = () => {
  return (
    <chakra.svg ml="2" width="25px" fill="none" viewBox="0 0 38 38">
      <path
        d="M38 18.9998C38 29.4931 29.4935 37.9996 19.0002 37.9996C19.0006 25.0797 19 29.4931 19 18.9998C19 8.50649 19.0002 18.9998 19.0002 0C29.4935 0 38 8.50649 38 18.9998Z"
        fill="#1CBBFF"
      />
      <path
        d="M3.6012e-07 18.9998C3.6012e-07 29.4931 8.50649 37.9996 18.9998 37.9996C18.9994 25.0797 19 29.4931 19 18.9998C19 8.50649 18.9998 18.9998 18.9998 0C8.50649 0 3.6012e-07 8.50649 3.6012e-07 18.9998Z"
        fill="#81D8FF"
      />
      <circle
        cx="18.9998"
        cy="18.9998"
        r="18.2778"
        stroke="#00A0F5"
        stroke-width="1.44398"
      />
      <path
        d="M28.8794 22.8927C28.8794 23.072 28.8237 23.2213 28.7122 23.3409C28.5785 23.4404 28.4113 23.4902 28.2107 23.4902L25.6697 23.4902C25.4022 23.4902 25.1905 23.4305 25.0344 23.311C24.8561 23.1915 24.6667 23.0022 24.4661 22.7433L19.0162 17.0032L13.5664 22.7433C13.3658 23.0022 13.1875 23.1915 13.0315 23.311C12.8531 23.4305 12.6191 23.4902 12.3293 23.4902L9.82175 23.4902C9.59886 23.4902 9.43168 23.4305 9.32024 23.311C9.1865 23.1915 9.11963 23.052 9.11963 22.8927C9.11963 22.7334 9.21993 22.5242 9.42054 22.2653L17.1105 13.7168C17.3557 13.3981 17.5897 13.179 17.8126 13.0595C18.0355 12.9201 18.303 12.8504 18.615 12.8504L19.4174 12.8504C19.7295 12.8504 19.997 12.9201 20.2199 13.0595C20.4205 13.179 20.6434 13.3981 20.8886 13.7168L28.6119 22.2653C28.7902 22.5043 28.8794 22.7134 28.8794 22.8927Z"
        fill="#007CCC"
      />
    </chakra.svg>
  );
};
