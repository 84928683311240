import { Text, HStack, Center, Flex, Icon, StackProps } from '@chakra-ui/react';

interface Props extends StackProps {
  icon: any;
  title: string;
  subtitle: string;
}

export const Header = ({ icon, title, subtitle }: Props) => {
  return (
    <HStack spacing="6" mb="6">
      <Center p="8" rounded="lg" border="1px" borderColor="gray.200">
        <Icon as={icon} w={6} h={6} color="blue.400" />
      </Center>
      <Flex direction="column">
        <Text textStyle="body">{title}</Text>
        <Text as="h3" textStyle="h3">
          {subtitle}
        </Text>
      </Flex>
    </HStack>
  );
};
