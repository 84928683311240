import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { SEND_PIXEL } from 'graphql/mutations/userPixel';
import { SendPixel, SendPixelVariables } from 'graphql/generated/SendPixel';
import * as Sentry from '@sentry/react';
const publicIp = require('public-ip');

const usePixel = () => {
  const [sendEventMutation, { loading }] = useMutation<
    SendPixel,
    SendPixelVariables
  >(SEND_PIXEL);

  const sendEvent = useCallback(
    async (data: SendPixelVariables) => {
      try {
        const userAgent = window.navigator.userAgent;
        const ip = await publicIp.v4();
        await sendEventMutation({
          variables: {
            ip: ip,
            userAgent: userAgent,
            event: data.event,
            eventID: data.eventID,
          },
        });
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo enviar evento'));
        console.log(error);
      }
    },
    [sendEventMutation]
  );

  return {
    loading,
    sendEvent,
  };
};

export default usePixel;
