import { gql } from '@apollo/client';
import { ME_FRAGMENT } from 'graphql/fragments/me';

export const IS_AUTH = gql`
  query IsAuth {
    me {
      ...MeParts
    }
  }
  ${ME_FRAGMENT}
`;
