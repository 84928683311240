import { useJob } from 'graphql/hooks/admin/useJob';
import { useJobApplicationActions } from 'graphql/hooks/admin/useJobApplicationActions';
import { useNewsActions } from 'graphql/hooks/admin/useNewsActions';

export const useConnect = (
  user: any,
  jobId: string,
  company: any,
  isNew: boolean | undefined,
  enter: boolean,
  jobApplicationId: string | undefined,
  newInscribed: any[]
) => {
  const { quitNews } = useNewsActions();
  const { job, refetch, error } = useJob({ jobId });
  const { editDiscarded } = useJobApplicationActions();
  let paths2: any[] = [];
  let lastUpdate;

  const handleClick = async () => {
    let idUser: any = user.id,
      jobPositionId: any = jobId,
      type = 'Inscribed';

    if (!company.superAdmin && type === 'Inscribed' && isNew && !enter) {
      await quitNews({ type, idUser, jobPositionId });
      refetch();
    }
  };

  user.lastActive !== undefined
    ? (lastUpdate = new Date(user.lastActive))
    : (lastUpdate = new Date(user.updated_at));

  const editIsDiscarded = async (type?: string) => {
    if (jobApplicationId) await editDiscarded({ jobApplicationId, type });
    refetch();
  };

  user.careers?.forEach((career: any) => {
    career.activePaths.forEach((path: any) => {
      paths2.push({
        level: path.level,
        path: path.path,
        preLevel: path.preLevel,
      });
    });
  });

  const position = newInscribed
    ?.map((inscribed: any) => {
      return inscribed.user.id;
    })
    .indexOf(user.id);

  return {
    job,
    handleClick,
    lastUpdate,
    error,
    editIsDiscarded,
    refetch,
    paths2,
    position,
  };
};
