import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import {
  QuizForPath,
  QuizForPathVariables,
} from 'graphql/generated/QuizForPath';
import { QUIZ_FOR_PATH } from 'graphql/queries/quiz';
import { removeNulls } from 'utils/removeNulls';
import { normalizeQuiz } from 'model/Quiz';

const useQuizForPath = ({ userPathId }: QuizForPathVariables) => {
  const { data, loading, error } = useQuery<QuizForPath, QuizForPathVariables>(
    QUIZ_FOR_PATH,
    {
      variables: { userPathId },
      fetchPolicy: 'no-cache',
    }
  );

  const quiz = useMemo(
    () =>
      data && data.quizForPath
        ? normalizeQuiz(removeNulls(data.quizForPath))
        : undefined,
    [data]
  );

  return {
    error,
    quiz,
    loading,
  };
};

export default useQuizForPath;
