import { PartyPopper } from 'components/ModalCompletedProfile/party-popper';
import {
  Text,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

interface Props {
  isOpen2: boolean;
  setisOpen2: (isOpen: boolean) => void;
  finishTour: () => void;
}

export const CompleteOnboardingModal = ({
  isOpen2,
  setisOpen2,
  finishTour,
}: Props) => {
  return (
    <Modal
      isOpen={isOpen2}
      onClose={() => {
        setisOpen2(false);
        finishTour();
      }}
    >
      <ModalOverlay />
      <ModalContent display="block" minWidth="34%">
        <ModalHeader textAlign="center">
          <PartyPopper w="60px" h="60px" mb="4" mt="4" />
          <br />
          <Text as="h3" fontSize="22px" fontWeight="bold" color="grey.100">
            ¡Felicidades! <br /> Has completado el onboarding
          </Text>
        </ModalHeader>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};
