import { createIcon } from '@chakra-ui/icons';

export const NewSlotIllustration = createIcon({
  displayName: 'Illustration',
  viewBox: '0 0 420 336',
  path: (
    <>
      <rect
        x="82.5"
        y="78.52"
        width="329.88"
        height="238.62"
        rx="9.78"
        fill="#00A0F5"
        stroke="#2e353a"
        stroke-miterlimit="10"
        stroke-width="1.501"
      />
      <path
        d="M88.67 101.44H406.2v201.07H88.67z"
        fill="#fff"
        stroke="#2e353a"
        stroke-miterlimit="10"
        stroke-width="1.501"
      />
      <path
        d="M105.28 259.96h94.47m-78.83 9.38h78.83m-94.47 0h7.5m-7.5 9.38h94.47m-6.89 9.39h6.89m-94.47 0h79.45"
        fill="none"
        stroke="#2e353a"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="102.22"
        y="117.88"
        width="228.33"
        height="73.43"
        rx="5.69"
        fill="#2e353a"
      />
      <path
        d="M324.87 118.38a5.18 5.18 0 0 1 5.18 5.19v62.05a5.18 5.18 0 0 1-5.18 5.19h-217a5.19 5.19 0 0 1-5.19-5.19v-62.05a5.19 5.19 0 0 1 5.19-5.19h217m0-1h-217a6.2 6.2 0 0 0-6.19 6.19v62.05a6.2 6.2 0 0 0 6.19 6.19h217a6.19 6.19 0 0 0 6.18-6.19v-62.05a6.19 6.19 0 0 0-6.18-6.19Z"
        fill="#2e353a"
      />
      <path
        d="M284.42 118.38a12.08 12.08 0 0 1-17.45 0h-5.22l.14.24-1.89 1.92a1.89 1.89 0 0 0 0 2.66l2.49 2.49a1.87 1.87 0 0 0 2.66 0l1.92-1.92a16 16 0 0 0 5 2.08v2.72a1.88 1.88 0 0 0 1.88 1.88h3.52a1.88 1.88 0 0 0 1.88-1.88v-2.72a16 16 0 0 0 5-2.08l1.92 1.92a1.87 1.87 0 0 0 2.66 0l2.49-2.49a1.89 1.89 0 0 0 0-2.66l-1.92-1.92.14-.24Zm-89.06 71.5h-2.72a16 16 0 0 0-2.08-5l1.92-1.92a1.87 1.87 0 0 0 0-2.66l-2.48-2.51a1.88 1.88 0 0 0-2.67 0l-1.92 1.92a16.06 16.06 0 0 0-5-2.08v-2.72a1.89 1.89 0 0 0-1.89-1.88H175a1.89 1.89 0 0 0-1.89 1.88v2.72a16.06 16.06 0 0 0-5 2.08l-1.92-1.92a1.87 1.87 0 0 0-2.66 0l-2.53 2.49a1.87 1.87 0 0 0 0 2.66l1.92 1.92a16 16 0 0 0-2.08 5h-2.72a1.85 1.85 0 0 0-1.61.93h8.39a12.13 12.13 0 0 1 23.65 0H197a1.85 1.85 0 0 0-1.64-.91Zm-89.85-21.61H109a1.89 1.89 0 0 0 1.89-1.89v-2.71a16.06 16.06 0 0 0 5-2.08l1.92 1.92a1.87 1.87 0 0 0 2.66 0L123 161a1.87 1.87 0 0 0 0-2.66l-1.92-1.92a16 16 0 0 0 2.08-5h2.72a1.88 1.88 0 0 0 1.88-1.88V146a1.88 1.88 0 0 0-1.88-1.88h-2.72a16.21 16.21 0 0 0-2.08-5l1.92-1.92a1.87 1.87 0 0 0 0-2.66l-2.49-2.49a1.89 1.89 0 0 0-2.66 0l-1.92 1.95a16.28 16.28 0 0 0-5-2.07v-2.72a1.89 1.89 0 0 0-1.93-1.91h-3.52a1.88 1.88 0 0 0-1.88 1.88v2.72c-.31.07-.61.16-.91.25v4.39a11.64 11.64 0 0 1 3.32-.83 12.14 12.14 0 1 1-3.29 23.29v4.4c.3.09.6.18.91.25v2.71a1.89 1.89 0 0 0 1.88 1.91Z"
        fill="#00A0F5"
      />
      <path
        d="M188.1 84.73h157.76v9.66H188.1z"
        fill="#fff"
        stroke="#2e353a"
        stroke-miterlimit="10"
        stroke-width="1.501"
      />
      <path
        d="m96.31 83.19-4.55 4.38 4.72 5.06m-4.72-5.06h10.11m20.23-4.38 4.56 4.38-4.73 5.06m4.72-5.06h-10.11m31.85-.57a5 5 0 0 0-4.64-3.62 4.55 4.55 0 1 0 2.88 8.07"
        fill="none"
        stroke="#2e353a"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.501"
      />
      <path
        d="M145.32 87.49h3.54v-4.21M172.5 86l-4.2-3.4-4 3.4v7.4h8.2V86z"
        fill="none"
        stroke="#2e353a"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.501"
      />
      <path d="M166.7 88h3.2v5.13h-3.2z" fill="#2e353a" />
      <path
        d="m164.3 86-2.4 2.2m10.6-2.2 2.26 2.01m214.72-1.51h12.65m-12.65 2.85h12.65m-12.65 2.86h12.65m-12.65 2.85h12.65"
        fill="none"
        stroke="#2e353a"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.501"
      />
      <path
        d="M412.38 143.81h-206a7.61 7.61 0 0 0-7.61 7.61v57.21a7.61 7.61 0 0 0 7.61 7.61h206Z"
        mix-blend-mode="multiply"
        fill="#00A0F5"
      />
      <path
        d="m376.63 433.87 5.22-159.36m-2.32 92.72 27.24-25.5m-14.49 13.91 5.79-16.23m-41.14 47.52 20.86 22.6m34.77-9.85-34.77 17.97m16.23-7.54 12.75-.58"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.727"
      />
      <g>
        <ellipse cx="265.68" cy="439.16" rx="175.21" ry="8.97" fill="#00A0F5" />
        <ellipse
          cx="265.68"
          cy="439.16"
          rx="175.21"
          ry="8.97"
          fill="#fff"
          opacity=".3"
        />
        <path
          d="M271.12 433a5.15 5.15 0 0 0-2.37.61c-1.25.66-6.65 2.14-10.13 3.37-5.43 1.93-9.67.89-9.23 2.31s8.91 4.27 13.81 4.34 7.26-1.15 9.6-1.1.62 1.18 4.52 1.62 5.17.45 6.53-1.68 2.13-2.68 1.7-5.38V437a5 5 0 0 0-5-4.08Z"
          fill="#2e353a"
        />
        <path
          d="M301.47 430.4a3.59 3.59 0 0 0-2.05.58c-1.07.67-5.72 2.08-8.71 3.29-4.67 1.9-8.39.63-8 2.17s5.43 3.64 9.7 3.88a34.79 34.79 0 0 0 6.92-.26c4.62-.26 1.68 1.38 3.34 2.05a11 11 0 0 0 3.59.51c4.87.26 5.46.65 6.58-1.6s1.76-2.81 1.29-5.73v-.09c-.43-2.59-2.36-4.5-4.48-4.56Z"
          fill="#2e353a"
        />
        <path
          d="M257.88 278.1s.84 29.4.84 39.27 2.52 54.48 5.88 70.65 5.25 39.27 5.25 41.79-.84 3.15.84 4.83 8 4.83 10.3 3.78 3.57-5 3.57-5 0-21.84.63-36.12-.42-33-.42-33 8.19 37.81 10.5 45.37 4.2 21.27 4.62 24 2.26 2.82 4.61 2.82c2.82 0 6.31-.36 7.57-2.46s-1.47-24.15-1.47-33-6.93-63.51-6.3-77 4.62-36.75 3.36-43.68A77.5 77.5 0 0 1 306.4 268s-43.27-5-48.52 10.1Z"
          fill="#2e353a"
          stroke="#2e353a"
          stroke-miterlimit="10"
        />
        <path
          d="M260.41 281.4a50.14 50.14 0 0 0 2.72 7.62 8.86 8.86 0 0 0 3.78 4m7.09 134.4a49.6 49.6 0 0 1-.17 8.9m-6.08-124.43c.59 22.86 1.71 61.75 2.73 71.09 1.14 10.35 2.15 26.19 2.84 35.8m-6.48-137.06c-.21.63.49 13.18.49 13.18s.1 4.24.27 10.88"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
        />
        <path
          d="M208.74 198.86s-.21-5 .84-3.78 1.26 3.57 1.68 5.88c0 0 .63-4.41 1.26-3.15a8.94 8.94 0 0 1 .63 5 13.57 13.57 0 0 0 1.47 6.93c.84 1.26-4.41-2.27-5.88-10.88Z"
          fill="#fff"
          stroke="#2e353a"
          stroke-miterlimit="10"
        />
        <path
          d="M366.8 205.44s3-4.06 1.42-3.6-3 2.26-4.68 3.94c0 0 1.94-4 .71-3.32a9 9 0 0 0-3.34 3.83 13.49 13.49 0 0 1-5.09 4.92c-1.4.58 4.95.55 10.98-5.77Zm3.2-67.07v-2.53a1.35 1.35 0 0 0-1.35-1.36h-2a11.72 11.72 0 0 0-1.49-3.61l1.38-1.38a1.36 1.36 0 0 0 0-1.92l-1.79-1.79a1.36 1.36 0 0 0-1.92 0l-1.38 1.38a11.54 11.54 0 0 0-3.62-1.49v-2a1.35 1.35 0 0 0-1.35-1.36H354a1.36 1.36 0 0 0-1.35 1.36v2a11.38 11.38 0 0 0-3.61 1.49l-1.39-1.38a1.35 1.35 0 0 0-1.91 0l-1.74 1.79a1.36 1.36 0 0 0 0 1.92l1.38 1.38a11.74 11.74 0 0 0-1.5 3.61h-1.95a1.36 1.36 0 0 0-1.36 1.36v2.53a1.36 1.36 0 0 0 1.36 1.36h1.95a11.74 11.74 0 0 0 1.5 3.61l-1.38 1.38a1.36 1.36 0 0 0 0 1.92l1.79 1.79a1.35 1.35 0 0 0 1.91 0l1.39-1.38a11.64 11.64 0 0 0 3.61 1.5v1.95a1.36 1.36 0 0 0 1.35 1.36h2.54a1.35 1.35 0 0 0 1.35-1.36v-1.95a11.8 11.8 0 0 0 3.62-1.5l1.38 1.38a1.36 1.36 0 0 0 1.92 0l1.79-1.79a1.36 1.36 0 0 0 0-1.92l-1.38-1.38a11.72 11.72 0 0 0 1.49-3.61h2a1.35 1.35 0 0 0 1.24-1.36Zm-14.16 4.5a5.8 5.8 0 1 1 5.16-5.17 5.79 5.79 0 0 1-5.14 5.17Zm-107.53 65.59v-2.54a1.35 1.35 0 0 0-1.36-1.35h-2a11.38 11.38 0 0 0-1.45-3.57l1.38-1.39a1.35 1.35 0 0 0 0-1.91l-1.79-1.8a1.36 1.36 0 0 0-1.92 0l-1.38 1.39a11.74 11.74 0 0 0-3.61-1.5v-2a1.36 1.36 0 0 0-1.36-1.35h-2.53a1.36 1.36 0 0 0-1.36 1.35v2a11.74 11.74 0 0 0-3.61 1.5l-1.38-1.39a1.36 1.36 0 0 0-1.92 0l-1.79 1.8a1.35 1.35 0 0 0 0 1.91l1.38 1.39a11.64 11.64 0 0 0-1.5 3.61h-2a1.35 1.35 0 0 0-1.36 1.35v2.54a1.35 1.35 0 0 0 1.36 1.35h2a11.8 11.8 0 0 0 1.5 3.62l-1.38 1.38a1.36 1.36 0 0 0 0 1.92l1.79 1.79a1.36 1.36 0 0 0 1.92 0l1.38-1.39a11.74 11.74 0 0 0 3.61 1.5v2a1.35 1.35 0 0 0 1.36 1.35h2.53a1.35 1.35 0 0 0 1.36-1.35v-2a11.74 11.74 0 0 0 3.61-1.5l1.38 1.39a1.36 1.36 0 0 0 1.92 0l1.79-1.79a1.36 1.36 0 0 0 0-1.92l-1.38-1.38a11.54 11.54 0 0 0 1.49-3.62h2a1.35 1.35 0 0 0 1.32-1.39Zm-14.14 4.69a6 6 0 1 1 5.34-5.35 6 6 0 0 1-5.34 5.35Z"
          fill="#fff"
          stroke="#2e353a"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect
          x="203.48"
          y="135.95"
          width="227.33"
          height="72.43"
          rx="7.61"
          fill="#fff"
          stroke="#2e353a"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M423.72 154.99h-205.2m205.2 15.87h-205.2m205.2 15.87h-205.2"
          fill="none"
          stroke="#2e353a"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M224.27 220.69s-2.47-2.11-2.77-3a23 23 0 0 0-1.21-4.12c-.86-1.87-4-3.54-5-5.76s-2.33-6.11-3-6.86-1.66-.41-2 1.69 3.59 7.25 3.84 8-4.11-.55-6.91-2.35-4.52-5.55-6.21-7.93-3.07-.71-3 .66 5.1 9.1 6.07 11.92 4.2 5.85 6 7.39 5.75 2.16 5.75 2.16l2.65 2.64Z"
          fill="#fff"
          stroke="#2e353a"
          stroke-miterlimit="10"
        />
        <path
          d="m216.72 231.62-3.57-7.77a9 9 0 0 1 6.09-3.78c4.41-.84 5 0 5 0l2.31 8s-7.55.61-9.83 3.55Z"
          fill="#00A0F5"
          stroke="#2e353a"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M251.37 205s-10.71 30.66-12.18 34.65c-.38 1-1.11 2.7-2 4.64-1.87-3.25-6-10.42-7.23-13.46-1.68-4-4-5-7.77-4.62s-7.14 4.41-7.14 7.35c0 5.57 4.93 13 7.11 18 2.34 5.3 3.91 7.28 7.86 11.71 5 5.61 10.67 3.67 14.5-1.79 2.94-4.2 10.43-19.49 10.43-19.49s-1.9-32.21-3.58-36.99Z"
          fill="#00A0F5"
          stroke="#2e353a"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M237.18 244.24s-4.14 5.95-4.53 9.1"
          fill="none"
          stroke="#2e353a"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M340.92 216.77s2.93-1.4 3.45-2.17a22.53 22.53 0 0 1 2.23-3.68c1.31-1.58 4.82-2.37 6.35-4.27s3.82-5.3 4.68-5.84 1.71 0 1.52 2.15-5.33 6.08-5.75 6.7 4.85-1.21 8.1-.54 7.12-.79 10-1.3 2.69 1.64 1.71 2.59-10 2.93-12.68 4.26-5 2.37-7.21 3.38a44.81 44.81 0 0 0-4.61 2.62l-3.24 1.87Z"
          fill="#fff"
          stroke="#2e353a"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="m348 228.48 3.57-7.77a9 9 0 0 0-6.09-3.78c-4.41-.84-5 0-5 0l-2.31 8s7.56.61 9.83 3.55Z"
          fill="#00A0F5"
          stroke="#2e353a"
          stroke-miterlimit="10"
        />
        <path
          d="M342.58 223c-3.78-.42-6.09.63-7.77 4.62-1.28 3-5.35 10.21-7.23 13.46-.9-1.94-1.63-3.6-2-4.64-1.47-4-12.18-34.65-12.18-34.65-1.68 4.83-3.57 37-3.57 37s8 15.28 11 19.49c1.73 2.47 3.1 4.76 6.27 5.23 9.32 1.39 12.11-7.38 15-13.86 1.81-4.09 3.61-8.19 5.29-12.34.82-2 2.38-4.69 2.38-6.92-.05-2.96-3.41-6.95-7.19-7.39Z"
          fill="#00A0F5"
          stroke="#2e353a"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M327.58 241.1s4.2 5.94 5 9.09"
          fill="none"
          stroke="#2e353a"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <g clip-path="url(#c)">
          <path
            d="m276.15 183.74.42 5.67 16.81-1-.63-10.08-.36-13.08c-2.19 0-4.19.09-4.81.09-1.53 0-4.87-.26-5.64 0s-1.79.51-1.79.51l-3.84 4.1s.25 5.38-1.54 8.2c-1.5 2.36-5.44 3.29-5.66 4.36a4.93 4.93 0 0 0 .32.65c.2.15.42.31.66.47 1.82.73 6.06.11 6.06.11Z"
            mix-blend-mode="multiply"
            fill="#00A0F5"
            opacity=".5"
          />
        </g>
        <path
          d="M269.5 163.79c0 .85-.31 1.53-.69 1.53s-.7-.68-.7-1.53.31-1.53.7-1.53.69.68.69 1.53Z"
          fill="#2e353a"
        />
        <path
          d="M288.13 140.26c-1 .16-9 0-15.34 1.05s-8.19 1.47-8 4.2-.42 3.78 1.89 4.83a9.44 9.44 0 0 0 3.15 1s-.63 2.94-.84 5 3.57 4.41 3.57 4.41a15.63 15.63 0 0 0 .21 4.21c.42 1 1.47 1.26 2.1.42s1.05-4.84 1.68-3.16 3.15 14.29 4.84 15.76 9.24.63 11.34.21 6.09-9.24 8-14.5 1-14.49-1.68-18.69-8.19-5.16-10.92-4.74Z"
          fill="#fff"
          stroke="#2e353a"
          stroke-miterlimit="10"
        />
        <path
          d="M299.69 146.27c-.75 2-1.52 4.5-3.65 4.66-3.33.26-3.33-4.1-5.64-5.64s1.54 7.69-.25 5.13-6.41-5.13-10.51-5.38 2.3 7.43.77 7.43-4.1-4.87-4.1-6.15-3.85-1.32-8.21-1.32-2.56 2.56-2.56 4.1a1.18 1.18 0 0 0 .18.62 4.78 4.78 0 0 0 1 .58 9.44 9.44 0 0 0 3.15 1s-.63 2.94-.84 5 3.57 4.41 3.57 4.41a15.63 15.63 0 0 0 .21 4.21c.42 1 1.47 1.26 2.1.42s1.05-4.84 1.68-3.16 3.15 14.29 4.84 15.76 9.24.63 11.34.21 6.09-9.24 8-14.5c1.69-4.65 1.12-12.82-1.08-17.38Z"
          fill="#2e353a"
        />
        <path
          d="m272.58 188.78-4.62 8.4s11.55-3.36 17.86-3.36a86.36 86.36 0 0 1 13.23 1.26l-2.94-8.19s-15.97-1.68-23.53 1.89Z"
          fill="#00A0F5"
          stroke="#2e353a"
          stroke-miterlimit="10"
        />
        <path
          d="M269.64 196.13s-7.56 4-12 6.09a64.91 64.91 0 0 1-6.3 2.73s5.25 66.85 5.25 70.42-.21 3.78 1.05 4.83 7.78 1.53 19.33 1.32 26-2.58 27.09-2.58a5.42 5.42 0 0 0 4.2-2.52c1.26-1.89 5.25-73.15 5.46-74s-13.86-6.93-15.33-7.35-9.84-6.07-28.75 1.06Z"
          fill="#00A0F5"
          stroke="#2e353a"
          stroke-miterlimit="10"
        />
        <path
          d="M295.1 249.65a34.16 34.16 0 0 1-4.23 3.29m19.67-30.68s-2.92 12.89-11.44 23.18"
          fill="none"
          stroke="#2e353a"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M274.77 162.21a53.81 53.81 0 0 1 .51 5.9c0 2.3-1.54 3.58-.51 4.61s1.79.51 2.56-1.28a15.25 15.25 0 0 0 1-6.67c-.23-1.77-.49-5.64-3.56-2.56Z"
          fill="#fff"
          stroke="#2e353a"
          stroke-miterlimit="10"
        />
        <path
          d="m286.08 370.44 5.63-57.56 8.76-10.64"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
        />
      </g>
    </>
  ),
});
