import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { Company } from 'graphql/generated/Company';
import { COMPANIES } from '../../queries/company';
import { removeNulls } from 'utils/removeNulls';
import { normalizeCompany } from 'model/Company';

const useCompanies = () => {
  const { data, loading, refetch, error } = useQuery<Company>(COMPANIES);

  const company = useMemo(() => {
    return data?.meCompany
      ? normalizeCompany(removeNulls(data.meCompany))
      : undefined;
  }, [data]);

  return {
    error,
    refetch,
    company,
    loading,
  };
};

export default useCompanies;
