import { gql } from '@apollo/client';
import {
  JOB_FRAGMENT,
  ONLY_POSITION_FRAGMENT,
  POSITION_FRAGMENT,
  SANITIZED_JOB_FRAGMENT,
} from 'graphql/fragments/job';

export const JOBS = gql`
  query Jobs {
    activeJobs {
      ...JobParts
    }
  }
  ${JOB_FRAGMENT}
`;

export const ADMIN_JOBS = gql`
  query jobPositions {
    getJobs {
      id
      created_at
      state
      name
      salaryMin
      salaryMax
      company {
        name
        logo {
          url
        }
      }
      profession {
        experienceYears
      }
      restrictedLocations {
        id
        name
      }
      restrictedCountries {
        id
        name
      }
      restrictedCities {
        id
        name
      }
      user_job_applications {
        new
      }
      resolutions {
        new
      }
    }
  }
`;

export const SANITIZEDJOBS = gql`
  query allJobsSanitized {
    allJobsSanitized {
      ...PositionSanitizedParts
    }
  }
  ${SANITIZED_JOB_FRAGMENT}
`;

export const ALLJOBS = gql`
  query allJobs {
    allJobs {
      ...PositionParts
    }
  }
  ${POSITION_FRAGMENT}
`;

export const ONEJOB = gql`
  query oneJob($id: ID) {
    oneJob(id: $id) {
      ...OnlyPositionParts
    }
  }
  ${ONLY_POSITION_FRAGMENT}
`;

export const ONEJOB_SANITIZED = gql`
  query oneJobSanitized($id: ID!) {
    oneJobSanitized(id: $id) {
      ...PositionSanitizedParts
    }
  }
  ${SANITIZED_JOB_FRAGMENT}
`;

export const JOB = gql`
  query Job($jobId: ID) {
    activeJob(jobId: $jobId) {
      ...JobParts
    }
  }
  ${JOB_FRAGMENT}
`;

export const JOBS_MATCH_USER = gql`
  query jobsMatchUser {
    jobsMatchUser
  }
`;
