import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { CompanyTypes } from 'graphql/generated/CompanyTypes';
import { COMPANY_TYPES } from 'graphql/queries/companyType';
import { removeNulls } from 'utils/removeNulls';
import { normalizeCompanyType, CompanyType } from 'model/CompanyType';

const useCompanyType = () => {
  const { data, loading, error } = useQuery<CompanyTypes>(COMPANY_TYPES);

  const companyTypes = useMemo(() => {
    return (data?.companyTypes || []).reduce<CompanyType[]>(
      (acum, input) =>
        !!input ? acum.concat(normalizeCompanyType(removeNulls(input))) : acum,
      []
    );
  }, [data]);

  return {
    error,
    companyTypes,
    loading,
  };
};

export default useCompanyType;
