import { BoxProps, Box, SimpleGrid } from '@chakra-ui/react';
import { Career } from 'model/Career';
import { Link } from 'react-router-dom';
import { WorkshopCard } from 'components/WorkshopCard';
import { Header } from '../Header';

interface Props extends BoxProps {
  career: Career;
}

export const NewItinerary = ({ career, ...props }: Props) => {
  const { itineraries } = career;

  if (!itineraries) return null;

  return (
    <Box {...props}>
      {itineraries.map((itinerary) => (
        <>
          <Header
            name={itinerary && itinerary.name ? itinerary.name : ''}
            mb="4"
          />
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap="8" mb="9">
            {itinerary.workshops.map((workshop, index) => (
              <Link to={workshop ? `/workshop/${workshop.id}` : '/'}>
                <WorkshopCard
                  title={workshop ? workshop.name : ''}
                  tags={
                    workshop && workshop.skills
                      ? workshop.skills.map((skill) =>
                          skill ? skill.name : ''
                        )
                      : []
                  }
                  durationMinutes={workshop ? workshop.estimatedTimeMinutes : 0}
                  progress={0}
                  locked={false}
                />
              </Link>
            ))}
          </SimpleGrid>
        </>
      ))}
    </Box>
  );
};
