import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { ALL_USERS } from 'graphql/queries/user';
import { Users } from 'graphql/generated/Users';
import { removeNulls } from 'utils/removeNulls';
import { normalizeUsers } from 'model/FormUsers';

const useUser = () => {
  const { data, loading } = useQuery<Users>(ALL_USERS);

  const users = useMemo(() => {
    return data ? normalizeUsers(removeNulls(data.allUsers)) : [];
  }, [data]);

  return {
    users,
    loading,
  };
};

export default useUser;
