import {
  Flex,
  FlexProps,
  HStack,
  Icon,
  Box,
  Text,
  Button,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Sections } from './Sections';
import { FiClock, FiCheckCircle, FiChevronLeft } from 'react-icons/fi';

import { useConnect } from './connect';
import { normalizeDuration } from 'utils/normalizeDuration';
import useMe from 'graphql/hooks/profile/useMe';
import * as Sentry from '@sentry/react';
import { OfferTags } from 'components/OfferTags';

interface Props extends FlexProps {}

export const Workshop = (props: Props) => {
  const { workshop, userWorkshops, loading } = useConnect();
  const { me, error } = useMe();
  let userWorkshop;

  if (error) Sentry.captureException(error?.graphQLErrors[0]);

  if ((loading && !workshop) || !workshop) return <div></div>;

  if (userWorkshops !== null) {
    userWorkshop = userWorkshops?.userWorkshops?.filter(
      (workshop) => workshop?.user?.id === me?.id
    );
  }

  return (
    <Box
      as="main"
      mx="auto"
      w="100%"
      maxW="50rem"
      p={{ base: '4', md: '6' }}
      pt="16"
      pb="12"
      mt="5"
    >
      <Button
        as={RouterLink}
        to="/resources"
        variant="link"
        mb="6"
        leftIcon={<FiChevronLeft />}
      >
        Volver a mis recursos
      </Button>
      <Flex
        bg="white"
        p={{ base: '4', lg: '6' }}
        borderRadius="md"
        direction={{ base: 'column' }}
        {...props}
      >
        <Text as="h4" textStyle="h4" mb="4" color="gray.600">
          {workshop.name}
        </Text>
        <Text textStyle="body" mb="4" color="gray.500">
          {workshop.description}
        </Text>
        {workshop.teacherName && (
          <Text textStyle="body" color="gray.600">
            Impartido por
            <Text as="strong">{' ' + workshop.teacherName}</Text>
          </Text>
        )}

        <HStack spacing="6" mb="10" mt="6">
          <HStack spacing="2">
            <Icon color="gray.300" as={FiClock} />
            <Text textStyle="body" color="gray.600">
              {normalizeDuration(workshop.durationMinutes)}
            </Text>
          </HStack>
          <OfferTags
            tags={workshop.skills.map((skill) => skill.name)}
            mt="5"
            spacing="3"
          />
        </HStack>
        <Flex
          mb={{ base: '6', md: '8' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Text as="h5" textStyle="h5">
            Temario del taller
          </Text>
          <HStack spacing="3">
            <Icon color="blue.400" as={FiCheckCircle} w={5} h={5} />
            <Text textStyle="body" color="gray.600">
              {userWorkshop !== undefined &&
              userWorkshop[0]?.progress !== undefined
                ? userWorkshop[0]?.progress
                : 0}
              % completado
            </Text>
          </HStack>
        </Flex>
        <Sections
          repoUrl={workshop.repoUrl}
          sections={workshop.sections}
          userWorkshop={userWorkshop}
        />
      </Flex>
    </Box>
  );
};
