import { useMutation } from '@apollo/client';
import {
  editDiscarded,
  editDiscardedVariables,
} from 'graphql/generated/editDiscarded';
import { FlyncerError } from 'graphql/helpers';
import { EDIT_DISCARDED } from 'graphql/mutations/userJobApplicationActions';
import { useCallback, useState } from 'react';
import * as Sentry from '@sentry/react';
import { ADMIN_JOBS } from 'graphql/queries/job';

export const useJobApplicationActions = () => {
  const [loadingEditDiscarded, setloadingEditDiscarded] = useState(false);
  const [editDiscardedMutation] = useMutation<
    editDiscarded,
    editDiscardedVariables
  >(EDIT_DISCARDED, {
    refetchQueries: [{ query: ADMIN_JOBS }],
  });

  const editDiscarded = useCallback(
    async (input: editDiscardedVariables) => {
      setloadingEditDiscarded(true);
      try {
        await editDiscardedMutation({ variables: input });
        setloadingEditDiscarded(false);
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo quitar los nuevos'));
        throw new FlyncerError('No se pudo quitar los nuevos', error);
      }
    },
    [editDiscardedMutation]
  );
  return {
    editDiscarded,
    loadingEditDiscarded,
  };
};
