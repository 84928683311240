import { CandidatesParts } from 'graphql/generated/CandidatesParts';
import { Jobs_activeJobs } from 'graphql/generated/Jobs';
import { removeNulls, WithoutNulls } from 'utils/removeNulls';
import { normalizePosition } from './JobRequest';

export const normalizeCandidates = (input: WithoutNulls<CandidatesParts>) => ({
  list: input.list.map((candidate) => ({
    id: candidate.id,
    uuid: candidate.uuid,
    email: candidate.email,
    phoneNumber: candidate.phoneNumber,
    name: candidate.name,
    linkedinURL: candidate.linkedinURL,
    githubURL: candidate.githubURL,
    englishLevel: candidate.englishLevel,
    professions: candidate.professions,
    completed_profile: candidate.completed_profile,
    desiredLocations: candidate.desiredLocations,
    desiredCountries: candidate.desiredCountries,
    desiredCities: candidate.desiredCities,
    user_cv: candidate.user_cv,
    cv: { name: candidate.cv?.name },
    updated_at: candidate.updated_at,
    lastActive: candidate.lastActive,
    careers: candidate.careers,
    resolution: candidate.resolution
      ? {
          id: candidate.resolution.id,
          isAccepted: candidate.resolution.isAccepted,
          new: candidate.resolution.new,
          cancelReason: candidate.resolution.cancelReason,
        }
      : undefined,
  })),
});

export type Candidates = ReturnType<typeof normalizeCandidates>;

export const normalizeJob = (input: WithoutNulls<Jobs_activeJobs>) => ({
  id: input.id,
  position: normalizePosition(input.position),
  candidates: normalizeCandidates(input.candidates),
});

export type JobRequest = ReturnType<typeof normalizeJob>;

export const normalizeJobs = (
  input: (WithoutNulls<Jobs_activeJobs> | undefined)[]
) =>
  input.reduce<JobRequest[]>(
    (acum, input) =>
      !!input ? acum.concat(normalizeJob(removeNulls(input))) : acum,
    []
  );
