import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { FlyncerError } from 'graphql/helpers';

import {
  CompleteWorkshop,
  CompleteWorkshopVariables,
} from 'graphql/generated/CompleteWorkshop';
import { COMPLETE_WORKSHOP } from 'graphql/mutations/userWorkshop';
import { useConnect } from 'containers/Main/Workshop/connect';
import * as Sentry from '@sentry/react';

const useFullWorkshopActions = () => {
  const { refetch } = useConnect();

  const [completeWorkshopMutation, { loading }] = useMutation<
    CompleteWorkshop,
    CompleteWorkshopVariables
  >(COMPLETE_WORKSHOP);

  const completeWorkshop = useCallback(
    async (input: CompleteWorkshopVariables) => {
      try {
        await completeWorkshopMutation({ variables: input });
        refetch();
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo completar la lección'));
        throw new FlyncerError('No se pudo completar la lección', error);
      }
    },
    [completeWorkshopMutation, refetch]
  );

  return {
    completeWorkshop,
    loading,
  };
};

export default useFullWorkshopActions;
