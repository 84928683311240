import { useCallback } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { FlyncerError } from 'graphql/helpers';

import { SendJob, SendJobVariables } from 'graphql/generated/SendJob';
import { NotifJob, NotifJobVariables } from 'graphql/generated/NotifJob';
import { UpdateJob, UpdateJobVariables } from 'graphql/generated/UpdateJob';
import {
  SEND_JOB,
  NOTIF_JOB,
  EDIT_SEEN_USER,
  UPDATE_JOB,
  CLOSE_JOB,
} from 'graphql/mutations/job';
import { JOB_FRAGMENT } from 'graphql/fragments/job';
import {
  EditSeenUser,
  EditSeenUserVariables,
} from 'graphql/generated/EditSeenUser';
import { Job_activeJob } from 'graphql/generated/Job';
import { createToast } from 'services/toast';
import {
  closeJobPosition,
  closeJobPositionVariables,
} from 'graphql/generated/closeJobPosition';
import { COMPANIES } from 'graphql/queries/company';
import { ADMIN_JOBS } from 'graphql/queries/job';
import * as Sentry from '@sentry/react';
import { ME } from 'graphql/queries/me';
import { GET_NOTIFICATIONS } from 'graphql/queries/notifications';

const useJobActions = () => {
  const client = useApolloClient();
  const [notifJobtMutation, { loading: notifJobLoading }] = useMutation<
    NotifJob,
    NotifJobVariables
  >(NOTIF_JOB, { refetchQueries: [{ query: COMPANIES }] });
  const [sendJobtMutation, { loading: sendJobLoading }] = useMutation<
    SendJob,
    SendJobVariables
  >(SEND_JOB, {
    refetchQueries: [
      { query: ME },
      { query: COMPANIES },
      { query: GET_NOTIFICATIONS },
    ],
  });
  const [editSeenUserMutation, { loading: seenUserLoading }] = useMutation<
    EditSeenUser,
    EditSeenUserVariables
  >(EDIT_SEEN_USER);

  const [editJobMutation, { loading: editJobLoading }] = useMutation<
    UpdateJob,
    UpdateJobVariables
  >(UPDATE_JOB);

  const [closeJobMutation] = useMutation<
    closeJobPosition,
    closeJobPositionVariables
  >(CLOSE_JOB, {
    refetchQueries: [{ query: ADMIN_JOBS }, { query: COMPANIES }],
  });

  const closeJob = useCallback(
    async (id: closeJobPositionVariables) => {
      try {
        await closeJobMutation({ variables: id });
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo cerrar la oferta'));
        throw new FlyncerError('No se pudo cerrar la oferta', error);
      }
    },
    [closeJobMutation]
  );

  const updateJob = useCallback(
    async (input: UpdateJobVariables) => {
      try {
        await editJobMutation({ variables: input });
        createToast({
          title: 'Actualizar oferta',
          description: 'Oferta actualizada correctamente.',
          status: 'success',
        });
      } catch (error: any) {
        createToast({
          title: 'Actualizar oferta',
          description: 'Algo falló intentando actualizar la oferta.',
          status: 'error',
        });
        Sentry.captureException(new Error('No se pudo actualizar la oferta'));
        throw new FlyncerError('No se pudo actualizar la oferta', error);
      }
    },
    [editJobMutation]
  );

  const notifJob = useCallback(
    async (input: NotifJobVariables) => {
      try {
        await notifJobtMutation({ variables: input });
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo enviar la notificación'));
        throw new FlyncerError('No se pudo enviar la notificación', error);
      }
    },
    [notifJobtMutation]
  );

  const sendJob = useCallback(
    async (input: SendJobVariables) => {
      try {
        const { data } = await sendJobtMutation({ variables: input });
        const jobRequest = data?.sendJob;

        const job = client.cache.readFragment<Job_activeJob>({
          id: `Job:${input.id}`,
          fragment: JOB_FRAGMENT,
          fragmentName: 'JobParts',
        });

        if (job && jobRequest) {
          client.cache.writeFragment({
            id: `Job:${input.id}`,
            fragment: JOB_FRAGMENT,
            fragmentName: 'JobParts',
            data: {
              ...job,
              candidates: {
                ...job.candidates,
                list: job.candidates.list.map((jobElement) => ({
                  ...jobElement,
                  resolution:
                    jobElement.id === input.userId
                      ? {
                          id: jobRequest.id,
                          isAccepted: jobRequest.isAccepted,
                        }
                      : jobElement.resolution,
                })),
              },
            },
          });
        }
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo enviar la oferta'));
        throw new FlyncerError('No se pudo enviar la oferta', error);
      }
    },
    [sendJobtMutation, client]
  );

  const editSeenUser = useCallback(
    async (input: EditSeenUserVariables) => {
      try {
        await editSeenUserMutation({ variables: input });
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo actualizar el usuario'));
        throw new FlyncerError('No se pudo actualizar el usuario', error);
      }
    },
    [editSeenUserMutation]
  );

  return {
    editJobLoading,
    updateJob,
    notifJob,
    sendJob,
    editSeenUser,
    notifJobLoading,
    loading: sendJobLoading,
    closeJob,
    seenUserLoading: seenUserLoading,
  };
};

export default useJobActions;
