import { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Stack,
  StackProps,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';

import useConnect from './connect';
import { ExtendCard } from './ExtendCard';
import { Header } from './Header';
import { Itinerary } from './Path';
import LogoOW from 'components/LogoOW';
import { ModalOnboarding } from 'components/ModalOnboarding';
import StepsOnboarding from 'utils/stepsOnboarding';
import { useHistory } from 'react-router-dom';

const Container = ({ children, ...props }: StackProps) => (
  <Stack
    as="main"
    mx="auto"
    w="100%"
    maxW="container.xl"
    px={{ base: '4', md: '6' }}
    py={{ base: '8', md: '14' }}
    spacing="10"
    {...props}
  >
    {children}
  </Stack>
);

export const Courses = () => {
  const { me, userCareers, allowAddNewCareer, loading } = useConnect();
  const hasCareer = userCareers.length > 0;
  const [isOpen2, setisOpen2] = useState(true);
  const [stepIndex, setstepIndex] = useState(0);
  const [addPaths, setaddPaths] = useState(false);
  const { steps, stepsTest } = StepsOnboarding();

  const [forceRender2, setforceRender2] = useState(false);
  const [forceRender3, setforceRender3] = useState(false);
  const [forceRender, setforceRender] = useState(false);
  const { replace } = useHistory();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isSend, setisSend] = useState(false);

  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    document.getElementById('top')?.scrollIntoView();
    if (me?.onboarding) replace('/onboarding-welcome');
  }, [me?.onboarding, replace]);

  if (forceRender2)
    setTimeout(() => {
      setforceRender2(false);
    }, 210);

  if (isSend && !localStorage.getItem('button')) {
    setstepIndex(2);
    setisSend(false);
  }

  if (isSend && localStorage.getItem('button')) {
    setstepIndex(1);
    setisSend(false);
  }

  if (addPaths && !localStorage.getItem('button')) {
    setstepIndex(1);
    setaddPaths(false);
  }

  if (!forceRender && !isOpen2)
    setTimeout(() => {
      setforceRender(true);
    }, 50);

  const callBack = useCallback(() => {
    if (stepIndex === 1 && !forceRender3) {
      setforceRender3(true);
    }
  }, [stepIndex, forceRender3]);

  if (loading && (!hasCareer || !me)) {
    return null;
  }

  if (!hasCareer) {
    return (
      <>
        <ModalOnboarding
          me={me}
          isOpen2={isOpen2}
          setisOpen2={setisOpen2}
          onOpen={onOpen}
          steps={localStorage.getItem('button') ? stepsTest : steps}
          stepIndex={stepIndex}
          callBack={callBack}
        />
        <Container>
          <ExtendCard
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            title="¿Con qué área sueles trabajar?"
            subtitle="Selecciona las tecnologías con las que trabajas, así podremos crear un itinerario personalizado para ti."
            firstCareer
            forceRender={setforceRender2}
            onTour={me?.onboarding}
            stepIndex={stepIndex}
            setisSend={setisSend}
            userCareersLength={userCareers.length}
            isOpen2={isOpen2}
            setaddPaths={setaddPaths}
          />
        </Container>
      </>
    );
  }

  return (
    <>
      <ModalOnboarding
        me={me}
        isOpen2={isOpen2}
        setisOpen2={setisOpen2}
        onOpen={onOpen}
        steps={localStorage.getItem('button') ? stepsTest : steps}
        stepIndex={stepIndex}
      />
      <Container id={isMobile ? 'top' : ''} mb={isMobile ? '12' : '0'}>
        {userCareers
          .filter(
            (userCareer) =>
              userCareer.career.name !== 'Empleabilidad' &&
              userCareer.career.name !== 'Management'
          )
          .map((userCareer) => {
            const { id, activePaths, isMain } = userCareer;
            return (
              <Fragment key={`${id}-${isMain ? 'main' : ''}`}>
                <Header userCareer={userCareer} />
                {activePaths.map((path) => (
                  <Itinerary key={path.id} userPath={path} />
                ))}
              </Fragment>
            );
          })}

        {allowAddNewCareer && (
          <ExtendCard
            title="¿Te gustaría ampliar tu perfil?"
            subtitle="¡Parece que tienes nivel suficiente en tus otras carreras! Si quieres puedes complementar tu perfil añadiendo otra nueva."
            userCareersLength={userCareers.length}
            forceRender={setforceRender2}
            onTour={me?.onboarding}
            stepIndex={stepIndex}
            setisSend={setisSend}
            isOpen={isOpen}
            isOpen2={isOpen2}
            onOpen={onOpen}
            onClose={onClose}
            setaddPaths={setaddPaths}
          />
        )}
      </Container>

      <Text textAlign="center">Powered by</Text>
      <LogoOW />
    </>
  );
};
