import { gql } from '@apollo/client';
import { CAREER_FRAGMENT } from 'graphql/fragments/career';

export const CAREERS = gql`
  query Careers {
    careers(sort: "order:asc") {
      ...CareerParts
    }
  }
  ${CAREER_FRAGMENT}
`;
