import Vimeo from '@u-wave/react-vimeo';
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
} from '@chakra-ui/react';
import useMe from 'graphql/hooks/profile/useMe';
import useMeActions from 'graphql/hooks/profile/useMeActions';
import { useCallback } from 'react';
import * as Sentry from '@sentry/react';

export const IntroVideo = () => {
  const { me, error } = useMe();
  const { updateOnboarding } = useMeActions();
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (error) Sentry.captureException(error?.graphQLErrors[0]);

  const handleClose = useCallback(async () => {
    if (!me) {
      return;
    }
    try {
      await updateOnboarding({
        input: {
          where: {
            id: me.id,
          },
          data: {
            onboarding: false,
          },
        },
      });
      onClose();
    } catch (error: any) {
      console.log(error);
    }
  }, [updateOnboarding, onClose, me]);

  return (
    <>
      <Button
        type="submit"
        aria-label="Contacta con nosotros"
        onClick={onOpen}
        justifyContent="start"
        fontSize="16px"
        colorScheme="brand"
        variant="outline"
        mt="3"
      >
        Ver video de introducción
      </Button>
      <Modal isOpen={isOpen} onClose={handleClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Bienvenido a Flyncer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Vimeo
              video="https://vimeo.com/562542010"
              autoplay
              responsive
              onEnd={onClose}
              showTitle={false}
              showPortrait={false}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
