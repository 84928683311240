import { CreateOfferContainer } from '../createOffer';
import { Companies } from '../../../../model/Company';

interface Props {
  company: Companies;
}

export const NoOffersContainer = ({ company }: Props) => {
  if (
    company?.bonosSlot > 0 &&
    company.bonosJobPosting === 0 &&
    !company.superAdmin
  ) {
    return (
      <CreateOfferContainer
        title="Publica en tu Slot"
        text="Podrás acceder a todos los candidatos de nuestra base de datos que
        encajen con lo que buscas o esperar a que ellos vengan a ti"
        alert="Consulta con tu comercial para activar los bonos de Slots"
        bonos={company?.bonosSlot}
        isSlot={true}
      />
    );
  }

  if (
    company.bonosJobPosting > 0 &&
    company.bonosSlot > 0 &&
    !company.superAdmin
  ) {
    return (
      <>
        <CreateOfferContainer
          title="Publica en tu Slot"
          text="Podrás acceder a todos los candidatos de nuestra base de datos que
        encajen con lo que buscas o esperar a que ellos vengan a ti"
          alert="Consulta con tu comercial para activar los bonos de Slots"
          bonos={company?.bonosSlot}
          isSlot={true}
        />
        <CreateOfferContainer
          title="Crea tu primera oferta"
          text="Podrás acceder a todos los candidatos de nuestra base de datos que
        encajen con lo que buscas o esperar a que ellos vengan a ti"
          alert="Consulta con tu comercial para activar los bonos de job posting"
          bonos={company?.bonosJobPosting}
        />
      </>
    );
  }

  if (!company.superAdmin)
    return (
      <CreateOfferContainer
        title="Crea tu primera oferta"
        text="Podrás acceder a todos los candidatos de nuestra base de datos que
      encajen con lo que buscas o esperar a que ellos vengan a ti"
        alert="Consulta con tu comercial para activar los bonos de job posting"
        bonos={company?.bonosJobPosting}
      />
    );
  else return null;
};
