import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { FlyncerError } from 'graphql/helpers';

import * as Sentry from '@sentry/react';
import {
  ADD_SEEN_USER_NOTIFICATION,
  DELETE_NOTIFICATION,
} from 'graphql/mutations/notifications';
import {
  deleteUserNotification,
  deleteUserNotificationVariables,
} from 'graphql/generated/deleteUserNotification';
import { GET_NOTIFICATIONS } from 'graphql/queries/notifications';
import {
  addSeenUserNotification,
  addSeenUserNotificationVariables,
} from 'graphql/generated/addSeenUserNotification';

const useNotificationsActions = () => {
  const [deleteNotificationMutation, { loading }] = useMutation<
    deleteUserNotification,
    deleteUserNotificationVariables
  >(DELETE_NOTIFICATION, { refetchQueries: [{ query: GET_NOTIFICATIONS }] });

  const deleteNotification = useCallback(
    async (notificationID: deleteUserNotificationVariables) => {
      try {
        await deleteNotificationMutation({ variables: notificationID });
      } catch (error: any) {
        Sentry.captureException(new Error('No se eliminar la notificacion'));
        throw new FlyncerError('No se eliminar la notificacion', error);
      }
    },
    [deleteNotificationMutation]
  );

  const [addSeenUserNotificationMutation, { loading: loadingAddSeenUser }] =
    useMutation<addSeenUserNotification, addSeenUserNotificationVariables>(
      ADD_SEEN_USER_NOTIFICATION,
      {
        refetchQueries: [{ query: GET_NOTIFICATIONS }],
      }
    );

  const addSeenUserNotification = useCallback(
    async (notificationID: addSeenUserNotificationVariables) => {
      try {
        await addSeenUserNotificationMutation({ variables: notificationID });
      } catch (error: any) {
        Sentry.captureException(
          new Error('No se pudo marcar como visto la notificacion')
        );
        throw new FlyncerError(
          'No se pudo marcar como visto la notificacion',
          error
        );
      }
    },
    [addSeenUserNotificationMutation]
  );

  return {
    deleteNotification,
    loading,
    addSeenUserNotification,
    loadingAddSeenUser,
  };
};

export default useNotificationsActions;
