export const ReferralMessages = (
  fullUsername: string | undefined,
  referral_num: string | undefined
) => {
  const bodyEmailUser = `${fullUsername} quiere invitarte a probar Flyncer, la plataforma con la que impulsarás tu carrera gracias a cursos gratuitos y la búsqueda de ofertas personalizadas para ti.
    Al darte de alta, introduce el código de referral ${referral_num} y agradécele la invitación!`;

  const bodyEmail = `Quiero invitarte a probar Flyncer, la plataforma con la que impulsarás tu carrera gracias a cursos gratuitos y la búsqueda de ofertas personalizadas para ti.
    Al darte de alta, introduce mi código de referral ${referral_num}!`;
  return { bodyEmail, bodyEmailUser };
};
