import { gql } from '@apollo/client';
import { ME_EXTENDED_FRAGMENT } from 'graphql/fragments/me';

export const ME = gql`
  query Me {
    meExtended {
      ...MeExtendedParts
    }
  }
  ${ME_EXTENDED_FRAGMENT}
`;
