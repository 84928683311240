import { gql } from '@apollo/client';
import { USER_WORKSHOP_FRAGMENT } from 'graphql/fragments/userWorkshop';

export const COMPLETE_WORKSHOP= gql`
  mutation CompleteWorkshop($workshopId: ID!, $lessonId: ID!) {
    completeWorkshop(workshopId: $workshopId, lessonId: $lessonId) {
      ...MeWorkshopParts
    }
  }
  ${USER_WORKSHOP_FRAGMENT}
`;
