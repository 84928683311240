import {
  MePathParts,
  MePathParts_path_courses,
} from 'graphql/generated/MePathParts';
import { normalizeSkills } from 'model/Skill';
import { removeNulls, WithoutNulls } from 'utils/removeNulls';
import { normalizeLevel } from './Level';

const normalizeBasicCourse = (
  course: WithoutNulls<MePathParts_path_courses>
) => {
  return {
    id: course.id,
    name: course.name,
    estimatedTimeMinutes: course.estimatedTimeMinutes,
    progress: course.progress,
    level: course.level ? normalizeLevel(course.level) : undefined,
    skills: normalizeSkills(course.skills || []),
    quizApproved: course.quizApproved,
    blocked: course.blocked,
  };
};

export type BasicCourse = ReturnType<typeof normalizeBasicCourse>;

export const normalizePath = (input: WithoutNulls<MePathParts>) => ({
  id: input.id,
  path: {
    id: input.path!.id,
    name: input.path!.name,
  },
  level: input.level ? normalizeLevel(input.level) : undefined,
  preLevel: input.preLevel ? normalizeLevel(input.preLevel) : undefined,
  courses: (input.path!.courses || []).reduce<BasicCourse[]>(
    (acum, course) =>
      !!course && course?.level
        ? acum.concat(normalizeBasicCourse(removeNulls(course)))
        : acum,
    []
  ),
});

export type Path = ReturnType<typeof normalizePath>;
