import { createIcon } from '@chakra-ui/icons';

export const PartyPopper = createIcon({
  displayName: 'PartyPopper',
  viewBox: '0 0 500 500',
  path: (
    <>
      <path
        d="M29.1016 481.523C37.9688 491.133 74.4922 474.375 103.32 460.937C124.922 450.898 211.367 415.43 254.336 397.031C265.938 392.07 282.813 385.586 294.961 369.609C305.742 355.391 334.336 295 276.758 233.828C218.32 171.719 158.125 188.867 135.625 204.805C122.383 214.18 111.602 235.312 106.758 246.055C86.2891 291.445 57.1094 374.609 45.2344 407.305C36.5234 431.406 20.3125 471.992 29.1016 481.523V481.523Z"
        fill="#FFC107"
      />
      <path
        d="M100.977 259.727C101.523 266.523 102.891 277.578 107.578 298.867C110.781 313.477 116.016 328.789 120.273 338.906C133.047 369.336 150.977 381.602 169.141 391.445C200 408.164 220.977 411.289 220.977 411.289L195.82 421.563C195.82 421.563 180.586 418.398 159.805 408.164C140 398.398 119.375 381.875 104.297 349.531C97.7734 335.508 93.9844 321.914 91.7969 311.133C89.1016 297.773 88.6719 290.195 88.6719 290.195L100.977 259.727V259.727Z"
        fill="#FF8F00"
      />
      <path
        d="M70.0781 338.945C70.0781 338.945 73.2031 364.297 94.1406 396.289C118.672 433.711 152.93 439.844 152.93 439.844L130.156 449.219C130.156 449.219 104.727 441.445 80.5469 408.437C65.4688 387.852 61.25 363.242 61.25 363.242L70.0781 338.945V338.945Z"
        fill="#FF8F00"
      />
      <path
        d="M48.3984 399.023C48.3984 399.023 54.1016 420.898 66.6016 437.227C81.4844 456.719 100.43 462.383 100.43 462.383L82.9687 470.117C82.9687 470.117 69.7266 467.344 55.2344 448.984C44.2187 435.039 41.0938 419.023 41.0938 419.023L48.3984 399.023V399.023Z"
        fill="#FF8F00"
      />
      <path
        opacity="0.44"
        d="M38.9062 454.57C38.125 452.813 38.125 450.82 38.9453 449.102L138.437 242.773L154.805 304.297L50.1172 455.508C47.3047 459.727 40.9766 459.18 38.9062 454.57V454.57Z"
        fill="#FFFDE7"
      />
      <path
        d="M162.695 324.961C209.18 379.336 262.109 372.539 279.727 358.789C297.383 345 311.328 297.617 265.039 244.141C216.523 188.125 161.602 204.102 148.906 216.211C136.211 228.32 120.039 275.078 162.695 324.961V324.961Z"
        fill="url(#paint0_linear_1_2)"
      />
      <path
        d="M322.344 347.344C305.391 333.125 296.367 335.664 284.258 340.703C268.633 347.188 244.062 351.992 210.703 340.703L220.742 316.523C240.547 323.203 254.883 319.961 267.266 312.656C283.203 303.281 305 290.43 338.906 318.906C353.047 330.781 367.539 338.672 378.164 335.078C385.898 332.5 390 320.977 392.07 311.797C392.266 310.977 392.578 308.633 392.812 306.563C394.687 292.227 397.812 261.289 420.859 245.469C445.508 228.555 471.406 228.555 471.406 228.555L476.094 275.117C464.18 273.359 455.898 275.781 448.906 279.648C422.578 294.297 445.508 350.547 404.531 369.453C365.117 387.773 332.891 356.172 322.344 347.344V347.344Z"
        fill="#01AA50"
      />
      <path
        d="M177.344 287.969L160.391 272.773C191.523 238.008 183.32 212.461 177.344 193.867C176.133 190.117 175 186.562 174.258 183.164C171.602 171.133 171.055 160.664 171.875 151.523C159.922 136.641 154.648 121.055 154.297 120C147.031 98.0078 152.5 76.5625 165.039 56.4453C190.391 15.625 236.289 15.625 236.289 15.625L251.602 56.6016C239.961 56.1328 201.797 56.7187 190.078 75.1953C175.273 98.4766 185 112.852 185.703 114.492C188.555 110.781 191.445 107.813 194.023 105.508C212.734 88.9063 228.984 86.5234 239.336 87.4609C250.977 88.5156 261.523 94.375 269.062 103.984C277.305 114.531 280.703 128.242 278.086 140.703C275.547 152.852 267.461 163.125 255.313 169.648C234.102 181.055 216.445 179.492 204.609 175.547C204.688 175.82 204.727 176.133 204.805 176.406C205.234 178.359 206.094 181.094 207.109 184.258C214.023 205.664 226.875 239.648 177.344 287.969V287.969ZM206.055 141.367C208.32 143.008 210.703 144.375 213.164 145.352C221.367 148.633 230.313 147.539 240.469 142.07C246.445 138.867 247.148 135.43 247.383 134.297C248.086 130.898 246.914 126.562 244.375 123.32C242.148 120.469 239.57 118.984 236.484 118.672C230.625 118.164 222.695 121.875 214.766 128.945C210.977 132.344 208.086 136.523 206.055 141.367Z"
        fill="#F44336"
      />
      <path
        d="M245.195 294.336L220.938 293.672C220.938 293.672 232.461 228.594 269.766 217.656C276.758 215.625 284.414 213.555 292.109 212.422C296.68 211.719 303.906 210.664 307.461 209.336C308.281 203.203 305.703 195.391 302.812 186.523C300.547 179.648 298.203 172.578 296.953 164.844C294.531 149.766 298.555 136.445 308.281 127.266C320.156 116.133 339.336 112.578 360.977 117.5C373.32 120.313 382.422 126.367 390.43 131.68C401.875 139.297 408.555 143.164 422.539 133.75C439.453 122.344 417.344 77.6953 405.586 51.9141L449.453 33.6328C455.352 46.5234 483.828 112.852 465.039 150.703C458.711 163.438 447.812 171.875 433.516 175.039C402.422 181.992 384.219 169.883 370.937 161.055C364.648 156.875 359.141 153.594 353.164 151.875C311.641 140.039 369.609 201.133 342.461 228.594C326.172 245.039 286.367 249.375 283.789 250C258.164 256.172 245.195 294.336 245.195 294.336V294.336Z"
        fill="#F48FB1"
      />
      <path
        d="M171.836 151.523C171.094 160.117 170.742 165.234 172.969 176.406C183.711 184.297 207.109 184.297 207.109 184.297C206.094 181.133 205.195 178.398 204.805 176.445C204.727 176.172 204.688 175.859 204.609 175.586C180.82 163.711 171.836 151.523 171.836 151.523V151.523Z"
        fill="#C92B27"
      />
      <path
        d="M123.164 190L82.7734 170.195L102.891 141.133L134.57 162.109L123.164 190Z"
        fill="#FFC107"
      />
      <path
        d="M63.6328 135.156C43.0078 132.383 21.9922 114.883 19.6875 112.891L39.9609 89.1016C46.0937 94.2969 59.1016 103.008 67.8125 104.18L63.6328 135.156Z"
        fill="#FB8C00"
      />
      <path
        d="M100.039 83.0859L70.3516 73.3594C73.75 62.9688 74.6484 51.7578 72.8906 40.9375L103.75 35.9766C106.289 51.6797 105 67.9688 100.039 83.0859V83.0859Z"
        fill="#01AA50"
      />
      <path
        d="M285.441 59.8633L315.969 53.1836L324.785 93.4805L294.258 100.16L285.441 59.8633Z"
        fill="#FB8C00"
      />
      <path
        d="M361.172 69.4141L339.687 46.7187C350.937 36.0547 353.516 22.1094 353.516 21.9531L384.375 26.9922C383.984 29.4531 380.039 51.5625 361.172 69.4141V69.4141Z"
        fill="#01AA50"
      />
      <path
        d="M373.109 189.77L400.402 181.242L409.727 211.07L382.434 219.598L373.109 189.77Z"
        fill="#FB8C00"
      />
      <path
        d="M381.055 441.523L350 437.852C351.328 426.797 343.086 413.242 340.82 410.234L365.82 391.484C367.695 393.945 383.984 416.484 381.055 441.523V441.523Z"
        fill="#F44336"
      />
      <path
        d="M470.195 401.914C458.516 400.156 446.563 399.453 434.766 399.883L433.711 368.633C447.422 368.164 461.289 368.945 474.844 371.016L470.195 401.914V401.914Z"
        fill="#FB8C00"
      />
      <path
        d="M428.18 444.93L450.133 422.691L480.352 452.523L458.395 474.762L428.18 444.93Z"
        fill="#F48FB1"
      />
      <path
        d="M363.668 247.387L386.246 273.203L360.43 295.781L337.852 269.965L363.668 247.387Z"
        fill="#F44336"
      />
    </>
  ),
});
