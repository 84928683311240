import { NavLink, useHistory, useLocation } from 'react-router-dom';
import useAuth from 'graphql/hooks/auth/useAuth';

import {
  Box,
  Flex,
  HStack,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link,
  useToken,
  useMediaQuery,
  Divider,
  Stack,
} from '@chakra-ui/react';
import { Logo } from 'components/Logo';
import { FiUser } from 'react-icons/fi';
import useJobRequests from 'graphql/hooks/jobs/useJobRequests';
import useMe from 'graphql/hooks/profile/useMe';
import useFullCareer from 'graphql/hooks/career/useFullCareer';
import * as Sentry from '@sentry/react';
import useUserPaths from 'graphql/hooks/career/useUserPaths';
import { useState } from 'react';
import useNotifications from 'graphql/hooks/notifications/useNotifications';
import { sanitizePathsHexagon } from 'utils/sanitizePathsHexagon';
import { MenuNotifications } from './MenuNotifications';
import { HexagonMenuItem } from './HexagonMenuItem';

export const Topbar = () => {
  const { me, error } = useMe();
  const { error: jobRequestsError } = useJobRequests();
  const { error: fullCareerError } = useFullCareer();
  const { logout } = useAuth();
  const [brand600] = useToken('colors', ['brand.600']);
  const idUser: any = me?.id;
  const { paths } = useUserPaths({ idUser });
  const [isOpen, setisOpen] = useState(false);
  const [isOpen2, setisOpen2] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { pathsWithLevel, pathsHexagon, pathsId } = sanitizePathsHexagon(paths);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const { notifications } = useNotifications();

  if (error || jobRequestsError || fullCareerError) {
    let message = error
      ? error
      : jobRequestsError
      ? jobRequestsError
      : fullCareerError;
    Sentry.captureException(message?.graphQLErrors[0]);
  }

  if (!me) return null;
  if (!me || !notifications) return null;

  let notificacions2 = [...notifications];

  const orderedNotifications = notificacions2
    ?.sort(
      (a: any, b: any) =>
        new Date(a?.created_at).getTime() - new Date(b?.created_at).getTime()
    )
    .reverse();

  const seenNotifications = orderedNotifications.filter((notification) =>
    notification.seenUsers.find((user: any) => user.id === me?.id)
  );

  return (
    <>
      {!isMobile && (
        <Box bg="white">
          <Flex
            as="nav"
            aria-label="Navegación principal"
            maxW="container.xl"
            px={{ base: '4', md: '6' }}
            mx="auto"
            h="16"
            alignItems="center"
          >
            <Link as={NavLink} to="/career">
              <Logo id="logo" w="auto" height="8" />
            </Link>
            <HStack ml="auto" spacing={{ base: '1', md: '6' }}>
              <Button
                className="careerTop"
                as={NavLink}
                exact
                to="/career"
                variant="ghost"
                activeStyle={{ color: brand600 }}
              >
                Carrera
              </Button>
              <Button
                className="careerTop"
                as={NavLink}
                exact
                to="/resources"
                variant="ghost"
                activeStyle={{ color: brand600 }}
              >
                Recursos
              </Button>
              <Button
                as={NavLink}
                to="/jobs"
                className="offers"
                variant="ghost"
                activeStyle={{ color: brand600 }}
              >
                Oportunidades
              </Button>
              <Stack
                onMouseOver={() => {
                  setisOpen2(true);
                }}
                onMouseLeave={() => {
                  setisOpen2(false);
                }}
              >
                <Menu placement="bottom-start" isOpen={isOpen2}>
                  <MenuNotifications
                    notifications={notifications}
                    orderedNotifications={orderedNotifications}
                    seenNotifications={seenNotifications}
                  />
                </Menu>
              </Stack>

              <Menu>
                <Stack
                  onMouseOver={() => {
                    setisOpen(true);
                  }}
                  onMouseLeave={() => {
                    setisOpen(false);
                  }}
                >
                  <Menu isOpen={isOpen} size="xl">
                    <MenuButton
                      activeStyle={{ color: brand600 }}
                      as={IconButton}
                      isRound
                      fontSize="xl"
                      aria-label="Perfil de usuario"
                      variant="ghost"
                      icon={<FiUser />}
                    />
                    <MenuList zIndex="5">
                      <HexagonMenuItem
                        pathsWithLevel={pathsWithLevel}
                        pathsHexagon={pathsHexagon}
                        pathsId={pathsId}
                        me={me}
                        isOpen={isOpen}
                      />
                      <Divider my="2" />
                      <MenuItem pl="4" as={NavLink} to="/profile">
                        Mi perfil
                      </MenuItem>
                      <MenuItem
                        pl="4"
                        as={NavLink}
                        to="/configuration"
                        aria-label="Configuración"
                      >
                        Configuración
                      </MenuItem>
                      <MenuItem
                        pl="4"
                        onClick={() => {
                          if (
                            location.pathname.includes('/jobsOffer/') ||
                            location.pathname.includes('/cv/')
                          )
                            history.go(0);
                          logout();
                        }}
                      >
                        Logout
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Stack>
              </Menu>
            </HStack>
          </Flex>
        </Box>
      )}
    </>
  );
};
