import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import * as yup from 'yup';

import useAuth from 'graphql/hooks/auth/useAuth';
import { useSearchParams } from 'utils/useSearchParams';
import { FormValues } from './types';
import { ResetPasswordVariables } from 'graphql/generated/ResetPassword';

const initialValues: FormValues = {
  password: '',
  passwordConfirmation: '',
};

const validationSchema = yup.object().shape({
  password: yup.string(),
  passwordConfirmation: yup.string(),
});

const useConnect = () => {
  const { push } = useHistory();
  const code = useSearchParams().get('code');
  const { loading, updatePassword } = useAuth();

  useEffect(() => {
    if (!code) {
      push('/');
    }
  }, [push, code]);

  const handleSubmit = useCallback(
    async (
      values: FormValues,
      { setFieldError }: FormikHelpers<FormValues>
    ) => {
      try {
        const input: ResetPasswordVariables = {
          password: values.password,
          passwordConfirmation: values.passwordConfirmation,
          code: code || '',
        };
        await updatePassword(input);
      } catch (error: any) {
        if (error.fields?.id === 'Auth.form.error.code.provide') {
          setFieldError(
            'passwordConfirmation',
            'El código ya se ha usado o no es válido'
          );
        } else {
          setFieldError('passwordConfirmation', error.message);
        }
      }
    },
    [updatePassword, code]
  );

  return {
    isLoading: loading,
    handleSubmit,
    initialValues,
    schema: validationSchema,
  };
};

export default useConnect;
