import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useApolloClient } from '@apollo/client';

import {
  UPLOAD_CV,
  DELETE_CV,
  UPLOAD_PHOTO,
  DELETE_PHOTO,
} from 'graphql/mutations/files';
import { UploadCV, UploadCVVariables } from 'graphql/generated/UploadCV';
import { DeleteCV } from 'graphql/generated/DeleteCV';
import { createToast } from 'services/toast';
import { Me } from 'graphql/generated/Me';
import { ME } from 'graphql/queries/me';
import * as Sentry from '@sentry/react';
import {
  UploadPhoto,
  UploadPhotoVariables,
} from 'graphql/generated/UploadPhoto';
import { DeletePhoto } from 'graphql/generated/DeletePhoto';

const useCVActions = () => {
  const client = useApolloClient();
  const [uploadPhotoMutation, { loading: uploadPhotoLoading }] = useMutation<
    UploadPhoto,
    UploadPhotoVariables
  >(UPLOAD_PHOTO);

  const uploadPhoto = useCallback(
    async (data: UploadPhotoVariables) => {
      try {
        const repsonse = await uploadPhotoMutation({ variables: data });
        const photo = repsonse.data?.uploadPhoto;

        const me = client.readQuery<Me>({ query: ME });

        if (me?.meExtended && photo) {
          client.writeQuery<Me>({
            query: ME,
            data: {
              meExtended: {
                ...me.meExtended,
                photo,
              },
            },
          });
        }

        createToast({
          title: 'Actualizar perfil',
          description: 'Imagen subida correctamente.',
          status: 'success',
        });
      } catch (error: any) {
        createToast({
          title: 'Actualizar perfil',
          description: 'Algo falló intentando subir la imagen.',
          status: 'error',
        });
        Sentry.captureException(new Error('No se pudo subir la imagen'));
      }
    },
    [uploadPhotoMutation, client]
  );

  const [uploadCVMutation, { loading: uploadCVLoading }] = useMutation<
    UploadCV,
    UploadCVVariables
  >(UPLOAD_CV);

  const uploadCV = useCallback(
    async (data: UploadCVVariables) => {
      try {
        const repsonse = await uploadCVMutation({ variables: data });
        const cv = repsonse.data?.uploadCV;

        const me = client.readQuery<Me>({ query: ME });

        if (me?.meExtended && cv) {
          client.writeQuery<Me>({
            query: ME,
            data: {
              meExtended: {
                ...me.meExtended,
                cv,
              },
            },
          });
        }

        if (!me?.meExtended?.onboarding)
          createToast({
            title: 'Actualizar perfil',
            description: 'CV subido correctamente.',
            status: 'success',
          });
      } catch (error: any) {
        createToast({
          title: 'Actualizar perfil',
          description: 'Algo falló intentando subir el CV.',
          status: 'error',
        });
        Sentry.captureException(new Error('No se pudo subir el CV'));
      }
    },
    [uploadCVMutation, client]
  );

  const [deletePhotoMutation, { loading: deletePhotoLoading }] =
    useMutation<DeletePhoto>(DELETE_PHOTO);

  const deletePhoto = useCallback(async () => {
    try {
      await deletePhotoMutation();
      const me = client.readQuery<Me>({ query: ME });

      if (me?.meExtended) {
        client.writeQuery<Me>({
          query: ME,
          data: {
            meExtended: {
              ...me.meExtended,
              photo: null,
            },
          },
        });
      }

      createToast({
        title: 'Actualizar perfil',
        description: 'Imagen eliminada correctamente.',
        status: 'success',
      });
    } catch (error: any) {
      createToast({
        title: 'Actualizar perfil',
        description: 'Algo falló intentando eliminar tu foto de perfil.',
        status: 'error',
      });
      Sentry.captureException(new Error('No se pudo eliminar la imagen'));
    }
  }, [deletePhotoMutation, client]);

  const [deleteCVMutation, { loading: deleteCVLoading }] =
    useMutation<DeleteCV>(DELETE_CV);

  const deleteCV = useCallback(async () => {
    try {
      await deleteCVMutation();
      const me = client.readQuery<Me>({ query: ME });

      if (me?.meExtended) {
        client.writeQuery<Me>({
          query: ME,
          data: {
            meExtended: {
              ...me.meExtended,
              cv: null,
            },
          },
        });
      }
      if (!me?.meExtended?.onboarding)
        createToast({
          title: 'Actualizar perfil',
          description: 'CV eliminado correctamente.',
          status: 'success',
        });
    } catch (error: any) {
      createToast({
        title: 'Actualizar perfil',
        description: 'Algo falló intentando eliminar el CV.',
        status: 'error',
      });
      Sentry.captureException(new Error('No se pudo eliminar el CV'));
    }
  }, [deleteCVMutation, client]);

  return {
    isLoading: uploadCVLoading || deleteCVLoading,
    uploadPhotoLoading,
    deletePhotoLoading,
    uploadCV,
    deleteCV,
    uploadPhoto,
    deletePhoto,
  };
};

export default useCVActions;
