import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  ListItem,
  Stack,
  StackProps,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import useConnect from './connect';
import { FormInput } from 'components/FormInput';
import { ProfessionSelector } from './professionSelector';
import { ExpYearsSlider } from './expYearsSlider';
import { useEffect, useRef, useState } from 'react';
import { OfferPublished } from './offerPublished';
import { SalaryInputs } from './salaryInputs';
import { TecnologiesForm } from './tecnologies';
import { LocationForm } from './location';
import { DescriptionForm } from './description';
import { GetFilters } from './filters';
import { CompanyType } from './companyType';

const Container = ({ children, ...props }: StackProps) => (
  <Stack
    as="main"
    mx="auto"
    w="100%"
    maxW="container.md"
    px={{ base: '4', md: '6' }}
    py={{ base: '8', md: '14' }}
    spacing="10"
    {...props}
  >
    {children}
  </Stack>
);

export const FormOffer = () => {
  const [offerIsCreated, setOfferIsCreated] = useState(false);

  useEffect(() => {
    document.getElementById('top')?.scrollIntoView();
  }, []);

  const {
    totalUsers,
    setSalaryMinState,
    setSalaryMaxState,
    setExpYearsState,
    setProfessionState,
    setTecnologiesState,
    tecnologiesState,
    setLocationsState,
    setWhereToSearchState,
    locationsState,
  } = GetFilters();

  const {
    isSlot,
    company,
    loading,
    loadingCountries,
    jobPositionId,
    initialValues,
    handleSubmit,
    schema,
    seterrorPath,
    seterrorLocation,
    errorLocation,
    errorPath,
    errorValidation,
    seterrorValidation,
  } = useConnect();

  if (errorValidation) {
    document.getElementById('top')?.scrollIntoView();
    seterrorValidation(false);
  }

  const [descriptionValue] = useState(initialValues.description ?? '');

  const bonosRef = useRef(company?.bonosJobPosting);
  const bonosSlots = useRef(company?.bonosSlot);

  useEffect(() => {
    if (
      company?.bonosSlot === undefined ||
      company.bonosJobPosting === undefined
    )
      return;

    if (isSlot === 'true') {
      if (bonosSlots.current === undefined) {
        bonosSlots.current = company.bonosSlot;
        if (isSlot === 'true') return;
      }
    } else {
      if (bonosRef.current === undefined) {
        bonosRef.current = company.bonosJobPosting;
        if (isSlot !== 'true') return;
      }
    }

    if (isSlot === 'true') {
      if (bonosSlots.current !== undefined) {
        if (bonosSlots.current > company.bonosSlot) {
          setOfferIsCreated(true);
          if (localStorage.getItem('isSlot')) localStorage.removeItem('isSlot');
        }
      }
    } else {
      if (bonosRef.current !== undefined) {
        if (bonosRef.current > company.bonosJobPosting) {
          setOfferIsCreated(true);
        }
      }
    }
  }, [company, isSlot]);

  if (loading || loadingCountries || !company) return null;

  if (offerIsCreated) return <OfferPublished id={jobPositionId.current} />;

  return (
    <Container id="top">
      <Grid templateColumns="repeat(5, 1fr)" gap={6}>
        <GridItem colSpan={1}></GridItem>
        <GridItem colSpan={{ base: 5, md: 4 }}>
          <Heading>Configura tu oferta</Heading>
        </GridItem>
      </Grid>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ isSubmitting, handleChange, values }) => (
          <Form>
            <Box bg="white" p={{ base: '4', md: '8' }} borderRadius="md">
              <Text as="h3" fontSize="22" fontWeight="bold">
                Sobre la oferta
              </Text>

              <Text fontSize="16" fontWeight="bold" mt="10">
                Título *
              </Text>

              <FormInput
                value={values.name}
                mt="3"
                name="name"
                autoComplete="title"
                placeholder="ej. Frontend Developer, Project Manager, etc"
              />

              <Text fontSize="16" fontWeight="bold" mt="10">
                Descripción de la posición *
              </Text>
              <Text mt="3" fontSize="14">
                Te recomendamos añadir:{' '}
              </Text>
              <UnorderedList ml="10" my="5" fontSize="14">
                <ListItem>Descripción de rol</ListItem>
                <ListItem>Funciones</ListItem>
                <ListItem>Beneficios ofrecidos</ListItem>
              </UnorderedList>

              <DescriptionForm
                descriptionValue={descriptionValue}
                handleChange={handleChange}
              />

              {company.headHunting && <CompanyType name="companyType" />}

              <LocationForm
                locationsState={locationsState}
                seterrorLocation={seterrorLocation}
                errorLocation={errorLocation}
                totalUsers={totalUsers}
                setLocationsState={setLocationsState}
                setWhereToSearchState={setWhereToSearchState}
              />
            </Box>

            <Box bg="white" p={{ base: '4', md: '8' }} borderRadius="md" mt="6">
              <Text as="h3" fontSize="22" fontWeight="bold">
                Requisitos
              </Text>

              <Box mt="10">
                <Text fontSize="16" fontWeight="bold">
                  Rol profesional *
                </Text>
                <ProfessionSelector
                  setProfessionState={setProfessionState}
                  name="profession.professionId"
                />

                <ExpYearsSlider
                  totalUsers={totalUsers}
                  setExpYearsState={setExpYearsState}
                  name="profession.experienceYears"
                />
              </Box>

              <TecnologiesForm
                seterrorPath={seterrorPath}
                errorPath={errorPath}
                values={values}
                totalUsers={totalUsers}
                setTecnologiesState={setTecnologiesState}
                tecnologiesState={tecnologiesState}
              />

              <SalaryInputs
                totalUsers={totalUsers}
                setSalaryMinState={setSalaryMinState}
                setSalaryMaxState={setSalaryMaxState}
                min="salaryMin"
                max="salaryMax"
              />
            </Box>

            <Button
              mt="6"
              type="submit"
              colorScheme="brand"
              disabled={isSubmitting}
            >
              Guardar
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
