import { gql } from '@apollo/client';
import { CV_FRAGMENT } from 'graphql/fragments/meCV';

export const ME_CV = gql`
  query MeCV {
    meCV {
      ...MeCVParts
    }
  }
  ${CV_FRAGMENT}
`;
