import { ExperienceParts } from 'graphql/generated/ExperienceParts';
import { normalizeSkills } from 'model/Skill';
import { WithoutNulls } from 'utils/removeNulls';

export const normalizeMeExperience = (
  input: WithoutNulls<ExperienceParts>
) => ({
  id: input.id,
  empresa: input.empresa,
  puesto: input.puesto,
  funciones: input.funciones,
  inicio: input.inicio,
  fin: input.fin,
  skills: normalizeSkills(input.skills || []),
});

export type Experience = ReturnType<typeof normalizeMeExperience>;
