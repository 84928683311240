import { Box, HStack, Flex, Image, Text } from '@chakra-ui/react';
import { AllOffersTags } from 'components/AllOffersTags';
import { Avatar } from 'components/Avatar';
import { Position } from 'model/JobRequest';
import { normalizeExperience } from 'utils/normalizeExperience';
import { normalizeSalaryRange } from 'utils/normalizeSalaryRange';

interface Props {
  company: Position['company'];
  position: Position['name'];
  skills: Position['skills'];
  location: Position['location'];
  experienceYears: Position['experienceYears'];
  salary: Position['salary'];
  state?: Position['state'];
}

export const JobCard = ({
  company,
  position,
  skills,
  location,
  experienceYears,
  salary,
  state,
}: Props) => {
  let skillsName: string[] = [];

  skills?.forEach((skill: any) => {
    skillsName.push(skill);
  });

  return (
    <Flex
      direction="column"
      bg="white"
      shadow="base"
      _hover={{
        transform: 'translateY(-4px)',
        boxShadow: 'md',
      }}
      p="6"
      rounded="md"
      backgroundColor={state === 'active' ? 'white' : 'gray.50'}
    >
      <HStack spacing="2" mb="5">
        <Avatar
          w="12"
          h="12"
          alt={company?.name}
          src={company?.logo?.url}
          zIndex="1"
        />
        <Image
          rounded="md"
          objectFit="cover"
          border="1px"
          borderColor="gray.200"
        />
        <Box>
          <Text as="h5" textStyle="h5">
            {position}
          </Text>
          <Text textStyle="body" color="gray.500">
            {company?.name}
          </Text>
        </Box>
      </HStack>
      <AllOffersTags tags={skillsName} level={null} mb="5" />

      <HStack mt="auto" spacing="4">
        <Text textStyle="caption">{location.join(' / ')}</Text>
        <Text textStyle="caption" whiteSpace="nowrap">
          {normalizeExperience(experienceYears)}
        </Text>
        {salary && (
          <Text textStyle="caption">{normalizeSalaryRange(salary)}</Text>
        )}
      </HStack>
    </Flex>
  );
};
