import { gql } from '@apollo/client';
import { FULL_CAREER_FRAGMENT } from 'graphql/fragments/fullCareer';
import { LEVEL_FRAGMENT } from 'graphql/fragments/level';

export const EVALUATE_PATH_QUIZ = gql`
  mutation EvalutatePathQuiz($userPathId: ID!, $answers: [QuizAnswers!]!) {
    evaluatePathQuiz(userPathId: $userPathId, answers: $answers) {
      level {
        ...LevelParts
      }
      successPercent
      fullCareer {
        ...FullCareerParts
      }
      jobsMatch
    }
  }
  ${LEVEL_FRAGMENT}
  ${FULL_CAREER_FRAGMENT}
`;

export const EVALUATE_ONLY_PATH = gql`
  mutation EvaluateOnlyPath($pathID: ID!, $answers: [QuizAnswers!]!) {
    evaluateOnlyPath(pathID: $pathID, answers: $answers) {
      level {
        ...LevelParts
      }
      successPercent
    }
  }
  ${LEVEL_FRAGMENT}
`;

export const EVALUATE_COURSE_QUIZ = gql`
  mutation EvalutateCourseQuiz($quizId: ID!, $answers: [QuizAnswers!]!) {
    evaluateCourseQuiz(quizId: $quizId, answers: $answers) {
      successPercent
      approved
      fullCareer {
        ...FullCareerParts
      }
    }
  }
  ${LEVEL_FRAGMENT}
  ${FULL_CAREER_FRAGMENT}
`;
