import { massMedias_massMedias } from 'graphql/generated/massMedias';
import { removeNulls, WithoutNulls } from 'utils/removeNulls';

export const normalizeMassMedia = (
  input: WithoutNulls<massMedias_massMedias>
) => ({
  id: input.id,
  name: input.name,
});

export type MassMedia = ReturnType<typeof normalizeMassMedia>;

export const normalizeMassMedias = (
  input: (massMedias_massMedias | undefined)[]
) =>
  input.reduce<MassMedia[]>(
    (acum, input) =>
      !!input ? acum.concat(normalizeMassMedia(removeNulls(input))) : acum,
    []
  );
