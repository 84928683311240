import { gql } from '@apollo/client';
import { FILE_FRAGMENT } from 'graphql/fragments/file';

export const DOWNLOADABLE_FILE_FRAGMENT = gql`
  fragment DownloadableFileParts on DownloadableFile {
    id
    file {
      ...FileParts
    }
    name
    viewedBy{
      id
      name
      email
    }
  }
  ${FILE_FRAGMENT}
`;
