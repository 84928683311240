import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { JobRequests } from 'graphql/generated/JobRequests';
import { JOB_REQUESTS } from 'graphql/queries/jobRequest';
import { removeNulls } from 'utils/removeNulls';
import { normalizeJobRequests } from 'model/JobRequest';
import { getToken } from 'services/token';

const useJobRequests = () => {
  const isAuth = getToken() !== '';
  const { data, loading, error } = useQuery<JobRequests>(JOB_REQUESTS, {
    skip: !isAuth,
  });

  const jobRequests = useMemo(() => {
    return data?.jobRequests
      ? normalizeJobRequests(removeNulls(data.jobRequests))
      : [];
  }, [data]);

  return {
    error,
    jobRequests,
    loading,
  };
};

export default useJobRequests;
