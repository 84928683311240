import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { USER_PROFESSION } from 'graphql/queries/userProfession';
import { oneUserProfession } from 'graphql/generated/oneUserProfession';
import { getToken } from 'services/token';

const useProfession = () => {
  const isAuth = getToken() !== '';
  const {
    data,
    loading,
    refetch: refetchUserProfession,
    error,
  } = useQuery<oneUserProfession>(USER_PROFESSION, {
    skip: !isAuth,
  });

  const profession = useMemo(() => {
    return data;
  }, [data]);

  return {
    error,
    profession,
    loading,
    refetchUserProfession,
  };
};

export default useProfession;
