import { Box, BoxProps, Stack, Text } from '@chakra-ui/react';

interface Props extends BoxProps {
  title?: string;
  subtitle?: string;
  onboarding?: boolean;
}

export const SectionCard = ({
  title,
  subtitle,
  children,
  onboarding,
  ...props
}: Props) => (
  <Box bg="white" p={{ base: '4', md: '8' }} borderRadius="md" {...props}>
    <Stack spacing="4" mb="10">
      {title && (
        <Text as="h5" textStyle={{ base: 'h6', md: 'h5' }}>
          {title}{' '}
          {onboarding && (
            <Text as="span" color="red" fontSize="35px">
              *
            </Text>
          )}
        </Text>
      )}

      {subtitle && (
        <Text textStyle="small" color="gray.500">
          {subtitle}
        </Text>
      )}
    </Stack>
    {children}
  </Box>
);
