import {
  FullCourseParts,
  FullCourseParts_sections,
  FullCourseParts_sections_lessons,
} from 'graphql/generated/FullCourseParts';
import { WithoutNulls, removeNulls } from 'utils/removeNulls';
import { normalizeSkills } from 'model/Skill';

export const normalizeLesson = (
  input: WithoutNulls<FullCourseParts_sections_lessons>
) => ({
  id: input.id,
  name: input.name,
  vimeoId: input.vimeoId,
  durationMinutes: input.durationMinutes,
});

export type Lesson = ReturnType<typeof normalizeLesson>;

export const normalizeLessons = (
  input: (WithoutNulls<FullCourseParts_sections_lessons> | undefined)[]
) =>
  input.reduce<Lesson[]>(
    (acum, input) =>
      !!input ? acum.concat(normalizeLesson(removeNulls(input))) : acum,
    []
  );

export const normalizeSection = (
  input: WithoutNulls<FullCourseParts_sections>
) => ({
  id: input.id,
  name: input.name,
  durationMinutes: input.durationMinutes,
  lessons: normalizeLessons(input.lessons || []),
});

export type Section = ReturnType<typeof normalizeSection>;

export const normalizeSections = (
  input: (WithoutNulls<FullCourseParts_sections> | undefined)[]
) =>
  input.reduce<Section[]>(
    (acum, input) =>
      !!input ? acum.concat(normalizeSection(removeNulls(input))) : acum,
    []
  );

export const normalizeFullCourse = (input: WithoutNulls<FullCourseParts>) => ({
  id: input.id,
  name: input.name,
  description: input.description,
  teacherName: input.teacherName,
  durationMinutes: input.estimatedTimeMinutes,
  skills: normalizeSkills(input.skills || []),
  sections: normalizeSections(input.sections || []),
  repoUrl: input.repoUrl,
  quiz: input.quiz,
});

export type FullCourse = ReturnType<typeof normalizeFullCourse>;
