import { memo, useCallback } from 'react';
import { useField } from 'formik';
import {
  Box,
  BoxProps,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  HStack,
  Icon,
} from '@chakra-ui/react';
import { FiBell } from 'react-icons/fi';
import { FiLock } from 'react-icons/fi';
import { toEUR } from 'utils/normalizeSalary';

interface Props extends BoxProps {
  name: string;
  summarized?: boolean;
  seterrorSalary?: (isSend: any) => void;
  settouchedSalary?: (isSend: any) => void;
  touchedSalary?: boolean;
}

export const DesiredSalary = memo(
  ({
    name,
    summarized,
    touchedSalary,
    seterrorSalary,
    settouchedSalary,
    ...props
  }: Props) => {
    const [{ value = 15000 }, , { setValue }] = useField(name);

    const onChange = useCallback(
      (newValue) => {
        if (settouchedSalary) settouchedSalary(true);
        if (seterrorSalary && !touchedSalary) seterrorSalary(false);
        setValue(newValue);
      },
      [setValue, settouchedSalary, seterrorSalary, touchedSalary]
    );

    return (
      <Box {...props}>
        <Text textStyle="body" fontWeight="medium" mb={4}>
          {toEUR.format(value)} / año
        </Text>
        <Slider
          aria-label="slider-ex-1"
          value={value}
          name={name}
          min={15000}
          max={100000}
          step={1000}
          onChange={onChange}
          mb={8}
          colorScheme="brand"
        >
          <SliderTrack bg="gray.200">
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
        {!summarized && (
          <HStack spacing={2} mb={2}>
            <Icon as={FiBell} color="gray.300" />
            <Text textStyle="caption" color="gray.500">
              Sé realista, si pones una cifra muy alta, podrías perderte
              oportunidades interesantes.
            </Text>
          </HStack>
        )}
        {!summarized && (
          <HStack spacing={2}>
            <Icon as={FiLock} color="gray.300" />
            <Text textStyle="caption" color="gray.500">
              Las empresas no podrán ver esta información.
            </Text>
          </HStack>
        )}
      </Box>
    );
  }
);
