import { memo, useCallback, useState } from 'react';
import { useField } from 'formik';
import {
  Box,
  BoxProps,
  RadioGroup,
  Radio,
  Slider,
  SliderThumb,
  SliderTrack,
  SliderFilledTrack,
  Text,
  Grid,
  CheckboxGroup,
  Checkbox,
} from '@chakra-ui/react';
import { normalizeExperience } from 'utils/normalizeExperience';

interface Props extends BoxProps {
  name: string;
  subProfessions: any;
  settouched?: (isSend: any) => void;
  seterrorYearsExp?: (isSend: any) => void;
  touched?: boolean;
}

export const ExperienceYears = memo(
  ({
    subProfessions,
    name,
    settouched,
    seterrorYearsExp,
    touched,
    ...props
  }: Props) => {
    const [{ value = '' }, , { setValue }] = useField(name);

    const [
      { value: expYearsDeveloper = '' },
      ,
      { setValue: setExpYearsDeveloper },
    ] = useField('expYearsDeveloper');

    const [
      { value: expYearsManager = '' },
      ,
      { setValue: setExpYearsManager },
    ] = useField('expYearsManager');

    const [
      { value: subprofessionIds = [] },
      ,
      { setValue: setSubprofessionIds },
    ] = useField('subprofessionIds');

    const [isEdited, setisEdited] = useState(false);

    const onChange = useCallback(
      (newValue) => {
        if (settouched) settouched(true);
        if (seterrorYearsExp && !touched) seterrorYearsExp(false);
        setValue(newValue);
      },
      [setValue, seterrorYearsExp, settouched, touched]
    );

    const onChangeYearsDeveloper = useCallback(
      (newValue) => {
        if (settouched) settouched(true);
        if (seterrorYearsExp && !touched) seterrorYearsExp(false);
        setExpYearsDeveloper(newValue);
      },
      [setExpYearsDeveloper, settouched, seterrorYearsExp, touched]
    );

    const onChangeYearsManager = useCallback(
      (newValue) => {
        if (settouched) settouched(true);
        if (seterrorYearsExp && !touched) seterrorYearsExp(false);
        setExpYearsManager(newValue);
      },
      [setExpYearsManager, settouched, seterrorYearsExp, touched]
    );

    const onChangeSubprofession = useCallback(
      (newValue) => {
        let position = 0;
        if (subprofessionIds.length > 0)
          position = subprofessionIds.indexOf(newValue);
        if (position === -1 || subprofessionIds.length === 0) {
          subprofessionIds.push(newValue);
        } else subprofessionIds.splice(position, 1);
        setSubprofessionIds(subprofessionIds);
        if (!isEdited) setisEdited(true);
      },
      [setSubprofessionIds, subprofessionIds, isEdited]
    );

    if (subprofessionIds.length === 0 && !isEdited && value === '') return null;

    return (
      <Box {...props}>
        <RadioGroup value={value} onChange={onChange} name={name} mb="7">
          <Radio value="1" colorScheme="brand">
            Developer
          </Radio>
          <Radio value="2" ml="30%" colorScheme="brand">
            Manager
          </Radio>
        </RadioGroup>
        {value === '1' ? (
          <>
            <Text textStyle="body" fontWeight="medium" mb={4}>
              {normalizeExperience(expYearsDeveloper)}
            </Text>
            <Slider
              aria-label="slider-experience"
              value={expYearsDeveloper}
              name="expYearsDeveloper"
              min={0}
              max={10}
              onChange={onChangeYearsDeveloper}
              colorScheme="brand"
            >
              <SliderTrack bg="gray.200">
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </>
        ) : (
          <>
            <Text textStyle="body" fontWeight="medium" mb={4}>
              {normalizeExperience(expYearsManager)}
            </Text>
            <Slider
              aria-label="slider-experience"
              value={expYearsManager}
              name="expYearsManager"
              min={0}
              max={10}
              onChange={onChangeYearsManager}
              colorScheme="brand"
            >
              <SliderTrack bg="gray.200">
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>

            <Text mt="4" mb="4" textStyle="h6" color="gray.900">
              Posición
            </Text>
            <Grid templateColumns="repeat(2, 1fr)">
              <CheckboxGroup defaultValue={subprofessionIds}>
                {subProfessions?.allSubProfessions.map((subProfession: any) => {
                  return (
                    <Checkbox
                      mb="2"
                      onChange={() => onChangeSubprofession(subProfession.id)}
                      key={subProfession.id}
                      value={subProfession.id}
                      colorScheme="brand"
                    >
                      {subProfession.Name}
                    </Checkbox>
                  );
                })}
              </CheckboxGroup>
            </Grid>
          </>
        )}
      </Box>
    );
  }
);
