import { useMutation } from '@apollo/client';

import { ApplyOffer, ApplyOfferVariables } from 'graphql/generated/ApplyOffer';
import { APPLY_OFFER } from 'graphql/mutations/userJobApplicationActions';
import { useCallback } from 'react';
import { FlyncerError } from 'graphql/helpers';
import * as Sentry from '@sentry/react';
import { SANITIZEDJOBS } from 'graphql/queries/job';

const useApplyOfferActions = () => {
  const [applyOfferMutation, { loading: loadingApply }] = useMutation<
    ApplyOffer,
    ApplyOfferVariables
  >(APPLY_OFFER, {
    refetchQueries: [{ query: SANITIZEDJOBS }],
  });

  const applyOffer = useCallback(
    async (input: ApplyOfferVariables) => {
      try {
        await applyOfferMutation({ variables: input });
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo aplicar la oferta'));
        throw new FlyncerError('No se pudo aplicar la oferta', error);
      }
    },
    [applyOfferMutation]
  );

  return {
    applyOffer,
    loadingApply,
  };
};

export default useApplyOfferActions;
