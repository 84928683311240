import { gql } from '@apollo/client';
import { SKILL_FRAGMENT } from 'graphql/fragments/skill';

export const EXPERIENCE_FRAGMENT = gql`
  fragment ExperienceParts on Experience {
    empresa
    puesto
    funciones
    inicio
    fin
    skills {
      ...SkillParts
    }
    id
  }
  ${SKILL_FRAGMENT}
`;

export const EXPERIENCE_FRAGMENT2 = gql`
  fragment ExperienceParts2 on UserCvExperience {
    empresa
    puesto
    funciones
    inicio
    fin
    skills {
      ...SkillParts
    }
    id
  }
  ${SKILL_FRAGMENT}
`;
