import { gql } from '@apollo/client';

export const QUIZ_FRAGMENT = gql`
  fragment QuizParts on Quiz {
    id
    name
    course {
      id
    }
    questions {
      id
      content
      precode
      language
      isMultiple
      options {
        id
        content
      }
    }
  }
`;
