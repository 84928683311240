import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { MASS_MEDIAS } from 'graphql/queries/massMedia';
import { massMedias } from 'graphql/generated/massMedias';
import { removeNulls } from 'utils/removeNulls';
import { normalizeMassMedia, MassMedia } from 'model/MassMedia';

const useMassMedias = () => {
  const { data, loading, error } = useQuery<massMedias>(MASS_MEDIAS);

  const massMedias = useMemo(() => {
    return (data?.massMedias || []).reduce<MassMedia[]>(
      (acum, input) =>
        !!input ? acum.concat(normalizeMassMedia(removeNulls(input))) : acum,
      []
    );
  }, [data]);

  return {
    massMedias,
    loading,
    error,
  };
};

export default useMassMedias;
