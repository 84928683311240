import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react';
import { theme } from 'styles/theme';

export const toast = createStandaloneToast({ theme });

export const createToast = (props: UseToastOptions) =>
  toast({
    duration: 4000,
    isClosable: true,
    position: 'bottom-right',
    variant: 'subtle',
    ...props,
  });
