import {
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
} from '@chakra-ui/react';
import { forwardRef, ReactNode, useRef } from 'react';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { useField } from 'formik';

interface Props extends InputProps {
  label?: string;
  extraInfo?: ReactNode;
}

export const FormInputPassword = forwardRef<HTMLInputElement, Props>(
  ({ id, label, name = '', extraInfo, ...props }, ref) => {
    const [field, { error, touched }] = useField(name);
    const { isOpen, onToggle } = useDisclosure();
    const inputRef = useRef<HTMLInputElement>(null);

    const isInvalid = touched && !!error;

    const mergeRef = useMergeRefs(inputRef, ref);

    const onClickReveal = () => {
      onToggle();
      const input = inputRef.current;
      if (input) {
        input.focus({ preventScroll: true });
        const length = input.value.length * 2;
        requestAnimationFrame(() => {
          input.setSelectionRange(length, length);
        });
      }
    };

    return (
      <FormControl id={id || name} isInvalid={isInvalid}>
        <Flex justify="space-between" align="baseline" mb="2">
          {label && <FormLabel textStyle="small">{label}</FormLabel>}
          {extraInfo}
        </Flex>
        <InputGroup>
          <InputRightElement>
            <IconButton
              bg="transparent !important"
              variant="ghost"
              aria-label={
                isOpen ? 'Enmascarar contraseña' : 'Revelar contraseña'
              }
              icon={isOpen ? <HiEyeOff /> : <HiEye />}
              onClick={onClickReveal}
            />
          </InputRightElement>
          <Input
            ref={mergeRef}
            type={isOpen ? 'text' : 'password'}
            focusBorderColor="brand.500"
            {...props}
            {...field}
          />
        </InputGroup>
        {isInvalid && (
          <FormErrorMessage mt="1" textStyle="caption">
            {error}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  }
);
