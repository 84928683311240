import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { MeCV } from 'graphql/generated/MeCV';
import { ME_CV } from 'graphql/queries/meCV';
import { removeNulls } from 'utils/removeNulls';
import { normalizeCV } from 'model/userCV';
import { getToken } from 'services/token';

const useMeCV = () => {
  const isAuth = getToken() !== '';
  const { data, loading, refetch, error } = useQuery<MeCV>(ME_CV, {
    skip: !isAuth,
  });

  const meCV = useMemo(
    () => (data ? normalizeCV(removeNulls(data.meCV)) : undefined),
    [data]
  );
  return {
    error,
    meCV,
    loading,
    refetch,
  };
};

export default useMeCV;
