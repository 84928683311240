import {
  Text,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
} from '@chakra-ui/react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  closeOffer: () => void;
}

export const CloseOfferModal = ({ isOpen, onClose, closeOffer }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent justifyContent="center">
        <ModalHeader>¿Seguro que deseas cerrar la oferta?</ModalHeader>
        <ModalBody>
          <Text mb="6">
            Si cierras la oferta no podrás volverla a abrir a no ser que
            contactes con uno de nuestros comerciales.
          </Text>
          <Flex justifyContent="flex-end" mb="4">
            <Button
              colorScheme="green"
              variant="outline"
              mr="4"
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                closeOffer();
              }}
            >
              Cerrar oferta
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
