import { gql } from '@apollo/client';
import { LOCATION_FRAGMENT } from 'graphql/fragments/location';
import { CITY_FRAGMENT } from './city';
import { EXPERIENCE_FRAGMENT } from './userCVExperience';

export const POSITION_FRAGMENT = gql`
  fragment PositionParts on Position {
    id
    name
    description
    salaryMin
    salaryMax
    experienceYears
    profession
    bonosCandidates
    subprofessions
    company {
      id
      name
      logo {
        url
      }
      type
    }
    locations {
      ...LocationParts
    }
    countries {
      id
      name
    }
    resolutions {
      id
      isAccepted
      new
      cancelReason
      user {
        id
        uuid
        email
        phoneNumber
        name
        professions {
          experienceYears
          profession {
            name
          }
          startDate
        }
        user_cv {
          experiences {
            ...ExperienceParts
          }
        }
        linkedinURL
        githubURL
        englishLevel
        cv {
          name
        }
        desiredLocations {
          name
        }
        desiredCountries {
          id
          name
        }
        desiredCities {
          name
        }
        careers {
          activePaths {
            path {
              name
            }
            preLevel {
              name
            }
            level {
              name
            }
          }
        }
        completed_profile
        updated_at
        lastActive
      }
    }
    cities {
      ...CityParts
    }
    skills {
      paths {
        id
        name
        level {
          id
          name
          order
        }
        order
      }
    }
    seenUsers {
      id
    }
    notifiedUsers {
      id
    }
    user_job_applications {
      id
      new
      isDiscarded
      isMatch
      user {
        id
        uuid
        email
        phoneNumber
        name
        professions {
          experienceYears
          profession {
            name
          }
          startDate
        }
        user_cv {
          experiences {
            ...ExperienceParts
          }
        }
        linkedinURL
        githubURL
        englishLevel
        cv {
          name
        }
        desiredLocations {
          name
        }
        desiredCountries {
          id
          name
        }
        desiredCities {
          name
        }
        careers {
          activePaths {
            path {
              name
            }
            preLevel {
              name
            }
            level {
              name
            }
          }
        }
        resolution {
          id
          isAccepted
          new
          cancelReason
        }
        completed_profile
        updated_at
        lastActive
      }
    }
    state
    created_at
    showPrelevelMatches
  }
  ${CITY_FRAGMENT}
  ${EXPERIENCE_FRAGMENT}
  ${LOCATION_FRAGMENT}
`;

export const ONLY_POSITION_FRAGMENT = gql`
  fragment OnlyPositionParts on OnlyPosition {
    id
    name
    description
    salaryMin
    salaryMax
    experienceYears
    profession
    bonosCandidates
    subprofessions
    company {
      id
      name
      logo {
        url
      }
      type
    }
    locations {
      ...LocationParts
    }
    countries {
      id
      name
    }
    resolutions {
      id
      isAccepted
      new
      cancelReason
      user {
        id
        uuid
        email
        phoneNumber
        name
        professions {
          experienceYears
          profession {
            name
          }
          startDate
        }
        user_cv {
          experiences {
            ...ExperienceParts
          }
        }
        linkedinURL
        githubURL
        englishLevel
        cv {
          name
        }
        desiredLocations {
          name
        }
        desiredCountries {
          id
          name
        }
        desiredCities {
          name
        }
        careers {
          activePaths {
            path {
              name
            }
            preLevel {
              name
            }
            level {
              name
            }
          }
        }
        completed_profile
        updated_at
        lastActive
      }
    }
    cities {
      ...CityParts
    }
    skills {
      paths {
        id
        name
        level {
          id
          name
          order
        }
        order
      }
    }
    seenUsers {
      id
    }
    notifiedUsers {
      id
    }
    state
    created_at
    showPrelevelMatches
  }
  ${CITY_FRAGMENT}
  ${EXPERIENCE_FRAGMENT}
  ${LOCATION_FRAGMENT}
`;

export const CANDIDATES = gql`
  fragment CandidatesParts on Candidates {
    list {
      id
      uuid
      email
      phoneNumber
      name
      professions {
        experienceYears
        profession {
          name
        }
        startDate
      }
      user_cv {
        experiences {
          ...ExperienceParts
        }
      }
      linkedinURL
      githubURL
      englishLevel
      cv {
        name
      }
      desiredLocations {
        name
      }
      desiredCountries {
        name
      }
      desiredCities {
        name
      }
      careers {
        activePaths {
          path {
            name
          }
          level {
            name
          }
          preLevel {
            name
          }
        }
      }
      resolution {
        id
        isAccepted
        new
        cancelReason
      }
      completed_profile
      updated_at
      lastActive
    }
  }
  ${EXPERIENCE_FRAGMENT}
`;

export const JOB_FRAGMENT = gql`
  fragment JobParts on Job {
    id
    position {
      ...PositionParts
    }
    candidates {
      ...CandidatesParts
    }
  }
  ${POSITION_FRAGMENT}
  ${CANDIDATES}
`;

export const SANITIZED_JOB_FRAGMENT = gql`
  fragment PositionSanitizedParts on PositionSanitized {
    id
    name
    description
    salaryMin
    salaryMax
    profession
    experienceYears
    subprofessions
    locations {
      id
      name
    }
    cities {
      id
      name
    }
    countries {
      id
      name
    }
    company {
      id
      name
      logo {
        url
      }
      type
    }
    skills {
      paths {
        id
        name
        level {
          order
          id
          name
        }
        order
        career
      }
    }
    state
    created_at
    user_job_applications {
      id
      user
      isDiscarded
    }
  }
`;
