import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
  Button,
  HStack,
  Spinner,
} from '@chakra-ui/react';
import { FormInput } from 'components/FormInput';
import { Form, Formik } from 'formik';
import { FiLinkedin } from 'react-icons/fi';

interface Props {
  initialValues: any;
  isOpen: boolean;
  loadImport: boolean;
  errorImport: boolean;
  orderedExpLength: number;
  setisOpen: (isOpen: boolean) => void;
  handleSubmit1: (values: any) => void;
  handleDelete: () => void;
}

export const ModalLinkedin = ({
  isOpen,
  initialValues,
  loadImport,
  setisOpen,
  errorImport,
  handleSubmit1,
  orderedExpLength,
  handleDelete,
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setisOpen(false);
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {orderedExpLength === 0 ? 'Importa' : 'Actualiza'} tus experiencias
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik initialValues={initialValues} onSubmit={handleSubmit1}>
            <Form>
              <HStack>
                <FormInput
                  name="linkedinURL"
                  placeholder="URL de tu perfil en Linkedin"
                  rightElement={{
                    children: <Icon as={FiLinkedin} color="linkedin.500" />,
                  }}
                  value={initialValues?.linkedinURL}
                />
                <Button
                  colorScheme="brand"
                  mt="6"
                  mb="10"
                  type="submit"
                  variant="solid"
                  paddingX="40px"
                  onClick={() => {
                    if (orderedExpLength !== 0) {
                      handleDelete();
                      handleSubmit1(initialValues);
                    }
                  }}
                >
                  {orderedExpLength === 0 ? 'Importar' : 'Actualizar'}
                </Button>
                {loadImport && !errorImport && (
                  <Spinner color="brand.500" padding="10px" />
                )}
              </HStack>
            </Form>
          </Formik>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
