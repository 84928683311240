import { Link as RouterLink } from 'react-router-dom';

import {
  Switch,
  Box,
  Flex,
  Heading,
  IconButton,
  Image,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  Spacer,
  HStack,
} from '@chakra-ui/react';
import { FiEye } from 'react-icons/fi';
import useCompanies from 'graphql/hooks/company/useCompany';
import { normalizeDate } from 'utils/normalizeDate';
import { normalizeExperience } from 'utils/normalizeExperience';
import { removeNulls } from 'utils/removeNulls';
import { useJobs } from 'graphql/hooks/admin/useJobs';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { NoOffersContainer } from './noOffersContainer';
import { SanitizeContainer } from './createOffer/sanitizeContainers';

export const Jobs = () => {
  const { company, error } = useCompanies();
  const { allJobs, error: jobsError } = useJobs();
  const data = localStorage.getItem('showClosed');
  const [showClosed, setShowClosed] = useState(data === 'on' ? true : false);

  const handleSwitch = () => {
    if (showClosed) {
      localStorage.setItem('showClosed', 'off');
      setShowClosed(!showClosed);
    } else {
      localStorage.setItem('showClosed', 'on');
      setShowClosed(!showClosed);
    }
  };

  if (error || jobsError) {
    let message = error ? error : jobsError;
    Sentry.captureException(message?.graphQLErrors[0]);
  }

  if (!company || !allJobs) return null;

  //----ORDENAR LAS OFERTAS POR FECHA Y LAS CERRADAS AL FINAL----
  const jobPositions = removeNulls(allJobs);
  const openPositions = jobPositions.filter((job) => job.state !== 'closed');
  const closedPositions = jobPositions.filter((job) => job.state === 'closed');

  openPositions.sort((a, b) => {
    if (a.created_at < b.created_at) return 1;
    if (a.created_at > b.created_at) return -1;
    return 0;
  });

  let orderedJobPositions = openPositions;

  if (showClosed) orderedJobPositions = openPositions.concat(closedPositions);
  //--------------------------------------------------------------

  const isSuperAdmin = company.superAdmin;

  if (!allJobs.length && !isSuperAdmin) {
    return (
      <Box as="section" py="12">
        <Box
          maxW={{ base: 'xl', md: '7xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
        >
          {company.logo && (
            <Box>
              <Image src={company.logo?.url} h="100px" mb="5" />
            </Box>
          )}
          <Heading>Tus ofertas</Heading>
          <NoOffersContainer company={company} />
        </Box>
      </Box>
    );
  }

  return (
    <Box as="section" py="12">
      <Box
        maxW={{ base: 'xl', md: '7xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
      >
        {!isSuperAdmin && company.logo && (
          <Box>
            <Image src={company.logo?.url} h="100px" mb="5" />
          </Box>
        )}

        <Flex w="full">
          <Box>
            <Heading>
              {isSuperAdmin ? 'Todas las ofertas' : 'Tus ofertas'}
            </Heading>
          </Box>
          <Spacer />
          <HStack spacing={2} alignItems="flex-end">
            <Text color="gray.500" fontWeight="regular">
              Mostrar ofertas cerradas
            </Text>
            <Switch
              colorScheme="brand"
              onChange={() => handleSwitch()}
              isChecked={showClosed}
              size="lg"
            />
          </HStack>
        </Flex>

        <Box
          overflowX="auto"
          borderRadius="8px"
          my="8"
          borderRight="1px solid #EBEFF4"
          borderLeft="1px solid #EBEFF4"
        >
          <Table bg="white" fontSize="sm">
            <Thead bg="#EBEFF4">
              <Tr>
                <Th>Estado</Th>
                <Th>Fecha</Th>
                <Th>Oferta</Th>
                <Th>Novedades Candidatos</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {orderedJobPositions?.map((job: any) => {
                if (!job?.user_job_applications || !job?.resolutions)
                  return null;
                const newInscribed = job.user_job_applications.filter(
                  (u: any) => u?.new
                );

                const cities = job.restrictedCities.map(
                  (city: any) => city.name
                );

                const newUsers = job.resolutions.filter((u: any) => u?.new);
                const active = job.state === 'active';
                const invisible = job.state === 'invisible';
                const slot = job.state === 'slot';

                const hasRemote = job.restrictedCountries.filter(
                  (country: any) => country.name === 'Remoto'
                );

                return (
                  <Tr key={job?.id}>
                    <Td minW="13vh">
                      {!invisible && (
                        <Tag
                          colorScheme={active ? 'brand' : slot ? 'blue' : 'red'}
                        >
                          {active ? 'Activa' : slot ? 'Slot' : 'Cerrada'}
                        </Tag>
                      )}
                    </Td>
                    <Td>
                      <Text color="gray.400" fontSize="12">
                        {job?.created_at && normalizeDate(job?.created_at)}
                      </Text>
                    </Td>
                    <Td>
                      <VStack align="left">
                        {isSuperAdmin && (
                          <Flex>
                            <Image
                              src={job.company?.logo?.url}
                              height="20px"
                              mr="1"
                            />
                            <Text
                              fontWeight="medium"
                              width="auto"
                              fontSize="12"
                            >
                              {job?.company?.name}
                            </Text>
                          </Flex>
                        )}
                        <Text fontWeight="bold" fontSize="18">
                          {job?.name}
                        </Text>

                        <Text color="gray.400" fontSize="12">
                          {!hasRemote.length ? cities.join(' / ') : 'Remoto'}
                        </Text>

                        <Text color="gray.400" fontSize="12">
                          {normalizeExperience(job.profession?.experienceYears)}
                        </Text>
                      </VStack>
                    </Td>
                    <Td>
                      {newUsers.length ? (
                        <Tag color="gray.400" fontSize="12">
                          Base de datos !
                        </Tag>
                      ) : null}
                      {newInscribed.length ? (
                        <Tag color="gray.400" fontSize="12" ml="2">
                          Inscritos !
                        </Tag>
                      ) : null}
                      {!newUsers.length && !newInscribed.length && (
                        <Text color="gray.400" fontSize="12">
                          Sin novedades
                        </Text>
                      )}
                    </Td>
                    <Td>
                      {active || invisible || slot ? (
                        <RouterLink
                          to={
                            !invisible
                              ? `/company/job/inscribed/${job?.id}`
                              : `/company/mistery/job/${job?.id}`
                          }
                        >
                          <IconButton
                            icon={<FiEye />}
                            aria-label="Ver oferta"
                            colorScheme="brand"
                          />
                        </RouterLink>
                      ) : null}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>

        <SanitizeContainer company={company} />
      </Box>
    </Box>
  );
};
