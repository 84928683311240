import {
  Box,
  Button,
  Circle,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useMediaQuery,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { FlyncerSvg } from 'components/FlyncerSvg';
import { useCallback, useState } from 'react';
import { levels } from '../../../../../utils/preLevels';
import { CloseIcon } from '@chakra-ui/icons';
import { normalizeLevel } from 'utils/normalizeLevel';
import { PrelevelGuide } from 'components/PrelevelGuide';
import { FormCheckbox } from 'components/FormCheckbox';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import useConnect from '../../connect';
import { PreLevelSvg } from './preLevelSvg';
import { useHistory } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const schema = Yup.object().shape({
  dataConsent: Yup.boolean()
    .required('La empresa necesita tus datos para contactarte')
    .oneOf([true], 'La empresa necesita tus datos para contactarte'),
});

const initialValues = {
  dataConsent: false,
};

export const PrelevelModal = ({ isOpen, onClose }: Props) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { handleAccept, jobSkills, id } = useConnect();
  const [pathsValues, setPathsValues] = useState(jobSkills);
  const { replace } = useHistory();
  const changeLevel = useCallback(
    (id: string | undefined, level: string | undefined) => {
      if (!pathsValues) return;

      let updatedValue: any;

      pathsValues.forEach((pathGroup: any) => {
        if (pathGroup.find((path: any) => path.pathId === id)) {
          updatedValue = pathGroup.find((path: any) => path.pathId === id);
        }
      });

      if (updatedValue) {
        updatedValue.preLevel = level;

        setPathsValues([...pathsValues, updatedValue]);
        setPathsValues(
          pathsValues.filter(
            (path: any, index: any) => pathsValues.indexOf(path) === index
          )
        );
      }
    },
    [pathsValues, setPathsValues]
  );

  const handleClose = useCallback(() => {
    replace(`/jobsOffer/${id}`);
    onClose();
  }, [replace, id, onClose]);

  const totalPaths = pathsValues
    .map((pathGroup: any) => pathGroup.length)
    .reduce(
      (previousValue: number, currentValue: number) =>
        previousValue + currentValue
    );

  const isDisabled = !pathsValues.every((pathGroup: any) =>
    pathGroup.some((path: any) => path.preLevel || path.level)
  );

  return (
    <Modal isOpen onClose={handleClose} size={totalPaths < 3 ? '2xl' : '3xl'}>
      <ModalOverlay />
      <ModalContent p={10}>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <PreLevelSvg />
            <Text fontSize="17" fontWeight="bold" textAlign="center">
              Añade tu nivel en las siguientes tecnologías para aplicar a la
              oferta
            </Text>

            <PrelevelGuide />
            <Wrap spacing="20px" justify="center">
              {pathsValues.map((pathGroup: any, index: number) => (
                <HStack bg="#EBEFF4" py={4} key={index}>
                  {pathGroup.map((path: any, index: number) => {
                    return (
                      <>
                        <Box
                          px={8}
                          bg="#EBEFF4"
                          maxW="48"
                          minW="48"
                          key={index}
                        >
                          <Text fontWeight="semibold">{path.name}</Text>
                          {path.level ? (
                            <HStack fontSize={12} spacing={1}>
                              <Text>Nivel {normalizeLevel(path.level)}</Text>
                              <FlyncerSvg />
                            </HStack>
                          ) : path.preLevel ? (
                            <HStack fontSize={12} spacing={1}>
                              <Text>Nivel </Text>
                              <Circle
                                size="21px"
                                cursor="pointer"
                                color="gray.400"
                                bg="#D8DFEA"
                                borderWidth="1px"
                                borderColor="gray.400"
                              >
                                {path.preLevel}
                              </Circle>
                              <CloseIcon
                                cursor="pointer"
                                w={2}
                                h={2}
                                onClick={() =>
                                  changeLevel(path.pathId, undefined)
                                }
                              />
                            </HStack>
                          ) : (
                            <HStack fontSize={12} spacing={1} key={index}>
                              <Text>Nivel</Text>
                              {levels.map((level, index) => (
                                <Circle
                                  key={index}
                                  size="21px"
                                  cursor="pointer"
                                  color="gray.400"
                                  bg="white"
                                  borderWidth="1px"
                                  borderColor="gray.400"
                                  onClick={() =>
                                    changeLevel(path.pathId, level)
                                  }
                                >
                                  {level}
                                </Circle>
                              ))}
                            </HStack>
                          )}
                        </Box>
                        {index !== pathGroup.length - 1 && (
                          <Box
                            bg="black"
                            h="full"
                            borderWidth="0.5px"
                            borderColor="gray.400"
                          ></Box>
                        )}
                      </>
                    );
                  })}
                </HStack>
              ))}
            </Wrap>

            <Formik
              initialValues={initialValues}
              onSubmit={() => handleAccept(pathsValues)}
              validationSchema={schema}
            >
              <Form>
                <Wrap align="center" spacing={isMobile ? '20px' : '1px'}>
                  <WrapItem w="100px">
                    <Button
                      id="applyButton"
                      colorScheme="brand"
                      type="submit"
                      isDisabled={isDisabled}
                    >
                      Aplicar
                    </Button>
                  </WrapItem>
                  <WrapItem w={isMobile ? '300px' : '390px'}>
                    <FormCheckbox name="dataConsent">
                      Acepto que mis datos sean facilitados a la empresa.
                    </FormCheckbox>
                  </WrapItem>
                </Wrap>
              </Form>
            </Formik>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
