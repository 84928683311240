import { gql } from '@apollo/client';

export const APPLY_OFFER = gql`
  mutation ApplyOffer($input: ApplyOfferInput!) {
    applyOffer(input: $input)
  }
`;

export const EDIT_DISCARDED = gql`
  mutation editDiscarded($jobApplicationId: ID!, $type: String) {
    editDiscarded(jobApplicationId: $jobApplicationId, type: $type)
  }
`;
