import { Box, Divider } from '@chakra-ui/react';
import { OneJob } from 'graphql/hooks/admin/useJob';
import { useParams } from 'react-router-dom';

import { JobHeader } from 'components/JobHeader';
import { Markdown } from 'components/Markdown';
import { quizRenderer } from 'components/Markdown/QuizRenderer';
import * as Sentry from '@sentry/react';
import { sanitizeDate } from 'utils/sanitizeDate';

export const JobDescription = () => {
  const { id } = useParams<{ id: string }>();
  const { job, loading, error } = OneJob({ id });

  if (error) Sentry.captureException(error?.graphQLErrors[0]);

  if (loading || !job) return null;

  let locations;

  locations = job.countries.filter((country: any) => country.name === 'Remoto');

  locations = locations.map((location: any) => location.name);

  if (!locations.length)
    locations = job.cities.map((loc: any) => {
      return loc.name;
    });

  const salary = { min: job.salaryMin, max: job.salaryMax };
  const created_at = sanitizeDate(job.created_at);

  return (
    <>
      <Box
        as="main"
        mx="auto"
        w="100%"
        maxW="50rem"
        mt="12"
        px={{ base: '4', md: '6' }}
      ></Box>

      <JobHeader
        created_at={created_at}
        title={job.name}
        company={job.company}
        skills={job.skills}
        experienceYears={job.experienceYears}
        location={locations}
        salary={salary}
        buttons={false}
      >
        <Divider my="8" color="gray.200" />

        <Markdown source={job.description} renderers={quizRenderer()} />
      </JobHeader>
    </>
  );
};
