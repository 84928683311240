export const sanitizeDate = (update: any) => {
  const today = new Date();
  const lastUpdate = new Date(update);

  const lastUpdateMonthDays = new Date(
    lastUpdate.getFullYear(),
    lastUpdate.getMonth() + 1,
    0
  ).getDate();

  let date: string = '';

  if (
    lastUpdate.getDate() === today.getDate() &&
    lastUpdate.getMonth() === today.getMonth()
  )
    date = ' hoy';
  else if (today.getMonth() - lastUpdate.getMonth() === 0) {
    date = ` hace ${
      Math.abs(today.getDate() - lastUpdate.getDate()) !== 1
        ? `${Math.abs(today.getDate() - lastUpdate.getDate())} días`
        : `${Math.abs(today.getDate() - lastUpdate.getDate())} día`
    }`;
  } else if (
    Math.abs(today.getMonth() - lastUpdate.getMonth()) !== 0 &&
    Math.abs(today.getDate() - lastUpdate.getDate()) >= 0
  ) {
    let daysLastLeft = 0;
    daysLastLeft = lastUpdateMonthDays - lastUpdate.getDate();

    if (
      today.getDate() + daysLastLeft < 31 &&
      Math.abs(today.getMonth() - lastUpdate.getMonth()) === 1
    )
      date = ` hace ${
        Math.abs(today.getDate() + daysLastLeft) !== 1
          ? `${Math.abs(today.getDate() + daysLastLeft)} días`
          : `${Math.abs(today.getDate() + daysLastLeft)} día`
      }`;
    else date = ' hace más de un mes';
  }

  return date;
};

export const sanitizeDate2 = (user: any) => {
  const today = new Date();
  let date: any;
  if (user.professions) date = new Date(user.professions[0].startDate);

  let years = today.getFullYear() - date?.getFullYear();

  if (today.getMonth() < date?.getMonth()) years--;
  else if (today.getMonth() === date?.getMonth()) {
    if (today.getDate() < date?.getDate()) years--;
  }

  return years;
};

export const sanitizeDate3 = (me: any) => {
  const today = new Date();
  let date: any;
  if (me?.professions) date = new Date(me?.professions[0]?.startDate);
  let years = today.getFullYear() - date?.getFullYear();

  if (
    me?.professions &&
    me?.professions[0]?.experienceYears !== null &&
    years - 1 >= 0
  ) {
    if (today.getMonth() < date?.getMonth()) years--;
    else if (today.getMonth() === date?.getMonth()) {
      if (today.getDate() < date?.getDate()) years--;
    }
  } else years = 0;

  return years;
};

export const sanitizeDateExp = (values: any) => {
  let fechaInicio, fechaFin, fechaActual;
  let isValid = false;

  if (values.inicio !== null && values.inicio !== undefined) {
    fechaActual = new Date();
    fechaInicio = new Date(values.inicio);
    if (values.fin) fechaFin = new Date(values.fin);
  }

  if (
    (fechaInicio !== undefined &&
      fechaActual !== undefined &&
      fechaFin !== undefined &&
      fechaInicio < fechaFin &&
      (fechaActual > fechaInicio ||
        (fechaActual.getDay() === fechaInicio.getDay() &&
          fechaActual.getMonth() === fechaInicio.getMonth() &&
          fechaActual.getFullYear() === fechaInicio.getFullYear()))) ||
    (fechaInicio !== undefined &&
      fechaActual !== undefined &&
      fechaFin === undefined &&
      (fechaActual > fechaInicio ||
        (fechaActual.getDay() === fechaInicio.getDay() &&
          fechaActual.getMonth() === fechaInicio.getMonth() &&
          fechaActual.getFullYear() === fechaInicio.getFullYear())))
  ) {
    isValid = true;
  }

  return isValid;
};
