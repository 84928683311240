import { useCallback } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { useDisclosure, Button, HStack } from '@chakra-ui/react';
import { FormCheckbox } from 'components/FormCheckbox';
import { ApplyModal } from '../ApplyModal';

interface Props {
  companyName: string;
  handleAccept: () => void;
  isAccepted?: boolean;
}

const schema = Yup.object().shape({
  dataConsent: Yup.boolean()
    .required('La empresa necesita tus datos para contactarte.')
    .oneOf([true], 'La empresa necesita tus datos para contactarte.'),
});

const initialValues = {
  dataConsent: false,
};

export const AcceptButtonWithModal = ({
  companyName,
  handleAccept,
  isAccepted,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSubmit = useCallback(async () => {
    try {
      await handleAccept();
      onOpen();
    } catch {}
  }, [onOpen, handleAccept]);

  return (
    <>
      {!isAccepted && (
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <HStack spacing="4" mt="8" mb="4" alignItems="center">
                <Button
                  id="accepOffer-button"
                  type="submit"
                  colorScheme="brand"
                  isDisabled={isSubmitting}
                >
                  Aceptar
                </Button>
                <FormCheckbox name="dataConsent">
                  Acepto que mis datos sean facilitados a la empresa.
                </FormCheckbox>
              </HStack>
            </Form>
          )}
        </Formik>
      )}

      <ApplyModal isOpen={isOpen} onClose={onClose} companyName={companyName} />
    </>
  );
};
