import { useCallback } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import {
  CREATE_USER_CAREER,
  UPDATE_USER_CAREER,
  UPDATE_USER_CAREER_MAIN,
  DELETE_USER_CAREER,
} from 'graphql/mutations/userCareer';
import { FlyncerError } from 'graphql/helpers';
import { AddCareer, AddCareerVariables } from 'graphql/generated/AddCareer';
import {
  UpdateMeCareer,
  UpdateMeCareerVariables,
} from 'graphql/generated/UpdateMeCareer';
import {
  UpdateMeCareerMain,
  UpdateMeCareerMainVariables,
} from 'graphql/generated/UpdateMeCareerMain';
import {
  DeleteMeCareer,
  DeleteMeCareerVariables,
} from 'graphql/generated/DeleteMeCareer';
import {
  AddCareerInput,
  UpdateCareerInput,
  UpdateCareerMainInput,
  deleteUserCareerInput,
} from 'graphql/generated/globalTypes';
import { FullCareer } from 'graphql/generated/FullCareer';
import { FULL_CAREER } from 'graphql/queries/fullCareer';
import useUserLevel from './useUserLevel';
import { GET_USER_PATHS, USER_LEVEL_FROM_PATH } from 'graphql/queries/userPath';
import useFullItineraryActions from 'graphql/hooks/career/useFullItinerayActions';
import { ME } from 'graphql/queries/me';
import * as Sentry from '@sentry/react';
import useMe from 'graphql/hooks/profile/useMe';
import { JOBS_MATCH_USER } from 'graphql/queries/job';

const useUserCareerActions = () => {
  const { completeItinerary } = useFullItineraryActions();
  const client = useApolloClient();
  const { refetchLevel } = useUserLevel();
  const { me } = useMe();

  const [createMutation] = useMutation<AddCareer, AddCareerVariables>(
    CREATE_USER_CAREER,
    {
      refetchQueries: [
        { query: ME },
        { query: GET_USER_PATHS, variables: { idUser: me?.id } },
      ],
    }
  );

  const create = useCallback(
    async (input: AddCareerInput) => {
      try {
        const response = await createMutation({ variables: { input } });

        input.activeItineraries?.forEach((itinerary) => {
          if (itinerary !== null) {
            completeItinerary({ input: itinerary });
          }
        });

        const fullCareer = response.data?.addCareer;
        refetchLevel();

        if (fullCareer) {
          client.cache.writeQuery<FullCareer>({
            data: {
              fullCareer,
            },
            query: FULL_CAREER,
          });
        }

        return fullCareer;
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo crear'));
        throw new FlyncerError('No se pudo crear', error);
      }
    },
    [createMutation, client, refetchLevel, completeItinerary]
  );

  const [updateMutation, { loading: updateLoading }] = useMutation<
    UpdateMeCareer,
    UpdateMeCareerVariables
  >(UPDATE_USER_CAREER, {
    refetchQueries: [
      { query: FULL_CAREER },
      { query: JOBS_MATCH_USER },

      { query: GET_USER_PATHS, variables: { idUser: me?.id } },
    ],
  });
  const update = useCallback(
    async (input: UpdateCareerInput) => {
      try {
        const response = await updateMutation({ variables: { input } });
        input.data?.activeItineraries?.forEach((itinerary) => {
          if (itinerary !== null) completeItinerary({ input: itinerary });
        });
        const fullCareer = response.data?.updateMeCareer;
        refetchLevel();

        if (fullCareer) {
          client.cache.writeQuery<FullCareer>({
            data: {
              fullCareer,
            },
            query: FULL_CAREER,
          });
        }

        return fullCareer;
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo actualizar'));
        throw new FlyncerError('No se pudo actualizar', error);
      }
    },
    [updateMutation, client, refetchLevel, completeItinerary]
  );

  const [updateMainMutation] = useMutation<
    UpdateMeCareerMain,
    UpdateMeCareerMainVariables
  >(UPDATE_USER_CAREER_MAIN);
  const updateMain = useCallback(
    async (input: UpdateCareerMainInput) => {
      try {
        const response = await updateMainMutation({ variables: { input } });
        const fullCareer = response.data?.updateMeCareerMain;

        if (fullCareer) {
          client.cache.writeQuery<FullCareer>({
            data: {
              fullCareer,
            },
            query: FULL_CAREER,
          });
        }
      } catch (error: any) {
        Sentry.captureException(
          new Error('No se pudo actualizar la carrera principal')
        );
        throw new FlyncerError(
          'No se pudo actualizar la carrera principal',
          error
        );
      }
    },
    [updateMainMutation, client]
  );

  const [deleteMutation] = useMutation<DeleteMeCareer, DeleteMeCareerVariables>(
    DELETE_USER_CAREER,
    {
      refetchQueries: [
        { query: USER_LEVEL_FROM_PATH },
        { query: JOBS_MATCH_USER },

        { query: GET_USER_PATHS, variables: { idUser: me?.id } },
      ],
    }
  );
  const remove = useCallback(
    async (input: deleteUserCareerInput) => {
      try {
        const response = await deleteMutation({ variables: { input } });
        const fullCareer = response.data?.deleteMeCareer;
        if (fullCareer) {
          client.cache.writeQuery<FullCareer>({
            data: {
              fullCareer,
            },
            query: FULL_CAREER,
          });
        }
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo borrar la carrera'));
        throw new FlyncerError('No se pudo borrar', error);
      }
    },
    [deleteMutation, client]
  );

  return {
    updateLoading,
    create,
    update,
    updateMain,
    remove,
  };
};

export default useUserCareerActions;
