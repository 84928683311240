import {
  Avatar,
  Box,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  VStack,
} from '@chakra-ui/react';
import useFileActions from 'graphql/hooks/profile/useCVActions';
import { ChangeEvent, useCallback, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { FiEdit2 } from 'react-icons/fi';
import { createToast } from 'services/toast';
import useConnect from '../connect';

export const AvatarContainer = () => {
  const { me } = useConnect();
  const { uploadPhoto, deletePhoto } = useFileActions();
  const [isUpload, setIsUpload] = useState(false);
  const [enter, setEnter] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleUploadPhoto = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        if (event.target.files[0].size < 1000000) {
          setIsUpload(true);
          await uploadPhoto({ file: event.target.files[0] }).then(() => {
            setIsUpload(false);
          });
        } else {
          createToast({
            title: 'Error al subir la imagen',
            description: 'La imagen debe de pesar menos de 1MB.',
            status: 'error',
          });
        }
      }
    },
    [uploadPhoto]
  );

  if (!me) return null;

  return (
    <>
      {!isUpload && (
        <Box>
          <Box position="relative" mr={{ lg: '6' }} display="flex">
            <Avatar
              cursor="pointer"
              size="xl"
              borderRadius="lg"
              src={!me?.photo ? '/fallback-avatar.png' : me?.photo.url}
            />
            <Box
              onMouseOver={() => setEnter(true)}
              onMouseLeave={() => setEnter(false)}
              cursor="pointer"
              bg={enter ? '#3A3939' : ''}
              borderRadius="lg"
              opacity=".6"
              display="grid"
              textAlign="center"
              onClick={() => {
                if (enter) setIsOpen(true);
              }}
              position="absolute"
              top="0"
              left="0"
              bottom="0"
              right="0"
            />
            <Box
              pointerEvents="none"
              position="absolute"
              left="50%"
              top="50%"
              transform="translate(-50%,-50%)"
            >
              <FiEdit2
                color={enter ? 'white' : 'transparent'}
                fontSize="35px"
              />
            </Box>
          </Box>
        </Box>
      )}
      {isUpload && <Spinner size="xl" mr="3" color="brand.500" />}

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <ModalOverlay />
        <ModalContent width="240px" height="230px" pb="8">
          <ModalHeader>Foto de perfil</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack spacing="0" height="100%" alignItems="flex-end">
              {!isUpload && (
                <Avatar
                  size="2xl"
                  mr="6"
                  borderRadius="lg"
                  src={!me?.photo ? '/fallback-avatar.png' : me?.photo.url}
                />
              )}

              {isUpload && (
                <Stack width="200px">
                  <Spinner margin="auto" mb="8" size="xl" color="brand.500" />
                </Stack>
              )}
              <VStack>
                <label>
                  <Input
                    type="file"
                    id="photo"
                    name="file"
                    display="none"
                    onChange={handleUploadPhoto}
                  />
                  <IconButton
                    variant="outline"
                    aria-label="Edit"
                    icon={<FiEdit2 />}
                    onClick={() => {
                      document.getElementById('photo')?.click();
                    }}
                  />
                </label>
                <IconButton
                  isDisabled={me?.photo?.url ? false : true}
                  variant="outline"
                  onClick={() => {
                    setIsUpload(true);
                    deletePhoto().then(() => {
                      setIsUpload(false);
                    });
                  }}
                  aria-label="Edit"
                  icon={<AiOutlineDelete />}
                />
              </VStack>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
