import { Wrap, WrapProps, Text, HStack } from '@chakra-ui/react';

interface Props extends WrapProps {
  tags: any[];
  isDownload?: boolean;
}

export const TagsCV = ({ tags, isDownload, ...props }: Props) => {
  return (
    <Wrap {...props}>
      {tags?.map((tag) => {
        return (
          <HStack
            color="gray.500"
            bg={'gray.100'}
            key={tag.id}
            borderRadius="5"
            pl="2"
            pr="2"
            m="1"
            pb={isDownload ? '3' : '0'}
            spacing="2"
          >
            <Text>{tag}</Text>
          </HStack>
        );
      })}
    </Wrap>
  );
};
