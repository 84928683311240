import { Formik, Form } from 'formik';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Stack, Text, Button, Link } from '@chakra-ui/react';
import { Layout } from 'containers/Guest/Shared/Layout';
import { DividerWithText } from 'components/DividerWithText';
import { FormInput } from 'components/FormInput';
import { FormInputPassword } from 'components/FormInputPassword';
import { FormCheckbox } from 'components/FormCheckbox';
import { FiLinkedin } from 'react-icons/fi';
import { FiGithub } from 'react-icons/fi';

import useConnect from './connect';
import { Confirmation } from './Confirmation';
import { LayoutTest } from '../Shared/LayoutTest';

export const Register = () => {
  const {
    handleSubmit,
    initialValues,
    schema,
    showConfirmation,
    handleGithubProvider,
    handleLinkedinProvider,
    location,
  } = useConnect();

  if (showConfirmation) {
    return (
      <Layout>
        <Confirmation />
        <DividerWithText py="8" />
        <Box>
          <Text as="span" textStyle="body" mr="2">
            ¿Ya la has confirmado?
          </Text>
          <Link as={RouterLink} to="/" textStyle="bodyLink" color="brand.600">
            Accede ahora
          </Link>
        </Box>
      </Layout>
    );
  }

  if (!localStorage.getItem('button')) {
    return (
      <Layout
        title="Únete a flyncer"
        subtitle="Crea tu cuenta en menos de 1 min."
        register={true}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <>
              <Stack spacing="4" mt="-6">
                <Button
                  textStyle="button"
                  colorScheme="linkedin"
                  leftIcon={<FiLinkedin />}
                  disabled={isSubmitting}
                  onClick={handleLinkedinProvider}
                >
                  Linkedin
                </Button>
                <Button
                  textStyle="button"
                  variant="outline"
                  colorScheme="black"
                  leftIcon={<FiGithub />}
                  disabled={isSubmitting}
                  onClick={handleGithubProvider}
                >
                  GitHub
                </Button>
                <DividerWithText pt="4">O regístrate con</DividerWithText>
                <Stack as={Form} noValidate spacing="4">
                  {!localStorage.getItem('button') &&
                    !location.pathname.toLowerCase().includes('/becasow') && (
                      <FormInput
                        name="referred_from_num"
                        label="Código de invitación (Opcional)"
                      />
                    )}
                  <FormInput name="email" label="Email" autoComplete="email" />
                  <FormInputPassword
                    name="password"
                    autoComplete="current-password"
                    label="Contraseña"
                  />
                  <FormCheckbox name="accept">
                    He leido y acepto los{' '}
                    <Link
                      href="https://flyncer.com/gprd/"
                      isExternal
                      textDecoration="underline"
                    >
                      Términos y Condiciones
                    </Link>
                    .
                  </FormCheckbox>

                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    colorScheme="brand"
                    textStyle="button"
                  >
                    Crear cuenta
                  </Button>
                </Stack>
                {!localStorage.getItem('button') &&
                  !location.pathname.toLowerCase().includes('/becasow') && (
                    <Box mt="4">
                      <Text as="span" textStyle="body" mr="2">
                        ¿Ya tienes cuenta?
                      </Text>
                      <Link
                        as={RouterLink}
                        to="/"
                        textStyle="bodyLink"
                        color="brand.600"
                      >
                        Accede ahora
                      </Link>
                    </Box>
                  )}
              </Stack>
            </>
          )}
        </Formik>
      </Layout>
    );
  }

  return (
    <LayoutTest
      title="Únete a flyncer"
      subtitle={
        localStorage.getItem('button') === 'training'
          ? 'Y obtén tu formación gratuita.'
          : localStorage.getItem('button') === 'test'
          ? 'Y accede a tu flyncer map.'
          : 'Crea tu cuenta en menos de 1 min.'
      }
      register={true}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <>
            <Stack spacing="4" mt="-6">
              <Button
                textStyle="button"
                colorScheme="linkedin"
                leftIcon={<FiLinkedin />}
                disabled={isSubmitting}
                onClick={handleLinkedinProvider}
              >
                Linkedin
              </Button>
              <Button
                textStyle="button"
                variant="outline"
                colorScheme="black"
                leftIcon={<FiGithub />}
                disabled={isSubmitting}
                onClick={handleGithubProvider}
              >
                GitHub
              </Button>
              <DividerWithText pt="4">O regístrate con</DividerWithText>
              <Stack as={Form} noValidate spacing="4">
                {!localStorage.getItem('button') && (
                  <FormInput
                    name="referred_from_num"
                    label="Código de invitación (Opcional)"
                  />
                )}
                <FormInput name="email" label="Email" autoComplete="email" />
                <FormInputPassword
                  name="password"
                  autoComplete="current-password"
                  label="Contraseña"
                />
                <FormCheckbox name="accept">
                  He leido y acepto los{' '}
                  <Link
                    href="https://flyncer.com/gprd/"
                    isExternal
                    textDecoration="underline"
                  >
                    Términos y Condiciones
                  </Link>
                  .
                </FormCheckbox>

                <Button
                  type="submit"
                  disabled={isSubmitting}
                  colorScheme="brand"
                  textStyle="button"
                >
                  Crear cuenta
                </Button>
              </Stack>
              {!localStorage.getItem('button') && (
                <Box mt="4">
                  <Text as="span" textStyle="body" mr="2">
                    ¿Ya tienes cuenta?
                  </Text>
                  <Link
                    as={RouterLink}
                    to="/"
                    textStyle="bodyLink"
                    color="brand.600"
                  >
                    Accede ahora
                  </Link>
                </Box>
              )}
            </Stack>
          </>
        )}
      </Formik>
    </LayoutTest>
  );
};
