import { Box, BoxProps, Text, Flex, Icon } from '@chakra-ui/react';
import { FiAward } from 'react-icons/fi';

interface Props extends BoxProps {
  itinerary: string;
}

export const EndItineraryCard = ({ itinerary, ...props }: Props) => {
  return (
    <Box
      position="relative"
      borderRadius="lg"
      bg="white"
      shadow="base"
      overflow="hidden"
      px="6"
      py="4"
      border="solid 1px"
      borderTop="solid 6px"
      borderColor="blue.400"
      {...props}
    >
      <Flex direction="column" align="center" justify="center" height="100%">
        <Icon as={FiAward} w="10" h="10" mb="2" color="blue.400" />
        <Text as="h5" textStyle="h5">
          Itinerario superado!
        </Text>
        <Text textStyle="body" color="gray.500" mt="2" textAlign="center">
          Complementa tus conocimientos activando otro itinerario que te ayude a
          mejorar tu perfil profesional.
        </Text>
      </Flex>
    </Box>
  );
};
