import { Wrap, WrapProps, Tag, Text } from '@chakra-ui/react';

interface Props extends WrapProps {
  tags: string[];
}

export const OfferTags = ({ tags, ...props }: Props) => {
  return (
    <Wrap {...props}>
      {tags.map((tag) => (
        <Tag key={tag} color="gray.500">
          <Text textStyle="small">{tag}</Text>
        </Tag>
      ))}
    </Wrap>
  );
};
