import { gql } from '@apollo/client';

export const USER_LEVEL_FROM_PATH = gql`
  query userPathFromPathId {
    userPathFromPathId {
      path {
        id
        name
      }
      level {
        order
      }
    }
  }
`;

export const GET_USER_PATHS = gql`
  query getUserPaths($idUser: ID) {
    getUserPaths(idUser: $idUser) {
      id
      updated_at
      hexagon
      path {
        id
        name
      }
      level {
        id
        name
        order
      }
      preLevel {
        id
        name
        order
      }
      userCareer {
        career {
          id
          name
        }
      }
    }
  }
`;
