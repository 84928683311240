import { Link as RouterLink } from 'react-router-dom';
import { HStack, SimpleGrid } from '@chakra-ui/react';

import { Placeholder } from 'components/Placeholder';
import { NoJobIllustration } from '../NoJobIllustration';
import { MuteJobIllustration } from '../MuteJobIllustration';
import { Text, Button, Stack, Link } from '@chakra-ui/react';
import { AllJobCard } from '../AllJobCard';
import useMe from 'graphql/hooks/profile/useMe';
// const _ = require('lodash');

interface Props {
  active: boolean;
  job: any[];
  tab?: string;
  level: any;
  userId: string;
  jobsMatchUserUser: any;
}

export const AllJobList = ({
  active,
  job,
  tab,
  level,
  userId,
  jobsMatchUserUser,
}: Props) => {
  const { me } = useMe();
  if (!active) {
    return (
      <Placeholder
        title="No te enviaremos oportunidades"
        subtitle="No te olvides activarlo cuando vuelvas a estar disponible"
        icon={<MuteJobIllustration w="250px" h="150px" />}
      />
    );
  }
  if ((job.length === 0 && tab === '1') || !jobsMatchUserUser) {
    return <Text>Cargando oportunidades...</Text>;
  }
  if (job.length === 0 && tab !== '1') {
    return (
      <Stack align="center" maxW="md" w="100%" marginX="auto">
        <Placeholder
          title="No hay oportunidades disponibles"
          icon={<NoJobIllustration w="250px" h="150px" />}
        />
        <Text align="center" textStyle="body" color="gray.500" pt="1" pb="2">
          Para estar al día de las ofertas activas, visita el tablero de ofertas
          o únete a nuestro canal de Telegram.
        </Text>
        <HStack>
          <Button
            colorScheme="brand"
            as={Link}
            href="/jobs"
            style={{ textDecoration: 'none' }}
          >
            Ver ofertas
          </Button>
          <Button
            colorScheme="brand"
            variant="outline"
            as={Link}
            href="https://t.me/flyncertribe"
            isExternal
            style={{ textDecoration: 'none' }}
          >
            Unirse a Telegram
          </Button>
        </HStack>
      </Stack>
    );
  }

  if (!job || !me) return null;

  // const matchJobs = job.filter((position) =>
  //   jobsMatchUserUser.find((id: string) => id === position.id)
  // );

  // const salaryMatchJobs = job.filter(
  //   (position) =>
  //     me.desiredMinSalary && position.salaryMax >= me.desiredMinSalary
  // );

  // let citiesMatchJobs, countriesMatchJobs;

  // if (me.desiredCountries.some((country) => country?.name === 'Remoto')) {
  //   countriesMatchJobs = job.filter((position) =>
  //     position.countries.find((country: any) =>
  //       me.desiredCountries.find(
  //         (desiredCountry) => desiredCountry?.name === country.name
  //       )
  //     )
  //   );
  // }

  // citiesMatchJobs = job.filter((position) =>
  //   position.cities.find((city: any) =>
  //     me.desiredCities.find(
  //       (desiredCity: any) => desiredCity.name === city.name
  //     )
  //   )
  // );

  // const locationAndSalaryMatchJobs = _.intersection(
  //   salaryMatchJobs,
  //   countriesMatchJobs,
  //   citiesMatchJobs
  // );

  // let allJobs = matchJobs.concat(
  //   locationAndSalaryMatchJobs,
  //   countriesMatchJobs,
  //   citiesMatchJobs,
  //   salaryMatchJobs,
  //   job
  // );

  // allJobs = allJobs.reduce(
  //   (partial, item) => (partial.includes(item) ? partial : [...partial, item]),
  //   []
  // );

  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8} py={6}>
      {job.map((position: any) => {
        const hasRemote = position?.countries?.filter(
          (country: any) => country.name === 'Remoto'
        );
        const userJobApplicationId = position?.user_job_applications?.findIndex(
          (p: any) => p.user === userId
        );

        let locations: string[] = [],
          salary: any;

        if (!hasRemote?.length)
          position?.cities?.forEach((location: any) => {
            locations.push(location.name);
          });
        else locations.push('Remoto');

        salary = { min: position?.salaryMin, max: position?.salaryMax };

        let created_at = { created_at: position?.created_at };

        return (
          <RouterLink to={`/jobsOffer/${position?.id}`} key={position?.id}>
            <AllJobCard
              match={jobsMatchUserUser?.some((id: any) => id === position?.id)}
              userJobApplication={
                position?.user_job_applications[userJobApplicationId]
              }
              level={level}
              company={position?.company}
              position={position?.name}
              skills={position?.skills}
              location={locations}
              experienceYears={position?.experienceYears}
              salary={salary}
              created_at={created_at}
              me={me}
            />
          </RouterLink>
        );
      })}
    </SimpleGrid>
  );
};
