import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Box,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Divider,
  Button,
  Switch,
  HStack,
  Badge,
  useToken,
  useMediaQuery,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { JobNotifcation } from './JobNotifcation';
import { JobList } from './JobList';
import useConnect from './connect';
import { AllJobList } from './AllJobList';
import useUserLevel from 'graphql/hooks/career/useUserLevel';
import * as Sentry from '@sentry/react';

import Joyride from 'react-joyride';
import { useJobMatchUsers } from 'graphql/hooks/jobs/useJobsMatchUsers';
import StepsOnboarding from 'utils/stepsOnboarding';
import StepsCompleteProfileModal from 'containers/Main/Shared/StepsCompleteProfileModal';

export const Jobs = () => {
  const {
    me,
    toggleAllowReceiveJob,
    loading,
    allJobsError,
    getJobs,
    acceptedJobsFiltered,
    acceptedJobsOrdered,
    pendingJobsFiltered,
    jobsFiltered,
    pathsWithLevel,
  } = useConnect();
  const { userLevel, error } = useUserLevel();
  const [orange400] = useToken('colors', ['orange.400']);
  const [showClosed, setShowClosed] = useState(false);
  const { replace } = useHistory();
  const { steps2 } = StepsOnboarding();

  const handleClick = () => {
    setShowClosed(!showClosed);
  };

  const { jobsMatchUserUser } = useJobMatchUsers();

  const offerId = localStorage.getItem('offerId');
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    document.getElementById('top')?.scrollIntoView();
  }, []);

  if (offerId !== null && me?.onboarding) {
    replace(`/jobsOffer/${offerId}`);
  }

  if (error || allJobsError) {
    let message = error ? error : allJobsError;
    Sentry.captureException(message?.graphQLErrors[0]);
  }

  if (!me || !getJobs) return null;

  if (!me.user_cv && me.onboarding) replace('/onboarding-profile');
  else if (
    !me?.desiredMinSalary &&
    (!me?.desiredLocations.length || !me.desiredCountries.length) &&
    me.onboarding
  )
    replace('/onboarding-profile-goals');
  else if (
    (!me.name ||
      !me.surname ||
      (me?.phoneNumber && me?.phoneNumber?.length < 10)) &&
    me.onboarding
  )
    replace('/onboarding-profile-contact');

  return (
    <>
      <Box
        as="main"
        mx="auto"
        w="100%"
        maxW="container.xl"
        px={{ base: '4', md: '6' }}
        py={{ base: '8', md: '14' }}
        id={isMobile ? 'top' : ''}
      >
        {me.onboarding && !offerId && (
          <Joyride
            scrollToFirstStep={true}
            showSkipButton={true}
            continuous={true}
            steps={steps2}
            showProgress={false}
            styles={{
              options: { zIndex: 3000, overlayColor: '#3A3939' },
              buttonNext: {
                backgroundColor: '#009253',
              },
            }}
          />
        )}
        <Flex
          mb={{ base: '6', md: '8' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Text as="h3" textStyle={{ base: 'h4', md: 'h3' }}>
            Oportunidades
          </Text>
          <Button
            variant="outline"
            colorScheme="brand"
            as={RouterLink}
            to="/profile/goals"
          >
            Configuración
          </Button>
        </Flex>
        <Tabs colorScheme="brand" mb="10">
          <Flex
            flexDirection={{ base: 'column', md: 'row-reverse' }}
            justify="space-between"
            align="center"
          >
            <Box alignSelf="flex-start" mb={{ base: '6', md: '0' }}>
              <JobNotifcation
                isChecked={me.allowReceiveJobPositions}
                onChange={toggleAllowReceiveJob}
                isDisabled={loading.update}
              />
            </Box>
            <TabList
              border="0"
              position="relative"
              zIndex={1}
              w={{ base: '100%', md: 'auto' }}
            >
              <Tab fontWeight="semibold" as={RouterLink} to={`/jobs`}>
                <Text textStyle="body">Publicadas</Text>
              </Tab>
              <Tab
                fontWeight="semibold"
                as={RouterLink}
                to={`/jobsResolutions`}
              >
                <Text textStyle="body">Recibidas</Text>
                {pendingJobsFiltered.length > 0 && (
                  <Badge ml="1" backgroundColor={orange400} variant="solid">
                    !
                  </Badge>
                )}
              </Tab>
              <Tab fontWeight="semibold" as={RouterLink} to={`/jobsAccepted`}>
                <Text textStyle="body">Aceptadas</Text>
              </Tab>
            </TabList>
          </Flex>
          <Box pos="relative" zIndex={0}>
            <Divider
              borderBottomWidth="2px"
              opacity={1}
              borderColor="gray.200"
            />
          </Box>
          <TabPanels>
            <TabPanel p="0" mb={isMobile ? '100' : '0'}>
              <AllJobList
                userId={me.id}
                active={true}
                job={jobsFiltered}
                level={userLevel}
                jobsMatchUserUser={jobsMatchUserUser}
                tab="1"
              />
            </TabPanel>
            <TabPanel p="0" mb={isMobile ? '120' : '0'}>
              <JobList
                active={me.allowReceiveJobPositions}
                job={pendingJobsFiltered}
                receive={true}
              />
            </TabPanel>
            <TabPanel p="0" mb={isMobile ? '120' : '0'}>
              <HStack align="end">
                <Text mt="4">Mostrar ofertas cerradas</Text>
                <Switch colorScheme="brand" onChange={handleClick} />
              </HStack>

              <JobList
                active={me.allowReceiveJobPositions}
                job={showClosed ? acceptedJobsOrdered : acceptedJobsFiltered}
                receive={false}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>

        {!me.onboarding && !me.completed_profile && (
          <StepsCompleteProfileModal
            hasCareer={me?.careers?.length ? true : false}
            experiences={me?.user_cv ? true : false}
            contactInfo={
              me?.name && me.surname && me.phoneNumber ? true : false
            }
            professionalRol={
              me?.professions && me?.professions[0]?.experienceYears
                ? true
                : false
            }
            pathsWithLevel={pathsWithLevel?.length ? true : false}
            hasObjectives={
              (me?.desiredCities.length || me.desiredCountries.length) &&
              me.desiredMinSalary
                ? true
                : false
            }
          />
        )}
      </Box>
    </>
  );
};
