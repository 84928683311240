import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useJobRequest from 'graphql/hooks/jobs/useJobRequest';
import useJobRequestActions from 'graphql/hooks/jobs/useJobRequestActions';
import { ENUM_USERJOBRESOLUTION_CANCELREASON } from 'graphql/generated/globalTypes';
import * as Sentry from '@sentry/react';

export interface HandleReject {
  cancelReason: ENUM_USERJOBRESOLUTION_CANCELREASON;
  cancelReasonOtherMessage?: string;
  banCompany: boolean;
}

export const useConnect = () => {
  const { id } = useParams<{ id: string }>();
  const { jobRequest, loading, error } = useJobRequest({ id });
  const {
    acceptJobRequest,
    rejectJobRequest,
    blockCompany,
  } = useJobRequestActions();

  if (error) Sentry.captureException(error?.graphQLErrors[0]);

  const handleAccept = useCallback(async () => {
    await acceptJobRequest({ id });
  }, [acceptJobRequest, id]);

  const handleReject = useCallback(
    async ({
      cancelReason,
      cancelReasonOtherMessage,
      banCompany,
    }: HandleReject) => {
      let blockPromise;

      if (banCompany && jobRequest) {
        blockPromise = blockCompany({ id: jobRequest.position.company.id });
      }

      const rejectPromise = rejectJobRequest({
        id,
        cancelReason,
        cancelReasonOtherMessage:
          cancelReason === ENUM_USERJOBRESOLUTION_CANCELREASON.other
            ? cancelReasonOtherMessage
            : undefined,
      });

      if (blockPromise) {
        await blockPromise;
      }
      await rejectPromise;
    },
    [id, rejectJobRequest, jobRequest, blockCompany]
  );

  return {
    jobRequest,
    loading,
    handleAccept,
    handleReject,
  };
};
