export const sanitizeLocations = (user: any, position: any, hasRemote: any) => {
  let locations: any = [];
  if (user.desiredCities !== undefined && !hasRemote.length) {
    locations = user.desiredCities?.filter((location: any) =>
      position.city.find((locationP: any) => location?.name === locationP)
    );
  } else
    locations = user.desiredCountries?.filter(
      (location: any) => location?.name === 'Remoto'
    );

  return locations;
};
