import { useCallback, useState } from 'react';
import { createToast } from 'services/toast';
import useUserPathActions from 'graphql/hooks/career/useUserPathActions';
import useUserPaths from 'graphql/hooks/career/useUserPaths';
import { useLocation } from 'react-router-dom';

export const useConnect = (paths: any, idUser: string) => {
  const { savePaths } = useUserPathActions();
  const [isEdited, setisEdited] = useState(false);
  const { refetch: refetchPaths, error } = useUserPaths({ idUser });
  let level: number = 0;
  let data: any = [];
  const location = useLocation();

  let className: string = '';

  const basicHexagonInfo = [
    {
      subject: 'HTML y CSS',
      A: 80,
    },
    {
      subject: 'Javascript',
      A: 48,
    },
    {
      subject: 'PHP',
      A: 86,
    },
    {
      subject: 'React',
      A: 29,
    },
    {
      subject: 'Typescript',
      A: 55,
    },
    {
      subject: 'Git',
      A: 65,
    },
  ];

  const onChangePaths = useCallback(
    (newValue) => {
      let position = 0;
      if (paths.pathsId.length > 0) position = paths.pathsId.indexOf(newValue);
      if (position === -1 || paths.pathsId.length === 0) {
        paths.pathsId.push(newValue);
      } else paths.pathsId.splice(position, 1);
      if (!isEdited) setisEdited(true);
      else setisEdited(false);
    },
    [isEdited, paths.pathsId]
  );

  const save = () => {
    const pathId = paths.pathsId;

    savePaths({ idUser, pathId }).then(() => {
      refetchPaths();
      createToast({
        title: `Paths guardados`,
        description: 'Paths guardados con éxito',
        status: 'success',
      });
    });
  };

  paths.paths?.forEach((path: any) => {
    if (path.level) {
      switch (path.level?.name) {
        case 'Junior':
          level = 25;
          break;
        case 'Intermedio':
          level = 50;
          break;
        case 'Avanzado':
          level = 75;
          break;
        case 'Experto':
          level = 100;
          break;
      }
    } else if (path.preLevel && !path.level) {
      switch (path.preLevel?.name) {
        case 'Junior':
          level = 25;
          break;
        case 'Intermedio':
          level = 50;
          break;
        case 'Avanzado':
          level = 75;
          break;
        case 'Experto':
          level = 100;
          break;
      }
    }

    const pathsId = paths.pathsId.filter(
      (pathId: any, index: string) => paths.pathsId.indexOf(pathId) === index
    );

    if (paths.pathsId.length < 7)
      pathsId.forEach((id: any) => {
        if (id === path.path.id)
          data.push({
            subject: `${
              path.level && location.pathname.includes('company')
                ? `${path.path.name} 🏅`
                : `${path.path.name}`
            }`,
            A: level,
          });
      });

    //Posibles clases para maquetar el hexágono
    paths.cv === 'CV'
      ? (className = 'cv')
      : paths.cv === 'Candidate' &&
        paths.pathsId.length === 6 &&
        (paths.type === 'Inscribed' || paths.type === 'Discarded')
      ? (className = 'candidate4')
      : (paths.cv === 'Candidate' && paths.type === 'Inscribed') ||
        paths.type === 'Discarded'
      ? (className = 'candidate')
      : paths.cv === 'Candidate' && paths.type === 'Shortlisted'
      ? (className = 'candidate')
      : (paths.cv === 'Candidate' && paths.type === 'PublicProfile') ||
        paths.type === 'Discarded'
      ? (className = 'candidate')
      : (paths.cv === 'Candidate' && paths.type === 'Inscribed') ||
        paths.type === 'Discarded'
      ? (className = 'candidate')
      : (paths.cv === 'Candidate' && paths.type === 'Match') ||
        paths.type === 'Accepted'
      ? (className = 'candidate2')
      : (paths.cv === 'Candidate' && paths.type === 'Pending') ||
        paths.type === 'Reject'
      ? (className = 'candidate3')
      : paths.cv === 'CvPage'
      ? (className = 'CvPage')
      : paths.cv === 'HexagonCV'
      ? (className = 'HexagonCV')
      : paths.cv === 'HexagonTopBar'
      ? (className = 'HexagonTopBar')
      : (className = 'cv2');
  });

  return {
    basicHexagonInfo,
    onChangePaths,
    save,
    error,
    data,
    className,
    location,
  };
};
