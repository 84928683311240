import { gql } from '@apollo/client';

export const ACCEPT_JOB_REQUEST = gql`
  mutation AcceptJobRequest($id: ID!) {
    acceptJobRequest(id: $id) {
      id
    }
  }
`;

export const REJECT_JOB_REQUEST = gql`
  mutation RejectJobRequest(
    $id: ID!
    $cancelReason: ENUM_USERJOBRESOLUTION_CANCELREASON!
    $cancelReasonOtherMessage: String
  ) {
    rejectJobRequest(
      id: $id
      cancelReason: $cancelReason
      cancelReasonOtherMessage: $cancelReasonOtherMessage
    ) {
      id
    }
  }
`;

export const BLOCK_COMPANY = gql`
  mutation BlockCompany($id: ID!) {
    blockCompany(id: $id)
  }
`;
