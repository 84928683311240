import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { Studies } from 'graphql/generated/Studies';
import { STUDIES } from 'graphql/queries/study';
import { removeNulls } from 'utils/removeNulls';
import { normalizeStudy, Study } from 'model/Study';

const useStudies = () => {
  const { data, loading, error } = useQuery<Studies>(STUDIES);

  const studies = useMemo(() => {
    return (data?.studies || []).reduce<Study[]>(
      (acum, input) =>
        !!input ? acum.concat(normalizeStudy(removeNulls(input))) : acum,
      []
    );
  }, [data]);

  return {
    error,
    studies,
    loading,
  };
};

export default useStudies;
