import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { setToken } from 'services/token';
import useIsAuth from 'graphql/hooks/auth/useIsAuth';
import { Center, Text } from '@chakra-ui/react';
import { createToast } from 'services/toast';
import * as Sentry from '@sentry/react';

const backendUrl = process.env.REACT_APP_API_URL;

export const AuthRedirect = () => {
  const { refetch, error } = useIsAuth();
  const { search } = useLocation();
  const { replace } = useHistory();
  const { providerName } = useParams<{ providerName: string }>();

  useEffect(() => {
    fetch(`${backendUrl}/auth/${providerName}/callback${search}`)
      .then(async (res) => {
        if (res.status !== 200) {
          const data = await res.json();
          const error = data.message[0]?.messages[0]?.id;

          throw new Error(error);
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setToken(res.jwt);
        await refetch();
        replace('/');
      })
      .catch(({ message }) => {
        let description = 'Algo fue mal. Intentalo de nuevo.';

        if (message === 'Auth.form.error.email.taken') {
          description = 'Ya exise una cuenta asociada a ese email.';
        }

        createToast({
          title: `Iniciar sesión con ${providerName}`,
          description: description,
          status: 'error',
        });
        replace('/');
      });
  }, [search, providerName, replace, refetch]);

  if (error) Sentry.captureException(error?.graphQLErrors[0]);

  return (
    <Center minHeight="100vh">
      {providerName && <Text>Conectando con {providerName}...</Text>}
    </Center>
  );
};
