import { gql } from '@apollo/client';
import { JOB_REQUEST_FRAGMENT } from 'graphql/fragments/jobRequest';

export const JOB_REQUEST = gql`
  query JobRequest($id: ID!) {
    jobRequest(id: $id) {
      ...JobRequestParts
    }
  }
  ${JOB_REQUEST_FRAGMENT}
`;

export const JOB_REQUESTS = gql`
  query JobRequests {
    jobRequests {
      ...JobRequestParts
    }
  }
  ${JOB_REQUEST_FRAGMENT}
`;
