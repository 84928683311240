import { StackProps } from '@chakra-ui/react';
import { ENUM_USERSPERMISSIONSUSER_ENGLISHLEVEL } from 'graphql/generated/globalTypes';
import { FormSelect } from 'components/FormSelect';

interface Props extends StackProps {
  name: string;
}

const levels = Object.values(ENUM_USERSPERMISSIONSUSER_ENGLISHLEVEL);

export const EnglishLevel = ({ name, ...props }: Props) => {
  return (
    <FormSelect name={name} placeholder="Selecciona tu nivel">
      {levels.map((level, index) => {
        return (
          <option value={level} key={'level' + index}>
            {level}
          </option>
        );
      })}
    </FormSelect>
  );
};
