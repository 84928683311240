import { useCallback, useState } from 'react';
import {
  Button,
  Modal,
  ModalProps,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import useUserCareerActions from 'graphql/hooks/career/useUserCareerActions';
import { UserCareer } from 'model/UserCareer';
import { createToast } from 'services/toast';
import {
  UserCareerFormValues,
  UserCareerUpdate,
} from 'components/UserCareerUpdate';

interface Props extends Omit<ModalProps, 'children'> {
  userCareer: UserCareer;
}

export const UpdateCareerModal = ({ isOpen, onClose, userCareer }: Props) => {
  const { update, updateLoading } = useUserCareerActions();

  const [userCareerFormValues, setUserCareerFormValues] =
    useState<UserCareerFormValues>({
      isMain: userCareer.isMain,
      experienceYears: userCareer.experienceYears,
      careerId: userCareer.career.id,
      isBasic: userCareer.career.isBasic,
      activePaths: userCareer.activePaths.map((activePath) => ({
        name: activePath.path.name,
        id: activePath.path.id,
        preLevel: activePath.preLevel?.id || '',
        level: activePath.level?.id || '',
      })),
      activeItineraries: userCareer.activeItineraries?.map(
        ({ itinerary }) => itinerary.id
      ),
    });

  const handleSave = useCallback(async () => {
    try {
      const { isBasic, ...values } = userCareerFormValues;
      if (
        !isBasic &&
        values.activeItineraries.length === 0 &&
        values.activePaths.length === 0
      ) {
        createToast({
          title: 'Actualizar tecnologías o itinerarios',
          description: 'Debe seleccionar al menos una.',
          status: 'error',
        });

        return;
      }

      let activePaths: any[] = [];

      values.activePaths.forEach((path) => {
        activePaths.push({ pathId: path.id, preLevel: path.preLevel });
      });

      await update({
        where: { id: userCareer.id },
        data: {
          experienceYears: values.experienceYears,
          activePaths,
          activeItineraries: values.activeItineraries,
        },
      });
      onClose();
    } catch (error: any) {
      console.log(error);
    }
  }, [userCareer, userCareerFormValues, update, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{userCareer.career.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <UserCareerUpdate
            userCareerFormValues={userCareerFormValues}
            handleChangeUserCareers={setUserCareerFormValues}
            allowUpdateCareer={false}
          />
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} variant="ghost">
            Cancelar
          </Button>
          <Button
            ml="4"
            colorScheme="brand"
            onClick={handleSave}
            disabled={updateLoading}
          >
            Guardar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
