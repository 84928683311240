import { ApolloError } from '@apollo/client';

export class FlyncerError extends Error {
  public fields?: Record<string, string[]>;

  constructor(message = 'Something went wrong!', error: ApolloError) {
    super(message);

    if (error?.networkError) {
      return;
    }

    this.fields =
      error?.graphQLErrors[0]?.extensions?.exception?.data?.message[0].messages[0];
  }
}
