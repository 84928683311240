import { gql } from '@apollo/client';
import { ITINERARY_FRAGMENT } from './itinerary';
import { PATH_FRAGMENT } from './path';

export const CAREER_FRAGMENT = gql`
  fragment CareerParts on Career {
    id
    name
    isBasic
    esRecurso
    paths {
      ...PathParts
    }
    itineraries {
      ...ItineraryParts
    }
  }
  ${PATH_FRAGMENT}
  ${ITINERARY_FRAGMENT}
`;
