import { CityParts } from 'graphql/generated/CityParts';
import { removeNulls, WithoutNulls } from 'utils/removeNulls';

export const normalizeCity = (input: WithoutNulls<CityParts>) => ({
  id: input.id,
  name: input.name,
});

export type City = ReturnType<typeof normalizeCity>;

export const normalizeCities = (input: (CityParts | undefined)[]) =>
  input.reduce<City[]>(
    (acum, input) =>
      !!input ? acum.concat(normalizeCity(removeNulls(input))) : acum,
    []
  );
