import { Checkbox, Grid, GridItem } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';

interface Props {
  isCompleted: boolean;
  title: string;
  url: string;
}

export const ProgressCheckbox = ({ isCompleted, title, url }: Props) => {
  const { replace } = useHistory();

  const urlName = url.replaceAll('/', '-');

  return (
    <Checkbox
      id={`completeProfile-widget${urlName}`}
      width="100%"
      _hover={{
        background: 'gray.100',
      }}
      color="gray.500"
      isChecked={isCompleted}
      textDecoration={isCompleted ? 'line-through' : ''}
      onChange={() => {
        replace(url);
      }}
    >
      <Grid templateColumns="repeat(4,1fr)">
        <GridItem whiteSpace="nowrap">{title}</GridItem>
        <GridItem colStart={5} rowStart={1}>
          <ChevronRightIcon w={6} h={6} />
        </GridItem>
      </Grid>
    </Checkbox>
  );
};
