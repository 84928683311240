import { Box, Flex } from '@chakra-ui/react';
import { Logo } from 'components/Logo';

export const NoLoginHeader = () => {
  return (
    <Box bg="white">
      <Flex
        as="nav"
        aria-label="Navegación principal"
        maxW="container.xl"
        px={{ base: '4', md: '6' }}
        mx="auto"
        h="16"
        alignItems="center"
      >
        <Logo id="logo" w="auto" height="8" />
      </Flex>
    </Box>
  );
};
