import { useMutation } from '@apollo/client';
import { quitNews, quitNewsVariables } from 'graphql/generated/quitNews';
import { FlyncerError } from 'graphql/helpers';
import { QUIT_NEWS } from 'graphql/mutations/job';
import { COMPANIES } from 'graphql/queries/company';
import { ADMIN_JOBS } from 'graphql/queries/job';
import { useCallback } from 'react';
import * as Sentry from '@sentry/react';

export const useNewsActions = () => {
  const [quitNewsMutation] = useMutation<quitNews, quitNewsVariables>(
    QUIT_NEWS,
    { refetchQueries: [{ query: COMPANIES }, { query: ADMIN_JOBS }] }
  );

  const quitNews = useCallback(
    async (input: quitNewsVariables) => {
      try {
        await quitNewsMutation({ variables: input });
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo quitar los nuevos'));
        throw new FlyncerError('No se pudo quitar los nuevos', error);
      }
    },
    [quitNewsMutation]
  );

  return {
    quitNews,
  };
};
