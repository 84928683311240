import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { Me } from 'graphql/generated/Me';
import { ME } from 'graphql/queries/me';
import { removeNulls } from 'utils/removeNulls';
import { normalizeMe } from 'model/Me';

const useMe = () => {
  const { data, loading, refetch: refetchUser, error } = useQuery<Me>(ME);

  const me = useMemo(
    () =>
      data && data?.meExtended
        ? normalizeMe(removeNulls(data.meExtended))
        : undefined,
    [data]
  );

  return {
    error,
    me,
    loading,
    refetchUser,
  };
};

export default useMe;
