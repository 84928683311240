import { gql } from '@apollo/client';

export const UPDATE_JOB = gql`
  mutation UpdateJob($input: UpdateJobInput!) {
    editJobPosition(input: $input)
  }
`;

export const ADD_JOB = gql`
  mutation AddJob($input: addJobPositionInput!) {
    addJobPosition(input: $input)
  }
`;

export const NOTIF_JOB = gql`
  mutation NotifJob($id: ID!, $userId: ID!) {
    notifJob(id: $id, userId: $userId)
  }
`;

export const SEND_JOB = gql`
  mutation SendJob($id: ID!, $userId: ID!) {
    sendJob(id: $id, userId: $userId) {
      id
      isAccepted
    }
  }
`;

export const EDIT_SEEN_USER = gql`
  mutation EditSeenUser($id: ID!, $userId: ID!) {
    editSeenUser(id: $id, userId: $userId)
  }
`;

export const QUIT_NEWS = gql`
  mutation quitNews($type: String!, $jobPositionId: ID!, $idUser: ID!) {
    quitNews(type: $type, jobPositionId: $jobPositionId, idUser: $idUser)
  }
`;

export const CLOSE_JOB = gql`
  mutation closeJobPosition($id: ID!) {
    closeJobPosition(id: $id)
  }
`;
