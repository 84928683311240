import pdfMake from 'pdfmake/build/pdfmake';
import html2canvas from 'html2canvas';
import { useState } from 'react';
import useUserPaths from 'graphql/hooks/career/useUserPaths';

export const useConnect = (idUser: string | undefined) => {
  const [isPrinted, setisPrinted] = useState(false);
  const {
    paths,
    error: userPathsError,
    loadingPaths,
  } = useUserPaths({ idUser });

  const downloadCV = async () => {
    await setisPrinted(true);
    const cv = document.getElementById('cv') as HTMLElement;
    html2canvas(cv).then((canvas) => {
      var data = canvas.toDataURL();
      setisPrinted(false);
      var docDefinition: any = {
        pageOrientation: 'landscape',
        content: [
          {
            image: data,
            width: 740,
          },
        ],
      };

      pdfMake.createPdf(docDefinition).download('certificado.pdf');
    });
  };

  const array = paths?.map((path) => {
    return path?.updated_at;
  });

  let dates: any = array?.map((date) => new Date(date));

  const lastUpdate = new Date(Math.max.apply(null, dates));

  const isExisted = paths?.filter((a) => a?.updated_at);

  return {
    downloadCV,
    isPrinted,
    lastUpdate,
    userPathsError,
    isExisted,
    paths,
    loadingPaths,
  };
};
