import { gql } from '@apollo/client';
import { WORKSHOP_FRAGMENT } from './workshop';

export const ITINERARY_FRAGMENT = gql`
  fragment ItineraryParts on Itinerary {
    id
    Name
    career {
      id
      name
      isBasic
    }
    workshops {
      ...FullWorkshopParts
    }
  }
  ${WORKSHOP_FRAGMENT}
`;
