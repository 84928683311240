import { Button, Stack, Text } from '@chakra-ui/react';
import { SectionCard } from 'components/SectionCard';
import { Field, Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import Joyride from 'react-joyride';
import { useLocation } from 'react-router-dom';
import useConnect from '../../connect';
import { DesiredLocations } from './DesiredCities';
import { DesiredCompanyTypes } from './DesiredCompanyTypes';
import { DesiredCountries } from './DesiredCountries';
import { DesiredSalary } from './DesiredSalary';

export const GoalsTab = () => {
  const isSelected = useRef(false);
  const [forceRender, setforceRender] = useState(false);
  const {
    handleSubmit,
    initialValues,
    me,
    errorSalary,
    seterrorSalary,
    touchedSalary,
    settouchedSalary,
    errorLocations,
    seterrorLocations,
    countries,
    isMobile,
  } = useConnect();
  const locationPath = useLocation();

  if (
    errorSalary &&
    (locationPath.pathname === '/profile/goals' ||
      locationPath.pathname === '/onboarding-profile-goals')
  )
    document.getElementById('salary')?.scrollIntoView();
  else if (
    errorLocations &&
    (locationPath.pathname === '/profile/goals' ||
      locationPath.pathname === '/onboarding-profile-goals')
  )
    document.getElementById('locations')?.scrollIntoView();

  const steps2: any = [
    {
      target: '.yourObjectives',
      disableBeacon: true,
      disableOverlayClose: true,
      spotlightClicks: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: 'right-start',
      disableCloseOnEsc: true,

      content: (
        <>
          <Text>
            Ahora, dínos lo que buscas en una oferta. Sólo recibirás ofertas que
            encajen con estos requisitos, así que sé realista :) Cuando lo
            tengas, haz click en Guardar.
          </Text>
          <Text mt="3" color="#009253" fontWeight="500">
            Paso (4/6)
          </Text>
        </>
      ),
    },
  ];

  if (forceRender) {
    setTimeout(() => {
      setforceRender(false);
    }, 250);
  }

  if (!me) return null;

  return (
    <>
      {me?.onboarding && (
        <Joyride
          scrollToFirstStep={true}
          showSkipButton={false}
          stepIndex={0}
          continuous={true}
          steps={steps2}
          showProgress={false}
          styles={{
            options: { zIndex: 3000, overlayColor: '#3A3939' },
            buttonNext: {
              backgroundColor: '#009253',
            },
          }}
        />
      )}
      <Stack className="yourObjectives" spacing="6" mt="6">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <Stack spacing="6" mt="6" mb={isMobile ? '28' : '0'}>
                <SectionCard
                  id="salary"
                  onboarding={true}
                  border={
                    errorSalary
                      ? '1px solid #CB1410'
                      : !me.onboarding && !me.desiredMinSalary
                      ? '1px solid #F25D09'
                      : ''
                  }
                  title="¿Cuánto quieres ganar, como mínimo?"
                  subtitle="Indica una cantidad mínima para aceptar oportunidades."
                >
                  <DesiredSalary
                    seterrorSalary={seterrorSalary}
                    touchedSalary={touchedSalary}
                    settouchedSalary={settouchedSalary}
                    name="desiredMinSalary"
                  />
                  {errorSalary && (
                    <Text mt="2" fontSize="14px" color="#CB1410">
                      Este campo es requerido
                    </Text>
                  )}
                </SectionCard>
                <SectionCard
                  id="locations"
                  onboarding={true}
                  border={
                    errorLocations
                      ? '1px solid #CB1410'
                      : !me.onboarding && !me.desiredCountries.length
                      ? '1px solid #F25D09'
                      : ''
                  }
                  title="¿Dónde quieres trabajar?"
                >
                  <Field
                    isSelected={isSelected}
                    className="custom-select"
                    name="desiredCountries"
                    countries={countries}
                    component={DesiredCountries}
                    setforceRender={setforceRender}
                    seterrorLocations={seterrorLocations}
                  />

                  {isSelected.current === true && (
                    <Field
                      className="custom-select"
                      name="desiredLocations"
                      component={DesiredLocations}
                      setforceRender={setforceRender}
                      seterrorLocations={seterrorLocations}
                    />
                  )}
                  {errorLocations && (
                    <Text mt="2" fontSize="14px" color="#CB1410">
                      Este campo es requerido
                    </Text>
                  )}
                </SectionCard>
                <SectionCard title="¿En qué tipo de empresa te gustaría trabajar?">
                  <DesiredCompanyTypes name="desiredCompanyTypes" />
                </SectionCard>

                <Button
                  colorScheme="brand"
                  mt="6"
                  mb="10"
                  type="submit"
                  disabled={isSubmitting}
                  maxWidth="max-content"
                >
                  Guardar
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </>
  );
};
