import { gql } from '@apollo/client';
import { FULL_CAREER_FRAGMENT } from 'graphql/fragments/fullCareer';

export const INITALIZE_LEVEL = gql`
  mutation InitialiceLevel($input: InitialicePathLevelInput!) {
    initialiceMePathLevel(input: $input) {
      ...FullCareerParts
    }
  }
  ${FULL_CAREER_FRAGMENT}
`;

export const SAVE_PATHS = gql`
  mutation saveHexagonPaths($pathId: [ID], $idUser: ID!) {
    saveHexagonPaths(pathId: $pathId, idUser: $idUser) {
      id
      path {
        id
        name
      }
      hexagon
    }
  }
`;
