import { useCallback, useMemo } from 'react';
import useMe from 'graphql/hooks/profile/useMe';
import useMeActions from 'graphql/hooks/profile/useMeActions';
import { JobRequest } from 'model/JobRequest';
import useJobRequests from 'graphql/hooks/jobs/useJobRequests';
import * as Sentry from '@sentry/react';
import useFullCareer from 'graphql/hooks/career/useFullCareer';
import { SanitizedJobs } from 'graphql/hooks/jobs/useJobs';
import useUserPaths from 'graphql/hooks/career/useUserPaths';

const useConnect = () => {
  const { me, loading, refetchUser, error } = useMe();
  const { userCareers } = useFullCareer();
  const { getJobs, error: allJobsError } = SanitizedJobs();
  const idUser = me?.id;

  const { paths } = useUserPaths({ idUser });

  const {
    jobRequests,
    loading: loadingJobs,
    error: jobRequestsError,
  } = useJobRequests();
  const { updateProfile, isLoading } = useMeActions();

  if (error || jobRequestsError) {
    let message = error ? error : jobRequestsError;
    Sentry.captureException(message?.graphQLErrors[0]);
  }

  const toggleAllowReceiveJob = useCallback(async () => {
    if (!me) return;

    try {
      await updateProfile({
        input: {
          where: {
            id: me.id,
          },
          data: {
            allowReceiveJobPositions: !me.allowReceiveJobPositions,
          },
        },
      });
    } catch {}
  }, [updateProfile, me]);

  const { pendingJobs, acceptedJobs } = useMemo(() => {
    const result = {
      pendingJobs: [] as JobRequest[],
      acceptedJobs: [] as JobRequest[],
    };

    for (const jobRequest of jobRequests) {
      if (jobRequest.isAccepted === true) {
        result.acceptedJobs.push(jobRequest);
      } else if (jobRequest.isAccepted === undefined) {
        result.pendingJobs.push(jobRequest);
      }
    }

    return result;
  }, [jobRequests]);

  let pathInfo: any = [];

  userCareers.map((userCareer) => {
    const { activePaths } = userCareer;
    activePaths.map((path) => {
      if (path.path.name === 'Javascript') pathInfo.push(path);
      return '';
    });
    return '';
  });

  const courses = pathInfo[0]?.courses;
  const level = pathInfo[0]?.level;

  const orderedCourses = useMemo(
    () =>
      courses?.sort(
        (a: any, b: any) => (a.level?.order || 0) - (b.level?.order || 0)
      ),
    [courses]
  );
  const currentLevel = orderedCourses?.filter(
    (course: any) =>
      course.level?.name === level?.name && course.quizApproved === false
  );
  let currentLevelID;

  if (currentLevel && currentLevel[0]?.id) currentLevelID = currentLevel[0]?.id;
  else currentLevelID = undefined;

  const acceptedJobsOrdered = acceptedJobs
    .sort(
      (a, b) =>
        (a.position.created_at?.order || 0) -
        (b.position.created_at?.order || 0)
    )
    .reverse();

  const acceptedJobsFiltered = acceptedJobs.filter(
    (j) => j.position.state !== 'closed'
  );

  const pendingJobsFiltered = pendingJobs
    .filter((j) => j.position.state !== 'closed')
    .sort(
      (a, b) =>
        (a.position.created_at?.order || 0) -
        (b.position.created_at?.order || 0)
    )
    .reverse();

  const jobsFiltered = getJobs
    .filter((j: any) => j.state !== 'closed')
    .sort((a: any, b: any) => {
      if (a.created_at < b.created_at) return 1;
      else if (a.created_at > b.created_at) return -1;
      return 0;
    });

  const pathsNames = paths
    ?.filter((path) => !path?.level)
    .map((path: any) => {
      return { id: path?.id, name: path?.path?.name };
    });

  let pathsWithLevel;

  if (me?.careers)
    pathsWithLevel = me?.careers.filter((career) =>
      career?.activePaths?.find((path) => path?.level)
    );

  return {
    me,
    refetchUser,
    pendingJobs,
    acceptedJobs,
    toggleAllowReceiveJob,
    loading: {
      me: loading,
      jobs: loadingJobs,
      update: isLoading,
    },
    currentLevelID,
    acceptedJobsOrdered,
    acceptedJobsFiltered,
    pendingJobsFiltered,
    jobsFiltered,
    allJobsError,
    getJobs,
    pathsNames,
    pathsWithLevel,
  };
};

export default useConnect;
