import {
  FormControl,
  FormErrorMessage,
  SliderProps,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { Dispatch, memo, SetStateAction, useCallback, useState } from 'react';
import { normalizeExperience } from 'utils/normalizeExperience';
interface Props extends SliderProps {
  name: string;
  altError?: String;
  setExpYearsState: Dispatch<SetStateAction<string>>;
  totalUsers: number;
}

export const ExpYearsSlider = memo(
  ({ name, altError, setExpYearsState, totalUsers, ...props }: Props) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [{ value = 0 }, { error, touched }, { setValue }] = useField(name);

    const onChangeYears = useCallback(
      (newValue) => {
        setValue(newValue);
        setExpYearsState(newValue);
      },
      [setValue, setExpYearsState]
    );

    const isInvalid = touched && !!error;

    return (
      <FormControl isInvalid={isInvalid}>
        <Text textStyle="body" fontWeight="medium" mt="10" mb="5">
          {normalizeExperience(value)}
        </Text>
        <Slider
          aria-label="slider-experience"
          value={value}
          name={name}
          onChange={onChangeYears}
          min={0}
          max={10}
          mt="4"
          colorScheme="brand"
          {...props}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <SliderTrack bg="gray.200">
            <SliderFilledTrack />
          </SliderTrack>
          <Tooltip
          visibility="hidden"
            fontSize="11"
            p="1"
            rounded="md"
            placement="top"
            isOpen={showTooltip}
            label={
              totalUsers !== 1
                ? `${totalUsers} DISPONIBLES`
                : `${totalUsers} DISPONIBLE`
            }
            bg="gray.200"
            color="black"
            fontWeight="bold"
          >
            <SliderThumb />
          </Tooltip>
          <SliderThumb />
        </Slider>

        {isInvalid && (
          <FormErrorMessage mt="1" textStyle="caption">
            {altError ? altError : error}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  }
);
