import { gql } from '@apollo/client';
import { CITY_FRAGMENT } from 'graphql/fragments/city';
import { LOCATION_FRAGMENT } from 'graphql/fragments/location';

export const CITIES = gql`
  query Cities {
    cities {
      ...CityParts
    }
  }
  ${CITY_FRAGMENT}
`;

export const COUNTRIES = gql`
  query Countries {
    countries {
      id
      name
      locations {
        ...LocationParts
      }
      cities {
        ...CityParts
      }
    }
  }
  ${CITY_FRAGMENT}
  ${LOCATION_FRAGMENT}
`;
