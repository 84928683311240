import { Users_allUsers } from 'graphql/generated/Users';
import { removeNulls, WithoutNulls } from 'utils/removeNulls';

export const normalizeUser = (input: WithoutNulls<Users_allUsers>) => ({
  id: input.id,
  locationsId: input.id,
  citiesId: input.citiesId,
  desiredCountries: input.desiredCountries,
  professionsId: input.professionsId,
  userContinent: input.userContinent,
  countryResidence: input.countryResidence,
  developerExperienceYears: input.developerExperienceYears,
  managerExperienceYears: input.managerExperienceYears,
  tecnologiesId: input.tecnologiesId,
  salaryMin: input.salaryMin,
});

export type FormUser = ReturnType<typeof normalizeUser>;

export const normalizeUsers = (
  input: (WithoutNulls<Users_allUsers> | undefined)[]
) =>
  input.reduce<FormUser[]>(
    (acum, input) =>
      !!input ? acum.concat(normalizeUser(removeNulls(input))) : acum,
    []
  );
