import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  Heading,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import useConnect from './connect';

export const Configuration = () => {
  const {
    schema,
    initialValues,
    handleSubmit,
    me,
    massMedias,
    whatsapp,
    email,
    loading,
    contactOffersArray,
    candidacyUpdateArray,
    newsArray,
    loadingMedias,
    sms,
  } = useConnect();

  if (loading || loadingMedias) return null;

  return (
    <>
      <Box
        as="main"
        mx="auto"
        w="100%"
        maxW="50rem"
        mt="12"
        px={{ base: '4', md: '6' }}
      >
        <Text as="h3" textStyle={{ base: 'h4', md: 'h3' }}>
          Configuración
        </Text>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          {({ isSubmitting, handleChange, errors }) => (
            <Form>
              <Box
                bg="white"
                p={{ base: '4', md: '8' }}
                borderRadius="md"
                mt="10"
              >
                <Heading as="h5" size="md" fontWeight="bold">
                  Comunicaciones
                </Heading>

                <Text textStyle="small" color="gray.500" mt="4">
                  Selecciona cómo quieres recibir tus comunicaciones
                </Text>

                <Box>
                  <Text fontWeight="semibold" mt="8">
                    Solicitudes de contacto para ofertas
                  </Text>

                  <FormControl isRequired>
                    <Stack spacing={10} direction="row" mt="4">
                      <CheckboxGroup
                        colorScheme="brand"
                        defaultValue={
                          me?.messagingConfiguration
                            ? contactOffersArray
                            : [email?.id, sms?.id]
                        }
                      >
                        {massMedias.map(({ id, name }) => {
                          return (
                            <Checkbox
                              key={id}
                              name="contactOffers"
                              value={id}
                              onChange={handleChange}
                            >
                              {name}
                            </Checkbox>
                          );
                        })}
                      </CheckboxGroup>
                    </Stack>
                    {errors.contactOffers && (
                      <Text mt="2" color="red">
                        {errors.contactOffers}
                      </Text>
                    )}
                  </FormControl>
                </Box>

                <Box>
                  <Text fontWeight="semibold" mt="8">
                    Actualización de las ofertas y tus candidaturas
                  </Text>

                  <Stack spacing={10} direction="row" mt="4">
                    <CheckboxGroup
                      colorScheme="brand"
                      defaultValue={
                        me?.messagingConfiguration
                          ? candidacyUpdateArray
                          : [whatsapp?.id]
                      }
                    >
                      {massMedias
                        .filter((media) => media.name !== 'SMS')
                        .map(({ id, name }) => {
                          return (
                            <Checkbox
                              key={id}
                              name="candidacyUpdate"
                              value={id}
                              onChange={handleChange}
                            >
                              {name}
                            </Checkbox>
                          );
                        })}
                    </CheckboxGroup>
                  </Stack>
                  {errors.candidacyUpdate && (
                    <Text mt="2" color="red">
                      {errors.candidacyUpdate}
                    </Text>
                  )}
                </Box>

                <Box>
                  <Text fontWeight="semibold" mt="8">
                    Novedades de la aplicación
                  </Text>

                  <Stack spacing={10} direction="row" mt="4">
                    <CheckboxGroup
                      colorScheme="brand"
                      defaultValue={
                        me?.messagingConfiguration ? newsArray : [email?.id]
                      }
                    >
                      {massMedias
                        .filter((media) => media.name !== 'SMS')
                        .map(({ id, name }) => {
                          return (
                            <Checkbox
                              key={id}
                              name="news"
                              value={id}
                              onChange={handleChange}
                            >
                              {name}
                            </Checkbox>
                          );
                        })}
                    </CheckboxGroup>
                  </Stack>
                </Box>
              </Box>

              <Flex alignItems="flex-end" minWidth="max-content">
                <Spacer />
                <Button
                  type="submit"
                  colorScheme="brand"
                  mt="5"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};
