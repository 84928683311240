import { createIcon } from '@chakra-ui/icons';

export const NoCandidatesIllustration = createIcon({
  displayName: 'Illustration',
  viewBox: '0 0 500 420',
  path: (
    <>
      <path
        fill="none"
        stroke="#263238"
        stroke-linecap="round"
        stroke-miterlimit="10"
        d="m105 123 366 342"
      />
      <path
        d="M153.37 78A24.94 24.94 0 0 0 115 52.69a33.65 33.65 0 0 0-64.8 12.8v1.24a21.9 21.9 0 0 0 3 42.77v.23h99.1A15.87 15.87 0 0 0 153.37 78Z"
        fill="none"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        fill="none"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M228.76 177.82v-1.5"
      />
      <path
        fill="none"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2.967 2.967"
        d="M228.76 173.36V68.03"
      />
      <path
        fill="none"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M228.76 66.55v-1.5h-1.5"
      />
      <path
        fill="none"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="3.025 3.025"
        d="M224.24 65.05h-58.99"
      />
      <path
        fill="none"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M163.74 65.05h-1.5"
      />
      <path fill="#263238" d="m233.75 176.37-4.99 8.63-4.98-8.63h9.97z" />
      <path
        fill="#fff"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M246.74 138.41h-37.16V86.8h26.34l10.82 10.73v40.88z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M235.92 86.8v10.54h10.82L235.92 86.8z"
      />
      <path
        fill="none"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M215.32 104.17h25.67M215.32 111.17h25.67M215.32 118.17h25.67M215.32 125.17h25.67"
      />
      <path
        fill="#fff"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M174.98 88.46h-37.16V36.85h26.34l10.82 10.73v40.88z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M164.16 36.85v10.54h10.82l-10.82-10.54z"
      />
      <path
        fill="none"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M143.56 54.22h25.67M143.56 61.22h25.67M143.56 68.22h25.67M143.56 75.22h25.67"
      />
      <path
        fill="none"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="3 3"
        d="M82.4 416V131.14"
      />
      <path fill="#263238" d="m87.38 132.6-4.98-8.64-4.99 8.64h9.97z" />
      <path
        fill="#fff"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M101.52 368.58H64.37v-51.61H90.7l10.82 10.73v40.88z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M90.7 316.97v10.54h10.82L90.7 316.97z"
      />
      <path
        fill="none"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M70.11 334.34h25.67M70.11 341.34h25.67M70.11 348.34h25.67M70.11 355.34h25.67"
      />
      <path
        fill="#fff"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M101.52 231.58H64.37v-51.61H90.7l10.82 10.73v40.88z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M90.7 179.97v10.54h10.82L90.7 179.97z"
      />
      <path
        fill="none"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M70.11 197.34h25.67M70.11 204.34h25.67M70.11 211.34h25.67M70.11 218.34h25.67"
      />
      <g stroke="#b2b2b2" stroke-miterlimit="10">
        <path
          fill="none"
          d="M392.54 24.36h-74.27l-.98 10.21h75.25V24.36zM392.54 34.57h-74.27l-.98 10.21h75.25V34.57zM392.54 44.78h-74.27l-.98 10.21h75.25V44.78zM392.54 54.99h-74.27l-.98 10.21h75.25V54.99zM392.54 65.2h-74.27l-.98 10.21h75.25V65.2zM392.54 75.41h-74.27l-.98 10.21h75.25V75.41zM392.54 85.62h-74.27l-.98 10.21h75.25V85.62z"
        />
        <path
          fill="none"
          d="M392.54 95.83h-74.27l-.98 10.21h75.25V95.83zM392.54 106.04h-74.27l-.98 10.21h75.25v-10.21zM392.54 116.25h-74.27l-.98 10.21h75.25v-10.21z"
        />
        <path
          fill="none"
          d="M392.54 126.46h-74.27l-.98 10.21h75.25v-10.21zM392.54 136.67h-74.27l-.98 10.21h75.25v-10.21zM392.54 146.88h-74.27l-.98 10.22h75.25v-10.22zM392.54 157.1h-74.27l-.98 10.21h75.25V157.1zM392.54 167.31h-74.27l-.98 10.21h75.25v-10.21zM468.89 24.36h-74.27l-.99 10.21h75.26V24.36zM468.89 34.57h-74.27l-.99 10.21h75.26V34.57zM468.89 44.78h-74.27l-.99 10.21h75.26V44.78zM468.89 54.99h-74.27l-.99 10.21h75.26V54.99zM468.89 65.2h-74.27l-.99 10.21h75.26V65.2zM468.89 75.41h-74.27l-.99 10.21h75.26V75.41zM468.89 85.62h-74.27l-.99 10.21h75.26V85.62z"
        />
        <path
          fill="none"
          d="M468.89 95.83h-74.27l-.99 10.21h75.26V95.83zM468.89 106.04h-74.27l-.99 10.21h75.26v-10.21zM468.89 116.25h-74.27l-.99 10.21h75.26v-10.21z"
        />
        <path
          fill="none"
          d="M468.89 126.46h-74.27l-.99 10.21h75.26v-10.21zM468.89 136.67h-74.27l-.99 10.21h75.26v-10.21zM468.89 146.88h-74.27l-.99 10.22h75.26v-10.22zM468.89 157.1h-74.27l-.99 10.21h75.26V157.1zM468.89 167.31h-74.27l-.99 10.21h75.26v-10.21zM315.55 16.45H472.6v7.7H315.55zM472.61 24.15v135.66M470.89 24.37v93.07"
        />
        <path
          fill="#263238"
          d="M471.61 159.51h2.11v3.46h-2.11zM469.7 116.7h2.11v3.46h-2.11z"
        />
      </g>
      <g>
        <path
          d="M292.86 157.09S266.1 167.25 262 171s-27.77 79.95-27.77 79.95h165S387 199.77 384 182.49s-16.26-21-23-22.36-68.14-3.04-68.14-3.04Z"
          fill="#263238"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill="#01aa50"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m337.92 171.54-3.54 79.38h28.61l-11.5-86.33-13.57 6.95z"
        />
        <path
          d="M295.14 150.43s-4.42 6.49-3.54 8.56 10.92 17.7 20.95 19.47 22.71-3.84 29.5-8 15.63-7.37 17.1-8.26-3.83-8-6.19-9.14-5.31-3-5.31-3-3.24-4.13-3.83-5-.89-2.36-1.77-3.54a3.94 3.94 0 0 0-2.95-1.77c-1.48-.29-3.54 7.08-12.39 8.56s-30.39 2.36-30.39 2.36Z"
          fill="#01aa50"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M322.58 178.46s17.11-8.56 19.17-15.34 2.07-18 2.07-18"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M335.56 171.08s7.37-3.24 9.73-7.67 2.36-13.27 2.36-13.27"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M285.65 113.76c-.32 1.2 6.59 35.46 11.54 44.12s15.25 13.6 21.44 10.72c7.16-3.35 15.05-9.92 18.47-17.21.63-1.35 4.12-15.94 3.38-16.19 0 0 4.95 1.65 7-7.42s3.71-14.43 1.24-19.38-16.48-16.49-29.27-14.4-31.74 11.93-33.8 19.76Z"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M319.87 103s-18.14 14-30.51 15.26-12.36-4.5-12.36-4.5-16.08 4.54-14.43-7.83S289 83.66 289 83.66s-8.66-2.47-4.54-7 19.79-9.49 30.1-8.25 14.84 3.71 14.84 3.71-2.89-7.42 3.3-5 11.49 14.48 11.49 14.48 10.72.83 11.55 3.71-6.19 3.69-6.19 3.69 6.6 12.37 3.3 19.38-10.31 9.07-10.72 10.31 1.24 5.77-2.06 4.12-4.54-8.66-4.54-8.66-4.94-4.94-5.36-7-.82-4.53-.82-4.53-2.89 8.66-9.48 9.89 0-9.51 0-9.51Z"
          fill="#263238"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M305 132.73s-8.24 18.14-3.71 18.55 8.66-4.54 8.66-4.54"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M305.44 154.17s5.36 5.77 15.25-2.07M316.24 136.35s1 3.73 8.47 3.05M300.65 135.67s-2.37 3.05-5.08 3.05"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M332.38 127s-19.11-.41-21.17.42-2.47 5.77-1.24 9.07 4.13 7.83 11.55 7.83 11.13-3.71 13.6-10.72-2.74-6.6-2.74-6.6ZM285.22 127s14.71-.41 16.3.42 1.9 5.77 1 9.07-3.18 7.83-8.89 7.83-8.57-3.71-10.47-10.72 2.06-6.6 2.06-6.6ZM336.02 129.33l6.28-8.3M311.21 127.37h-9.79M308.7 131.35h-6.07M283 128.92l4.66-3.03"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M320.81 134.36c0 1.54-.73 2.8-1.63 2.8s-1.62-1.26-1.62-2.8.73-2.8 1.62-2.8 1.63 1.25 1.63 2.8ZM299.27 133.18c0 1.55-.72 2.8-1.62 2.8s-1.62-1.25-1.62-2.8.73-2.8 1.62-2.8 1.62 1.25 1.62 2.8Z"
          fill="#263238"
        />
        <path
          d="M291.6 123.59s5.9-2.36 8.85-.59M314 123.3a16.39 16.39 0 0 1 12.1 1.18"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          d="M219.75 241.05a18.36 18.36 0 0 0-14.91-2c-8.13 2.37-12.19 9.49-12.19 11.86h34.21a14.85 14.85 0 0 0-7.11-9.86Z"
          fill="gray"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M198.74 232.92s-4.4 6.44-4.4 8.81-2.37 4.74 1 5.76 5.42-10.84 5.42-12.19-2.02-2.38-2.02-2.38Z"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M204.84 232.58s-4.74-1-6.1.34-3.72 13.22-3.38 14.57 3.72 1.69 4.74.34 4.74-15.25 4.74-15.25Z"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M225.17 238s-14.23-5.09-17.28-7.12-4.74 2-5.76 4.41-3.39 10.84-3.39 13.21 3.73 3.38 4.41.67 6.1-11.51 6.1-11.51l7.11 2.71s.68 1-2.37 3.05-7.45 6.43-6.1 7.11 5.42.34 6.78-1 8.46-3.05 8.46-3.05 6.44 4.07 7.8 4.41 9.82-1.36 9.82-1.36-9.49-8.13-10.84-9.14a29.12 29.12 0 0 0-4.74-2.39ZM353.5 232.74h-7.11c-3.05 0-9.15-2-9.49 1.35s6.78 5.42 10.16 5.42 5.42-1.35 9.49.68 6.44 3.39 6.44 3.39l-4.75-7.46Z"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M381.62 250.35s-6.44-13.89-9.49-18.29-21-7.79-23-7.79-7.12 11.18-6.44 13.89 2-.34 3-2 4.74-6.43 4.74-6.43 5.76 9.48 8.13 14.56 7.12 5.76 9.83 6.44 13.23-.38 13.23-.38Z"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M365 230s-6.44-8.47-9.83-7.12-8.8 17.62-9.48 19.65 3.39.68 4.06-1 6.78-10.5 6.78-10.5l5.42 3.39"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M369.76 230s-6.77-5.76-9.15-5.09-10.84 17.28-9.48 18 4.06-.68 5.42-2.71 4.06-6.78 4.06-6.78l5.09 3.73"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M373.07 234.66s-3.31-6.67-5.68-5.65-10.5 13.55-8.13 13.55 9.49-7.45 9.49-7.45-.68 5.76 0 8.8"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill="gray"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M363.57 250.92h-88.89v-2.75h88.89z"
        />
        <path
          fill="gray"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M209.79 190.35h89.28l18.09 60.57h-89.28l-18.09-60.57z"
        />
        <path
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M172.24 250.92h315.43"
        />
      </g>
      <g>
        <path
          d="M412 218.41s1.47 15.63.59 20.65-3.25 5.6-3.84 8 3.84 2.07 6.49 1.77 5.6-13 8.85-28.61M435.53 246.87s-6.72.79-6.92 2.57 1.18 3.16 6.32 3.36 18.38-.39 20.56-1.58-4.94-3-9.29-3.56-10.67-.79-10.67-.79Z"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M407.24 184.78s-7.08 5.31-7.67 17.41 9.43 21.23 14.43 23.3 11.21 4.13 13 5.6 2.22 10 4.72 13.87c3.81 5.87 14.16 8.26 27.14 6.78s13.23-15.74 14.45-25.37C474.75 215 468 196 459.74 191s-21.53-3.54-25.66-4.43a17 17 0 0 1-7.38-4.13 17.05 17.05 0 0 0-19.46 2.34Z"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M429.34 182c1.09 3.5-3.33 8-9.86 10s-12.71.84-13.8-2.66 3.32-8 9.86-10 12.71-.86 13.8 2.66Z"
          fill="#01aa50"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M416.89 195.72a3.52 3.52 0 1 1-4.4-2.32 3.53 3.53 0 0 1 4.4 2.32Z"
          fill="#263238"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M420.22 216.64s0 14.75-.59 20.94-1.77 7.67-3.54 8.85-2.07 3.25.59 4.13 6.19-.29 7.07-1.47 6.79-16.82 10.92-30.09"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M471.89 239.16s7.91 4.91 5.55 11.7-28.32 8.26-47.79 8.26-39.82-1.48-38-8 15.93-2.06 29.79-1.18 43.59 1.13 46.32-2.65a36.67 36.67 0 0 0 4.13-8.13ZM382.86 159.23a47.63 47.63 0 0 0 4.33 6.68 16.07 16.07 0 0 0 3.27 3.19s3.85-6.27 2.51-8.92-10.11-.95-10.11-.95ZM425.54 143.81a18.57 18.57 0 0 1 1.28 6.82c0 4-.85 5.6-.85 5.6l-7.53-3.38s4.78-8.01 7.1-9.04Z"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M391.65 162.38s-5.12 10.17-1.47 16.67 12 8.76 15.63 9.2 9.7 1.94 15.14-3.07 6.18-5.34 6.18-5.34 7.88-8.18 6.49-17S420.56 152 420.56 152l5-8.19s-7.11 2.44-9.38 4a46.71 46.71 0 0 1-4.39 2.59 25.69 25.69 0 0 0-9.08 1.54 53.8 53.8 0 0 0-9.08 5.33s-11.36.78-10.75 2 8.77 3.11 8.77 3.11Z"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M406.75 177.88a8.19 8.19 0 0 0 4.29-.77c2.11-1.07 1.25-2.27-.06-2.37a7.75 7.75 0 0 0-4 1.27c-.98.61-1.24 1.11-.23 1.87Z"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M407.07 172.59s-4 1.12-4.33 3.44 2.29 3.88 3.9 2.57a5.73 5.73 0 0 0 1.95-2.94s4.09 2.29 5.86.77 2.31-3.67.74-4.77-8.12.93-8.12.93Z"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M410.1 171.82s-3.33.67-3.68 1.22 1 2.78 2.28 2.27a3.48 3.48 0 0 0 1.81-2.43 1 1 0 0 0-.41-1.06Z"
          fill="#263238"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M401.4 168.94c.76 1.51.74 3.06-.06 3.45s-2-.5-2.8-2-.74-3.06 0-3.45 2.1.49 2.86 2ZM413.49 165.4c.68 1.55.57 3.09-.24 3.45s-2-.62-2.7-2.17-.57-3.09.24-3.44 2.02.61 2.7 2.16Z"
          fill="#263238"
        />
      </g>
      <g stroke="#263238" stroke-linecap="round" stroke-linejoin="round">
        <path
          d="M324.66 470.89a20.5 20.5 0 0 1-7.24-1.72 2.5 2.5 0 1 1 2-4.6c2.31 1 5.27 1.58 6.07 1.24 1.45-1.63 3.7-11.53.8-16.25a2.31 2.31 0 0 0-1.66-1.3c-1.65-.22-4.09 1.47-4.95 2.28a2.5 2.5 0 0 1-3.41-3.66c.46-.43 4.61-4.18 9-3.58a7.24 7.24 0 0 1 5.25 3.64c4.27 6.94 1.34 20.17-1.93 22.79a6.23 6.23 0 0 1-3.93 1.16Z"
          fill="#263238"
        />
        <path fill="#263238" d="M292.6 441.25h27.63v36.67H292.6z" />
        <path
          d="M295.11 439.24s0-3.05 5.59-6.1 8.66-7.64 5.6-16.29-2.54-14.76 3.57-19.85M296.38 406.69c.28-3.72 1.95-6.86 5.34-9.69M298.16 416.85a37 37 0 0 1-1.35-4.72M317.84 419.83a24 24 0 0 0-.85-3c-.16-.47-.32-.93-.46-1.38M305.79 439.24s0-3.05 5.6-6.1c3.68-2 6.25-4.67 6.73-8.7"
          fill="none"
        />
      </g>
      <g>
        <path
          d="M367.47 434s-3.1-3.94-6.47-5.62-10.4-3.1-10.4-3.1 6.18-1.4 11-.28 8.16 5.91 8.16 5.91-1.41-2-3.38-7.88-2-18.55-2-18.55a40 40 0 0 1 7 11.81 58.73 58.73 0 0 1 2.81 12.09s-.56-9.84 1.13-15.46 6.46-12.65 6.46-12.65.28 12.36-1.68 19.11-2.82 9.84-2.82 9.84a24.26 24.26 0 0 1 5.91-9.28c4.21-3.93 14.33-7.31 14.33-7.31s-4.78 8.72-7.87 11.53-6.18 5.9-6.18 5.9a16.33 16.33 0 0 1 9.27-2.53c5.91 0 12.93 3.94 12.93 3.94s-20.51-.28-24.45 3.09Z"
          fill="#01aa50"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m371.28 432.48-3.46-2.39M375.01 434.35l-4.79-12.79M374.48 435.95l3.2-18.65M375.01 434.35l9.06-11.19M373.24 433.2l13.23-3.38"
        />
        <path
          d="M391.05 477.92H363l-1.27-10.19a95.45 95.45 0 0 1-.13-22.41l1.4-12.45h28.1l1.38 12.18a94.74 94.74 0 0 1-.1 22.39Z"
          fill="#263238"
        />
        <path
          fill="#263238"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m392.91 458.78-4.21-25.58-5.63 44.13-9.83-44.13-5.91 44.7-4.38-45.03"
        />
        <path
          d="M391.05 477.92H363l-1.27-10.19a95.45 95.45 0 0 1-.13-22.41l1.4-12.45h28.1l1.38 12.18a94.74 94.74 0 0 1-.1 22.39Z"
          fill="none"
          stroke="#263238"
          stroke-miterlimit="10"
        />
      </g>
      <g fill="none" stroke="#b2b2b2" stroke-miterlimit="10">
        <circle cx="132.46" cy="268" r="36.5" />
        <circle cx="132.46" cy="268" r="29.2" />
        <path d="M127.91 268a4.55 4.55 0 1 0 4.55-4.54 4.55 4.55 0 0 0-4.55 4.54ZM132.46 272.54v17.21M137 268h10.69" />
      </g>
      <g>
        <path
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M437 477.92H12.03"
        />
        <path
          d="M207.65 349s-2.67 18.3 0 21.74 8.77 6.1 17.54 8.77 14.49-3.06 22.87 6.48 15.26 33.93 19.83 41.56S277.81 447 279 455.81s-2.67 13.72-10.3 14.87-21-17.54-22.49-20.21-8.82-11.47-8.82-11.47-4.2 30.89-4.2 32.41.38 6.48.38 6.48H123.76s8.39-45.75 10.67-61.77 1.15-26.69 10.3-32.41 17.54-4.19 23.64-6.1 9.91-3.43 10.3-6.86.38-19.45.38-19.45Z"
          fill="#01aa50"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill="#01aa50"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M127.72 459.88 77.5 473.1l3.97 4.84h57.27"
        />
        <path
          d="M71.1 468.05a18.34 18.34 0 0 0-14.9-2c-8.13 2.37-12.2 9.49-12.2 11.86h34.22a14.84 14.84 0 0 0-7.12-9.86Z"
          fill="gray"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M50.1 459.92s-4.41 6.44-4.41 8.81-2.37 4.74 1 5.76 5.42-10.84 5.42-12.19-2.01-2.38-2.01-2.38Z"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M56.2 459.59s-4.75-1-6.1.33-3.73 13.22-3.39 14.57 3.73 1.69 4.74.34 4.75-15.24 4.75-15.24Z"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="m90.76 469.8-8-.91a13.24 13.24 0 0 0-1.48-1.51 28.72 28.72 0 0 0-4.76-2.38s-14.23-5.09-17.28-7.12-4.74 2-5.75 4.41-3.39 10.84-3.39 13.21 3.72 3.38 4.4.67 6.1-11.51 6.1-11.51l7.11 2.71s.68 1-2.37 3.05-7.45 6.43-6.1 7.11 5.42.34 6.78-1 8.47-3 8.47-3 6.44 4.07 7.79 4.41a15.63 15.63 0 0 0 2.64.16h8.26a8.71 8.71 0 0 0-2.42-8.3Z"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M66.21 469.8s3-3.42 5.43-3.83"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M263 442.11a97.93 97.93 0 0 1 10.63 4.15c4.93 2.33 6.75 8 6 15.05s-5.71 12.46-13 10.38-11.16-8-11.42-12.72-2.37-15.31 7.79-16.86ZM185.91 401.66c-17.16-.61-28.29-11.18-34.94-20.86 6.69-2.24 12.72-1.7 17.4-3.16 6.1-1.91 9.91-3.43 10.3-6.86s.38-19.45.38-19.45l28.6-2.29s-2.67 18.3 0 21.74 8.77 6.1 17.54 8.77c3.84 1.16 7.09.72 10.25.66-5.15 5.62-22.83 22.4-49.53 21.45Z"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M179 362.79a39.42 39.42 0 0 0 7.1 5.45c4.58 2.58 12.42.29 14.71-2.57 1.12-1.4 3.43-4.16 5.9-7.48.35-4.83 1-9.15 1-9.15l-28.6 2.29s-.06 6.01-.11 11.46Z"
          fill="#263238"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M266.34 408.63s-15.57 20.5-17.13 29.07-6.49 29.3-6.49 29.3-15.05 6.49-24.65 6.75-26.21-1.3-26.21-1.3 10.64-27.45 14.79-41.24 5.19-21.54 5.19-21.54 23.62 3.11 37.11 0l20.24-4.67Z"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M265.6 451.61s-1.9-24-4.19-24.78-16-2.67-19.83-1.91-5 2.29-1.9 3.05 8.38 1.53 10.67 1.91a68.55 68.55 0 0 1 7.63 2.29s-8.39 1.14-11.82 1.14-11.44 3.43-11.82 5a3.59 3.59 0 0 0 .38 2.67 4.39 4.39 0 0 0 .06 2.39c.38 1.53 9.47.77 11.76 1.15s6.86 1.48 6.86 1.48-13.34-.76-13.34 1.91 5.71 2.28 8 2.28a15.38 15.38 0 0 1 7.63 3.05c1.14 1.15.76 7.13 4.19 8.28a18.18 18.18 0 0 0 4.58 1.14"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M234.72 440.94s15.25-3.44 22.88.38M152 420.35s-6.1 21.35-4.95 29 4.57 16.39 4.95 17.54-1.34 10.19-1.34 10.19"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M139.43 302.14c3.43-3.43 0-5.34.38-9.15s4.19-5.72 6.1-6.48 1.14-2.67 1.53-6.87 4.95-5.72 8-6.86 1.15-3 2.67-6.1 9.92-2.29 9.92-2.29 1.9-3.43 4.19-3.43 3.81 3.05 6.86.38 5.72-3.81 9.92-2.29a30.32 30.32 0 0 0 4.17 1.32 30 30 0 0 0 4.18-1.32c4.19-1.52 6.86-.38 9.91 2.29s4.58-.38 6.87-.38 4.19 3.43 4.19 3.43 8.39-.76 9.92 2.29-.39 4.95 2.66 6.1 7.63 2.67 8 6.86-.38 6.1 1.53 6.87 5.72 2.66 6.1 6.48-3 5.72.38 9.15 6.86 6.1 3.81 8.39-6.1 3.05-5.72 6.48 4.2 7.63 1.15 9.53-6.1 1.91-6.1 5.34 1.14 8.39-2.29 9.15-8.39 0-8.39 2.29-.38 6.48-5.72 5.72-7.24-2.29-8.39.76-3.81 5-6.86 4.58-7.25-.76-9.92 1.52a9.25 9.25 0 0 1-4.54 1.88v.13h-1.56v-.13a9.33 9.33 0 0 1-4.54-1.88c-2.67-2.28-6.86-1.9-9.91-1.52s-5.72-1.53-6.86-4.58-3.05-1.52-8.39-.76-5.72-3.43-5.72-5.72-5-1.52-8.39-2.29-2.29-5.72-2.29-9.15-3-3.43-6.1-5.34.76-6.1 1.14-9.53-2.67-4.19-5.72-6.48.4-4.96 3.83-8.39Z"
          fill="#263238"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M170 323.21s-4.34-6.51-6.95-3.47-1.73 10.85 1.74 14.76 7.37 2.6 7.37 2.6M216.41 322.88s5.07-6 7.3-2.64.45 11-3.45 14.45-7.63 1.73-7.63 1.73"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M214.6 307.48a6.91 6.91 0 0 1-5.72 1.71 17.77 17.77 0 0 1-5.43-1.71s-3.43 2.29-6 1.43-5.44-1.72-5.44-1.72a8.85 8.85 0 0 1-6.29 1.15c-4-.58-5.15-1.43-5.15-1.43s-2.29 2-4.57 2a33.6 33.6 0 0 1-4.29-.29 5.71 5.71 0 0 1-2 .57h-2s-.85 17.16 2 29.46 14 21.45 18.59 24 12.43.29 14.72-2.57 9.59-11.44 13-18.88 4.58-29.17 4.58-30.6-4.6.31-6-3.12Z"
          fill="#fff"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M198.46 317.49a2.09 2.09 0 0 0-1.27 2c.1 3.24.58 10.94 2.83 12.29 2.86 1.71 16.21 3 19.45.85 1.71-1.14 5.43-11.15 4-14s-13.57-5.84-25.01-1.14ZM189.63 317.49a2.09 2.09 0 0 1 1.27 2c-.1 3.24-.58 10.94-2.83 12.29-2.86 1.71-16.21 3-19.45.85-1.71-1.14-5.43-11.15-4-14s13.57-5.84 25.01-1.14Z"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M198.46 317.49s-5.3-1.15-8.74.57M196.59 320.92h-5.44"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <ellipse cx="205.17" cy="325.21" rx="1.72" ry="3.15" fill="#263238" />
        <ellipse cx="185.72" cy="325.21" rx="1.72" ry="3.15" fill="#263238" />
        <path
          d="M196 327.21s-.29 4 1.14 5.43 3.14 2.58 1.14 4-3.71 2.57-6.57 0M201.16 342.65s-7.15 3.44-13.44-1.14M210.6 318.92a8.44 8.44 0 0 0-8-1.15M187.15 318.63s-6.58-3.43-10.3 2M210.89 373.25l-22.31 54.91-13.16-52.62"
          fill="none"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M185.72 430.74a3.15 3.15 0 1 0 3.15-3.15 3.15 3.15 0 0 0-3.15 3.15Z"
          fill="#263238"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill="gray"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M203.97 477.93h-86.18v-2.67h86.18z"
        />
        <path
          fill="gray"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M54.88 419.2h86.55l17.54 58.72H72.42L54.88 419.2z"
        />
      </g>
    </>
  ),
});
