import {
  Box,
  BoxProps,
  Flex,
  Text,
  HStack,
  Icon,
  Progress,
  Tag,
  Center,
} from '@chakra-ui/react';
import { OfferTags } from 'components/OfferTags';
import { FiClock, FiLock, FiAward } from 'react-icons/fi';
import { normalizeDuration } from 'utils/normalizeDuration';

interface Props extends BoxProps {
  title: string;
  subtitle?: string;
  openTag?: string;
  tags?: string[];
  durationMinutes?: number;
  progress?: number;
  locked?: boolean;
  quizApproved?: boolean;
  active?: boolean;
}

export const CourseCard = ({
  title,
  subtitle,
  openTag,
  tags,
  durationMinutes = 0,
  progress = 0,
  locked,
  quizApproved = false,
  active,
  ...props
}: Props) => {
  return (
    <Box
      position="relative"
      borderRadius="lg"
      bg="white"
      shadow="base"
      overflow="hidden"
      p="6"
      {...(active
        ? {
            border: '1px solid',
            borderTop: '6px solid',
            borderColor: 'brand.500',
            pt: '4',
          }
        : {
            pt: '6',
          })}
      {...props}
    >
      {locked && (
        <Center
          position="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          bg="white"
          opacity="0.9"
        >
          <Icon as={FiLock} w="12" h="12" color="gray.300" />
        </Center>
      )}
      <Flex direction="column">
        <Text as="h5" textStyle="h5" noOfLines={2}>
          {title}
        </Text>
        {subtitle && (
          <Text textStyle="body" color="gray.500" mt="2">
            {subtitle}
          </Text>
        )}
        {openTag && (
          <Tag colorScheme="brand" mt="2" w="fit-content">
            <Text textStyle="small">{openTag}</Text>
          </Tag>
        )}
        {tags && <OfferTags tags={tags} mt="5" />}
        <Flex justify="space-between" mt="5">
          <HStack spacing="2">
            <Icon color="gray.300" as={FiClock} />
            <Text textStyle="caption" color="gray.500">
              {normalizeDuration(durationMinutes)}
            </Text>
          </HStack>
          {quizApproved && (
            <HStack spacing="2">
              <Text textStyle="caption" color="gray.500">
                Superado
              </Text>
              <Icon color="brand.500" as={FiAward} />
            </HStack>
          )}
        </Flex>
        {progress >= 0 && (
          <Progress
            value={progress}
            colorScheme="brand"
            size="sm"
            mt="2"
            borderRadius="full"
          />
        )}
      </Flex>
    </Box>
  );
};
