import { Link as RouterLink } from 'react-router-dom';
import { Box, Divider, Button, Text } from '@chakra-ui/react';

import { FiChevronLeft } from 'react-icons/fi';

import { useConnect } from './connect';
import { JobHeader } from 'components/JobHeader';
import { AcceptButtonWithModal } from './AcceptButtonWithModal';
import { CancelButtonWithModal } from './CancelButtonWithModal';
import { Markdown } from 'components/Markdown';
import { quizRenderer } from 'components/Markdown/QuizRenderer';
import { sanitizeDate } from 'utils/sanitizeDate';

export const Job = () => {
  const { jobRequest, loading, handleAccept, handleReject } = useConnect();

  if (loading && !jobRequest) {
    return null;
  }

  if (!jobRequest) {
    return null;
  }

  const { position, isAccepted } = jobRequest;
  const created_at = sanitizeDate(position.created_at);

  let locations;

  locations = position.countries.filter((country) => country === 'Remoto');

  if (!locations.length) locations = position.cities;

  return (
    <>
      <Box
        as="main"
        mx="auto"
        w="100%"
        maxW="50rem"
        mt="12"
        px={{ base: '4', md: '6' }}
      >
        <Button
          as={RouterLink}
          to="/jobs"
          variant="link"
          leftIcon={<FiChevronLeft />}
        >
          Volver a mis oportunidades
        </Button>
      </Box>

      <JobHeader
        created_at={created_at}
        title={position.name}
        company={position.company}
        skills={position.skills}
        experienceYears={position.experienceYears}
        location={locations}
        salary={position.salary}
        buttons={false}
      >
        <Divider
          my={{ sm: '4', lg: '8' }}
          mb={{ base: '4' }}
          color="gray.200"
        />

        <Markdown source={position.description} renderers={quizRenderer()} />

        {isAccepted === undefined ? (
          <>
            {' '}
            <AcceptButtonWithModal
              companyName={position.company.name}
              handleAccept={handleAccept}
              isAccepted={isAccepted}
            />
            <CancelButtonWithModal handleReject={handleReject} />
          </>
        ) : (
          <Text fontSize="0.7em" my={{ lg: '10' }} color="gray.400">
            Ya has respondido a esta oferta
          </Text>
        )}
      </JobHeader>
    </>
  );
};
