import { Link as RouterLink } from 'react-router-dom';

import { Flex, FlexProps, Text, Box, Button } from '@chakra-ui/react';
// import { DoAllModalRequest } from './DoAllModalRequest';
import { FiArrowRight } from 'react-icons/fi';
import { Path } from 'model/UserPath';
//import useUserPathActions from 'graphql/hooks/career/useUserPathActions';

interface Props extends FlexProps {
  userPath: Path;
}

export const NoLevelPlaceholder = ({ userPath, ...props }: Props) => {
  // const { initialiceLevel } = useUserPathActions();

  // const handleDoAll = useCallback(async () => {
  //  await initialiceLevel({ where: { id: userPath?.id } });
  // }, [initialiceLevel, userPath]);

  return (
    <Flex
      bg="white"
      px={{ base: '10', lg: '20' }}
      py={{ base: '10' }}
      shadow="base"
      borderRadius="md"
      direction={{ base: 'column', lg: 'row' }}
      justifyContent="space-between"
      alignItems={{ base: 'flex-start', lg: 'center' }}
      {...props}
    >
      <Box>
        <Text as="h4" textStyle={{ base: 'h4' }}>
          Evaluación inicial
        </Text>
        <Text textStyle="body" color="gray.500" mt="4" maxW="lg">
          Puedes certificar tus conocimientos realizando el test de evaluación
          inicial, o si lo prefieres, puedes realizar todo el itinerario de
          formación.
        </Text>
      </Box>
      {/* <DoAllModalRequest onAccept={handleDoAll} /> */}
      <Button
        colorScheme="brand"
        rightIcon={<FiArrowRight />}
        mt={{ base: '6', lg: '0' }}
        ml={{ base: '0', lg: '6' }}
        as={RouterLink}
        to={`/quiz/path/${userPath.id}`}
      >
        Ir al test de evaluación
      </Button>
    </Flex>
  );
};
