import {
  Box,
  BoxProps,
  Flex,
  Text,
  HStack,
  Icon,
  Progress,
  Tag,
  Center,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  useDisclosure,
} from '@chakra-ui/react';
import { OfferTags } from 'components/OfferTags';
import { useRef } from 'react';
import { FiClock, FiLock, FiAward } from 'react-icons/fi';
import { normalizeDuration } from 'utils/normalizeDuration';

interface Props extends BoxProps {
  title: string;
  subtitle?: string;
  openTag?: string;
  tags?: string[];
  durationMinutes?: number;
  progress?: number;
  locked?: boolean;
  active?: boolean;
}

export const WorkshopCard = ({
  title,
  openTag,
  tags,
  durationMinutes = 0,
  progress = 0,
  locked,
}: Props) => {
  const initialRef = useRef(null);
  const { isOpen, onToggle, onClose } = useDisclosure();

  const handleHover = (enter: boolean) => {
    if (locked) {
      if (enter) onToggle();
      else onClose();
    }
  };

  return (
    <Popover
      isLazy
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
    >
      <PopoverTrigger>
        <Box
          position="relative"
          borderRadius="lg"
          bg="white"
          shadow="base"
          overflow="hidden"
          p="6"
          pt="4"
          onMouseOver={() => handleHover(true)}
          onMouseOut={() => handleHover(false)}
        >
          {locked && (
            <Center
              position="absolute"
              top="0"
              left="0"
              w="100%"
              h="100%"
              bg="white"
              opacity="0.9"
            >
              <Icon as={FiLock} w="12" h="12" color="gray.300" />
            </Center>
          )}
          <Flex direction="column">
            <Text as="h5" textStyle="h5" noOfLines={2}>
              {title}
            </Text>
            {openTag && (
              <Tag colorScheme="brand" mt="2" w="fit-content">
                <Text textStyle="small">{openTag}</Text>
              </Tag>
            )}
            {tags && <OfferTags tags={tags} mt="5" />}
            <Flex justify="space-between" mt="5">
              <HStack spacing="2">
                <Icon color="gray.300" as={FiClock} />
                <Text textStyle="caption" color="gray.500">
                  {normalizeDuration(durationMinutes)}
                </Text>
              </HStack>
              {progress >= 85 && (
                <HStack spacing="2">
                  <Text textStyle="caption" color="gray.500">
                    Superado
                  </Text>
                  <Icon color="blue.400" as={FiAward} />
                </HStack>
              )}
            </Flex>
            {progress >= 0 && (
              <Progress
                value={progress}
                colorScheme="blue"
                size="sm"
                mt="2"
                borderRadius="full"
              />
            )}
          </Flex>
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader fontWeight="bold" border="0">
          Acceso Bloqueado
        </PopoverHeader>
        <PopoverBody fontSize="15" ref={initialRef}>
          Desbloquea el primer curso para acceder a este
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
