import { FC } from 'react';
import { Box, Flex, Text, Center, Container } from '@chakra-ui/react';
import { Illustration } from './Illustration';
import { IllustrationRegister } from './IllustrationRegister';
import { NoLoginHeader } from 'components/NoLoginHeader';

interface Props {
  title?: string;
  subtitle?: string;
  register?: boolean;
}

export const LayoutTest: FC<Props> = ({
  title,
  subtitle,
  children,
  register,
}) => {
  return (
    <>
      <NoLoginHeader />
      <Container maxW="container.xl" bg="white" mt="8">
        <Flex
          direction={{ base: 'column', md: 'row' }}
          overflow="hidden"
          minH="60vh"
          height="100%"
        >
          <Box flex="1" p="10" display={{ base: 'none', md: 'block' }}>
            <Flex
              maxW={{ md: 'sm' }}
              mr={{ lg: '20' }}
              ml="auto"
              h="100%"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Center flexDirection="column">
                {register ? (
                  <IllustrationRegister maxW="400px" w="100%" h="500px" />
                ) : (
                  <Illustration maxW="400px" w="100%" h="300px" />
                )}
              </Center>
              <Box />
            </Flex>
          </Box>

          <Box flex="1" p={{ base: '5', md: '10' }} overflowY="auto">
            <Flex
              maxW={{ md: 'sm' }}
              ml={{ lg: '20' }}
              h="100%"
              mx="auto"
              alignItems="center"
            >
              <Box flex="1">
                {title && (
                  <Text as="h3" textStyle="h3" mb="1">
                    {title}
                  </Text>
                )}
                {subtitle && (
                  <Text as="h3" textStyle="body" mb="10">
                    {subtitle}
                  </Text>
                )}
                {children}
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </>
  );
};
