import { useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  useDisclosure,
  Link,
  Tab,
  Text,
  Tag,
} from '@chakra-ui/react';

interface Props {
  job: any;
  newInscribed: any[];
}

export const InscribedTab = ({ job, newInscribed }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const isInvisible = job.position.state === 'invisible';

  const handleHover = () => {
    if (isInvisible) onOpen();
  };
  return (
    <Popover
      isLazy
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
      closeOnBlur
    >
      <PopoverTrigger>
        <Tab
          px="3"
          as={RouterLink}
          to={
            isInvisible
              ? `/company/mistery/job/${job.position.id}`
              : `/company/job/inscribed/${job.position.id}`
          }
          _selected={{ color: 'black', bg: '#FFFF' }}
          color="#8E9492"
          fontWeight="semibold"
          onMouseOver={handleHover}
        >
          <Text textStyle="body">
            Inscritos{' '}
            {newInscribed && newInscribed.length > 0 ? <Tag>!</Tag> : ''}{' '}
          </Text>
        </Tab>
      </PopoverTrigger>

      <PopoverContent p="2">
        <PopoverCloseButton />
        <PopoverArrow />
        <PopoverHeader fontWeight="bold" border="0">
          Sin acceso a job posting
        </PopoverHeader>

        <PopoverBody>
          Contacta con nuestros comerciales para contratar un bono de job
          posting y que los usuarios puedan inscribirse a tu oferta
        </PopoverBody>

        <PopoverFooter border="0" pb="3">
          <Link
            ref={initialRef}
            isExternal
            background="#01AA50"
            color="white"
            py="1.5"
            px="3.5"
            borderRadius="md"
            href={`mailto:ruben.berrocal@flyncer.com?subject=&body=`}
          >
            Contactar
          </Link>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
