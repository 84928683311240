import { FieldProps } from 'formik';
import { memo, useCallback, useEffect } from 'react';
import { Stack, Text } from '@chakra-ui/react';
import Select from 'react-select';
import { Country } from 'model/Country';
import { CustomStyles } from 'styles/customStyles';
import makeAnimated from 'react-select/animated';

interface Props extends FieldProps {
  isSelected: { current: boolean };
  countries: Country[];
  className?: string;
  fromCompany: boolean;
  setforceRender?: (isSend: boolean) => void;
  seterrorLocations?: (isSend: boolean) => void;
}

interface Element {
  label: string;
  value: string;
}

// TODO: hay que mejorar el componente de autocomplete estilos y lógica
// TODO: hay que mejorar la normalización/denormalización de los items del Autocomplete
export const DesiredCountries = memo(
  ({
    countries,
    className,
    fromCompany,
    setforceRender,
    seterrorLocations,
    field,
    form,
    isSelected,
    ...props
  }: Props) => {
    const animatedComponents = makeAnimated();
    const isMulti = true;
    const orderedCountries = countries.sort(function (a: any, b: any) {
      return a.name.localeCompare(b.name);
    });

    // const selectedCountries = countries?.filter((country) =>
    //   countriesValue?.find((value) => value.label === country?.name)
    // );

    const options = orderedCountries.map((country) => ({
      label: country.name,
      value: country.id,
    }));

    const onChange = (option: any) => {
      if (seterrorLocations && option.length) seterrorLocations(false);

      form.setFieldValue(
        field.name,
        isMulti
          ? (option as Element[]).map((item: Element) => item.value)
          : (option as Element).value
      );
    };

    const getValue = useCallback(() => {
      if (options) {
        return isMulti
          ? options.filter((option: Element) =>
              field.value.some((item: string) => item === option.value)
            )
          : options.find((option: Element) => option.value === field.value);
      } else {
        return isMulti ? [] : ('' as any);
      }
    }, [field.value, isMulti, options]);

    useEffect(() => {
      const results = getValue();

      isSelected.current = results.some(
        (option: any) => option.label === 'España'
      );
    }, [getValue, isSelected]);

    let locations: any = [];

    // selectedCountries.forEach((country) => {
    //   country?.cities.forEach(
    //     (location) => (locations = [...locations, location])
    //   );
    // });

    return (
      <>
        {!fromCompany && (
          <Stack
            spacing={8}
            {...props}
            mt="-7"
            onClick={() => {
              if (setforceRender) setforceRender(true);
            }}
          >
            <Text as="h5" textStyle="h6" mt="5" mb="-4">
              Escoge los países
            </Text>

            <Select
              className={className}
              name={field.name}
              placeholder="País"
              value={getValue()}
              onChange={onChange}
              options={options}
              isMulti
              styles={CustomStyles}
              components={animatedComponents}
            />
            {/* <CUIAutoComplete
              label=""
              placeholder="País"
              items={orderedCountries.map((location: any) => ({
                label: location.name,
                value: location.id,
              }))}
              tagStyleProps={{
                h: '20px',
                borderRadius: 'full',
                variant: 'subtle',
                colorScheme: 'green',
              }}
              inputStyleProps={{
                mt: '3',
              }}
              listStyleProps={{}}
              toggleButtonStyleProps={{ alignSelf: 'flex-end' }}
              selectedItems={countriesValue}
              onCreateItem={(item) => console.log('create item', item)}
              onSelectedItemsChange={({ type, selectedItems }) => {
                if (seterrorLocations) seterrorLocations(false);
                if (type === '__function_remove_selected_item__') {
                  setCountriesValue(selectedItems || []);
                } else if (selectedItems) {
                  setCountriesValue(selectedItems);
                }
              }}
            /> */}
          </Stack>
        )}

        {!!locations?.length && (
          <Stack
            {...props}
            spacing={8}
            onClick={() => setforceRender && setforceRender(true)}
          >
            {fromCompany ? (
              <Text fontSize="14" fontWeight="semibold" mt="5" mb="-8">
                ubicación de la oferta *
              </Text>
            ) : (
              <Text as="h6" textStyle="h6" mt="5" mb="-4">
                ¿En qué provincias de España?
              </Text>
            )}
            {/* <Field
              className="custom-select"
              name={citiesName}
              options={locations.map((location: any) => ({
                label: location?.name,
                value: location?.id,
              }))}
              component={CustomSelect}
              placeholder={
                fromCompany ? 'Ubicación de la oferta' : 'Ciudades de España'
              }
              isMulti={true}
            /> */}
          </Stack>
          // <Stack
          //   spacing={8}
          //   {...props}
          //   onClick={() => {
          //     if (setforceRender) setforceRender(true);
          //   }}
          // >
          //   {fromCompany ? (
          //     <Text fontSize="14" fontWeight="semibold" mt="5" mb="-8">
          //       Ubicación de la oferta *
          //     </Text>
          //   ) : (
          //     <Text as="h5" textStyle="h6" mt="5" mb="-4">
          //       ¿En qué ciudades de España?
          //     </Text>
          //   )}

          //   <CUIAutoComplete
          //     label=""
          //     placeholder={
          //       fromCompany ? 'Ubicación de la oferta' : 'Ciudades de España'
          //     }
          //     items={locations?.map((location: any) => ({
          //       label: location?.name,
          //       value: location?.id,
          //     }))}
          //     tagStyleProps={{
          //       h: '20px',
          //       borderRadius: 'full',
          //       variant: 'subtle',
          //       colorScheme: 'green',
          //     }}
          //     inputStyleProps={{
          //       mt: '3',
          //     }}
          //     listStyleProps={{}}
          //     toggleButtonStyleProps={{ alignSelf: 'flex-end' }}
          //     selectedItems={citiesValue}
          //     onCreateItem={(item) => console.log('create item', item)}
          //     onSelectedItemsChange={({ type, selectedItems }) => {
          //       if (seterrorLocations) seterrorLocations(false);
          //       if (type === '__function_remove_selected_item__') {
          //         setCitiesValue(selectedItems || []);
          //         if (setLocationsState) {
          //           setLocationsState(selectedItems || []);
          //         }
          //       } else if (selectedItems) {
          //         setCitiesValue(selectedItems);
          //         if (setLocationsState) {
          //           setLocationsState(selectedItems);
          //         }
          //       }
          //     }}
          //   />
          // </Stack>
        )}
      </>
    );
  }
);
