import {
  FormControl,
  FormErrorMessage,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import { useField } from 'formik';

interface Props extends TextareaProps {}

export const FormTextarea = ({ name = '', ...props }: Props) => {
  const [field, { error }] = useField(name);

  const isInvalid = !!error;
  return (
    <FormControl name={name} isInvalid={isInvalid}>
      <Textarea {...props} {...field} />
      {isInvalid && (
        <FormErrorMessage mt="1" textStyle="caption">
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
