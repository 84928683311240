import { gql } from '@apollo/client';

export const ONE_USER_WORKSHOP = gql`
  query oneUserWorkshops {
    oneUserWorkshops {
      user {
        name
      }
      progress
    }
  }
`;
