import { Wrap, WrapProps, Text, HStack } from '@chakra-ui/react';

interface Props extends WrapProps {
  tags: any[];
}

export const Tags = ({ tags, ...props }: Props) => {
  return (
    <Wrap {...props}>
      {tags?.map((tag) => {
        return (
          <HStack
            color="gray.500"
            bg={'gray.100'}
            key={tag.id}
            p="0.5"
            pl="2"
            borderRadius="5"
            m="1"
            spacing="2"
          >
            {tag?.paths?.map((path: any, i: number) => (
              <>
                <Text>{path.name}</Text>
                <Text fontWeight="semibold">
                  {tag.paths.length - 1 !== i && 'OR'}
                </Text>
              </>
            ))}
          </HStack>
        );
      })}
    </Wrap>
  );
};
