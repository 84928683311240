import { gql } from '@apollo/client';
import { STUDY_FRAGMENT } from 'graphql/fragments/study';
import { FILE_FRAGMENT } from 'graphql/fragments/file';
import { CV_FRAGMENT2 } from 'graphql/fragments/meCV';

export const USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      email
      phoneNumber
      name
      surname
      linkedinURL
      githubURL
      cv {
        ...FileParts
      }
      user_cv {
        ...MeCVParts2
      }
      englishLevel
      studies {
        ...StudyParts
      }
      careers {
        id
        career {
          name
        }
        activePaths {
          id
          path {
            name
          }
          level {
            name
          }
        }
      }
    }
  }
  ${STUDY_FRAGMENT}
  ${FILE_FRAGMENT}
  ${CV_FRAGMENT2}
`;

export const HAS_PHONE_NUMBER = gql`
  query hasPhoneNumber($id: ID!) {
    hasPhoneNumber(id: $id)
  }
`

export const ONE_USER = gql`
  query oneUser($uuid: String) {
    oneUser(uuid: $uuid) {
      id
      email
      phoneNumber
      name
      surname
      linkedinURL
      githubURL
      cv {
        ...FileParts
      }
      photo {
        ...FileParts
      }
      user_cv {
        ...MeCVParts2
      }
      englishLevel
      studies {
        ...StudyParts
      }
      careers {
        id
        career {
          name
        }
        activePaths {
          id
          path {
            name
          }
          level {
            name
          }
        }
      }
    }
  }
  ${STUDY_FRAGMENT}
  ${FILE_FRAGMENT}
  ${CV_FRAGMENT2}
`;

export const ALL_USERS = gql`
  query Users {
    allUsers {
      id
      locationsId
      citiesId
      desiredCountries
      professionsId
      userContinent
      countryResidence
      developerExperienceYears
      managerExperienceYears
      tecnologiesId
      salaryMin
    }
  }
`;
