import {
  Button,
  useDisclosure,
  ButtonProps,
  useMediaQuery,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import useConnect from '../connect';
import { CompleteProfileModal } from './CompleteProfileModal';
import { PrelevelModal } from './PrelevelModal';

interface Props extends ButtonProps {
  isCompleted: boolean | undefined;
  canApply: boolean;
  isSend: boolean;
}

const schema = Yup.object().shape({
  dataConsent: Yup.boolean()
    .required('La empresa necesita tus datos para contactarte')
    .oneOf([true], 'La empresa necesita tus datos para contactarte'),
});

const initialValues = {
  dataConsent: false,
};

export const ApplyButton = ({
  isCompleted,
  canApply,
  isSend,
  ...props
}: Props) => {
  const {
    isOpen: isOpenPrelevel,
    onOpen: onOpenPrelevel,
    onClose: onClosePrelevel,
  } = useDisclosure();
  const {
    isOpen: isOpenCompleteProfile,
    onOpen: onOpenCompleteProfile,
    onClose: onCloseCompleteProfile,
  } = useDisclosure();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { replace } = useHistory();
  const { id } = useConnect();

  const handleClick = useCallback(() => {
    if (isCompleted) {
      replace(`/jobsOffer/apply/${id}`);
      onOpenPrelevel();
    } else {
      onOpenCompleteProfile();
    }
  }, [isCompleted, onOpenCompleteProfile, onOpenPrelevel, replace, id]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleClick}
        validationSchema={schema}
      >
        <Form>
          <Button
            {...props}
            onClick={handleClick}
            colorScheme="brand"
            mb={isMobile ? 20 : 10}
          >
            Aplicar
          </Button>

          {isOpenCompleteProfile && (
            <CompleteProfileModal
              isOpen={isOpenCompleteProfile}
              onClose={onCloseCompleteProfile}
            />
          )}

          {isOpenPrelevel && (
            <PrelevelModal isOpen={isOpenPrelevel} onClose={onClosePrelevel} />
          )}
        </Form>
      </Formik>
    </>
  );
};
