import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  UPDATE_EXPERIENCE,
  DELETE_EXPERIENCE,
  IMPORT_LINKEDIN_EXP,
  DELETE_ALL_EXPERIENCES,
} from 'graphql/mutations/userCVExperience';
import { FlyncerError } from 'graphql/helpers';
import {
  UpdateExperience,
  UpdateExperienceVariables,
} from 'graphql/generated/UpdateExperience';
import {
  ImportLinkedinExp,
  ImportLinkedinExpVariables,
} from 'graphql/generated/ImportLinkedinExp';
import {
  DeleteExperience,
  DeleteExperienceVariables,
} from 'graphql/generated/DeleteExperience';
import { DeleteAllExperiences } from 'graphql/generated/DeleteAllExperiences';
import { createToast } from 'services/toast';
import { ME } from 'graphql/queries/me';
import * as Sentry from '@sentry/react';
import useMe from './useMe';

const useMeExperienceActions = () => {
  const { me } = useMe();

  const [errorExpLength, seterrorExpLength] = useState(false);

  const [updateExperienceMutation, { loading: updateLoading }] = useMutation<
    UpdateExperience,
    UpdateExperienceVariables
  >(UPDATE_EXPERIENCE, { refetchQueries: [{ query: ME }] });

  const updateExperience = useCallback(
    async (data: UpdateExperienceVariables) => {
      try {
        await updateExperienceMutation({ variables: data });
        if (!me?.onboarding)
          createToast({
            title: 'Editar experiencia',
            description: 'Experiencia editada correctamente.',
            status: 'success',
          });
      } catch (error: any) {
        createToast({
          title: 'Editar experiencia',
          description: 'Algo falló intentando editar la experiencia.',
          status: 'error',
        });
        Sentry.captureException(new Error('No se pudo actualizar el CV'));
        throw new FlyncerError('No se pudo actualizar el CV', error);
      }
    },
    [updateExperienceMutation, me?.onboarding]
  );

  const [deleteExperienceMutation, { loading: deleteLoading }] = useMutation<
    DeleteExperience,
    DeleteExperienceVariables
  >(DELETE_EXPERIENCE, { refetchQueries: [{ query: ME }] });

  const deleteExperience = useCallback(
    async (data: DeleteExperienceVariables) => {
      try {
        await deleteExperienceMutation({ variables: data });
        if (!me?.onboarding)
          createToast({
            title: 'Eliminar experiencia',
            description: 'Experiencia eliminada correctamente.',
            status: 'success',
          });
      } catch (error: any) {
        console.log(error);

        createToast({
          title: 'Editar experiencia',
          description: 'Algo falló intentando editar la experiencia.',
          status: 'error',
        });
        Sentry.captureException(
          new Error('No se pudo eliminar la experiencia del CV')
        );
        throw new FlyncerError('No se pudo actualizar el CV', error);
      }
    },
    [deleteExperienceMutation, me?.onboarding]
  );

  const [ImportLinkedinExpMutation, { loading: importLoading }] = useMutation<
    ImportLinkedinExp,
    ImportLinkedinExpVariables
  >(IMPORT_LINKEDIN_EXP, { refetchQueries: [{ query: ME }] });

  const importLinkedinExperience = useCallback(
    async (data: ImportLinkedinExpVariables) => {
      try {
        const experiences = await ImportLinkedinExpMutation({
          variables: data,
        });
        if (!experiences.data?.importLinkedinExp.length)
          seterrorExpLength(true);
        if (!me?.onboarding && experiences.data?.importLinkedinExp.length)
          createToast({
            title: 'Editar experiencia',
            description: 'Experiencia editada correctamente.',
            status: 'success',
          });
      } catch (error: any) {
        createToast({
          title: 'Editar experiencia',
          description: 'Algo falló intentando editar la experiencia.',
          status: 'error',
        });
        Sentry.captureException(new Error('No se pudo actualizar el CV'));
        throw new FlyncerError('No se pudo actualizar el CV', error);
      }
    },
    [ImportLinkedinExpMutation, me?.onboarding]
  );

  const [deleteAllExperiencesMutation, { loading: deleteAllLoading }] =
    useMutation<DeleteAllExperiences>(DELETE_ALL_EXPERIENCES, {
      refetchQueries: [{ query: ME }],
    });

  const deleteAllExperiences = useCallback(async () => {
    try {
      await deleteAllExperiencesMutation();
    } catch (error: any) {
      console.log(error);

      createToast({
        title: 'Editar experiencia',
        description: 'Algo falló intentando editar la experiencia.',
        status: 'error',
      });
      Sentry.captureException(
        new Error('No se pudieron eliminar las experiencias del CV')
      );
      throw new FlyncerError('No se pudo actualizar el CV', error);
    }
  }, [deleteAllExperiencesMutation]);

  return {
    updateLoading,
    deleteLoading,
    importLoading,
    deleteAllLoading,
    updateExperience,
    deleteExperience,
    importLinkedinExperience,
    deleteAllExperiences,
    seterrorExpLength,
    errorExpLength,
  };
};

export default useMeExperienceActions;
