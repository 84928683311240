import { CompanyParts } from 'graphql/generated/CompanyParts';
import { WithoutNulls } from 'utils/removeNulls';

export const normalizeCompany = (input: WithoutNulls<CompanyParts>) => ({
  id: input.id,
  bonosCandidates: input.bonosCandidates,
  bonosJobPosting: input.bonosJobPosting,
  bonosSlot: input.bonosSlot ? input.bonosSlot : 0,
  logo: input.logo,
  name: input.name,
  superAdmin: input.superAdmin,
  headHunting: input.headHunting,
});

export type Companies = ReturnType<typeof normalizeCompany>;
