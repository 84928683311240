import './styles.css';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from 'recharts';
import { CheckboxGroup, Checkbox, Button, Text, Grid } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router-dom';
import { useConnect } from './connect';

export const Hexagon = (paths: any) => {
  let idUser: string = paths.idUser;
  const {
    basicHexagonInfo,
    onChangePaths,
    save,
    error,
    data,
    className,
    location,
  } = useConnect(paths, idUser);

  const { replace } = useHistory();

  if (error) Sentry.captureException(error?.graphQLErrors[0]);

  return (
    <>
      {(paths.paths.length < 3 || paths.pathsId?.length < 3) &&
      paths.type !== 'Modal' &&
      paths.cv !== 'HexagonTopBar' ? (
        <Text textStyle="small" color="gray.500" mt="5">
          *Evalúa tus skills para poder añadirlas al Flyncer Map, necesitas al
          menos 3 para desbloquearlo.
        </Text>
      ) : (
        paths.pathsId?.length > 6 && (
          <Text textStyle="small" color="gray.500" mt="1">
            *Puedes marcar como máximo 6 tecnologías.
          </Text>
        )
      )}

      <div
        className={
          paths.paths.length > 2 && paths.pathsId.length > 2 ? className : 'cv2'
        }
      >
        <div
          className={
            paths.paths.length > 2 && paths.pathsId.length > 2
              ? 'cv-container'
              : paths.cv === 'HexagonTopBar'
              ? 'blurTopBar'
              : 'blur'
          }
        >
          <ResponsiveContainer width="99%">
            <RadarChart
              className={location.pathname.includes('company') ? 'font' : ''}
              data={data.length > 2 ? data : basicHexagonInfo}
            >
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" />
              <PolarRadiusAxis tick={false} angle={90} domain={[0, 100]} />
              <Radar
                className="hexagon"
                name="Mike"
                dataKey="A"
                stroke="#01AA50"
                fill="#01AA50"
                fillOpacity={0.4}
              />
            </RadarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {!paths.cv && paths.paths.length > 2 ? (
        <>
          <Grid mt="9" templateColumns="repeat(4,auto)">
            <CheckboxGroup colorScheme="green" defaultValue={paths.pathsId}>
              {paths.paths.map((path: any) => {
                const isChecked = !paths.pathsId.includes(path.path.id);

                return (
                  <Checkbox
                    isDisabled={
                      paths.pathsId.length === 6 && isChecked ? true : false
                    }
                    onChange={() => onChangePaths(path.path.id)}
                    value={path.path.id}
                  >
                    {path.path.name}
                  </Checkbox>
                );
              })}
            </CheckboxGroup>
          </Grid>
          <br />

          <Button
            onClick={() => {
              save();
            }}
            colorScheme="green"
            width="30%"
            mt="2"
            disabled={
              paths.pathsId?.length > 2 && paths.pathsId?.length < 7
                ? false
                : paths.pathsId?.length > 6
                ? true
                : true
            }
          >
            Guardar
          </Button>

          <Button
            disabled={
              paths.pathsHexagon?.length < 3 || paths.pathsWithLevel?.length < 3
                ? true
                : false
            }
            width="auto"
            ml="4"
            mt="2"
            variant="outline"
            colorScheme="brand"
            onClick={() => replace(`/cv/${paths.uuid}`)}
          >
            Ver Flyncer Map
          </Button>
        </>
      ) : (
        ''
      )}
    </>
  );
};
