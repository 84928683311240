import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { USER_LEVEL_FROM_PATH } from 'graphql/queries/userPath';
import { getToken } from 'services/token';

const useUserLevel = () => {
  const isAuth = getToken() !== '';
  const {
    data,
    loading,
    error,
    refetch: refetchLevel,
  } = useQuery(USER_LEVEL_FROM_PATH, {
    skip: !isAuth,
  });

  const userLevel = useMemo(() => {
    return data?.userPathFromPathId;
  }, [data]);

  return {
    userLevel,
    loading,
    error,
    refetchLevel,
  };
};

export default useUserLevel;
