import { useCallback } from 'react';
import { MenuItem } from '@chakra-ui/react';
import { FiStar } from 'react-icons/fi';
import useUserCareerActions from 'graphql/hooks/career/useUserCareerActions';

interface Props {
  id: string;
}

export const MarkAsMainMenuItem = ({ id }: Props) => {
  const { updateMain } = useUserCareerActions();

  const handleDelete = useCallback(async () => {
    try {
      await updateMain({ where: { id } });
    } catch {}
  }, [id, updateMain]);

  return (
    <MenuItem icon={<FiStar />} onClick={handleDelete}>
      Marcar como principal
    </MenuItem>
  );
};
