import { gql } from '@apollo/client';
import { USER_PROFESSION_FRAGMENT } from 'graphql/fragments/userProfession';

export const CREATE_USER_PROFESSION = gql`
  mutation createUserProfession(
    $profession: ID!
    $userId: ID!
    $expYears: Int!
    $subprofessions: [ID]!
    $startDate: Date!
  ) {
    createUserProfession(
      input: {
        data: {
          user: $userId
          profession: $profession
          experienceYears: $expYears
          subprofessions: $subprofessions
          startDate: $startDate
        }
      }
    ) {
      userProfession {
        ...UserProfessionParts
      }
    }
  }
  ${USER_PROFESSION_FRAGMENT}
`;

export const UPDATE_USER_PROFESSION = gql`
  mutation UpdateExpYears(
    $input: ID!
    $professionId: ID!
    $expYears: Int!
    $subprofessions: [ID]!
    $startDate: Date!
  ) {
    updateUserProfession(
      input: {
        where: { id: $input }
        data: {
          profession: $professionId
          experienceYears: $expYears
          subprofessions: $subprofessions
          startDate: $startDate
        }
      }
    ) {
      userProfession {
        ...UserProfessionParts
      }
    }
  }
  ${USER_PROFESSION_FRAGMENT}
`;
