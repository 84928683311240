import { SkillParts } from 'graphql/generated/SkillParts';
import { removeNulls, WithoutNulls } from 'utils/removeNulls';

export const normalizeSkill = (input: WithoutNulls<SkillParts>) => ({
  id: input.id,
  name: input.name,
});

export type Skill = ReturnType<typeof normalizeSkill>;

export const normalizeSkills = (input: (SkillParts | undefined)[]) =>
  input.reduce<Skill[]>(
    (acum, input) =>
      !!input ? acum.concat(normalizeSkill(removeNulls(input))) : acum,
    []
  );
