import { Tag, Text, Tooltip, Wrap } from '@chakra-ui/react';

interface Props {
  paths: any[];
  skills: any[];
}
function TagsCandidate({ paths, skills }: Props) {
  const pathsWithLevel = paths.filter((path) => path.level || path.preLevel);

  return (
    <Wrap
      mt={pathsWithLevel.length ? '2' : '0'}
      spacing={pathsWithLevel.length ? '3' : '0'}
    >
      {pathsWithLevel.length ? (
        pathsWithLevel.map((path: any) => {
          const pathPosition = skills.some((skill) =>
            skill.paths.find(
              (pathSkill: any) => pathSkill.name === path.path.name
            )
          );

          const borderColor = path.level ? 'brand.300' : 'gray.100';
          const hasLevel = path.level ? true : false;

          if (
            path.level?.name !== undefined ||
            path.preLevel?.name !== undefined
          ) {
            return (
              <Tooltip label="Nivel certificado" isDisabled={!hasLevel}>
                <Tag
                  fontWeight="400"
                  bg={!pathPosition ? 'white' : 'gray.100'}
                  border={`1px solid #EBEFF4`}
                  borderBottomColor={borderColor}
                  borderRightColor={borderColor}
                >
                  {path.path.name} - {path.level?.name || path.preLevel?.name}
                  {hasLevel && <Text ml="1">🏅</Text>}
                </Tag>
              </Tooltip>
            );
          } else {
            return null;
          }
        })
      ) : (
        <Text>Sin tecnologías evaluadas</Text>
      )}
    </Wrap>
  );
}

export default TagsCandidate;
