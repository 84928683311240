import { useCallback, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverTrigger,
  useDisclosure,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Text,
  Stack,
  PopoverFooter,
  Link,
} from '@chakra-ui/react';
import useJobActions from 'graphql/hooks/admin/useJobActions';
import { useHasPhoneNumber } from 'graphql/hooks/admin/useUser';

interface Props {
  jobId: string;
  userId: string;
  idCompany: string;
  setIsSend: (isSend: boolean) => void;
  bonosCandidates?: number;
  bonosJobPosting?: number;
  bonosSlots?: number;
  handleSendJob?: (jobId: string, userId: string) => void;
  state: string | undefined;
}

export const SendJobRequestModal = ({
  jobId,
  state,
  userId,
  setIsSend,
  bonosCandidates,
  bonosSlots,
  handleSendJob,
}: Props) => {
  const { hasPhoneNumber } = useHasPhoneNumber({
    id: userId,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { loading } = useJobActions();
  const initialRef = useRef(null);

  const hasBonosCandidates = bonosCandidates && bonosCandidates > 0;
  const hasBonosSlots = bonosSlots && bonosSlots > 0;
  const isDisabled = !hasBonosCandidates && !hasBonosSlots;

  const handleHover = useCallback(() => {
    isDisabled ? onOpen2() : onOpen();
  }, [isDisabled, onOpen2, onOpen]);

  return (
    <>
      <Popover
        isOpen={isOpen2}
        onClose={onClose2}
        isLazy
        initialFocusRef={initialRef}
        closeOnBlur
      >
        <PopoverTrigger>
          <Button
            id="sendOffer-button1"
            onMouseOver={() => {
              if (isDisabled) {
                handleHover();
              }
            }}
            onClick={() => {
              if (!isDisabled) {
                onOpen();
              }
            }}
            colorScheme="brand"
            opacity={isDisabled ? '0.4' : 1}
            isDisabled={loading}
          >
            Contactar
          </Button>
        </PopoverTrigger>
        <PopoverContent p="2">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader fontWeight="bold" border="0">
            Créditos insuficientes
          </PopoverHeader>
          <PopoverBody fontSize="15">
            <Stack>
              <Text>
                Consulta con tu comercial para activar los bonos de contacto
                directo con los candidatos.
              </Text>
            </Stack>
          </PopoverBody>
          {state === 'invisible' && (
            <PopoverFooter border="0" pb="3">
              <Link
                ref={initialRef}
                isExternal
                background="#01AA50"
                color="white"
                py="1.5"
                px="3.5"
                borderRadius="md"
                href={`mailto:ruben.berrocal@flyncer.com?subject=&body=`}
              >
                Contactar
              </Link>
            </PopoverFooter>
          )}
        </PopoverContent>
      </Popover>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {hasPhoneNumber ? (
                <p>Enviar oportunidad</p>
              ) : (
                <p>⚠️ Perfil incompleto ⚠️</p>
              )}
            </AlertDialogHeader>

            <AlertDialogBody>
              {hasPhoneNumber ? (
                <p>
                  ¿Estás seguro? Cuando la envíes el usuario recibirá un email y
                  esa acción no se puede deshacer.
                </p>
              ) : (
                <p>
                  Este perfil no tiene un teléfono válido, es posible que tarde
                  más en contestar a la oferta. ¿Quieres continuar con el
                  contacto?
                </p>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                id="sendOffer-button2"
                colorScheme="brand"
                onClick={async () => {
                  if (!loading && isOpen && handleSendJob) {
                    await handleSendJob(jobId, userId);
                    onClose();
                    setIsSend(true);
                  }
                }}
                ml={3}
                isDisabled={loading}
              >
                Enviar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
