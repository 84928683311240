import { Button, Flex, Grid, Input, Text, VStack } from '@chakra-ui/react';
import { SendJobRequestModal } from 'containers/Admin/Job/components/SendJobRequestModal';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  locations: any[];
  type: string;
  company: any;
  jobId: string;
  user: any;
  setenter: (isEnter: boolean) => void;
  setisSend: (isEnter: boolean) => void;
  editIsDiscarded?: (type?: string, jobApplicationId?: string) => void;
  cancelMessage: string | undefined;
  pathsWithLevel: any[];
  pathsWithPreLevel: any[];
  pathsHexagon: any[];
  showPrelevelMatches: boolean | undefined;
  paths2: any[];
  handleSendJob?: (jobId: string, userId: string) => void;
  jobApplicationId?: string;
}

export const CandidateFooter = ({
  locations,
  type,
  company,
  jobId,
  user,
  setenter,
  cancelMessage,
  setisSend,
  editIsDiscarded,
  pathsWithPreLevel,
  pathsWithLevel,
  showPrelevelMatches,
  paths2,
  jobApplicationId,
  handleSendJob,
}: Props) => {
  let allPaths = pathsWithLevel;

  if (showPrelevelMatches) {
    allPaths = pathsWithLevel.concat(pathsWithPreLevel);
    allPaths = allPaths.filter(
      (path, index) => allPaths.indexOf(path) === index
    );
  } else {
    paths2 = paths2.filter((path) => path.level);
  }

  return (
    <>
      <Text mt="5" fontWeight="500">
        Información adicional
      </Text>
      <Grid
        templateColumns="repeat(2,auto)"
        mb={
          (type === 'Inscribed' ||
            type === 'Shortlisted' ||
            type === 'Discarded') &&
          allPaths.length >= 3
            ? '16'
            : '8'
        }
      >
        <VStack alignItems="flex-start" spacing="1">
          {user.englishLevel ? (
            <Text>Nivel de inglés: {user.englishLevel}</Text>
          ) : (
            ''
          )}
          <Text>
            {locations.length > 0
              ? locations.map((location: any, index: number) => {
                  if (index !== 0) return `, ${location.name}`;
                  else return `Ubicación deseada - ${location.name}`;
                })
              : 'Ubicación deseada - Todo España'}{' '}
          </Text>
        </VStack>

        {type === 'Match' ? (
          <Flex
            justifyContent="flex-end"
            position="absolute"
            bottom="4"
            right="4"
          >
            <SendJobRequestModal
              bonosCandidates={company.bonosCandidates}
              idCompany={company.id}
              bonosJobPosting={company.bonosJobPosting}
              jobId={jobId}
              userId={user.id}
              setIsSend={setisSend}
              state={company.state}
              handleSendJob={handleSendJob}
            />
          </Flex>
        ) : (
          type === 'Accepted' && (
            <Flex
              justifyContent="flex-end"
              position="absolute"
              bottom="4"
              right="4"
            >
              <Button
                bg="gray.500"
                color="white"
                _hover={{ bg: 'gray.350' }}
                as={RouterLink}
                to={`/company/candidate/${user.uuid}`}
              >
                Ver perfil
              </Button>
            </Flex>
          )
        )}
        {type === 'Inscribed' || type === 'Shortlisted' ? (
          <Flex
            justifyContent="flex-end"
            mt={{ sm: '8' }}
            position="absolute"
            bottom="4"
            right="4"
          >
            {type === 'Inscribed' && (
              <Button
                id="acceptCandidate-button"
                onMouseOver={() => {
                  setenter(true);
                }}
                onMouseOut={() => {
                  setenter(false);
                }}
                onClick={() => {
                  if (editIsDiscarded)
                    editIsDiscarded('Shortlisted', jobApplicationId);
                }}
                variant="outline"
                colorScheme="green"
                mr="2"
              >
                Preseleccionar ✓
              </Button>
            )}
            <Button
              id="rejectCandidate-button"
              onMouseOver={() => {
                setenter(true);
              }}
              onMouseOut={() => {
                setenter(false);
              }}
              onClick={() => {
                if (editIsDiscarded) editIsDiscarded('', jobApplicationId);
              }}
              variant="outline"
              colorScheme="red"
              mr="2"
            >
              Descartar X
            </Button>
            <Button
              onMouseOver={() => {
                setenter(true);
              }}
              onMouseOut={() => {
                setenter(false);
              }}
              bg="gray.500"
              color="white"
              _hover={{ bg: 'gray.350' }}
              as={RouterLink}
              to={`/company/candidate/${user.uuid}`}
            >
              Ver perfil
            </Button>
          </Flex>
        ) : (
          ''
        )}
        {type === 'Discarded' && (
          <Flex justifyContent="flex-end">
            <Button
              id="restoreCandidate-button"
              onClick={() => {
                if (editIsDiscarded) editIsDiscarded('', jobApplicationId);
              }}
              colorScheme="green"
              variant="outline"
              mr="2"
              position="absolute"
              bottom="4"
              right="4"
            >
              Restaurar
            </Button>
          </Flex>
        )}
      </Grid>
      {type === 'Reject' && (
        <>
          <Text mt="5" fontWeight="500">
            Razón descarte:
            <Input value={cancelMessage} disabled />
          </Text>
        </>
      )}
    </>
  );
};
