export const sanitizeCandidates = (position: any, list: any) => {
  const inscribedUsers = position?.user_job_applications?.filter(
    (jobApplication: any) => jobApplication?.isDiscarded === undefined
  );

  const newInscribed = inscribedUsers?.filter(
    (jobApplication: any) =>
      jobApplication?.new && jobApplication?.isDiscarded === undefined
  );

  const shortlistedUsers = position?.user_job_applications?.filter(
    (jobApplication: any) => jobApplication?.isDiscarded === false
  );

  const discardedUsers = position?.user_job_applications?.filter(
    (jobApplication: any) => jobApplication?.isDiscarded === true
  );

  const matchUsers = list.filter((user: any) => user.resolution === undefined);
  const pendingUsers = position.resolutions.filter(
    (resolution: any) => resolution?.isAccepted === undefined
  );

  const acceptedUsers = position.resolutions.filter(
    (resolution: any) => resolution?.isAccepted === true
  );

  const rejectUsers = position.resolutions.filter(
    (resolution: any) => resolution?.isAccepted === false
  );

  const hasRemote = position.countries.filter(
    (country: any) => country === 'Remoto'
  );

  const pendingNews = pendingUsers.filter((resolution: any) => resolution?.new);
  const acceptedNews = acceptedUsers.filter(
    (resolution: any) => resolution?.new
  );
  const rejectNews = rejectUsers.filter((resolution: any) => resolution?.new);

  return {
    inscribedUsers,
    pendingUsers,
    newInscribed,
    shortlistedUsers,
    matchUsers,
    discardedUsers,
    rejectNews,
    pendingNews,
    acceptedNews,
    hasRemote,
    rejectUsers,
    acceptedUsers,
  };
};

export const ObjectCandidates = (
  inscribedUsers: any[],
  position: any,
  hasRemote: boolean,
  newInscribed: any[],
  shortlistedUsers: any[],
  me: any,
  discardedUsers: any[],
  matchUsers: any[],
  acceptedUsers: any[],
  pendingUsers: any[],
  rejectUsers: any[]
) => {
  const inscribed = [
    {
      users: inscribedUsers,
      position: position,
      hasRemote: hasRemote,
      newUsers: newInscribed,
      type: 'Inscribed',
      me: me,
    },
    {
      users: shortlistedUsers,
      position: position,
      hasRemote: hasRemote,
      type: 'Shortlisted',
      me: me,
    },
    {
      users: discardedUsers,
      position: position,
      hasRemote: hasRemote,
      type: 'Discarded',
      me: me,
    },
  ];

  const candidatesMatch = [
    {
      users: matchUsers,
      position: position,
      hasRemote: hasRemote,
      type: 'Match',
      me: me,
    },
    {
      users: pendingUsers,
      position: position,
      hasRemote: hasRemote,
      type: 'Pending',
      me: me,
    },
    {
      users: acceptedUsers,
      position: position,
      hasRemote: hasRemote,
      type: 'Accepted',
      me: me,
    },
    {
      users: rejectUsers,
      position: position,
      hasRemote: hasRemote,
      type: 'Reject',
      me: me,
    },
  ];

  return { inscribed, candidatesMatch };
};
