import {
  AccordionButton,
  AccordionItem,
  AccordionProps,
  Box,
  Icon,
  Link,
} from '@chakra-ui/react';
import { FiArrowRight } from 'react-icons/fi';

interface Props extends AccordionProps {
  repoUrl?: string | undefined;
}

export const RepoButtonSection = ({ repoUrl }: Props) => {
  return (
    <AccordionItem
      borderX="1px"
      borderLeftColor="gray.200"
      borderRightColor="gray.200"
    >
      <AccordionButton
        color="gray.500"
        p="4"
        as={Link}
        href={repoUrl ? repoUrl : ''}
      >
        <Box flex="1" textAlign="left">
          Recursos adicionales
        </Box>
        <Icon color="gray.500" as={FiArrowRight} />
      </AccordionButton>
    </AccordionItem>
  );
};
