import { memo } from 'react';
import {
  BoxProps,
  Checkbox,
  Box,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Divider,
  SimpleGrid,
  Text,
  HStack,
  Circle,
} from '@chakra-ui/react';
import useFullCareer from 'graphql/hooks/career/useFullCareer';
import { useField } from 'formik';
import { Itinerary } from 'model/Itinerary';
import { CloseIcon } from '@chakra-ui/icons';
import { levels } from '../../utils/preLevels';

export type Path = {
  name: string;
  id: string;
  preLevel?: string;
  checked?: boolean;
};

interface Props extends Omit<BoxProps, 'onChange'> {
  name: string;
  careerPaths: Path[];
  careerItineraries: Itinerary[];
}

export const CareerSelector = memo(
  ({ name, careerPaths, careerItineraries }: Props) => {
    const { userCareers } = useFullCareer();
    const [{ value, checked }, , { setValue }] = useField<Path[]>(name);
    const activePaths: string[] = [];
    const activeItineraries: string[] = [];

    const onChange = (isChecked: boolean, newValue: Path) => {
      if (isChecked) {
        setValue(value.filter((v) => v.id !== newValue.id));
      } else {
        setValue([...value, newValue]);
      }
    };

    const changeLevel = (id: string | undefined, level: string | undefined) => {
      const updatedValue = value.find((v) => v.id === id);

      if (updatedValue) {
        updatedValue.preLevel = level;

        if (updatedValue?.preLevel) updatedValue.checked = true;
        else updatedValue.checked = false;

        setValue([...value, updatedValue]);
        setValue(value.filter((v, index) => value.indexOf(v) === index));
      }
    };

    const getCheckboxId = (id: string) => {
      return value.find((v) => v.id === id)?.id;
    };

    userCareers.forEach((userCareer) => {
      userCareer.activePaths.forEach((activePath) => {
        activePaths.push(activePath.path.id);
      });
      userCareer.activeItineraries.forEach((activeItinerary) => {
        activeItineraries.push(activeItinerary.itinerary.id);
      });
    });

    return (
      <AccordionItem borderTop="none" borderBottom="none">
        <h2>
          <AccordionButton>
            <AccordionIcon />
            <Box flex="1" ml="1" textAlign="left">
              {name}
            </Box>
          </AccordionButton>
        </h2>

        <Divider mb="2" mt="2" color="gray.300" />

        <AccordionPanel
          bg="gray.10"
          borderBottomRadius="md"
          borderTopRadius="md"
        >
          <SimpleGrid
            columns={[1, 2]}
            spacingX={3}
            spacingY={3}
            alignItems="center"
          >
            {careerPaths.length > 0
              ? careerPaths.map((path) => {
                  const isChecked = value.some((val) => val.id === path.id);
                  const checkboxId = getCheckboxId(path.id);
                  const newValue = {
                    name: path.name,
                    id: path.id,
                    preLevel: undefined,
                    checked: false,
                  };

                  return (
                    <Box ml="4" key={path.id}>
                      <Checkbox
                        colorScheme="brand"
                        key={path.id}
                        value={path.id}
                        name={name}
                        onChange={() => onChange(isChecked, newValue)}
                        isChecked={isChecked}
                        isDisabled={activePaths?.includes(path.id)}
                      >
                        {path.name}
                      </Checkbox>
                      {isChecked && (
                        <HStack spacing={1}>
                          <Text ml={6} mr={1} fontSize="13" color="gray.500">
                            Nivel
                          </Text>
                          {value.some((v) => v.id === path.id && !v.checked) ? (
                            levels.map((level, index) => {
                              return (
                                <Circle
                                  key={index}
                                  size="25px"
                                  cursor="pointer"
                                  color="gray.500"
                                  bg="white"
                                  borderWidth="1px"
                                  onClick={() => changeLevel(checkboxId, level)}
                                >
                                  {level}
                                </Circle>
                              );
                            })
                          ) : (
                            <>
                              <Circle
                                size="25px"
                                cursor="pointer"
                                color="gray.500"
                                bg="gray.100"
                                borderWidth="1px"
                                borderColor="gray.500"
                              >
                                {
                                  value.find((v) => v.id === checkboxId)
                                    ?.preLevel
                                }
                              </Circle>
                              <CloseIcon
                                cursor="pointer"
                                w={2}
                                h={2}
                                onClick={() =>
                                  changeLevel(checkboxId, undefined)
                                }
                              />
                            </>
                          )}
                        </HStack>
                      )}
                    </Box>
                  );
                })
              : careerItineraries.map((itinerary) => {
                  // const isChecked = value?.includes(itinerary.id);
                  // const isChecked = value.some(
                  //   (val) => val.id === itinerary.id
                  // );

                  return (
                    <Box ml="6" key={itinerary.id}>
                      <Checkbox
                        colorScheme="brand"
                        key={itinerary.id}
                        value={itinerary.id}
                        name={name}
                        // onChange={() => onChange(isChecked, newValue)}
                        isChecked={checked}
                        isDisabled={activeItineraries?.includes(itinerary.id)}
                      >
                        {itinerary.name}
                      </Checkbox>
                    </Box>
                  );
                })}
          </SimpleGrid>
        </AccordionPanel>
      </AccordionItem>
    );
  }
);
