import { ItineraryParts } from 'graphql/generated/ItineraryParts';
import { WithoutNulls } from 'utils/removeNulls';

export const normalizeItinerary = (input: WithoutNulls<ItineraryParts>) => ({
  id: input.id,
  name: input.Name,
  workshops: input.workshops || [],
});

export type Itinerary = ReturnType<typeof normalizeItinerary>;
