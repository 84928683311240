import { extendTheme } from '@chakra-ui/react';

const styles = {
  global: {
    'html, body': {
      color: 'gray.600',
      backgroundColor: 'gray.10',
    },
  },
};

const colors = {
  brand: {
    50: '#E8FDE9',
    100: '#C8FACC',
    200: '#94F6A4',
    300: '#5CE581',
    400: '#33CC6B',
    500: '#01AA50',
    600: '#009253',
    700: '#007A51',
    800: '#00624B',
    900: '#005146',
  },
  gray: {
    10: '#F8FAFB',
    50: '#F2F6F8',
    100: '#EBEFF4',
    200: '#D8DFEA',
    300: '#ABB3C2',
    350: '#6F7580',
    400: '#5E646E',
    500: '#595F69',
    600: '#2A2D34',
    700: '#1E222C',
    800: '#151925',
    900: '#0D101E',
  },
  blue: {
    50: '#E1F6FF',
    100: '#B4E7FF',
    200: '#81D8FF',
    300: '#4BC8FF',
    400: '#1CBBFF',
    500: '#00AFFF',
    600: '#00A0F5',
    700: '#008DE0',
    800: '#007CCC',
    900: '#015BAA',
  },
  red: {
    500: '#CB1410',
  },
  orange: {
    400: '#F1950E',
    500: '#F57A0E',
    700: '#DD6B20',
  },
  yellow: {
    400: '#FFC700',
    500: '#f7ac08',
  },
  linkedin: {
    500: '#0e76a8',
  },
};

const textStyles = {
  h1: {
    fontSize: ['48px', '72px'],
    fontWeight: '500',
    lineHeight: '120%',
  },
  h2: {
    fontSize: ['42px', '60px'],
    fontWeight: '500',
    lineHeight: '125%',
  },
  h3: {
    fontSize: '36px',
    fontWeight: '500',
    lineHeight: '125%',
  },
  h4: {
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '125%',
  },
  h5: {
    fontSize: '19px',
    fontWeight: '500',
    lineHeight: '125%',
  },
  h6: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '125%',
  },
  p: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '160%',
  },
  body: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '140%',
  },
  bodyLink: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '140%',
    textDecoration: 'underline',
  },
  small: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '140%',
  },
  caption: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '150%',
  },
  buttonSmall: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '160%',
    letterSpacing: '4%',
  },
  button: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '160%',
    letterSpacing: '4%',
  },
};

export const theme = extendTheme({ styles, colors, textStyles });
