import { toEUR } from 'utils/normalizeSalary';

interface Props {
  min?: number;
  max?: number;
}

export const normalizeSalaryRange = ({ min, max }: Props) => {
  if (min && max) {
    return `${toEUR.format(min)} - ${toEUR.format(max)}`;
  }

  if (!min && max) {
    return toEUR.format(max);
  }

  if (!max && min) {
    return toEUR.format(min);
  }

  return '';
};
