import { gql } from '@apollo/client';
import { USER_CAREER_FRAGMENT } from 'graphql/fragments/userCareer';
import { USER_COURSE_FRAGMENT } from 'graphql/fragments/userCourse';

export const FULL_CAREER_FRAGMENT = gql`
  fragment FullCareerParts on FullCareer {
    careers {
      ...MeCareerParts
    }
    courses {
      ...UserCourseParts
    }
  }
  ${USER_CAREER_FRAGMENT}
  ${USER_COURSE_FRAGMENT}
`;
