import useCareers from 'graphql/hooks/career/useCareers';
import useFullCareer from 'graphql/hooks/career/useFullCareer';
import useMe from 'graphql/hooks/profile/useMe';

const useConnect = () => {
  const { me, loading: meLoading, refetchUser, error } = useMe();
  const {
    userCareers,
    loading,
    refetch,
    error: fullCareerError,
  } = useFullCareer();
  const { careers, error: careersError } = useCareers();

  return {
    me: me,
    userCareers,
    loading: loading || meLoading,
    refetch,
    careers,
    careersError,
    refetchUser,
    error,
    fullCareerError,
  };
};

export default useConnect;
