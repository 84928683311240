import { Formik, Form } from 'formik';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Stack, Text, Button, Link } from '@chakra-ui/react';
import { FormInput } from 'components/FormInput';
import { FormInputPassword } from 'components/FormInputPassword';

import useConnect from './connect';
import { LayoutCompany } from '../Shared/LayoutCompany';

export const LoginCompany = () => {
  const { handleSubmit, initialValues, schema } = useConnect();

  return (
    <LayoutCompany
      title="Únete a flyncer"
      subtitle="Crea tu cuenta y encuentra el talento que necesitas."
    >
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <>
            <Stack spacing="6">
              <Stack as={Form} noValidate spacing="6">
                <FormInput
                  name="identifier"
                  label="Email"
                  autoComplete="email"
                />
                <FormInputPassword
                  name="password"
                  autoComplete="current-password"
                  label="Contraseña"
                  extraInfo={
                    <Link
                      as={RouterLink}
                      to="/forgot-password"
                      textStyle="bodyLink"
                      color="brand.600"
                    >
                      ¿Olvidaste contraseña?
                    </Link>
                  }
                />
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  colorScheme="brand"
                  textStyle="button"
                >
                  Iniciar sesión
                </Button>
              </Stack>

              <Box mt="3">
                <Text as="span" textStyle="body" mr="2">
                  ¿No tienes cuenta?
                </Text>
                <Link
                  as={RouterLink}
                  to="/company/register"
                  textStyle="bodyLink"
                  color="brand.600"
                >
                  Registrate ahora
                </Link>
              </Box>
            </Stack>
          </>
        )}
      </Formik>
    </LayoutCompany>
  );
};
