import { IsAuth_me } from 'graphql/generated/IsAuth';
import { WithoutNulls } from 'utils/removeNulls';

export const normalizeIsAuth = (input: WithoutNulls<IsAuth_me>) => {
  return {
    id: input.id,
    confirmed: input.confirmed || false,
    blocked: input.blocked || false,
    role: { name: input?.role?.name },
  };
};

export type IsAuth = ReturnType<typeof normalizeIsAuth>;
