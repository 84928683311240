import {
  Badge,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  Text,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { Dispatch, memo, SetStateAction, useCallback } from 'react';

//icons
import { MdEuroSymbol } from 'react-icons/md';

interface Props extends InputProps {
  min: string;
  max: string;
  altError?: String;
  totalUsers: number;
  setSalaryMinState: Dispatch<SetStateAction<string>>;
  setSalaryMaxState: Dispatch<SetStateAction<string>>;
}

export const SalaryInputs = memo(
  ({
    min,
    max,
    altError,
    totalUsers,
    setSalaryMinState,
    setSalaryMaxState,
    ...props
  }: Props) => {
    const [
      { value: salaryMinValue },
      { error: errorMin, touched: touchedMin },
      { setValue: setSalaryMinValue },
    ] = useField(min);

    const [
      { value: salaryMaxValue },
      { error: errorMax, touched: touchedMax },
      { setValue: setSalaryMaxValue },
    ] = useField(max);

    const isInvalidMin = touchedMin && !!errorMin;
    const isInvalidMax = touchedMax && !!errorMax;

    const onChange = useCallback(
      (newValue, select) => {
        if (select === 'min') {
          setSalaryMinValue(parseInt(newValue));
          setSalaryMinState(newValue);
        }

        if (select === 'max') {
          setSalaryMaxValue(parseInt(newValue));
          setSalaryMaxState(newValue);
        }
      },
      [
        setSalaryMinValue,
        setSalaryMaxState,
        setSalaryMaxValue,
        setSalaryMinState,
      ]
    );

    return (
      <>
        <Text fontSize="16" fontWeight="bold" mt="10" className="salary">
          Salario anual ofrecido *
          <Badge
            ml={{ base: '2', md: '6' }}
            rounded="lg"
            fontSize="11"
            p="1"
            display="none"
          >
            {totalUsers !== 1
              ? `${totalUsers} disponibles`
              : `${totalUsers} disponible`}
          </Badge>
        </Text>
        <Grid templateColumns="repeat(2, 1fr)" gap={{ base: 6, md: 16 }}>
          <FormControl mt="6" isInvalid={isInvalidMin}>
            <FormLabel fontSize="14">Salario mínimo</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<MdEuroSymbol color="gray.100" />}
              />
              <Input
                focusBorderColor="brand.500"
                type="number"
                placeholder="20000"
                value={salaryMinValue}
                onChange={(e) => onChange(e.target.value, 'min')}
                {...props}
              />
            </InputGroup>

            {isInvalidMin && (
              <FormErrorMessage mt="1" textStyle="caption">
                {altError ? altError : errorMin}
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl mt="6" isInvalid={isInvalidMax}>
            <FormLabel fontSize="14">Salario máximo</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<MdEuroSymbol color="gray.100" />}
              />
              <Input
                focusBorderColor="brand.500"
                type="number"
                placeholder="24000"
                {...props}
                value={salaryMaxValue}
                onChange={(e) => onChange(e.target.value, 'max')}
              />
            </InputGroup>

            {isInvalidMax && (
              <FormErrorMessage mt="1" textStyle="caption">
                {altError ? altError : errorMax}
              </FormErrorMessage>
            )}
          </FormControl>
        </Grid>
      </>
    );
  }
);
