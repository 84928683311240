import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import { Root } from 'containers/Root';

import reportWebVitals from './reportWebVitals';
// Google Tag Manager
if (process.env.REACT_APP_GTM) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM,
  };
  TagManager.initialize(tagManagerArgs);
}

const rootEl = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  rootEl
);

if (module.hot) {
  module.hot.accept('./containers/Root', () => {
    // eslint-disable-next-line global-require
    const NextApp = require('./containers/Root').default;
    ReactDOM.render(<NextApp />, rootEl);
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
