import { gql } from '@apollo/client';
import { USER_ITINERARY_FRAGMENT } from './userItinerary';
import { USER_PATH_FRAGMENT } from './userPath';

export const USER_CAREER_FRAGMENT = gql`
  fragment MeCareerParts on MeCareer {
    id
    isMain
    experienceYears
    career {
      id
      name
      isBasic
      esRecurso
    }

    activePaths {
      ...MePathParts
    }

    activeItineraries {
      ...MeItineraryParts
    }
  }
  ${USER_PATH_FRAGMENT}
  ${USER_ITINERARY_FRAGMENT}
`;
