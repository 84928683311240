import { Link as RouterLink } from 'react-router-dom';
import { Box, Divider, Button, Text, HStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { JobHeader } from 'components/JobHeader';
import { Markdown } from 'components/Markdown';
import { quizRenderer } from 'components/Markdown/QuizRenderer';
import { ApplyButton } from './ApplyButton';
import useUserLevel from 'graphql/hooks/career/useUserLevel';
import useMe from 'graphql/hooks/profile/useMe';
import { CheckIcon } from '@chakra-ui/icons';
import { normalizeExperience } from 'utils/normalizeExperience';
import { RegisterContainer } from './RegisterContainer';

import * as Sentry from '@sentry/react';
import useUserProfession from 'graphql/hooks/profile/useUserProfessions';
import useUserActions from 'graphql/hooks/profile/useMeActions';
import { sanitizeDate3 } from 'utils/sanitizeDate';

import useConnect from './connect';
import { CompleteOnboardingModal } from './CompleteOnboardingModal';
import { InscribedModal } from './InscribedModal';

export const JobOffer = () => {
  const { me, refetchUser, error } = useMe();
  const [isOpen2, setisOpen2] = useState(true);
  const { finishTour } = useUserActions();
  const {
    oneJobError,
    job,
    isOpen,
    onClose,
    loadingApply,
    canApply,
    paths,
    locations,
    salary,
    isApply,
    isCompleted,
    created_at,
    loadingPaths,
  } = useConnect();

  let expYears = sanitizeDate3(me);

  const { error: userLevelError } = useUserLevel();
  const { error: userProfessionError } = useUserProfession(me?.username);

  if (error || oneJobError) {
    let message = error ? error : oneJobError;
    Sentry.captureException(message?.graphQLErrors[0]);
  }

  if (userLevelError || userProfessionError) {
    let message = userLevelError ? userLevelError : userProfessionError;
    Sentry.captureException(message?.graphQLErrors[0]);
  }

  useEffect(() => {
    if (!me?.completed_profile) return;

    isCompleted.current = me.completed_profile;

    return () => {
      refetchUser();
    };
  }, [me?.completed_profile, refetchUser, isCompleted]);

  if (!job || loadingPaths) return null;

  if (!job || !paths) return null;

  return (
    <>
      {me?.onboarding && (
        <CompleteOnboardingModal
          isOpen2={isOpen2}
          setisOpen2={setisOpen2}
          finishTour={finishTour}
        />
      )}

      {me && (
        <Box
          as="main"
          mx="auto"
          w="100%"
          maxW="50rem"
          mt="12"
          px={{ base: '4', md: '6' }}
        >
          <Button
            as={RouterLink}
            to="/jobs"
            variant="link"
            leftIcon={<FiChevronLeft />}
          >
            Volver a mis oportunidades
          </Button>
        </Box>
      )}

      <JobHeader
        created_at={created_at}
        title={job.name}
        company={job.company}
        skills={job.skills}
        experienceYears={job.experienceYears}
        location={locations}
        salary={salary}
        buttons={false}
      >
        <Divider my="8" color="gray.200" />
        <Markdown source={job.description} renderers={quizRenderer()} />
        <Text
          as="h4"
          textStyle={{ base: 'h5', md: 'h4' }}
          mb="4"
          mt="8"
          fontWeight="bold"
        >
          Requisitos
        </Text>
        {job?.skills.map((pathGroup: any) => {
          const checked = pathGroup.paths.some((path: any) =>
            paths?.find(
              (p) => p?.path?.id === path.id && (p?.level || p?.preLevel)
            )
          );

          return (
            <HStack alignItems="center" mt="3" ml="4" spacing="15px">
              {pathGroup.paths.map((path: any, i: number) => (
                <Box>
                  <HStack alignItems="center" spacing="15px">
                    <Text key={path.id}>{path.name}</Text>
                    <Text fontWeight="semibold" color="gray.500">
                      {pathGroup.paths.length - 1 !== i && 'OR'}
                    </Text>
                  </HStack>
                </Box>
              ))}
              {checked && <CheckIcon color="brand.500" />}
            </HStack>
          );
        })}
        <Text ml="4" mt="3">
          Experiencia - {normalizeExperience(job.experienceYears)}{' '}
          {job.experienceYears !== undefined &&
            job.experienceYears <= expYears && (
              <CheckIcon ml="3" color="brand.500" />
            )}
        </Text>
        {me ? (
          isApply ? (
            <Text fontSize="0.7em" my="10" color="gray.400">
              Ya has aplicado a esta oferta
            </Text>
          ) : (
            <ApplyButton
              mt={7}
              isCompleted={isCompleted.current}
              canApply={canApply}
              isSend={loadingApply}
            />
          )
        ) : (
          <RegisterContainer />
        )}
      </JobHeader>
      <InscribedModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
