import { Box, Button, Container, Text } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';

import { LockIcon } from '@chakra-ui/icons';
import { useCallback } from 'react';

export const RegisterContainer = () => {
  const { replace } = useHistory();
  const { id } = useParams<{ id: string }>();

  const handleClick = useCallback(() => {
    localStorage.setItem('offerId', id);
    replace('/register');
  }, [id, replace]);

  return (
    <Container
      maxW="container.xl"
      mx="auto"
      mt="7"
      w="100%"
      boxShadow="md"
      px={{ base: '8', md: '14' }}
      py="8"
      bg="gray.50"
      borderRadius={'8px'}
    >
      <Box textAlign="center">
        <LockIcon color="#01AA50" h={10} w={10} />
        <Text fontWeight="semibold" fontSize="2xl" mt="3">
          Necesitas estar registrado para aplicar a ofertas
        </Text>
        <Text my="5" color="gray.400">
          Registrate pulsando en el siguiente enlace y accede a esta y otras
          muchas ofertas
        </Text>
        <Button onClick={handleClick} colorScheme="brand">
          Registrarme
        </Button>
      </Box>
    </Container>
  );
};
