import ReactMarkdown from 'react-markdown/with-html';
import { Renderer } from './QuizRenderer';

type Props = {
  source: string;
  renderers: Renderer;
};

export const Markdown = ({ source, renderers }: Props) => {
  return (
    <ReactMarkdown source={source} escapeHtml={false} renderers={renderers} />
  );
};
