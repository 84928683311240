import {
  FormControl,
  FormErrorMessage,
  Select,
  SelectProps,
} from '@chakra-ui/react';
import { useField } from 'formik';

interface Props extends SelectProps {}

export const FormSelect = ({ name = '', children, ...props }: Props) => {
  const [field, { error, touched }] = useField(name);
  const isInvalid = !!error && touched;

  return (
    <FormControl name={name} isInvalid={isInvalid}>
      <Select {...props} {...field}>
        {children}
      </Select>
      {isInvalid && (
        <FormErrorMessage mt="1" textStyle="caption">
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
