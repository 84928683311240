import { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import { Quiz } from 'components/Quiz';
import { Answer } from 'components/Quiz/Form';
import { TestCompleted } from './testCompleted';
import * as Sentry from '@sentry/react';
import useQuizOnlyPath from 'graphql/hooks/career/useQuizOnlyPath';
import useEvaluateOnlyPathActions from 'graphql/hooks/career/useEvaluateOnlyPath';
import { NoLoginHeader } from '../../../../components/NoLoginHeader';

type Evaluation = {
  level: string;
  percent: number;
};

export const QuizOnlyPath = () => {
  const { id } = useParams<{ id: string }>();
  const { quiz, loading, error } = useQuizOnlyPath({ pathId: id });
  const { onlyPath } = useEvaluateOnlyPathActions();
  const [evaluation, setEvaluation] = useState<Evaluation | undefined>();

  if (error) Sentry.captureException(error?.graphQLErrors[0]);

  const handleSubmit = useCallback(
    async (answers: Answer[]) => {
      try {
        const response = await onlyPath({ pathID: id, answers });
        localStorage.setItem('evaluation', JSON.stringify(response));
        localStorage.setItem('pathID', id);

        if (response) {
          setEvaluation({
            level: response.level.name,
            percent: response.successPercent,
          });
        }
      } catch (error: any) {
        console.log(error);
      }
    },
    [id, onlyPath, setEvaluation]
  );

  if (loading && !quiz) return <div></div>;

  if (!quiz) return <div>El quiz no existe o no lo has desbloqueado aún.</div>;

  if (evaluation)
    return (
      <TestCompleted level={evaluation.level} percent={evaluation.percent} />
    );

  return (
    <>
      {!localStorage.getItem('flyncer-token') && <NoLoginHeader />}

      <Quiz
        quiz={quiz}
        quizType="Evaluación de nivel"
        handleSubmit={handleSubmit}
      />
    </>
  );
};
