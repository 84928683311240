import { gql } from '@apollo/client';
import { USER_PROFESSION_FRAGMENT } from 'graphql/fragments/userProfession';

export const USER_PROFESSION = gql`
  query oneUserProfession {
    oneUserProfession {
      ...UserProfessionParts
    }
  }
  ${USER_PROFESSION_FRAGMENT}
`;

export const USER_PROFESSIONS = gql`
  query UserProfessions {
    userProfessions {
      ...UserProfessionParts
    }
  }
  ${USER_PROFESSION_FRAGMENT}
`;
