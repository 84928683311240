export const normalizeExperience = (years: number | undefined) => {
  if (years === undefined) {
    return '';
  } else if (years === 0) {
    return `Sin experiencia`;
  } else if (years === 1) {
    return `1 año`;
  } else if (years > 1) {
    return `${years} años`;
  }
};
