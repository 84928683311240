import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { JobRequest, JobRequestVariables } from 'graphql/generated/JobRequest';
import { JOB_REQUEST } from 'graphql/queries/jobRequest';
import { removeNulls } from 'utils/removeNulls';
import { normalizeJobRequest } from 'model/JobRequest';

const useJobRequest = ({ id }: JobRequestVariables) => {
  const { data, loading, error } = useQuery<JobRequest, JobRequestVariables>(
    JOB_REQUEST,
    {
      variables: { id },
    }
  );

  const jobRequest = useMemo(
    () =>
      data && data.jobRequest
        ? normalizeJobRequest(removeNulls(data.jobRequest))
        : undefined,
    [data]
  );

  return {
    error,
    jobRequest,
    loading,
  };
};

export default useJobRequest;
