import { gql } from '@apollo/client';
import { ME_EXTENDED_FRAGMENT } from './me';
import { SKILL_FRAGMENT } from './skill';
import { WORKSHOP_FRAGMENT } from './workshop';

export const USER_WORKSHOP_FRAGMENT=gql`
    
    fragment MeWorkshopParts on UserWorkshop{
        user{
            ...MeExtendedParts
        }
        workshop{
           ...FullWorkshopParts
        }
        progress
        lessonsDone{
            lessonId
        }
        isCompleted
    }

    ${WORKSHOP_FRAGMENT}
    ${SKILL_FRAGMENT}
    ${ME_EXTENDED_FRAGMENT}

`