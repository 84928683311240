import { Link as RouterLink } from 'react-router-dom';
import { HStack, SimpleGrid } from '@chakra-ui/react';

import { Placeholder } from 'components/Placeholder';
import { JobCard } from '../JobCard';
import { NoJobIllustration } from '../NoJobIllustration';
import { MuteJobIllustration } from '../MuteJobIllustration';
import { JobRequest } from 'model/JobRequest';

import { Text, Button, Stack, Link } from '@chakra-ui/react';

interface Props {
  active: boolean;
  job: JobRequest[];
  receive: boolean;
}

export const JobList = ({ active, job, receive }: Props) => {
  if (!active) {
    return (
      <Placeholder
        title="No te enviaremos oportunidades"
        subtitle="No te olvides activarlo cuando vuelvas a estar disponible"
        icon={<MuteJobIllustration w="250px" h="150px" />}
      />
    );
  }

  if (job.length === 0) {
    return (
      <Stack align="center" maxW="md" w="100%" marginX="auto">
        <Placeholder
          title="No tienes oportunidades disponibles"
          subtitle="Realiza los tests de nivel e indica tus objetivos en tu perfil para encontrar oportunidades que encajen contigo."
          icon={<NoJobIllustration w="250px" h="150px" />}
        />
        <Text align="center" textStyle="body" color="gray.500" pt="1" pb="2">
          Para estar al día de las ofertas activas, visita el tablero de ofertas
          o únete a nuestro canal de Telegram.
        </Text>
        <HStack>
          <Button
            colorScheme="brand"
            as={Link}
            href="/jobs"
            style={{ textDecoration: 'none' }}
          >
            Ver ofertas
          </Button>
          <Button
            colorScheme="brand"
            variant="outline"
            as={Link}
            href="https://t.me/flyncertribe"
            isExternal
            style={{ textDecoration: 'none' }}
          >
            Unirse a Telegram
          </Button>
        </HStack>
      </Stack>
    );
  }

  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8} py={6}>
      {job.map(({ id, position }) => {
        let locations;

        locations = position.countries.filter(
          (country) => country === 'Remoto'
        );

        if (!locations.length) locations = position.location;
        return (
          <RouterLink
            to={receive ? `/jobResolution/${id}` : `/jobAccepted/${id}`}
            key={id}
          >
            <JobCard
              company={position.company}
              position={position.name}
              skills={position.skills}
              location={locations}
              experienceYears={position.experienceYears}
              salary={position.salary}
              state={position.state}
            />
          </RouterLink>
        );
      })}
    </SimpleGrid>
  );
};
