import { HStack, StackProps, Text } from '@chakra-ui/react';

interface Props extends StackProps {
  name: string;
  level?: string;
}

export const Header = ({ name, level, ...props }: Props) => {
  return (
    <HStack spacing="4" color="gray.500" {...props}>
      <Text textStyle="body">{name}</Text>
      {level && (
        <>
          <Text textStyle="body">|</Text>
          <Text textStyle="body">{level}</Text>
        </>
      )}
    </HStack>
  );
};
