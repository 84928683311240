import { gql } from '@apollo/client';

export const USER_PROFESSION_FRAGMENT = gql`
  fragment UserProfessionParts on UserProfession {
    id
    profession {
      id
      name
    }

    subprofessions {
      id
      Name
    }

    startDate

    experienceYears
  }
`;
/* REVISAR SUBPROFESSIONS*/
