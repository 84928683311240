import { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import useQuizForCourse from 'graphql/hooks/career/useQuizForCourse';

import { Quiz } from 'components/Quiz';
import { Answer } from 'components/Quiz/Form';
import { ModalQuizForCourse } from 'components/ModalQuiz/ForCourse';
import useEvaluateQuizActions from 'graphql/hooks/career/useEvaluateQuizActions';
import * as Sentry from '@sentry/react';

type Evaluation = {
  approved: boolean;
  percent: number;
};

export const QuizForCourse = () => {
  const { id } = useParams<{ id: string }>();
  const { quiz, loading, error } = useQuizForCourse({ quizId: id });
  const { forCourse } = useEvaluateQuizActions();
  const [evaluation, setEvaluation] = useState<Evaluation | undefined>();

  if (error) Sentry.captureException(error?.graphQLErrors[0]);

  const handleSubmit = useCallback(
    async (answers: Answer[]) => {
      try {
        const response = await forCourse({ quizId: id, answers });

        if (response) {
          setEvaluation({
            approved: response.approved,
            percent: response.successPercent,
          });
        }
      } catch (error: any) {
        console.log(error);
      }
    },
    [id, forCourse, setEvaluation]
  );

  if (loading && !quiz) return <div></div>;

  if (!quiz) return <div>El quiz no existe o no lo has desbloqueado aún.</div>;

  return (
    <>
      <Quiz
        quiz={quiz}
        quizType="Examen del curso"
        handleSubmit={handleSubmit}
      />
      {evaluation && (
        <ModalQuizForCourse
          isOpen={true}
          onClose={() => {}}
          textCheckbox={[]}
          course={quiz.name}
          courseId={quiz.course.id}
          percent={evaluation.percent}
          isApproved={evaluation.approved}
        />
      )}
    </>
  );
};
