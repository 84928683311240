import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import {
  QuizOnlyPath,
  QuizOnlyPathVariables,
} from 'graphql/generated/QuizOnlyPath';
import { QUIZ_ONLY_PATH } from 'graphql/queries/quiz';
import { removeNulls } from 'utils/removeNulls';
import { normalizeQuiz } from 'model/Quiz';

const useQuizOnlyPath = ({ pathId }: QuizOnlyPathVariables) => {
  const { data, loading, error } = useQuery<
    QuizOnlyPath,
    QuizOnlyPathVariables
  >(QUIZ_ONLY_PATH, {
    variables: { pathId },
    fetchPolicy: 'no-cache',
  });

  const quiz = useMemo(
    () =>
      data && data.quizOnlyPath
        ? normalizeQuiz(removeNulls(data.quizOnlyPath))
        : undefined,
    [data]
  );

  return {
    error,
    quiz,
    loading,
  };
};

export default useQuizOnlyPath;
