import { gql } from '@apollo/client';
import { COMPANY_FRAGMENT } from 'graphql/fragments/company';

export const COMPANIES = gql`
  query Company {
    meCompany {
      ...CompanyParts
    }
  }
  ${COMPANY_FRAGMENT}
`;
