import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { Careers } from 'graphql/generated/Careers';
import { CAREERS } from 'graphql/queries/career';
import { removeNulls } from 'utils/removeNulls';
import { normalizeCareer, Career } from 'model/Career';

const useCareers = () => {
  const { data, loading, error } = useQuery<Careers>(CAREERS);

  const careers = useMemo(() => {
    return (data?.careers || []).reduce<Career[]>(
      (acum, input) =>
        !!input ? acum.concat(normalizeCareer(removeNulls(input))) : acum,
      []
    );
  }, [data]);

  return {
    error,
    careers,
    loading,
  };
};

export default useCareers;
