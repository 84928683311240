import {
  Stack,
  Input,
  HStack,
  Button,
  Box,
  BoxProps,
  Textarea,
  CloseButton,
} from '@chakra-ui/react';
import { FormInput } from 'components/FormInput';
import { Formik, Form, Field } from 'formik';

import * as Sentry from '@sentry/react';
import CustomSelect from 'components/CustomSelect';
import { useConnect } from './connect';

interface Skill {
  id: string;
  name: string;
}
interface Props extends BoxProps {
  empresa?: string;
  puesto?: string;
  inicio?: Date | null;
  fin?: Date | null;
  funciones?: string;
  userSkills?: Skill[];
  expId?: string | null;
  update: () => void;
  updateUpdate: () => void;
  onTour?: boolean;
  setForceRender?: (isSend: boolean) => void;
}

export const Editor = ({
  empresa,
  puesto,
  inicio,
  fin,
  funciones,
  userSkills,
  expId,
  update,
  updateUpdate,
  setForceRender,
}: Props) => {
  const eId: string = expId ? expId : '';
  const {
    handleSubmit,
    schema,
    initialValues,
    orderedSkills,
    loading,
    error,
    handleClose,
    handleDelete,
  } = useConnect(
    eId,
    update,
    setForceRender,
    updateUpdate,
    empresa,
    puesto,
    inicio,
    fin,
    funciones,
    userSkills
  );

  if (error) Sentry.captureException(error?.graphQLErrors[0]);

  if (loading) {
    return null;
  }

  return (
    <Box
      bg="gray.10"
      border="1px"
      borderColor="gray.300"
      mt="3"
      borderRadius="md"
      p="4"
    >
      <Box display="flex" justifyContent="end" mb="1">
        <CloseButton onClick={handleClose} />
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Stack spacing={4}>
              <HStack>
                <FormInput
                  border="1px"
                  name="empresa"
                  placeholder="Empresa"
                  key={`empresaForm${empresa}`}
                />
                <FormInput
                  border="1px"
                  name="puesto"
                  placeholder="Puesto"
                  key={`puestoForm${empresa}`}
                />
              </HStack>
              <HStack>
                <FormInput
                  type="date"
                  color="gray.400"
                  name="inicio"
                  value={values.inicio?.toString()}
                  onChange={(e) => setFieldValue('inicio', e.target.value)}
                  key={`inicioForm${empresa}`}
                  altError="Campo obligatorio"
                ></FormInput>
                <Input
                  type="date"
                  color="gray.400"
                  name="fin"
                  value={values.fin?.toString()}
                  onChange={(e) => setFieldValue('fin', e.target.value)}
                  key={`finForm${empresa}`}
                ></Input>
              </HStack>
              <Textarea
                name="funciones"
                value={values.funciones}
                onChange={(e) => setFieldValue('funciones', e.target.value)}
                placeholder="Funciones"
                marginBottom="-8px !important"
                key={`funcionesForm${empresa}`}
              ></Textarea>
              <Stack
                name="skills"
                onClick={() => {
                  if (setForceRender) setForceRender(true);
                }}
              >
                <Field
                  options={
                    orderedSkills
                      ? orderedSkills.map((skill) => ({
                          label: skill.name,
                          value: skill.id,
                        }))
                      : [{ value: '', label: '' }]
                  }
                  isMulti={true}
                  className="custom-select"
                  placeholder="Skills"
                  name="skills"
                  component={CustomSelect}
                />
                {/* <CUIAutoComplete
                  placeholder="Skills"
                  key={`skillsForm${empresa}`}
                  label=""
                  tagStyleProps={{
                    borderRadius: 'full',
                    variant: 'subtle',
                    colorScheme: 'green',
                    height: '20px',
                    mb: -2,
                  }}
                  inputStyleProps={{
                    mt: '3',
                  }}
                  listStyleProps={{
                    bg: 'gray.10',
                    borderRadius: 'md',
                  }}
                  items={
                    orderedSkills
                      ? orderedSkills.map((skill) => ({
                          value: skill.id,
                          label: skill.name,
                        }))
                      : [{ value: '', label: '' }]
                  }
                  toggleButtonStyleProps={{ alignSelf: 'flex-end' }}
                  selectedItems={values.skills?.map((e) => ({
                    label: e.name,
                    value: e.id,
                  }))}
                  onSelectedItemsChange={({ type, selectedItems }) => {
                    if (type === '__function_remove_selected_item__') {
                      setFieldValue(
                        'skills',
                        selectedItems?.map((s) => ({
                          name: s.label,
                          id: s.value,
                        })) || []
                      );
                    } else if (selectedItems) {
                      setFieldValue(
                        'skills',
                        selectedItems?.map((s) => ({
                          name: s.label,
                          id: s.value,
                        }))
                      );
                    }
                  }}
                  onCreateItem={(item) => console.log('create item', item)}
                /> */}
              </Stack>

              <HStack justifyContent="end">
                <Button
                  colorScheme="red"
                  variant="outline"
                  onClick={handleDelete}
                >
                  Eliminar
                </Button>
                <Button colorScheme="brand" variant="outline" type="submit">
                  Guardar
                </Button>
              </HStack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
