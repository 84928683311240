import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { FullCourse, FullCourseVariables } from 'graphql/generated/FullCourse';
import { FULL_COURSE } from 'graphql/queries/course';
import { removeNulls } from 'utils/removeNulls';
import { normalizeFullCourse } from 'model/Course';

const useFullCourse = (id: string) => {
  const { data, loading, error } = useQuery<FullCourse, FullCourseVariables>(
    FULL_COURSE,
    {
      variables: { id },
    }
  );

  const course = useMemo(
    () =>
      data && data.course
        ? normalizeFullCourse(removeNulls(data.course))
        : undefined,
    [data]
  );

  return {
    course,
    loading,
    error,
  };
};

export default useFullCourse;
