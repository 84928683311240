import { CompanyTypeParts } from 'graphql/generated/CompanyTypeParts';
import { removeNulls, WithoutNulls } from 'utils/removeNulls';

export const normalizeCompanyType = (
  input: WithoutNulls<CompanyTypeParts>
) => ({
  id: input.id,
  name: input.name,
  description: input.description,
});

export type CompanyType = ReturnType<typeof normalizeCompanyType>;

export const normalizeCompanyTypes = (
  input: (WithoutNulls<CompanyTypeParts> | undefined)[]
) =>
  input.reduce<CompanyType[]>(
    (acum, input) =>
      !!input ? acum.concat(normalizeCompanyType(removeNulls(input))) : acum,
    []
  );
