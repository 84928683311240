import {
  MenuList,
  MenuItem,
  Text,
  Divider,
  Stack,
  Grid,
  GridItem,
  MenuButton,
  Badge,
  IconButton,
} from '@chakra-ui/react';
import { FiBell } from 'react-icons/fi';

import useNotificationsActions from 'graphql/hooks/notifications/useNotificationsActions';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { yearMonths } from 'utils/normalizeDate';
import { AiOutlineDelete } from 'react-icons/ai';

interface Props {
  notifications: any[];
  orderedNotifications: any[];
  seenNotifications: any[];
}

export const MenuNotifications = ({
  notifications,
  orderedNotifications,
  seenNotifications,
}: Props) => {
  const [enter, setenter] = useState(false);
  const [enterBin, setenterBin] = useState(false);
  const { deleteNotification, addSeenUserNotification } =
    useNotificationsActions();
  const { replace } = useHistory();
  const { months } = yearMonths();

  const [idNotification, setidNotification] = useState('');
  return (
    <>
      {' '}
      <MenuButton
        as={IconButton}
        isRound
        variant="ghost"
        fontSize="xl"
        icon={
          notifications?.length === seenNotifications?.length ? (
            <FiBell />
          ) : (
            <>
              <FiBell />{' '}
              <Badge
                position="relative"
                right="12%"
                top="5%"
                backgroundColor="orange.400"
                variant="solid"
              >
                !
              </Badge>
            </>
          )
        }
        aria-label="Notifications"
        position="relative"
        left={{ base: '1', md: '4' }}
      ></MenuButton>
      <MenuList zIndex="5" width={310}>
        {orderedNotifications.length ? (
          orderedNotifications?.map((notification: any, index: number) => {
            const created_at = new Date(notification?.created_at);
            const hasSeen = seenNotifications.filter(
              (sNotification) => sNotification.id === notification.id
            );

            return (
              <>
                <MenuItem>
                  <Stack
                    onClick={() => {
                      if (!enterBin) {
                        addSeenUserNotification({
                          notificationID: notification?.id,
                        });

                        replace(notification?.Url);
                      }
                    }}
                    onMouseOver={() => {
                      setenter(true);
                      setidNotification(notification?.id);
                    }}
                    onMouseLeave={() => setenter(false)}
                  >
                    <Grid templateColumns="repeat(8,1fr)">
                      <GridItem
                        colStart={1}
                        colSpan={8}
                        rowStart={1}
                        fontWeight={!hasSeen.length ? 600 : ''}
                        fontSize="16px"
                        color="gray.500"
                      >
                        {notification?.Title}
                      </GridItem>
                      <GridItem
                        color="gray.500"
                        fontSize="14px"
                        whiteSpace="pre"
                        colStart={8}
                        rowStart={1}
                      >
                        {created_at.getDate()} {months[created_at.getMonth()]}
                      </GridItem>
                    </Grid>

                    <Grid templateColumns="repeat(8,1fr)">
                      <Text
                        color="gray.500"
                        fontSize="14px"
                        width={270}
                        fontWeight={!hasSeen.length ? 600 : ''}
                      >
                        {notification?.Message}
                      </Text>

                      {enter && idNotification === notification?.id && (
                        <GridItem
                          onMouseOver={() => {
                            setenterBin(true);
                          }}
                          onMouseLeave={() => setenterBin(false)}
                          colStart={7}
                          zIndex="10"
                          onClick={() => {
                            deleteNotification({
                              notificationID: notification?.id,
                            });
                          }}
                        >
                          <AiOutlineDelete />
                        </GridItem>
                      )}
                    </Grid>
                  </Stack>
                </MenuItem>
                {index !== notifications?.length - 1 && <Divider />}
              </>
            );
          })
        ) : (
          <MenuItem>No tienes notificaciones</MenuItem>
        )}
      </MenuList>
    </>
  );
};
