import { Field } from 'formik';
import CustomSelect from '../../../../../../components/CustomSelect';
import { Country } from 'model/Country';

interface Props {
  name: string;
  countries: Country[];
}

export const CountryResidence = ({ name, countries }: Props) => {
  return (
    <Field
      className="custom-select"
      name={name}
      options={countries
        .filter((country) => country.name !== 'Remoto')
        .map((country) => ({
          label: country.name,
          value: country.id,
        }))}
      component={CustomSelect}
      placeholder="Seleccionar"
      isMulti={false}
    />
  );
};
