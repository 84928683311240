import { gql } from '@apollo/client';
import { ME_EXTENDED_FRAGMENT } from 'graphql/fragments/me';

export const UPDATE_PROFILE = gql`
  mutation UpdateUser($input: updateUserInput!) {
    updateUser(input: $input) {
      user {
        ...MeExtendedParts
      }
    }
  }
  ${ME_EXTENDED_FRAGMENT}
`;
