import {
  Box,
  Checkbox,
  Circle,
  HStack,
  SimpleGrid,
  Stack,
  StackProps,
  Text,
} from '@chakra-ui/react';
import useCareers from 'graphql/hooks/career/useCareers';
import useFullCareer from 'graphql/hooks/career/useFullCareer';
import { memo, useCallback } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { normalizeLevel } from 'utils/normalizeLevel';
import { FlyncerSvg } from '../FlyncerSvg';
import { levels } from '../../utils/preLevels';

export type Path = {
  name: string;
  id: string;
  preLevel: string | undefined;
  level?: string;
};

export type UserCareerFormValues = {
  careerId: string;
  isBasic: boolean;
  experienceYears?: number;
  activePaths: Path[];
  activeItineraries: string[];
  isMain: boolean;
};

interface Props extends StackProps {
  userCareerFormValues?: UserCareerFormValues;
  handleChangeUserCareers: (data: UserCareerFormValues) => void;
  allowUpdateCareer?: boolean;
}

export const UserCareerUpdate = memo(
  ({
    userCareerFormValues,
    handleChangeUserCareers,
    allowUpdateCareer = true,
    ...props
  }: Props) => {
    const { careers, loading: loadingCareers } = useCareers();
    const { userCareers, loading: loadingUserCareer } = useFullCareer();
    const selectedCareer = careers.find(
      (career) => career.id === userCareerFormValues?.careerId
    );

    const getCheckboxId = (id: string) => {
      if (!userCareerFormValues) return;
      return userCareerFormValues.activePaths.find((v) => v.id === id)?.id;
    };

    const handleChange = useCallback(
      (isChecked: boolean, newValue: Path) => {
        if (!userCareerFormValues) return;

        const userCareer = userCareers.find(
          (career) => career.career.id === userCareerFormValues.careerId
        );

        const selectedPath = userCareer?.activePaths.find(
          (path) => path.path.id === newValue.id
        );

        const isEvaluated = selectedPath?.level;

        if (isEvaluated) {
          newValue.level = selectedPath.level?.id;
          newValue.preLevel = selectedPath.preLevel?.id;
        }

        if (isChecked) {
          handleChangeUserCareers({
            ...userCareerFormValues,
            activePaths: userCareerFormValues.activePaths.filter(
              (v) => v.id !== newValue.id
            ),
          });
        } else {
          handleChangeUserCareers({
            ...userCareerFormValues,
            activePaths: [...userCareerFormValues.activePaths, newValue],
          });
        }
      },
      [userCareerFormValues, handleChangeUserCareers, userCareers]
    );

    const changeLevel = useCallback(
      (id: string | undefined, level: string | undefined) => {
        if (!userCareerFormValues) return;

        const updatedValue = userCareerFormValues.activePaths.find(
          (v) => v.id === id
        );

        if (updatedValue) updatedValue.preLevel = level;

        if (updatedValue) {
          handleChangeUserCareers({
            ...userCareerFormValues,
            activePaths: [...userCareerFormValues.activePaths, updatedValue],
          });

          handleChangeUserCareers({
            ...userCareerFormValues,
            activePaths: userCareerFormValues.activePaths.filter(
              (v, index) =>
                userCareerFormValues.activePaths.indexOf(v) === index
            ),
          });
        }
      },
      [handleChangeUserCareers, userCareerFormValues]
    );

    if (loadingCareers || loadingUserCareer) return null;

    return (
      <Stack spacing={8} {...props}>
        <Box bg="gray.10" p={4} borderRadius="base">
          <Text fontWeight="medium">Tecnologías</Text>
          <SimpleGrid m={3} columns={[1, 2]} spacingX={3} spacingY={4}>
            {selectedCareer?.paths.map((path, index) => {
              if (!userCareerFormValues) return null;
              const checkboxId = getCheckboxId(path.id);
              const preLevel = userCareerFormValues.activePaths.find(
                (p) => p.id === path.id
              )?.preLevel;
              const level = userCareerFormValues.activePaths.find(
                (p) => p.id === path.id
              )?.level;
              const isChecked = userCareerFormValues.activePaths.some(
                (v) => v.id === path.id
              );

              const newValue = {
                name: path.name,
                id: path.id,
                preLevel: preLevel ? preLevel : undefined,
                level: level ? level : undefined,
              };

              return (
                <Box ml="4" key={index}>
                  <Checkbox
                    size="lg"
                    spacing={3}
                    colorScheme="brand"
                    key={path.id}
                    value={path.id}
                    isChecked={isChecked}
                    onChange={() => handleChange(isChecked, newValue)}
                  >
                    <Text>{path.name}</Text>
                  </Checkbox>
                  {isChecked && (
                    <HStack spacing={1}>
                      <Text ml={6} mr={1} fontSize="13" color="gray.500">
                        Nivel
                      </Text>
                      {checkboxId && level && level !== '0' ? (
                        <>
                          <Text fontSize="13" color="gray.500">
                            {normalizeLevel(level)}
                          </Text>
                          <Circle size="25px" cursor="pointer">
                            <FlyncerSvg />
                          </Circle>
                        </>
                      ) : checkboxId === path.id &&
                        preLevel &&
                        preLevel !== '0' ? (
                        <>
                          <Circle
                            size="25px"
                            cursor="pointer"
                            color="gray.500"
                            bg="gray.100"
                            borderWidth="1px"
                            borderColor="gray.500"
                          >
                            {
                              userCareerFormValues.activePaths.find(
                                (v) => v.id === checkboxId
                              )?.preLevel
                            }
                          </Circle>
                          <CloseIcon
                            cursor="pointer"
                            w={2}
                            h={2}
                            onClick={() => changeLevel(checkboxId, undefined)}
                          />
                        </>
                      ) : (
                        levels.map((level, index) => {
                          return (
                            <Circle
                              key={index}
                              size="25px"
                              cursor="pointer"
                              color="gray.500"
                              bg="white"
                              borderWidth="1px"
                              onClick={() => changeLevel(checkboxId, level)}
                            >
                              {level}
                            </Circle>
                          );
                        })
                      )}
                    </HStack>
                  )}
                </Box>
              );
            })}
          </SimpleGrid>
        </Box>
      </Stack>
    );
  }
);
