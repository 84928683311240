import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { paths } from 'graphql/generated/paths';
import { PATHS } from 'graphql/queries/paths';
import { removeNulls } from 'utils/removeNulls';
import { normalizePath, Path } from 'model/Path';

const usePaths = () => {
  const { data, loading, error } = useQuery<paths>(PATHS);

  const paths = useMemo(() => {
    return (data?.paths || []).reduce<Path[]>(
      (acum, input) =>
        !!input ? acum.concat(normalizePath(removeNulls(input))) : acum,
      []
    );
  }, [data]);

  return {
    error,
    paths,
    loading,
  };
};

export default usePaths;
