import hljs from 'highlight.js';
import { useEffect, useRef } from 'react';
import 'highlight.js/styles/github.css';
import { Box } from '@chakra-ui/layout';

type Props = {
  content: string;
};

export const HighlightCode = ({ content }: Props) => {
  const el = useRef(null);
  useEffect(() => {
    const code = el.current;

    if (code) {
      hljs.highlightBlock(code);
    }
  }, [content, el]);
  return <Box mt={8} dangerouslySetInnerHTML={{ __html: content }} ref={el} />;
};
