import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { UserProfessions } from 'graphql/generated/UserProfessions';
import { USER_PROFESSION } from 'graphql/queries/userProfession';

const useUserProfession = (username: any) => {
  const {
    data,
    loading: loadingUserProfession,
    error,
  } = useQuery<UserProfessions>(USER_PROFESSION, {
    variables: { username: username },
  });

  const userProfession = useMemo(() => {
    return data?.userProfessions;
  }, [data]);

  return {
    error,
    userProfession,
    loadingUserProfession,
  };
};

export default useUserProfession;
