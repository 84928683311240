import {
  Box,
  CheckboxGroup,
  Circle,
  Grid,
  GridItem,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Progress,
  Text,
  useMediaQuery,
  Wrap,
} from '@chakra-ui/react';
import { ProgressCheckbox } from 'containers/Main/Shared/StepsCompleteProfileModal/ProgressCheckbox';
import { FiGift } from 'react-icons/fi';
import { StepsCompleteProfile } from 'utils/stepsCompleteProfile';
import useConnect from '../../connect';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const CompleteProfileModal = ({ isOpen, onClose }: Props) => {
  const { me } = useConnect();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  if (!me) return null;

  let pathsWithLevel = [];

  if (me.careers)
    pathsWithLevel = me?.careers.filter((career) =>
      career?.activePaths?.find((path) => path?.level)
    );
  const hasPathsWithLevel = pathsWithLevel.length ? true : false;
  const hasCareer = me.careers?.length ? true : false;
  const hasExperiences = me.user_cv ? true : false;
  const hasContactInfo = me.name && me.surname && me.phoneNumber ? true : false;
  const hasProfessionalRol =
    me.professions && me.professions[0]?.startDate ? true : false;
  const hasObjectives =
    (me.desiredCities.length || me.desiredCountries.length) &&
    me.desiredMinSalary
      ? true
      : false;

  const array = [
    hasCareer,
    hasExperiences,
    hasContactInfo,
    hasProfessionalRol,
    hasPathsWithLevel,
    hasObjectives,
  ];

  const stepsDone = array.filter((done) => done);
  const progress = (stepsDone.length * 100) / 6;

  const { stepsCompleteProfile } = StepsCompleteProfile(
    hasCareer,
    hasProfessionalRol,
    hasExperiences,
    hasContactInfo,
    hasObjectives,
    hasPathsWithLevel
  );

  return (
    <Modal isOpen onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent p={10}>
        <ModalCloseButton />
        <ModalBody>
          <Wrap spacing="15px" justify="center">
            <Text
              fontSize="20"
              lineHeight="25px"
              fontWeight="600"
              align="center"
            >
              Completa tu perfil para aplicar a esta oferta
            </Text>
            {!isMobile && (
              <Text
                fontSize="20"
                lineHeight="28px"
                fontWeight="400"
                color="black"
                align="center"
              >
                te ayudará a posicionar tu perfil y aumentará tus posibilidades
                de recibir tu oferta perfecta
              </Text>
            )}
            <Grid templateColumns="repeat(8, 1fr)" mr="6" gap={3}>
              <GridItem colSpan={2}>
                <Text ml="3" textAlign="center" fontSize="16">
                  {stepsDone.length}/6
                </Text>
              </GridItem>

              <GridItem colSpan={5} colStart={3} rowStart={1}>
                <Progress
                  border="1px solid #ABB3C2"
                  borderRadius="10px"
                  bg="transparent"
                  mt="7px"
                  colorScheme="brand"
                  value={progress}
                />
              </GridItem>

              <GridItem
                colStart={7}
                rowStart={1}
                zIndex="3"
                display="flex"
                justifyContent="flex-end"
              >
                <Circle size="28px" bg="white" border="1px solid #ABB3C2">
                  <Icon color="gray.300" as={FiGift} h="4"></Icon>
                </Circle>
              </GridItem>
            </Grid>

            <Box w="xs" lineHeight="40px">
              <CheckboxGroup colorScheme="green">
                {stepsCompleteProfile.map((step) => (
                  <ProgressCheckbox
                    title={step.title}
                    isCompleted={step.isCompleted}
                    url={step.url}
                  />
                ))}
              </CheckboxGroup>
            </Box>
          </Wrap>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
