import { Topbar } from './Shared/TopBar';
import { Redirect, Route, Switch } from 'react-router-dom';

import useMe from 'graphql/hooks/profile/useMe';
import { Candidate } from './Candidate';
import { Jobs } from './Jobs';
import { Job } from './Job';
import { JobDescription } from './Job/jobDescription';
import { FormOffer } from './Jobs/formOffer';
import * as Sentry from '@sentry/react';

export const Admin = () => {
  const { me, error } = useMe();

  if (error) Sentry.captureException(error?.graphQLErrors[0]);

  if (!me) {
    return null;
  }

  if (!me.isJobAdmin) return <Redirect to="/" />;

  return (
    <>
      <Topbar />
      <Switch>
        <Route path="/company/form/:id?" exact component={FormOffer} />
        <Route path="/company" exact component={Jobs} />
        <Route path="/company/job/inscribed/:jobId" exact component={Job} />
        <Route path="/company/job/:jobId" component={Job} />
        <Route path="/company/mistery/job/:jobId" component={Job} />
        <Route path="/company/description/:id" component={JobDescription} />
        <Route path="/company/candidate/:id" exact component={Candidate} />
        <Redirect to="/company" />
      </Switch>
    </>
  );
};
