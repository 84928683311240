import { useCallback } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import Vimeo from '@u-wave/react-vimeo';
import {
  ButtonProps,
  Text,
  Flex,
  Icon,
  HStack,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { Lesson } from 'model/Course';

import { FiCheckCircle, FiPlayCircle } from 'react-icons/fi';
import { normalizeDuration } from 'utils/normalizeDuration';
import useFullCourseActions from 'graphql/hooks/career/useFullCourseActions';

interface Props extends ButtonProps {
  lesson: Lesson;
  isDone: boolean;
}

interface Time {
  seconds: number;
  percent: number;
  duration: number;
}

export const LessonButtonModal = ({ lesson, isDone, ...props }: Props) => {
  const { id } = useParams<{ id: string }>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { completeLesson } = useFullCourseActions();
  const { replace } = useHistory();

  let aux: any = '1';
  let auxObject: Time = { seconds: 0, percent: 0, duration: 0 };

  const onEndVideo = useCallback(() => {
    completeLesson({ lessonId: lesson.id, courseId: id });
  }, [completeLesson, lesson, id]);

  const saveProgress = useCallback(
    (time: any) => {
      if (lesson.vimeoId)
        localStorage.setItem(lesson.vimeoId, JSON.stringify(time));
    },
    [lesson.vimeoId]
  );

  if (lesson.vimeoId !== null && lesson.vimeoId) {
    if (localStorage.getItem(lesson.vimeoId) !== null) {
      aux = localStorage.getItem(lesson.vimeoId);
      auxObject = JSON.parse(aux);
    }
  }

  const handleClose = () => {
    replace(`/course/${id}`);
    onClose();
  };

  return (
    <>
      <Button
        py="2.5"
        px="4"
        margin="0"
        width="100%"
        variant="ghost"
        borderRadius="0"
        onClick={onOpen}
        {...props}
      >
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <HStack spacing="3">
            {isDone ? (
              <Icon color="blue.400" as={FiCheckCircle} w={5} h={5} />
            ) : (
              <Icon color="gray.300" as={FiPlayCircle} w={5} h={5} />
            )}

            <Text textStyle="small" color="gray.500">
              {lesson.name}
            </Text>
          </HStack>
          <Text textStyle="small" color="gray.500">
            {normalizeDuration(lesson.durationMinutes)}
          </Text>
        </Flex>
      </Button>
      <Modal isOpen={isOpen} onClose={handleClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Vista previa</ModalHeader>
          <ModalCloseButton as={RouterLink} to={`/course/${id}`} />
          <ModalBody>
            {lesson.vimeoId && (
              <Vimeo
                start={auxObject ? auxObject.seconds : 0}
                onTimeUpdate={(c) => saveProgress(c)}
                video={lesson.vimeoId}
                autoplay
                responsive
                onEnd={onEndVideo}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
