import { useParams } from 'react-router';
import useFullCourse from 'graphql/hooks/career/useFullCourse';
import useFullCareer from 'graphql/hooks/career/useFullCareer';
import { useMemo } from 'react';

export const useConnect = () => {
  const { id } = useParams<{ id: string }>();
  const { course, loading, error } = useFullCourse(id);
  const {
    userCourses,
    loading: userInfoLoading,
    error: fullCareerError,
  } = useFullCareer();

  const userCourse = useMemo(
    () => userCourses.find((userCourse) => id === userCourse.course?.id),
    [id, userCourses]
  );

  return {
    fullCareerError,
    course,
    userCourse,
    error,
    loading: {
      course: loading,
      userCourse: userInfoLoading,
    },
  };
};
