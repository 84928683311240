import { gql } from '@apollo/client';

export const USER_COURSE_FRAGMENT = gql`
  fragment UserCourseParts on UserCourse {
    id
    progress
    lessonsDone {
      lessonId
    }
    course {
      id
    }
    userQuiz {
      id
    }
  }
`;
