import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { Cities } from 'graphql/generated/Cities';
import { CITIES } from 'graphql/queries/location';
import { removeNulls } from 'utils/removeNulls';
import { normalizeLocation, Location } from 'model/Location';

const useCities = () => {
  const { data, loading, error } = useQuery<Cities>(CITIES);

  const cities = useMemo(() => {
    return (data?.cities || []).reduce<Location[]>(
      (acum, input) =>
        !!input ? acum.concat(normalizeLocation(removeNulls(input))) : acum,
      []
    );
  }, [data]);

  return {
    error,
    cities,
    loading,
  };
};

export default useCities;
