import { useDisclosure } from '@chakra-ui/react';
import { OneJobSanitized } from 'graphql/hooks/admin/useJob';
// import useFullCareer from 'graphql/hooks/career/useFullCareer';
// import useUserCareerActions from 'graphql/hooks/career/useUserCareerActions';
import useUserPaths from 'graphql/hooks/career/useUserPaths';
import useApplyOfferActions from 'graphql/hooks/jobs/useApplyOfferActions';
import useMe from 'graphql/hooks/profile/useMe';
import { useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { sanitizeDate } from 'utils/sanitizeDate';

const useConnect = () => {
  // const { userCareers } = useFullCareer();
  // const { create, update } = useUserCareerActions();
  // const { replace } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { applyOffer, loadingApply } = useApplyOfferActions();
  const { job, error: oneJobError, refetch } = OneJobSanitized({ id });
  const { me } = useMe();
  const { isOpen, onOpen, onClose } = useDisclosure();
  let idUser = me?.id;
  const { paths, loadingPaths } = useUserPaths({ idUser });

  //Funcion para redirigir al test de un path
  // const handleClick = async (userPath: any, path: any) => {
  //   if (userPath !== undefined) {
  //     localStorage.setItem('jobId', id);
  //     replace(`/quiz/path/${userPath.id}`);
  //   } else {
  //     const career = userCareers.find(
  //       (userCareer) => userCareer.career.id === path.career
  //     );

  //     if (career !== undefined) {
  //       const activePathsIds = career.activePaths.map(({ path }) => path.id);

  //       await update({
  //         where: { id: career.id },
  //         data: {
  //           activePaths: [...activePathsIds, path.id],
  //           experienceYears: 0,
  //         },
  //       }).then((res) => {
  //         let userPathId: any;

  //         res?.careers.forEach((userCareer) => {
  //           if (userCareer.id === career.id) {
  //             userPathId = userCareer?.activePaths?.find(
  //               (activePath) => activePath?.path?.id === path.id
  //             );
  //           }
  //         });

  //         localStorage.setItem('jobId', id);
  //         replace(`/quiz/path/${userPathId?.id}`);
  //       });
  //     } else {
  //       await create({
  //         careerId: path.career,
  //         activePaths: [path.id],
  //         activeItineraries: [],
  //         isMain: false,
  //       }).then((res) => {
  //         let userPathId: any;

  //         res?.careers.forEach((userCareer) => {
  //           userPathId = userCareer?.activePaths?.find(
  //             (activePath) => activePath?.path?.id === path.id
  //           );
  //         });
  //         localStorage.setItem('jobId', id);
  //         replace(`/quiz/path/${userPathId?.id}`);
  //       });
  //     }
  //   }
  // };

  const canApply = job?.skills.every((pathGroup: any) =>
    paths?.find((userPath) =>
      pathGroup?.paths.find(
        (path: any) => path.id === userPath?.path?.id && userPath?.level
      )
    )
  );

  let locations;

  locations = job?.countries.filter(
    (country: any) => country.name === 'Remoto'
  );

  locations = locations?.map((location: any) => location.name);

  if (!locations?.length)
    locations = job?.cities.map((loc: any) => {
      return loc.name;
    });

  let salary = { min: job?.salaryMin, max: job?.salaryMax };

  const isApply = job?.user_job_applications.some(
    (e: any) => e.user === me?.id
  );

  const jobSkills = job?.skills?.map((pathGroup: any) =>
    pathGroup.paths.map((path: any) => ({
      name: path.name,
      pathId: path.id,
      careerId: path.career,
    }))
  );

  jobSkills?.forEach((pathGroup: any) => {
    pathGroup.forEach((path: any) => {
      const userPath = paths.find(
        (userPath) => userPath?.path && userPath.path.id === path.pathId
      );

      if (userPath?.id) path.userPathId = userPath.id;

      if (userPath?.preLevel) path.preLevel = userPath.preLevel.id;

      if (userPath?.level) path.level = userPath.level.id;
    });
  });

  const handleAccept = useCallback(
    async (values) => {
      let allPaths: any = [];

      values.forEach((pathGroup: any) =>
        pathGroup.forEach((path: any) => {
          if (path.preLevel && !path.level) allPaths.push(path);
        })
      );

      const object = {
        where: { id: id },
        data: allPaths,
      };

      applyOffer({ input: object }).finally(() => {
        refetch();
        onOpen();
      });
    },
    [applyOffer, id, onOpen, refetch]
  );

  const isCompleted = useRef(me?.completed_profile);
  const created_at = sanitizeDate(job?.created_at);

  return {
    handleAccept,
    loadingApply,
    job,
    oneJobError,
    isOpen,
    onClose,
    canApply,
    paths,
    locations,
    salary,
    isApply,
    isCompleted,
    created_at,
    jobSkills,
    me,
    loadingPaths,
    id,
  };
};

export default useConnect;
