import { useMemo } from 'react';
import useMe from 'graphql/hooks/profile/useMe';
import useFullCareer from 'graphql/hooks/career/useFullCareer';
import useCareers from 'graphql/hooks/career/useCareers';
import * as Sentry from '@sentry/react';

const useConnect = () => {
  const { me, loading: meLoading, refetchUser, error } = useMe();
  const {
    userCareers,
    loading,
    refetch,
    error: fullCareerError,
  } = useFullCareer();
  const { careers, error: careersError } = useCareers();

  if (error || fullCareerError || careersError) {
    let message = error
      ? error
      : fullCareerError
      ? fullCareerError
      : careersError;
    Sentry.captureException(message?.graphQLErrors[0]);
  }

  const allowAddNewCareer = useMemo(() => {
    const lastCareer = userCareers[userCareers.length - 1];
    if (!lastCareer) return false;
    refetch();
    refetchUser();
    const activePathsCount = lastCareer.activePaths.length;
    const activeItinerariesCount = lastCareer.activeItineraries.length;

    return (
      (activePathsCount > 0 || activeItinerariesCount > 0) &&
      userCareers.length < careers.length
    );
  }, [userCareers, careers, refetch, refetchUser]);

  return {
    me: me,
    userCareers,
    allowAddNewCareer,
    loading: loading || meLoading,
    refetch,
  };
};

export default useConnect;
