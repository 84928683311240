import { useCallback } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { FlyncerError } from 'graphql/helpers';
import { createToast } from 'services/toast';

import {
  AcceptJobRequest,
  AcceptJobRequestVariables,
} from 'graphql/generated/AcceptJobRequest';
import {
  RejectJobRequest,
  RejectJobRequestVariables,
} from 'graphql/generated/RejectJobRequest';
import {
  ACCEPT_JOB_REQUEST,
  BLOCK_COMPANY,
  REJECT_JOB_REQUEST,
} from 'graphql/mutations/jobRequest';
import {
  BlockCompany,
  BlockCompanyVariables,
} from 'graphql/generated/BlockCompany';
import { JOB_REQUEST_IS_ACCEPTED_FRAGMENT } from 'graphql/fragments/jobRequest';
import { COMPANIES } from 'graphql/queries/company';
import * as Sentry from '@sentry/react';

const useJobRequestActions = () => {
  const client = useApolloClient();
  const [acceptJobRequestMutation, { loading: acceptJobRequestLoading }] =
    useMutation<AcceptJobRequest, AcceptJobRequestVariables>(
      ACCEPT_JOB_REQUEST,
      { refetchQueries: [{ query: COMPANIES }] }
    );

  const acceptJobRequest = useCallback(
    async (input: AcceptJobRequestVariables) => {
      try {
        const { data } = await acceptJobRequestMutation({ variables: input });
        const id = data?.acceptJobRequest.id;

        if (id) {
          client.writeFragment({
            id: `JobRequest:${id}`,
            fragment: JOB_REQUEST_IS_ACCEPTED_FRAGMENT,
            data: {
              isAccepted: true,
            },
          });
        }
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo aceptar la oferta'));
        throw new FlyncerError('No se pudo aceptar la oferta', error);
      }
    },
    [acceptJobRequestMutation, client]
  );

  const [rejectJobRequestMutation, { loading: rejectJobRequestLoading }] =
    useMutation<RejectJobRequest, RejectJobRequestVariables>(
      REJECT_JOB_REQUEST,
      { refetchQueries: [{ query: COMPANIES }] }
    );
  const rejectJobRequest = useCallback(
    async (input: RejectJobRequestVariables) => {
      try {
        const { data } = await rejectJobRequestMutation({ variables: input });
        const id = data?.rejectJobRequest.id;

        if (id) {
          client.writeFragment({
            id: `JobRequest:${id}`,
            fragment: JOB_REQUEST_IS_ACCEPTED_FRAGMENT,
            data: {
              isAccepted: false,
            },
          });
        }

        createToast({
          title: 'Oferta rechazada',
          description:
            'Trabajaremos en enviarte ofertas que se ajusten mejor a lo que buscas.',
          status: 'success',
        });
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo rechazar la oferta'));
        throw new FlyncerError('No se pudo rechazar la oferta', error);
      }
    },
    [rejectJobRequestMutation, client]
  );

  const [blockCompanyMutation] = useMutation<
    BlockCompany,
    BlockCompanyVariables
  >(BLOCK_COMPANY);
  const blockCompany = useCallback(
    async (input: BlockCompanyVariables) => {
      try {
        await blockCompanyMutation({ variables: input });
        createToast({
          title: 'Empresa bloqueada',
          description: 'No te enviaremos más ofertas de esta empresa.',
          status: 'success',
        });
      } catch (error: any) {
        Sentry.captureException(new Error('No se pudo bloquear la oferta'));
        throw new FlyncerError('No se pudo bloquear la empresa', error);
      }
    },
    [blockCompanyMutation]
  );

  return {
    acceptJobRequest,
    rejectJobRequest,
    blockCompany,
    loading: acceptJobRequestLoading || rejectJobRequestLoading,
  };
};

export default useJobRequestActions;
