import {
  Button,
  Modal,
  ModalProps,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Accordion,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { CareerSelector } from 'components/UserCareerSelectorFormik';
import useUserCareerActions from 'graphql/hooks/career/useUserCareerActions';
import useCareers from 'graphql/hooks/career/useCareers';
import useFullCareer from 'graphql/hooks/career/useFullCareer';
import { createToast } from 'services/toast';
import { ActivePaths, AddCareerInput } from 'graphql/generated/globalTypes';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import useMe from 'graphql/hooks/profile/useMe';
import { useHistory } from 'react-router-dom';
import { PrelevelGuide } from 'components/PrelevelGuide';

interface Props extends Omit<ModalProps, 'children'> {
  title: string;
  subtitle?: boolean;
  onTour?: boolean;
  setisSend?: (isSend: boolean) => void;
  stepIndex?: number;
  forceRender?: (isForce: boolean) => void;
  isOpen: boolean;
  isOpen2?: boolean;
  onClose: () => void;
}

export type Values = {
  name: string;
  value: Path[];
};

export type Path = {
  name: string;
  id: string;
  preLevel?: string;
  checked?: boolean;
};

export const AddCareerModal = ({
  title,
  isOpen,
  onClose,
  setisSend,
  onTour,
  stepIndex,
  forceRender,
  isOpen2,
  subtitle,
}: Props) => {
  const { create, update } = useUserCareerActions();
  const { careers, loading: loadingCareers, error } = useCareers();
  const { userCareers, refetch, error: fullCareerError } = useFullCareer();
  const [isSubmitting, setisSubmitting] = useState(false);
  const { replace } = useHistory();
  const { me } = useMe();

  if (error || fullCareerError) {
    let message = error ? error : fullCareerError;
    Sentry.captureException(message?.graphQLErrors[0]);
  }

  if (loadingCareers) return null;

  return (
    <Modal
      isOpen={
        onTour && stepIndex === 0 && !isOpen2 && localStorage.getItem('button')
          ? true
          : isOpen
      }
      onClose={onClose}
      isCentered
      scrollBehavior="inside"
    >
      <Formik
        initialValues={{
          Backend: [],
          Frontend: [],
          Database: [],
          Mobile: [],
          Management: [],
        }}
        onSubmit={(values) => {
          setisSubmitting(true);

          try {
            if (
              !values.Backend.length &&
              !values.Frontend.length &&
              !values.Database.length &&
              !values.Mobile.length &&
              !values.Management.length
            ) {
              createToast({
                title: 'Añadir tecnologías',
                description: 'Debes seleccionar al menos una tecnología.',
                status: 'error',
              });
              setisSubmitting(false);
            }

            const careerList: Array<Values> = [
              { name: 'Backend', value: values.Backend },
              { name: 'Frontend', value: values.Frontend },
              { name: 'Database', value: values.Database },
              { name: 'Mobile', value: values.Mobile },
              { name: 'Management', value: values.Management },
            ];

            careerList.map(async (career) => {
              if (career.value.length) {
                const c = careers.find((c) => c.name === career.name);
                let careerId = '0';
                if (c) careerId = c.id;
                let activePaths: ActivePaths[] = [];
                let activeItineraries: string[] = [];

                if (career.name !== 'Management') {
                  activePaths = career.value.map((path) => ({
                    pathId: path.id,
                    preLevel: path.preLevel || undefined,
                  }));
                }
                //  else {
                //   activeItineraries = career.value.;
                // }

                const object: AddCareerInput = {
                  careerId,
                  activePaths,
                  activeItineraries,
                  isMain: false,
                };

                const userCareer = userCareers.find(
                  (userCareer) => userCareer.career.name === career.name
                );

                if (userCareer) {
                  userCareer.activePaths.forEach((activePath) => {
                    activePaths.push({
                      pathId: activePath.path.id,
                      preLevel: activePath.preLevel?.id || '',
                    });
                  });
                  userCareer.activeItineraries.map((activeItinerary) =>
                    activeItineraries.push(activeItinerary.itinerary.id)
                  );

                  await update({
                    where: { id: userCareer.id },
                    data: {
                      experienceYears: userCareer.experienceYears,
                      activePaths,
                      activeItineraries,
                    },
                  }).then(() => {
                    if (setisSend) setisSend(true);
                    refetch();
                  });
                } else {
                  await create(object).then(() => {
                    if (setisSend) setisSend(true);
                    refetch();
                  });
                }

                if (me?.onboarding) replace('/onboarding-send-to-profile');
                setisSubmitting(false);
                onClose();
              }
            });
          } catch (e) {
            console.log(e);
          }
        }}
      >
        <Form>
          <ModalOverlay />
          <ModalContent className="modal">
            <ModalHeader>
              {title}
              {subtitle && (
                <VStack mt="1" align="left" spacing={1}>
                  <Text fontSize="13" fontWeight="normal">
                    Añade tus tecnologías y tu nivel de conocimiento de ellas.
                  </Text>
                  <PrelevelGuide />
                </VStack>
              )}
            </ModalHeader>
            {!onTour && <ModalCloseButton />}
            <ModalBody>
              <Accordion
                onClick={() => {
                  if (forceRender && onTour) forceRender(true);
                }}
              >
                {careers
                  .filter(
                    (career) =>
                      career.name !== 'Management' &&
                      career.name !== 'Empleabilidad'
                  )
                  .map((career) => (
                    <CareerSelector
                      key={career.id}
                      name={career.name}
                      careerPaths={career.paths}
                      careerItineraries={career.itineraries}
                    />
                  ))}
              </Accordion>
            </ModalBody>

            <ModalFooter>
              {!onTour && (
                <Button onClick={onClose} variant="ghost">
                  Cancelar
                </Button>
              )}
              <Button
                isDisabled={isSubmitting}
                ml="4"
                colorScheme="brand"
                type="submit"
              >
                Guardar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Form>
      </Formik>
    </Modal>
  );
};
