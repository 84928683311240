import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  companyName: string;
}

export const ApplyModal = ({ isOpen, onClose, companyName }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        <ModalHeader>Enhorabuena</ModalHeader>
        <ModalBody>
          <Text textStyle="body">
            {companyName} se pondrá en contacto contigo
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="brand" as={RouterLink} to="/jobs">
            Volver a mis ofertas
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
