import { useMutation } from '@apollo/client';
import {
  AuxRegister,
  AuxRegisterVariables,
} from 'graphql/generated/AuxRegister';
import { FlyncerError } from 'graphql/helpers';
import { AUX_REGISTER } from 'graphql/mutations/auth';
import { useCallback } from 'react';

const useAuxRegister = () => {
  const [auxRegisterMutation, { loading: auxRegisterLoading }] = useMutation<
    AuxRegister,
    AuxRegisterVariables
  >(AUX_REGISTER);
  const auxRegister = useCallback(
    async (cookie?: string) => {
      try {
        await auxRegisterMutation({ variables: { cookie } });
      } catch (error: any) {
        throw new FlyncerError('Algo falló intentando acceder', error);
      }
    },
    [auxRegisterMutation]
  );

  return { auxRegister, auxRegisterLoading };
};

export default useAuxRegister;
