import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { Skills } from 'graphql/generated/Skills';
import { SKILLS } from 'graphql/queries/skill';
import { removeNulls } from 'utils/removeNulls';
import { normalizeSkill, Skill } from 'model/Skill';

const useSkills = () => {
  const { data, loading, error } = useQuery<Skills>(SKILLS);

  const skills = useMemo(() => {
    return (data?.skills || []).reduce<Skill[]>(
      (acum, input) =>
        !!input ? acum.concat(normalizeSkill(removeNulls(input))) : acum,
      []
    );
  }, [data]);

  return {
    error,
    skills,
    loading,
  };
};

export default useSkills;
