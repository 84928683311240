import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { Job, JobVariables } from 'graphql/generated/Job';
import { oneJobSanitizedVariables } from 'graphql/generated/oneJobSanitized';
import { JOB, ONEJOB, ONEJOB_SANITIZED } from 'graphql/queries/job';
import { removeNulls } from 'utils/removeNulls';
import { normalizeJob } from 'model/Job';
import { oneJobVariables } from 'graphql/generated/oneJob';

export const useJob = ({ jobId }: JobVariables) => {
  const { data, loading, refetch, error } = useQuery<Job, JobVariables>(JOB, {
    variables: { jobId },
  });
  const job = useMemo(() => {
    return data?.activeJob
      ? normalizeJob(removeNulls(data.activeJob))
      : undefined;
  }, [data]);

  return {
    error,
    job,
    loading,
    refetch,
  };
};

export const OneJob = ({ id }: oneJobVariables) => {
  const { data, loading, refetch, error } = useQuery(ONEJOB, {
    variables: { id },
  });

  const job = useMemo(() => {
    if (data) return data.oneJob;
  }, [data]);

  return {
    error,
    job,
    loading,
    refetch,
  };
};

export const OneJobSanitized = ({ id }: oneJobSanitizedVariables) => {
  const { data, loading, refetch, error } = useQuery(ONEJOB_SANITIZED, {
    variables: { id },
  });

  const job = useMemo(() => {
    return data ? data.oneJobSanitized : null;
  }, [data]);

  return {
    job,
    loading,
    refetch,
    error,
  };
};
