import { Redirect, Route, Switch } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import 'react-phone-input-2/lib/high-res.css';
import {
  Box,
  Tab,
  Tabs,
  TabList,
  Button,
  Flex,
  Text,
  Link,
} from '@chakra-ui/react';

import useConnect from './connect';
import { useHistory, useLocation } from 'react-router-dom';
import Joyride from 'react-joyride';
import { useState, useEffect } from 'react';
import { MobileOptions } from './MobileOptions';
import { AvatarContainer } from './AvatarContainer';
import { ProfileTab } from './Tabs/Profile';
import { GoalsTab } from './Tabs/Goals';
import { ContactTab } from './Tabs/Contact';
import { FlyncerMapTab } from './Tabs/FlyncerMap';
import { ReferralMessages } from 'utils/referralMessages';
import StepsOnboarding from 'utils/stepsOnboarding';

export const Profile = () => {
  const {
    fullName,
    referral_num,
    tabIndex,
    fullUsername,
    me,
    idUser,
    location,
    errorSalary,
    errorLocations,
    loading,
    isMobile,
  } = useConnect();
  const [forceRender, setforceRender] = useState(false);
  const { replace } = useHistory();
  const locationPath = useLocation();
  const { bodyEmail, bodyEmailUser } = ReferralMessages(fullName, referral_num);
  const { steps3 } = StepsOnboarding();

  useEffect(() => {
    document.getElementById('top')?.scrollIntoView();
  }, []);

  if (!me) return null;
  else if (errorSalary && locationPath.pathname === '/onboarding-profile-goals')
    document.getElementById('salary')?.scrollIntoView();
  else if (
    errorLocations &&
    locationPath.pathname === '/onboarding-profile-goals'
  )
    document.getElementById('locations')?.scrollIntoView();

  if (forceRender) {
    setTimeout(() => {
      setforceRender(false);
    }, 250);
  }

  if (!me?.careers?.length) replace('/onboarding-career-selector');

  if (
    (location.pathname === '/onboarding-profile-goals' ||
      location.pathname === '/onboarding-profile-contact') &&
    me?.onboarding &&
    !me.user_cv
  )
    replace('/onboarding-profile');
  if (
    location.pathname === '/onboarding-profile' &&
    me?.onboarding &&
    me.user_cv &&
    me?.professions &&
    me?.professions[0]?.startDate
  )
    replace('/onboarding-profile-goals');

  if (
    (location.pathname === '/onboarding-profile' ||
      location.pathname === '/onboarding-profile-contact') &&
    me?.onboarding &&
    (!me.desiredMinSalary || !me?.desiredCountries.length) &&
    me.user_cv &&
    me?.professions &&
    me?.professions[0]?.startDate
  )
    replace('/onboarding-profile-goals');
  else if (
    location.pathname === '/onboarding-profile-goals' &&
    me?.onboarding &&
    me.desiredMinSalary &&
    (me.desiredCities.length || me.desiredCountries.length)
  )
    replace('/onboarding-profile-contact');

  if (
    (location.pathname === '/onboarding-profile' ||
      location.pathname === '/onboarding-profile-goals') &&
    (!me?.name || !me?.surname || !me.phoneNumber) &&
    me?.onboarding &&
    me.desiredMinSalary &&
    (me.desiredCities.length || me.desiredCountries.length) &&
    me.user_cv
  )
    replace('/onboarding-profile-contact');
  else if (
    location.pathname === '/onboarding-profile-contact' &&
    me?.name &&
    me?.surname &&
    me.phoneNumber &&
    me.phoneNumber?.length >= 10 &&
    me.onboarding
  )
    replace('/onboarding-jobs');

  if ((!idUser && !me) || loading) return null;

  return (
    <>
      <Box
        as="main"
        mx="auto"
        w="100%"
        maxW="container.sm"
        px={{ base: '4', md: '6' }}
        py={{ base: '8', md: '14' }}
        id={isMobile ? 'top' : ''}
      >
        <Flex
          alignItems="center"
          mb="10"
          direction={isMobile ? 'column' : 'row'}
        >
          {isMobile && <MobileOptions />}

          <AvatarContainer />

          <Flex direction="column" textAlign={isMobile ? 'center' : 'left'}>
            <Text as="h3" textStyle={{ base: 'h4', md: 'h3' }}>
              {fullName}
            </Text>
            <Text color="gray.500" mt="1" textStyle="body">
              Código de referral:{' '}
              <Link
                href={
                  fullUsername !== ''
                    ? `mailto:?subject=Invitacion a Flyncer&body=${bodyEmailUser}`
                    : `mailto:?subject=Invitacion a Flyncer&body=${bodyEmail}`
                }
                color="gray.500"
                mt="1"
                textStyle="body"
              >
                {referral_num}
              </Link>
            </Text>
          </Flex>
        </Flex>
        <Flex alignItems="center" direction={isMobile ? 'column' : 'row'}>
          <Button
            as={RouterLink}
            to="/publicProfile"
            borderRadius="20"
            variant="outline"
            colorScheme="green"
            marginTop="-25px"
          >
            Ver mi perfil
          </Button>
        </Flex>
        <Tabs colorScheme="brand" mb="10" mt="5" index={tabIndex}>
          {me?.onboarding && (
            <Joyride
              scrollToFirstStep={true}
              showSkipButton={false}
              continuous={true}
              stepIndex={0}
              steps={steps3}
              showProgress={false}
              styles={{
                options: { zIndex: 3000, overlayColor: '#3A3939' },
                buttonNext: {
                  backgroundColor: '#009253',
                },
              }}
            />
          )}
          <TabList>
            <Tab
              as={RouterLink}
              to={!me.onboarding ? '/profile' : '/onboarding-profile'}
            >
              Tu perfil
            </Tab>
            <Tab
              as={RouterLink}
              to={
                !me.onboarding ? '/profile/goals' : '/onboarding-profile-goals'
              }
            >
              Tus objetivos
            </Tab>
            <Tab
              as={RouterLink}
              to={
                !me.onboarding
                  ? '/profile/contact'
                  : '/onboarding-profile-contact'
              }
            >
              Datos de contacto
            </Tab>
            <Tab as={RouterLink} to="/profile/flyncermap">
              Flyncer map
            </Tab>
          </TabList>
        </Tabs>
        <Switch>
          <Route
            exact
            path={!me.onboarding ? '/profile' : '/onboarding-profile'}
          >
            <ProfileTab />
          </Route>
          <Route
            exact
            path={
              !me.onboarding ? '/profile/goals' : '/onboarding-profile-goals'
            }
          >
            <GoalsTab />
          </Route>
          <Route
            exact
            path={
              !me.onboarding
                ? '/profile/contact'
                : '/onboarding-profile-contact'
            }
          >
            <ContactTab />
          </Route>
          <Route exact path="/profile/flyncermap">
            <FlyncerMapTab />
          </Route>
          <Redirect to={!me.onboarding ? '/profile' : '/onboarding-profile'} />
        </Switch>
      </Box>
    </>
  );
};
