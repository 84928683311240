import { useCallback } from 'react';
import { setToken, removeToken } from 'services/token';
import { useApolloClient, useMutation } from '@apollo/client';

import { LOGIN, REGISTER } from 'graphql/mutations/auth';
import { RESET_PASSWORD } from 'graphql/mutations/resetPassword';
import { FORGOT_PASSWORD } from 'graphql/mutations/forgotPassword';
import { IS_AUTH } from 'graphql/queries/isAuth';
import { FlyncerError } from 'graphql/helpers';

import { IsAuth } from 'graphql/generated/IsAuth';
import { Login, LoginVariables } from 'graphql/generated/Login';
import { Register, RegisterVariables } from 'graphql/generated/Register';
import {
  ForgotPassword,
  ForgotPasswordVariables,
} from 'graphql/generated/ForgotPassword';
import {
  ResetPassword,
  ResetPasswordVariables,
} from 'graphql/generated/ResetPassword';
import * as Sentry from '@sentry/react';

const useAuth = () => {
  const client = useApolloClient();

  const [loginMutation, { loading: loginLoading }] = useMutation<
    Login,
    LoginVariables
  >(LOGIN);

  const [registerMutation, { loading: registerLoading }] = useMutation<
    Register,
    RegisterVariables
  >(REGISTER);
  const [updatePasswordMutation, { loading: updatePasswordLoading }] =
    useMutation<ResetPassword, ResetPasswordVariables>(RESET_PASSWORD);
  const [forgotPasswordMutation, { loading: forgotPasswordLoading }] =
    useMutation<ForgotPassword, ForgotPasswordVariables>(FORGOT_PASSWORD);

  const login = useCallback(
    async ({ input }: LoginVariables) => {
      try {
        const response = await loginMutation({ variables: { input } });
        const data = response.data?.login;

        if (data && data.jwt) {
          setToken(data.jwt);

          client.cache.writeQuery<IsAuth>({
            data: { me: data.user },
            query: IS_AUTH,
          });
        }
      } catch (error: any) {
        throw new FlyncerError('Algo falló intentando acceder', error);
      }
    },
    [client, loginMutation]
  );

  const register = useCallback(
    async ({ input }: RegisterVariables) => {
      try {
        const response = await registerMutation({ variables: { input } });
        const data = response.data?.register;

        if (data && data.jwt) {
          setToken(data.jwt);

          client.cache.writeQuery<IsAuth>({
            data: { me: data.user },
            query: IS_AUTH,
          });
        }
      } catch (error: any) {
        Sentry.captureException(new Error('Fallo al intentar registrarse'));
        throw new FlyncerError('Fallo al intentar registrarse', error);
      }
    },
    [client, registerMutation]
  );

  const logout = useCallback(async () => {
    removeToken();
    await client.cache.reset();

    client.cache.writeQuery<IsAuth>({
      data: { me: null },
      query: IS_AUTH,
    });
  }, [client]);

  const forgotPassword = useCallback(
    async (input: ForgotPasswordVariables) => {
      try {
        await forgotPasswordMutation({ variables: input });
      } catch (error: any) {
        Sentry.captureException(
          new Error('No se pudo recuperar la contraseña')
        );
        throw new FlyncerError('No se pudo recuperar contraseña', error);
      }
    },
    [forgotPasswordMutation]
  );

  const updatePassword = useCallback(
    async (input: ResetPasswordVariables) => {
      try {
        const response = await updatePasswordMutation({ variables: input });

        const data = response.data?.resetPassword;

        if (data && data.jwt) {
          setToken(data.jwt);

          client.cache.writeQuery<IsAuth>({
            data: { me: data.user },
            query: IS_AUTH,
          });
        }
      } catch (error: any) {
        Sentry.captureException(
          new Error('No se pudo actualizar la contraseña')
        );
        throw new FlyncerError('No se pudo actualizar contraseña', error);
      }
    },
    [client, updatePasswordMutation]
  );

  return {
    loading:
      loginLoading ||
      registerLoading ||
      updatePasswordLoading ||
      forgotPasswordLoading,
    login,
    register,
    logout,
    forgotPassword,
    updatePassword,
  };
};

export default useAuth;
