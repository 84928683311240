import { useHistory } from 'react-router-dom';
import {
  Modal,
  ModalProps,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
  Divider,
  Text,
} from '@chakra-ui/react';
import { FiAward, FiLock } from 'react-icons/fi';
import { Header } from './shared/Header';
import { Percent } from './shared/Percent';
import ReactStars from 'react-rating-stars-component';
import { useState } from 'react';
import useReportActions from 'graphql/hooks/report/useReportActions';

interface Props extends Omit<ModalProps, 'children'> {
  course: string;
  isApproved: boolean;
  percent: number;
  courseId: string | undefined;
  textCheckbox: string[];
}

export const ModalQuizForCourse = ({
  course,
  isApproved,
  percent,
  isOpen,
  onClose,
  courseId,
  textCheckbox,
}: Props) => {
  const { goBack, replace } = useHistory();
  const { createReport } = useReportActions();

  const courseAssesment = async (type: string) => {
    const reason: any = 'CourseEval';

    if (assessment !== 0) {
      await createReport({
        message: '',
        reason: reason,
        courseId: courseId,
        courseAssessment: assessment,
      });
      if (type === 'Approved') replace('/');
      else goBack();
    } else if (type === 'Approved') replace('/');
    else goBack();
  };

  const [assessment, setassessment] = useState(0);

  const ratingChanged = (newRating: number) => {
    setassessment(newRating);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      scrollBehavior="inside"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody mt="2">
          <Header
            icon={isApproved ? FiAward : FiLock}
            title={course}
            subtitle={isApproved ? 'Curso superado' : 'Curso no superado'}
          />
          <Divider mb="6" />
          <Percent mb="7" percent={percent} />
          <Text textStyle="body" color="gray.500">
            {isApproved
              ? '¡Bien hecho! Sigue completando cursos para mejorar tu perfil profesional'
              : 'Necesitas obtener un 85% para superar el test. No pasa nada, vuelve a ver los vídeos e inténtalo de nuevo'}
          </Text>

          <Divider mt="5" mb="5" width={{ lg: '24%', sm: '27%' }} />
          {isApproved && (
            <>
              <Text>Valora este curso</Text>

              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={24}
                activeColor="#ffd700"
              />
            </>
          )}
        </ModalBody>

        <ModalFooter>
          {isApproved ? (
            <Button
              ml="4"
              colorScheme="brand"
              onClick={() => {
                courseAssesment('Approved');
              }}
            >
              Ir a mi carrera
            </Button>
          ) : (
            <Button
              ml="4"
              colorScheme="brand"
              onClick={() => {
                courseAssesment('Failed');
              }}
            >
              Volver al curso
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
