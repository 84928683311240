import { Button, FormLabel, Stack, Text } from '@chakra-ui/react';
import { FormInput } from 'components/FormInput';
import { SectionCard } from 'components/SectionCard';
import { Form, Formik } from 'formik';
import PhoneInput from 'react-phone-input-2';
import useConnect from '../../connect';

export const ContactTab = () => {
  const {
    handleSubmit,
    initialValues,
    me,
    schema3,
    phoneNumber,
    setphoneNumber,
    seterrorPhoneNumber,
    errorPhoneNumber,
    isMobile,
  } = useConnect();

  if (!me) return null;

  return (
    <>
      <Stack
        className="contactStack"
        spacing="6"
        mt="6"
        mb={isMobile ? '20' : '-10'}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={schema3}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <SectionCard
                border={
                  !me.onboarding && (!me.name || !me.surname || !me.phoneNumber)
                    ? '1px solid #F25D09'
                    : ''
                }
                onboarding={me?.onboarding}
                title="Datos de contacto"
                subtitle="No revelaremos tus datos sin tu consentimiento."
              >
                <Stack spacing="3">
                  <FormInput name="name" label="Nombre" />
                  <FormInput name="surname" label="Apellidos" />
                  <FormInput name="email" label="Email" isDisabled />
                  <FormLabel mb="2" textStyle="small">
                    Teléfono
                  </FormLabel>
                  <PhoneInput
                    country={'es'}
                    enableSearch
                    isValid={errorPhoneNumber ? false : true}
                    value={phoneNumber}
                    onChange={(phone) => {
                      setphoneNumber(phone);
                      if (errorPhoneNumber) seterrorPhoneNumber(false);
                    }}
                  />
                  {errorPhoneNumber && (
                    <Text mt="2" fontSize="14px" color="#CB1410">
                      Este campo es requerido
                    </Text>
                  )}
                </Stack>
              </SectionCard>

              <Button
                colorScheme="brand"
                mt="6"
                mb="10"
                type="submit"
                disabled={isSubmitting}
                maxWidth="max-content"
              >
                Guardar
              </Button>
            </Form>
          )}
        </Formik>
      </Stack>
    </>
  );
};
