import {
  QuizParts,
  QuizParts_questions,
  QuizParts_questions_options,
} from 'graphql/generated/QuizParts';
import { WithoutNulls, removeNulls } from 'utils/removeNulls';

export const normalizeOption = (
  input: WithoutNulls<QuizParts_questions_options>
) => ({
  id: input.id,
  content: input.content,
});

export type Option = ReturnType<typeof normalizeOption>;

export const normalizeOptions = (
  input: (WithoutNulls<QuizParts_questions_options> | undefined)[]
) =>
  input.reduce<Option[]>(
    (acum, input) =>
      !!input ? acum.concat(normalizeOption(removeNulls(input))) : acum,
    []
  );

export const normalizeQuestion = (
  input: WithoutNulls<QuizParts_questions>
) => ({
  id: input.id,
  content: input.content,
  precode: input.precode,
  language: input.language,
  isMultiple: input.isMultiple,
  options: normalizeOptions(input.options || []),
});

export type Question = ReturnType<typeof normalizeQuestion>;

export const normalizeQuestions = (
  input: (WithoutNulls<QuizParts_questions> | undefined)[]
) =>
  input.reduce<Question[]>(
    (acum, input) =>
      !!input ? acum.concat(normalizeQuestion(removeNulls(input))) : acum,
    []
  );

export const normalizeQuiz = (input: WithoutNulls<QuizParts>) => ({
  id: input.id,
  name: input.name,
  course: { id: input?.course?.id },
  questions: normalizeQuestions(input.questions || []),
});

export type Quiz = ReturnType<typeof normalizeQuiz>;
