import { Box, Center, Divider, Icon, Text, VStack } from '@chakra-ui/react';
import { Percent } from 'components/ModalQuiz/shared/Percent';
import { FiAward } from 'react-icons/fi';

type Evaluation = {
  level: string;
  percent: number;
};

export const ResultsContainer = ({ level, percent }: Evaluation) => {
  return (
    <>
      <Center>
        <Box
          border="1px solid"
          borderColor="gray.200"
          p="5"
          borderRadius="md"
          mb="5"
        >
          <Icon as={FiAward} w={12} h={12} color="blue.400" />
        </Box>
      </Center>

      <VStack textStyle="body" fontSize="20" fontWeight="semibold" mb="5">
        <Text>¡Enhorabuena!</Text>
        <Text>has obtenido el nivel</Text>
        <Text fontWeight="bold" fontSize="30" textAlign="center">
          {level}
        </Text>
      </VStack>

      <Center>
        <Percent percent={percent} />
      </Center>

      <Divider my="3" />

      <Box textAlign="center" color="gray.500">
        <Text>Media</Text>
        <Text>
          Nivel <b>Intermedio</b>
        </Text>
        <Text>60% acertadas</Text>
      </Box>
    </>
  );
};
