import { Formik, Form } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Stack, Text, Button, Link } from '@chakra-ui/react';
import { Layout } from 'containers/Guest/Shared/Layout';
import { DividerWithText } from 'components/DividerWithText';
import { FormInput } from 'components/FormInput';
import { FormInputPassword } from 'components/FormInputPassword';
import { FiLinkedin } from 'react-icons/fi';
import { FiGithub } from 'react-icons/fi';

import useConnect from './connect';

export const Login = () => {
  const {
    handleSubmit,
    initialValues,
    schema,
    handleGithubProvider,
    handleLinkedinProvider,
  } = useConnect();

  return (
    <Layout
      title="Bienvenido a Flyncer"
      subtitle="Introduce tus credenciales para acceder"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <>
            <Stack spacing="6">
              <Button
                textStyle="button"
                colorScheme="linkedin"
                leftIcon={<FiLinkedin />}
                disabled={isSubmitting}
                onClick={handleLinkedinProvider}
              >
                Linkedin
              </Button>
              <Button
                textStyle="button"
                variant="outline"
                colorScheme="black"
                leftIcon={<FiGithub />}
                disabled={isSubmitting}
                onClick={handleGithubProvider}
              >
                GitHub
              </Button>
              <DividerWithText pt="4">O continúa con</DividerWithText>
              <Stack as={Form} noValidate spacing="6">
                <FormInput
                  name="identifier"
                  label="Email"
                  autoComplete="email"
                />
                <FormInputPassword
                  name="password"
                  autoComplete="current-password"
                  label="Contraseña"
                  extraInfo={
                    <Link
                      as={RouterLink}
                      to="/forgot-password"
                      textStyle="bodyLink"
                      color="brand.600"
                    >
                      ¿Olvidaste contraseña?
                    </Link>
                  }
                />
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  colorScheme="brand"
                  textStyle="button"
                >
                  Iniciar sesión
                </Button>
              </Stack>

              <Box mt="3">
                <Text as="span" textStyle="body" mr="2">
                  ¿No tienes cuenta?
                </Text>
                <Link
                  as={RouterLink}
                  to="/register"
                  textStyle="bodyLink"
                  color="brand.600"
                >
                  Registrate ahora
                </Link>
              </Box>
            </Stack>
          </>
        )}
      </Formik>
    </Layout>
  );
};
