import { gql } from '@apollo/client';
import { LEVEL_FRAGMENT } from './level';
import { SKILL_FRAGMENT } from './skill';

export const USER_PATH_FRAGMENT = gql`
  fragment MePathParts on MePath {
    id

    level {
      ...LevelParts
    }

    preLevel {
      ...LevelParts
    }

    hexagon

    path {
      id
      name
      courses {
        id
        name
        estimatedTimeMinutes
        level {
          ...LevelParts
        }
        skills {
          ...SkillParts
        }
        progress
        quizApproved
        blocked
      }
    }
  }
  ${LEVEL_FRAGMENT}
  ${SKILL_FRAGMENT}
`;
