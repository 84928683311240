import { CareerParts } from 'graphql/generated/CareerParts';
import { WithoutNulls } from 'utils/removeNulls';
import { normalizePath, Path } from 'model/Path';
import { Itinerary, normalizeItinerary } from './Itinerary';

export const normalizeCareer = (input: WithoutNulls<CareerParts>) => ({
  id: input.id,
  name: input.name,
  isBasic: input.isBasic || false,
  esRecurso: input.esRecurso || false,
  paths: (input.paths || []).reduce<Path[]>(
    (acum, input) => (!!input ? acum.concat(normalizePath(input)) : acum),
    []
  ),
  itineraries: (input.itineraries || []).reduce<Itinerary[]>(
    (acum, input) => (!!input ? acum.concat(normalizeItinerary(input)) : acum),
    []
  ),
});

export type Career = ReturnType<typeof normalizeCareer>;
