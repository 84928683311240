import { useQuery } from '@apollo/client';
import { useMemo, useRef } from 'react';

import { IsAuth } from 'graphql/generated/IsAuth';
import { IS_AUTH } from 'graphql/queries/isAuth';
import { removeNulls } from 'utils/removeNulls';
import { normalizeIsAuth } from 'model/IsAuth';
import useMeActions from 'graphql/hooks/profile/useMeActions';
import { getCookie } from 'utils/getCookie';
import useAuxRegister from './useAuxRegister';

const useIsAuth = () => {
  const { data, refetch, loading, error } = useQuery<IsAuth>(IS_AUTH);
  const { updateProfile } = useMeActions();
  const reloadsNumber = useRef(0);
  const { auxRegister } = useAuxRegister();
  const cookie = getCookie('amp_306923');

  const isAuth = useMemo(() => {
    const current = removeNulls(data?.me);
    const today = new Date();
    const positionT = today.toISOString().indexOf('T');

    if (data?.me?.id) {
      reloadsNumber.current++;
      if (reloadsNumber.current < 7) {
        updateProfile({
          input: {
            where: {
              id: data.me.id,
            },
            data: {
              lastActive: today.toISOString().substring(0, positionT),
            },
          },
        });
      }
      auxRegister(cookie);
    }
    return current && normalizeIsAuth(current);
  }, [data, updateProfile, auxRegister, cookie]);

  return {
    error,
    isAuth,
    loading,
    refetch,
  };
};

export default useIsAuth;
