import { gql } from '@apollo/client';
import { EXPERIENCE_FRAGMENT } from 'graphql/fragments/userCVExperience';

export const UPDATE_EXPERIENCE = gql`
  mutation UpdateExperience($input: updateExperienceInput!) {
    updateExperience(input: $input) {
      ...ExperienceParts
    }
  }
  ${EXPERIENCE_FRAGMENT}
`;

export const DELETE_EXPERIENCE = gql`
  mutation DeleteExperience($id: ID!) {
    deleteExperience(id: $id) {
      ...ExperienceParts
    }
  }
  ${EXPERIENCE_FRAGMENT}
`;

export const IMPORT_LINKEDIN_EXP = gql`
  mutation ImportLinkedinExp($url: String) {
    importLinkedinExp(url: $url) {
      ...ExperienceParts
    }
  }
  ${EXPERIENCE_FRAGMENT}
`;

export const DELETE_ALL_EXPERIENCES = gql`
  mutation DeleteAllExperiences {
    deleteAllExperiences {
      ...ExperienceParts
    }
  }
  ${EXPERIENCE_FRAGMENT}
`;
